/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 13-ago-2018, 12:47:15
    Author     : PC03-MAXI
*/
.page-clinicas-especializadas{



    section.que-es-vita-care{
        padding-top:6rem;
        padding-bottom:5rem;
        background:#F6F6F6;

        h2{
            color:$color2;
            font-size:3.4rem;
            font-weight:700;
            margin-bottom:2.3rem;
        }


        p{
            color:$color2;
            font-size:1.8rem;
            font-weight:400;
        }

        &:before{


            content:" ";
            width: calc(40% - 1.5rem);
            height: 100%;
            position:absolute;
            left:0;
            top:0;
            background: url('../img/planes-medicos/clinicas-especializadas/vita-care.jpg');
            background-size:cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 0 3rem 0;

            &:before {
                position: relative;
                width: 100%;
                display: block;
                height: 20rem;
                margin-bottom: 2rem;
            }

            h2 {
                font-size: 2.5rem;
                margin-bottom: 1rem;
            }

            p {
                font-size: 1.6rem;
            }
        }
    }

    section.beneficios-vita {
        color:$color1;
        padding-top:8rem;
        padding-bottom:9rem;

        h2{
            font-size:3.4rem;
            font-weight:500;
            color:$color1;
            margin-bottom: 2rem;
        }

        h3{
            color:$color1;
            font-size:2.4rem;
            font-weight:500;
            padding-bottom:2rem;
            border-bottom:1px dotted #94ABB6;
            margin-bottom:2rem;
        }

        ul{
            li{
                position: relative;
                padding-left: 3rem;
                color:$color1;

                &:before {
                    position: absolute;
                    left: 0;
                    top: -0.1rem;
                }
            }
        }
        ul.list-circle{
            li{
                padding-left: 2rem;
                &:before{
                    top: 0.6rem;
                }
            }
        }
        
        .btn-share{
            color: #048086;
        }

        .beneficios-vita-care{
        }

        .condiciones-especialistas{
        }

        @include media-breakpoint-down(sm) {
            padding: 3rem 0;

            h2 {
                font-size: 2.5rem;
                margin-bottom: 1rem;
            }
        }
    }




    section.contacto{
        padding-top:7rem;
        padding-bottom:6rem;

        .icon-top{
            position: absolute;
            top: -7rem; 
        }

        h2{
            font-size:3.4rem;
            font-weight:500;
            margin-bottom:2.8rem;
        }

        .telefonos{
            .tel{
                padding: 2.3rem 0rem ;
                border-right: 1px dotted #94ABB6;

                a{
                    font-size:2rem;
                    font-weight:700;
                }

                .desc{
                    font-size:2rem;
                    font-weight:400;
                    display:block;
                }

                &.no-border{
                    border-right:none;
                }
            }
        }

        .horario-localidades{
            margin-top:2.2rem;
            margin-bottom:2rem;
            border-bottom: 1px dotted #94ABB6;
            border-top: 1px dotted #94ABB6;
            padding: 2.3rem 0rem ;

            .horario{

                font-size:2rem;
                font-weight:bold;

            }

            .btn-localidades{
                padding: 0rem 3rem;
                display: inline-flex;

                i{
                    font-size: 2.5rem; 

                }



            }

        }

        &:before{
            content:" ";
            width: calc(40% - 1.5rem);
            height: 100%;
            position:absolute;
            left:0;
            top:0;
            background: url('../img/planes-medicos/clinicas-especializadas/contacto.jpg');
            background-size:cover;
            background-position: center;
            background-repeat: no-repeat;
        }



        background: $color1;
        color:white;


        @include media-breakpoint-down(sm) {
            padding: 0 0 3rem 0;

            &:before {
                position: relative;
                width: 100%;
                display: block;
                height: 20rem;
                margin-bottom: 2rem;
            }

            h2 {
                font-size: 2.5rem;
                margin-bottom: 1rem;
            }
        }
    }

}

@media only screen and (min-width: 990px) and(max-width: 1575px){
    .page-clinicas-especializadas{
        section.que-es-vita-care{
            &:before{
                width: calc(34% - 1.5rem);
            }
        }
        section.contacto{
            &:before{
                width: calc(34% - 1.5rem);
            }
        }
    }
}