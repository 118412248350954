.page-afiliados-apelaciones-y-querellas{
    a:not(.btn){
        text-decoration: underline;
    }
    section.apelaciones-y-querellas-main{
        p{
            font-size: 1.8rem;
            font-weight: 400;
            color: #003F44;
            margin-bottom: 2rem;
            line-height: 130%;

            .green {
                color: #047F85;
            }
        }
        .box-nombramiento{
            background: rgba(#DDDDDD, 0.2);
            padding: 2rem;
            padding-top: 8rem;
            position: relative;
            &:before{
                content: " ";
                background: url('../img/nombramiento-de-representante.png');
                width: 100%;
                height: 8rem;
                position: absolute;
                top: 0rem;
                left: 0rem;
            }
            h2{
                font-size: 3.3rem;
                font-weight: 700;
                color: #003F44;
                margin-bottom: 0.5rem;
            }
            h3{
                font-size: 2rem;
                font-weight: 700;
                color: #003F44;
                margin-bottom: 0.5rem;
            }
            p{
                font-size: 2rem;
                font-weight: 400;
                line-height: 120%;
                color: #003F44;
                margin-bottom: 1rem;
            }
        }
        h4 {
            color: #003F44;
            font-size: 2.4rem;
            font-weight: 500;
            margin-bottom: 1rem;

            &.green {
                color: #047F85;
            }
        }
        .border-left {
            border-left: #94ABB6 dotted 1px;
            padding-left: 2.5rem;
        }
        .border-top {
            border-top: #94ABB6 dotted 1px;
            padding-top: 3rem;
            margin-top: 3rem;
        }
        h2 {
            color: #047F85;
            font-size: 3.4rem;
            font-weight: 500;
            margin-bottom: 2rem;
        }
        h5 {
            color: #333;
            font-size: 2rem;
            font-weight: 500;
            margin-bottom: 2rem;
        }
        .box-solicitud {
            background: #F4F4F4;
            margin: 3rem 0 4rem 0;

            h2 {
                color: #FFF;
                background: #047F85;
                font-size: 1.8rem;
                font-weight: 500;
                text-align: center;
                padding: 1.5rem 3rem;
            }
            .documents-container {
                border: 0;
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 2rem;
            }
        }
        .btn-open {
            margin-bottom: 1px;
            padding-left: 3rem;
            position: relative;

            &:before {
                content: '';
                display: block;
                height: 1px;
                background: rgba(#003F44, 0.12);
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                margin-top: -1px;
                z-index: 0;
            }
            &:hover {
                color: #FFF;
            }
            .btn {
                text-align: left;
                min-width: 51rem;
                color: #FFF;

                svg {
                    margin-top: 0.75rem;
                    float: right;
                    margin-left: 3rem;
                    fill: #FFF;
                    width: 2.4rem;
                    height: 2.4rem;
                }
            }
        }
    }

    .tab-content{
        border-left: 1px solid #444444;
        /*padding: 3.5rem 0rem;*/
        .card{
            border: none;
            .card-header{
                border: none;
                padding: 0;
                background: none;
                button{
                    transition: all .2s ease-out;
                    font-size: 1.6rem;
                    font-weight: 400;
                    color: #FFF;
                    padding: 0rem 1.25rem 0rem 3rem;
                    text-align: left;
                    background: #047F85;
                    line-height: 5.5rem;
                    height: auto;
                    text-transform: none;
                    width: 100%;

                    &:hover, &:focus{
                        text-decoration: none;
                    }
                    &:after{
                        content: ' ';
                        position: absolute;
                        background: #047F85;
                        transition: all .2s ease-out;
                        opacity: 1;
                        height: 100%;
                        width: 0.5rem;
                        left: -0.3rem;
                        top: 0;
                    }
                    &.collapsed{
                        color: #047F85;
                        background: none;
                        font-size: 1.6rem;
                        font-weight: 400;
                        padding-left: 1.5rem;
                        line-height: 3rem;
                        /*margin-bottom: 1rem;*/
                        &:before{
                            content: "+";
                            font-size: 3rem;
                            font-weight: 300;
                            position: relative;
                            top: 0.3rem;
                            margin-right: 0.8rem;
                        }
                        &:after{
                            height: 0;
                        }
                    }
                }
            }
            .card-body{
                padding: 3rem;

                h2 {
                    margin: 0 0 0.5rem 0;
                    color: #047F85;
                    font-size: 2rem;
                    font-weight: 500;
                }
                ul {
                    color: #333333;
                    font-size: 1.6rem;
                    font-weight: 400;
                    margin: 0;
                    padding: 0;
                    margin-left: 8rem;
                    margin-bottom: 3rem;
                    list-style: none;

                    li {
                        margin-bottom: 0rem;
                        position: relative;

                        &:before {
                            content: '';
                            display: inline-block;
                            width: 1rem;
                            height: 1rem;
                            border: #047F85 solid 2px;
                            border-radius: 100%;
                            margin-left: -2.5rem;
                            float: left;
                            margin-top: 0.7rem;
                            opacity: 0.54;
                        }
                    }
                }
                h3{
                    font-size: 2.4rem;
                    font-weight: 500;
                    color: #003F44;
                    margin-bottom: 1.5rem;
                }
                h4{
                    font-size: 2rem;
                    font-weight: 500;
                    color: #047F85;
                    margin-bottom: 1rem;
                }
                h5{
                    font-size: 1.6rem;
                    margin-bottom: 1.5rem;
                }
                p{
                    font-size: 1.6rem;
                    color: #333;
                    line-height: 2rem;

                    .green {
                        color: #047F85;
                        font-size: 1.8rem;
                    }
                    a{
                        color: #047F85;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .page-afiliados-apelaciones-y-querellas{
        section.apelaciones-y-querellas-main{
            .btn-open{
                padding-left: 0rem;
                &:before{
                    display: none;
                }
                .btn{
                    min-width: 100%;
                }
            }
            .border-left{
                border: none;
                padding-left: 1.5rem;
            }
            h4.green{
                margin-bottom: 2rem;  
            }
            .box-nombramiento{
                padding-top: 6rem;
                &:before{
                    background-repeat: no-repeat;
                    background-size: 100%;
                }
                h2{
                    font-size: 2.4rem;
                }
                h3{
                    font-size: 1.8rem;
                }
                p{
                    font-size: 1.8rem;
                }
                a{
                    font-size: 3.4vw;
                }
            }
            .box-solicitud{
                h2{
                    margin-bottom: 0rem;
                }
                .documents-container{
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
        .tab-content{
            .card{
                .card-header{
                    button{
                        white-space: normal;
                        &:not(.collapsed){
                            line-height: 150%;
                            padding-top: 1.5rem;
                            padding-bottom: 1.5rem;
                        }
                    }
                }
                .card-body{
                    ul{
                        margin-left: 2.5rem;
                    }
                }
            }
        }
    }   
}