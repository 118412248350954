.page-determinaciones-cubierta{
    
    section.box-file{
        padding-top: 0rem;
        .box-file{
            position: absolute;
            top: 0rem;
            left: 1.5rem;
            background: url('../img/icons/box-file.png');
            width: 65px;
            height: 57px;
        }
        .container{
            position: relative;
        }
    }
    section.determinaciones-de-cubierta{
        ul{
            margin-bottom: 4rem;
            li{
                list-style: none !important;
                font-size: 2rem;
                line-height: 140%;
                color: #047F85;
                margin-bottom: 2rem;
                position: relative;
                padding-left: 3rem;
                &:before{
                    position: absolute;
                    left: 0rem;
                }
            }
        }
        h1{
            font-size: 6rem;
            font-weight: 500;
            color: #047F85;
            margin-bottom: 1rem;
        }
        p.subtitle{
            font-size: 2rem;
            font-weight: 500;
            color: #047F85;
            margin-bottom: 3rem;
        }
        h4{
            font-size: 2.4rem;
            font-weight: 700;
            color: #047F85;
            margin-bottom: 3rem;
        }
        p{
            font-size: 2rem;
            line-height: 140%;
            color: #047F85;
        }
        a.btn.green{
            margin-top: 5rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            i{
                font-size: 2.6rem;
            }
        }
    }

    section.formas{
        padding-top: 0rem;
        background: #F0F3F4;
        .box-headset{
            position: absolute;
            top: 0rem;
            left: 1.5rem;
            background: url('../img/icons/box-headset.png');
            width: 65px;
            height: 57px;
        }
        .container{
            position: relative;
        }
        .formas-info{
            margin-top: 8rem;
            h2{
                font-size: 3.4rem;
                font-weight: 500;
                color: #048086;
            }
            .llamanos{
		border-top: #94ABB6 dotted 1px;
		padding-top: 2.5rem;
                display: flex;
                align-items: center;
                svg{
                    width: 4rem;
                    height: 4rem;
                    fill: #048086;
                    margin-right: 2rem;
                }
                h4{
                    font-size: 2.4rem;
                    font-weight: 500;
                    margin-bottom: 0;
                    color: #047F85;
                }
                p{
                    font-size: 2rem;
                    color: #003F44;
                    a{
                        color: #047F85;
                        text-decoration: underline;
                    }
                }
            }
            .faxs{
                /*margin-top: 7rem;*/
                /*border-bottom: 1px dotted #94ABB6;*/
                padding-bottom: 4.5rem;

                .icon-download {
                    position: absolute;
                    top: -8rem;
                }

                &.border-none{
                    border: none;
                }
                h4{
                    font-size: 2rem;
                    font-weight: 500;
                    line-height: 130%;
                    color: #047F85;
                    /*width: 75%;*/

                }
                .fax-item{
                    border-left: 1px dotted #94ABB6;
                    padding-top: 1rem;
                    padding-left: 2.5rem;
                    &:first-child, &.border-none{
                        border: none;
                        padding-left: 1.5rem;
                    }
                    h3{
                        font-size: 2.4rem;
                        font-weight: 500;
                        color: #003F44;
                        line-height: 100%;
                    }
                    h4{
                        font-size: 2rem;
                        font-weight: 500;
                        color: #003F44;
                        line-height: 100%;
                        margin-bottom: 3.5rem;
                    }
                }
                p{
                    font-size: 2rem;
                    color: #003F44;
                    line-height: 120%;
                    span.fw-medium{
                        font-weight: 500;
                    }
                }
                .solicitud{
                    display: flex;
                    align-items: center;
                    margin-bottom: 1rem;
                    svg{
                        margin-right: 1.5rem;
                    }
                    span{
                        font-size: 2.4rem;
                        font-weight: 500;
                        color: #047F85;
                    }
                }
            }
            .faxs-new{
                .fax-item{
                    p{
                        font-size: 1.8rem;
                        color: #003F44;
                    }
                }
            }
            ul{
                li{
                    &:before{
                        content: " ";
                        border: 2px solid #048086;
                        border-radius: 10rem;
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        left: 0;
                        top: 0.8rem;
                    }
                    list-style: none;
                    position: relative;
                    padding-left: 2rem;
                    font-size: 1.8rem;
                    color: #333;
                    line-height: 140%;
                    margin-bottom: 1.5rem;
                }
            }
            .envios{
                h4{
                    width: 45%;
                }
                ul{
                    li{
                        font-size: 1.8rem;
                        font-weight: 500;
                        margin-bottom: 1rem;
                    }
                }
                p{
                    font-size: 1.8rem;
                    color: #003F44;
                }
            }
        }
    }
}

section.excepciones{
    padding-top: 0rem;
    
    .btn-open {
	margin-bottom: 1px;
	padding-left: 3rem;
	position: relative;

	&:before {
	    content: '';
	    display: block;
	    height: 1px;
	    background: rgba(#003F44, 0.12);
	    position: absolute;
	    left: 0;
	    right: 0;
	    top: 50%;
	    margin-top: -1px;
	    z-index: 0;
	}
	&:hover {
	    color: #FFF;
	}
	.btn {
	    text-align: left;
	    min-width: 51rem;
	    color: #FFF;
            position: relative;
	    svg {
		margin-top: 0.75rem;
		float: right;
		margin-left: 3rem;
		fill: #FFF;
		width: 2.4rem;
		height: 2.4rem;
	    }
	}
    }
    ul {
	color: #003F44;
	font-weight: 400;
	font-size: 1.8rem;
	margin: 0;
	list-style: none;
	padding: 0;

	&.download {
	    li {
		color: #047F85;
		padding-left: 0;

		a {
		    color: #047F85;
		}
		&:before {
		    display: none;
		}
	    }
	}
	li {
	    padding-left: 2.5rem;
	    position: relative;
	    margin-bottom: 0.5rem;

	    &:before {
		content: '';
		display: block;
		position: absolute;
		width: 10px;
		height: 10px;
		border: #003F44 solid 2px;
		border-radius: 100%;
		left: 0;
		top: 0.75rem;
	    }
	    ul {
		margin-top: 0.5rem;
		margin-left: 3rem;

		li {
		    &:before {
			background: #003F44;
			border-color: #FFF;
		    }
		}
	    }
	}
    }
    .box-alert{
        position: absolute;
        top: 0rem;
        left: 1.5rem;
        background: url('../img/icons/box-alert.png');
        width: 65px;
        height: 57px;
    }
    .container{
        position: relative;
    }

    .excepciones-info{
        margin-top: 8rem;
        h2{
            font-size: 3.4rem;
            font-weight: 500;
            color: #047F85;
            margin-bottom: 1.5rem;
        }
        h4{
            font-size: 2.4rem;
            color: #047F85;
            margin-bottom: 4.5rem;
        }
        p{
            font-size: 1.8rem;
            color: #003F44;
            line-height: 120%;
            strong{
                font-weight: 700;
            }
        }
        .btn-conoce-mas{
            position: relative;
            margin-top: 5rem;
            &:before{
                content: " ";
                width: 100%;
                position: absolute;
                height: 1px;
                background: #003F44;
                top: 2rem;
            }
            a{
                padding-left: 4rem;
                padding-right: 4rem;
                margin-left: -2rem;
                font-size: 1.6rem;
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .page-determinaciones-cubierta{
        section.determinaciones-de-cubierta{
            padding-top: 6rem;
            h1{
                font-size: 3rem;
            }
            p.subtitle{
                font-size: 1.8rem;
            }
            h4{
                font-size: 2.2rem;
            }
            ul{
                li{
                    font-size: 1.8rem;
                    margin-bottom: 1.5rem;
                }
            }
            p{
                font-size: 1.8rem;
            }
        }
        section.formas{
            h2{
                font-size: 3rem;
            }
            .formas-info{
                .faxs{
                    margin-top: 2rem;
                    padding-bottom: 0rem;
                    .fax-item{
                        margin-bottom: 2rem;
                        padding-left: 1.5rem;
                        h3{
                            font-size: 2.2rem;
                        }
                        h4{
                            font-size: 1.8rem;
                            margin-bottom: 2rem;
                        }
                        p{
                            font-size: 1.8rem;
                        }
                    }
                }
                .envios{
                    h4{
                        width: 100%;
                    }
                }
            }
        }
        
        section.excepciones{
            padding-bottom: 3rem;
            h2{
                font-size: 3rem;
            }
            .excepciones-info{
                .btn-open{
                    padding-left: 0rem;
                    a.btn{
                        min-width: 100%;
                        font-size: 1.4rem;
                    }
                }
                .btn-conoce-mas{
                    margin-top: 3rem;
                    a{
                        padding-left: 0rem;
                        padding-right: 0rem;
                        width: 100%;
                        margin-left: 0rem;
                    }
                }
            }
        }
    }
}