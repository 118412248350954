/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Clear  */

.clear:after { content: ''; line-height: 0; display: table; clear: both; }


/*  Notext  */

.notext { white-space: nowrap; text-indent: 100%; text-shadow: none; overflow: hidden; }
.notext-old { font-size: 0; line-height: 0; text-indent: -4000px; }


/*  Hidden  */

[hidden],
.hidden { display: none; }


/*  Alignleft  */

.alignleft { float: left; }


/*  Alignright  */

.alignright { float: right; }


/*  Disabled  */

[disabled],
.disabled { cursor: default; }

/*  Background  */

.container-fullsize { background-size: cover; background-repeat: no-repeat; background-position: center center; }

/*  Animations  */

.animations { opacity: 0; }
.animations.animated { opacity: 1; }

/*  Grid  */

.cols { margin: 0 -15px; }
.cols-flex { display: flex; }
.cols-flex .col { width: auto; }

.row:after,
.cols:after { @include cf; }

.col { float: left; width: 100%; padding: 0 15px; }

.col-1of2 { width: 50%; }

/*  Mobile only  */

.mobile-only { display: none; }

@media(max-width: $mobile){
	.desktop-only { display: none; }
	.mobile-only { display: block; }
	.animations { opacity: 1; }
}
