/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 30-jun-2018, 14:08:49
    Author     : Rodri
*/

.section-proveedores {
    padding-top: 10rem;

    .icon-top {
        position: absolute;
        top: -10rem;

    }


    .container {
	position: relative;
    }

    h2 {
	display: flex;
	align-items: center;
	color: $color2;
	font-size: 3.4rem;
	font-weight: 500;
	margin-bottom: 1.5rem;

	i {
	    margin-right: 1.5rem;
	    background: $va_dark;
	    width: 6.5rem;
	    height: 5.7rem;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	}
	&.light {
	    font-weight: 400;
	    margin-bottom: 2rem;
	}
    }
    h4 {
	color: $va_dark;
	font-size: 2rem;
	font-weight: 500;
	margin-bottom: 1rem;
    }
    p {
	color: $va_dark;
	font-size: 1.8rem;
	font-weight: 400;
	margin-bottom: 1.5rem;
    }

    p.more-info{
	font-weight: 500;
	border-bottom: #94ABB6 dotted 1px;
	padding-bottom: 1rem;
    }
    ul {
	margin: 1.5rem 0 1.5rem 0;
	padding: 0;
	list-style: none;

	a {
	    color: #047F85;
	    font-size: 1.8rem;
	    text-decoration: underline;
	    margin-bottom: 1.5rem;
	    font-weight: 400;

	    &.download {
		/*padding-left: 3.6rem;*/
		/*background: url(../img/icon-list-download.svg) left 5px no-repeat;*/
		align-items: flex-start;
		display: flex;

		span {
		    flex: 1;
		}
	    }
	}
	&.open {
	    padding-left: 3.6rem;
	    background: url(../img/icon-list-open.svg) left 5px no-repeat;
	}
    }
    .bb-gray {
	border-bottom: #94ABB6 dotted 1px;
    }
    .br-gray {
	border-right: #94ABB6 dotted 1px;
    }

    @include media-breakpoint-down(sm) {
	padding-top: 7rem;

	.icon-top {
	    top: -7rem;
	}

	h2 {
	    font-size: 2.6rem;
	}
	ul.download {
	    padding-left: 0 !important;
	}
    }
}

.box-dudas {
    margin-top: 3rem;
    background: #048086;

    .gray {
	border-left: $va_dark solid 3px;
	border-right: $va_dark solid 3px;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	background: #F4F4F4;

	.icon-zone {
	    display: flex;
	    align-items: center;
	    margin-left: -7.5rem;
	    border-right: #94ABB6 dotted 1px;
	}
	i {
	    background: #FFF;
	    display: flex;
	    width: 12rem;
	    height: 12rem;
	    justify-content: center;
	    align-items: center;
	    border-radius: 100%;
	    margin-right: 1.5rem;
	}
	h2 {
	    color: #047F85;
	    font-size: 3.4rem;
	    line-height: 3.6rem;
	    flex: 1;
	}
	h4 {
	    color: $va_dark;
	    font-size: 2rem;
	    font-weight: bold;
	    margin-bottom: 1rem;
	}
    }

    p {
	color: $va_dark;
	font-size: 2rem;
	line-height: 2.4rem;
	font-weight: 400;

	&.white {
	    color: white;
	    padding: 1rem;
	}
    }

    @include media-breakpoint-down(sm) {
	.gray {
	    border-top: #003F44 solid 3px;
	    
	    .icon-zone {
		border: 0;
		margin-left: 0;
		margin-bottom: 1rem;

		i {
		    width: 6rem;
		    height: 6rem;
		    padding: 1.5rem;
		}
	    }
	    h2 {
		font-size: 2.4rem;
		line-height: 2.6rem;
	    }
	}
    }
}