/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 23-jul-2018, 16:19:01
    Author     : Rodri
*/

.section-detalle-noticias {
    h2 {
        color: #003F44;
        font-size: 3.4rem;
        margin-bottom: 1.5rem;
        line-height: 120%;

        img {
            display: inline-block;
            margin-right: 1rem;
        }
    }
    h3 {
        color: #666;
        font-size: 2rem;
        margin-bottom: 2rem;
    }
    h5{
        color: #666;
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }
    img.noticia-img{
        width: 100%;
        margin-bottom: 2.5rem;
    }
    p {
        color: #666;
        font-size: 1.6rem;

        & + h3 {
            margin-top: 2rem;
        }
    }
    
    .swiper-pagination{
        bottom: 25px;
        .swiper-pagination-bullet-active{
            background: #003F44;
        }
    }

    .platino-logo{

        max-width: 18rem;

    }


    .noticia-item {
        .cont, .btn {
            display: none;
        }
        .title {
            color: #047F85;
            font-size: 2.4rem;
        }
    }
    .aside {
        position: relative;
        h3 {
            margin-bottom: 2rem;
        }

        .noticia-item {
            margin-bottom: 3rem;
            padding-bottom: 1.5rem;
            border-bottom: #94ABB6 solid 1px;

            .title {
                color: #003F44;
                font-size: 1.6rem;
            }
        }

        form {
            margin-bottom: 3.5rem;
            display: flex;
            width: 100%;

            input {
                flex: 1;
                background: #EEEEEE;
                border: 0;
                height: 5.4rem;
                padding: 0 1.5rem;
                color: #003F44;
                font-size: 1.6rem;
                font-weight: 500;
            }
            button {
                cursor: pointer;
                text-align: center;
                background: #003F44;
                border: 0;
                display: block;
                width: 5.2rem;
                height: 5.4rem;
                margin: 0;
                transition: all .2s ease-out;
                float: none;

                &:hover {
                    background: #048086;
                }

                i {
                    color: #FFF;
                    font-size: 2.5rem;
                    display: inline-block;
                    line-height: 5.4rem;
                }
            }
        }

        a.back{
            font-size: 1.8rem;
            right: 0;
            color: #666;
            margin-bottom: 2.5rem;
            text-align: right;
            line-height: 3.3rem;
            margin-left: auto;
            display: block;
            width: 13rem;
            i{
                font-size: 2.8rem;
                position: relative;
                top: 4px;
            }
        }
    }
    .download {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            font-size: 1.6rem;
            line-height: 2rem;
            color: #003F44;
            margin-bottom: 1rem;
            background: url('../img/icons/ul_referencias.png') top left no-repeat;
            padding-left: 3.7rem;
        }
    }

    .top-bar-cont{
        position: relative;
        .share {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            justify-content: flex-end;
            p {
                margin: 0 1.5rem 0 0;
            }
        }
    }

    .comments {
        margin-top: 4rem;
    }
}