/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 17-may-2018, 12:08:45
    Author     : codigowww
*/

#smartwizard{
   box-shadow: none;

    .nav-tabs {
        display: none;
    }


    
    
    .sw-toolbar{
        background:transparent;
    }
    
    .step-content{
        
        background:transparent;
    }
    
}