/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 15-may-2018, 17:15:24
    Author     : PC03-MAXI
*/

.cards-1{

    .card{
        border: 0;
        /*        position: relative;
                padding-bottom: 3rem;
                border-radius: 0;
                border-color: transparent;
                background-color: transparent;
                height: 100%;
                overflow:hidden;*/


        position: relative;
        /* padding-bottom: 3rem; */
        border-radius: 0;
        border-color: transparent;
        background-color: transparent;
        width: 100%;
        height: 100%;
        overflow: hidden;


        .img-background{
            position: absolute;
            z-index: 0;
            position: absolute;
            height: auto;
            max-width: unset;
            min-height: 100%;
            display: none;
            /*width: 100%;*/
            /*            padding-bottom: 3rem;*/
        }


        .card-body{
            position: relative;
            width: 52%;
            max-width: 35rem;
            background: rgba($va_dark,0.9);
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 2rem;
            height: 35rem;
            @include media-breakpoint-down(sm){
                width: 55%;
            }
        }
        .card-title{
            font-size:1.8rem;
            color:rgba(white,.92);
            font-weight:500;
            margin-bottom:1.5rem;
            line-height: 2rem;
            width: 100%;
        }

        .card-subtitle{
            font-size:1.5rem;
            color:white;
            font-weight:500;
            margin-bottom: 1.5rem !important;
            line-height: 1.7rem;
        }


        p{
            color: white;
            margin-bottom: 0;
            font-weight:500;
        }
        ul{
            color:white;
            list-style-position: inside;
            font-weight:500;
        }

        .card-link{
            background: $va_light;
            color: white;
            font-size: 1.4rem;
            font-weight: bold;
            margin-left:-2rem;
            margin-top: auto;
            align-self: flex-start;

            &:hover {
                background: #FFF !important;
                color: $va_dark !important;
            }
        }



        &.right-content{
            display:flex;
            height: 100% !important;
            /*            flex-direction: row;*/


            .icon-top{
                position: absolute;
                top: 0;
                left: 2rem; 
                background: #EEEEEE;
                padding: 1rem;
                z-index: 1000;
            }


            .card-title{
                font-size:3rem; 
                line-height: 3.8rem;
                margin-bottom: 1rem;
            }

            .img-background{
                width: auto;
            }
            .card-body{
                /*              position: relative;
                                right: 0;
                                width: 70%;
                                background: $color2;
                                max-width: none;*/
                position: relative;
                /* right: 0; */
                /* width: 32%; */
                /* max-width: 33rem; */
                /* float: right; */
                background: $color2;
                /* max-width: none; */
                align-self: flex-end;
                max-width: 50rem;

                p{
                    font-size: 1.4rem;
                }
                ul{
                    li{
                        font-size: 1.4rem;
                    }
                }
            }

            .card-subtitle{
                width: 80%;
            }

            .btn{
                align-self: flex-start;
                margin-top: auto;
                font-size: 1.4rem;
            }






            @include media-breakpoint-down(lg) { 
                flex-direction: column;
                .card-title{
                    font-size:2.4rem;
                }

                .card-body{
                    position: relative;
                    left: 0;
                    width:100%;
                    max-width: unset;
                    .btn{
                        margin-top:2rem;
                    }
                }

                .card-subtitle{
                    width: 100%;
                }
                .img-background{
                    position: relative;
                    width: 100%;
                    height: auto;
                    min-height: auto;
                }

            }

            @include media-breakpoint-down(sm) {
                .card-body{
                    height: 100%;
                    padding-top: 6rem;
                }
            }

        }

        @include media-breakpoint-down(sm) {
            height: auto !important;
            margin-bottom: 1.5rem;

            .card-title {
                font-size: 2rem;
                line-height: 2.6rem;
            }
            .card-subtitle {
                font-size: 1.4rem;
            }
            .card-body{
                height: 100%;
            }
        }
    }


}

.swipper-slider-card{
    width: 100%;
    height: 100%;
    .swiper-wrapper{
        max-height: 35rem;
    }
    .swiper-slide{
        max-height: 35rem;
    }
    .swiper-pagination{
        right: 2rem;
        left: auto;
        /* bottom: 0; */
        /* margin: auto; */
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .swiper-pagination-bullet{
            margin: 4px 0px;
            background:white;
            opacity: 1;
        }

        .swiper-pagination-bullet-active{
            background:$color1;
        }
    }    
    @include media-breakpoint-down(lg) { 

        .swipper-slider-card{
            .swiper-pagination{
                position:relative;

                left: 0;
                width: 100%;
                display: flex;
                flex-direction: row;

                margin-top: 2rem;
                align-items: center;
                justify-content: center;

            }

            .swiper-pagination-bullet{
                margin: 0px 4px!important;

            }
        }

    }
}

.swipper-slider-card{
    ul{
        li{
            padding-right: 2rem;
        }
    }
}

.cards-2{



    .card-col{
        margin-bottom:3rem;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }
    }

    .card{
        position:relative;
        min-height: 23rem;
        border-radius:0;
        border-color:transparent;
        /*        display: flex;
                flex-direction: row;*/
        height: 100%;

        // padding-bottom:3rem;

        /*   .img-background{
               position:absolute;
           }*/

        @include media-breakpoint-down(lg) { 
            margin-bottom: 2rem;
        }
        .card-title{
            font-size:1.8rem;
            color:$va_light;
            font-weight:500;
            margin-bottom: 4rem;
        }

        .card-subtitle{
            color: #000;
            font-size: 1.5rem;
            font-weight: 400;
            margin-bottom: 2rem !important;

            a {
                color: #047F85;
                text-transform: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }



        .icono{
            position: absolute;
            right: 3rem;
            bottom: 2rem;
        }

        .card-link{
            margin-left: 0;
            position: relative;
            left: -21px;
            margin-top: auto;
            align-self: flex-start;
            font-size: 1.5rem;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            font-weight: 700;
        }
        .card-body{
            padding: 2rem;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-start;
            padding-top: 3rem;
            padding-bottom: 3rem;
        }


        &.green {
            background: $va_dark;
            color: white;

            .card-body {
                padding-top: 2rem;
                align-items: flex-start;
                flex-direction: column;
            }
            .card-title, .card-subtitle {
                color: white;
                margin-bottom: 1.5rem !important;
            }
            .card-link {
                color: white;
                background: $va_light;

                &:hover {
                    background: #FFF !important;
                    color: $va_dark !important;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            height: auto !important;
            min-height: 0;
            margin-bottom: 1.5rem;

            .card-title {
                font-size: 2.2rem;
                line-height: 2.6rem;
            }
            .card-subtitle {
                font-size: 1.6rem;
            }
        }
    }

}

@media only screen and (max-width: 1360px) and (min-width: 1100px){
    .cards-1{
        .card{
            .card-body{
                width: 55%;
                .card-title{
                    font-size: 18px;
                    line-height: 21px;
                }
                .card-subtitle{
                    font-size: 15px;
                    line-height: 17px;
                }
            }
        }
    }
}