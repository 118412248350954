section.programa-manejo-de-terapia-box-heart{
    padding-top: 0rem;
    .box-heart{
        position: absolute;
        top: 0rem;
        left: 1.5rem;
        background: url('../img/icons/box-heart.png');
        width: 65px;
        height: 57px;
    }
    .container{
        position: relative;
    }
}

section.programa-manejo-de-terapia-main{
    p{
        font-size: 1.6rem;
        color: #047F85;
        margin-bottom: 2rem;
        &.p-20{
            font-size: 2rem;
        }
        span.fw-medium{
            font-weight: 500;
        }
    }
}

section.programa-manejo-de-terapia-criterios{
    background: #F6F6F6;
    h2{
        font-size: 3.4rem;
        font-weight: 500;
        color: #047F85;
    }
    p.subtitle{
        font-size: 1.8rem;
        line-height: 140%;
        color: #003F44;
    }
    .lists{
        ul{
            padding-left: 1.5rem;
        }
        &>ul{
            &.no-list-style{
                &>li{
                    &:before{
                        display: none;
                    }
                    &>ul{
                        &>li{
                            &>ul{
                                &>li{
                                    &:first-child{
                                        margin-top: 0rem;
                                    }
                                }
                            }
                        }  
                    }
                }
            }
            li{
                font-size: 1.6rem;
                color: #333;
                line-height: 140%;
                list-style: none !important;
                position: relative;
            }
            &>li{
                margin-bottom: 3.5rem;
                padding-left: 0.5rem;
                &:before{
                    content: " ";
                    border: 2px solid #048086;
                    border-radius: 10rem;
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    left: -1.5rem;
                    top: 0.5rem;
                    opacity: .54;
                }
                &>ul{
                    margin-bottom: 2rem;
                    margin-top: 0.5rem;
                    &>li{
                        line-height: 160%;
                        &>ul{
                            padding-left: 4.5rem;
                            margin-bottom: 2rem;
                            margin-top: 0.5rem;
                            &>li{
                                list-style: disc !important;
                                margin-top: 0.5rem;
                            }
                        }
                    }
                }
            }
        }
        .legal{
            font-size: 2rem;
            font-weight: 500;
            color: #047F85;
            line-height: 120%;
            position: relative;
            padding-left: 4rem;
            padding-right: 2rem;
            i.mdi{
                font-size: 3.5rem;
                position: absolute;
                top: 0rem;
                left: 0rem;
            }
        }
        a.share{
            color: #003F44;
            font-size: 1.6rem;
            font-weight: 500;
            display: flex;
            margin-top: 2rem;
            align-items: center;
            text-align: right;
            justify-content: flex-end;
            i{
                font-size: 3rem;
                margin-right: 1rem;
            }
        }
    }
}

section.programa-manejo-de-terapia-servicios{
    background: #FFF;
    a.descarga{
        font-size: 1.8rem;
        font-weight: 500;
        color: #003F44;
        display: flex;
        align-items: center;
        line-height: 130%;
        svg{
            margin-right: 1rem;
            display: inline-block;
        }
    }
    .para-mas-info{
        h4{
            font-size: 2rem;
            font-weight: 500;
            color: #003F44;
            padding-bottom: 0.5rem;
            border-bottom: 1px dotted #94ABB6;
            margin-bottom: 1rem;
        }
        p{
            font-size: 1.8rem;
            color: #003F44;
        }
    }
}

@include media-breakpoint-down(sm){
    section.programa-manejo-de-terapia-main{
        padding-top: 6rem;
    }
    section.programa-manejo-de-terapia-criterios{
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        h2{
            font-size: 3rem;
            margin-bottom: 1.5rem;
        }
        .lists{
            .legal{
                padding-right: 0rem;
            }
        }
    }
    section.programa-manejo-de-terapia-criterios{
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        h2{
            font-size: 3rem;
            margin-bottom: 1.5rem;
        }
        a.descarga{
            margin-top: 2.5rem;
        }
    }
    section.programa-manejo-de-terapia-servicios{
        .lists{
            ul{
                li{
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
}