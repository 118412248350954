/* ------------------------------------------------------------ *\
	Mixins
\* ------------------------------------------------------------ */

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}

@mixin transition($transitions...) {
  -webkit-transition: $transitions;
          transition: $transitions;
}

@mixin cf { content: ''; line-height: 0; display: table; clear: both; }

@mixin chevron($dimensions, $borderWidth, $borderColor, $margin, $rotation, $origin, $transitionDuration) {
  content: '';
  width: $dimensions;
  height: $dimensions;
  display: inline-block;
  vertical-align: middle;
  border-width: 0 0 $borderWidth $borderWidth;
  border-style: solid;
  border-color: $borderColor;
  margin: $margin;
  transform: rotate($rotation);
  transform-origin: $origin;
  transition: transform $transitionDuration;
}

@mixin full { position: absolute; top: 0; bottom: 0; left: 0; right: 0; }
@mixin center { position: absolute; top: 50%; transform: translate(-50%, -50%); left: 50%; }
