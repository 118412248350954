/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 04-sep-2018, 17:38:31
    Author     : Rodri
*/

.afiliados-pago-primas {
    background: rgba(#97ACB7, 0.1);

    h1 {
        color: #003F44;
        font-size: 4.6rem;
        font-weight: 600;
    }
    h2 {
        color: #003F44;
        font-size: 2.8rem;
        font-weight: 400;
        margin-bottom: 3rem;
    }
    p {
        align-items: flex-start;
        color: #003F44;
        font-size: 2rem;
        font-weight: 400;

        &.line {
            font-size: 1.8rem;
            border-top: #94ABB6 dotted 1px;
            padding-top: 3rem;
            margin-top: 4rem;
            display: flex;

            i {
                margin-top: 0.5rem;
                background: #003F44;
                width: 6rem;
                height: 6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 1.5rem;

                svg {
                    width: 3rem;
                    height: 3rem;
                    fill: #FFF;
                }
            }
            span {
                flex: 1;
            }
            
        }
    }
    
    .lh-2{
        line-height: 2rem;
    }
    .card-white {
        position: relative;
        min-height: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 6rem 2.5rem;
        text-align: center;

        &.pt {
            padding-top: 8rem;
            padding-bottom: 4rem;
            justify-content: flex-start;
            align-items: flex-start;
            p{
                font-size: 1.6rem;
                &.fz-20{
                    font-size: 2rem;
                }
            }
        }
        .box-svg{
            background: #048086;
            width: 5.5rem;
            height: 5.5rem;
            position: absolute;
            left: 2.5rem;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                color: #FFF;
                font-size: 3.2rem;
            }
        }
        i {
            position: absolute;
            left: 2.5rem;
            top: 0;
            padding: 1rem;
            background: #048086;

            img {
                margin: 0;
            }
        }
        p {
            color: #047F85;
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin: 0;

            strong {
                display: block;
                font-size: 2rem;
                margin-bottom: 1.5rem;
            }
        }
        img {
            margin-bottom: 1.5rem;
        }
    }
    .swiper {
        position: relative;
    }
    .swiper-button-next,
    .swiper-button-prev {
        background: transparent;
        width: 3.5rem;
        height: 3.5rem;
        margin-top: -1.75rem;
        border: rgba(#003F44, 0.5) solid 2px;
        border-radius: 100%;
        svg {
            fill: #003F44;
        }
    }
    
    .swiper-button-disabled{
        display:none!important;
        
    }
    .swiper-button-next {
        right: -5rem;
    }
    .swiper-button-prev {
        left: -5rem;
    }

    ul.more-info{
        margin-top: 3rem;
        font-weight: 500;
        font-size: 1.5rem;
        li{
            margin-bottom: 0.5rem;
        }
    }

    @include media-breakpoint-down(sm) {
        h1 {
            font-size: 2.5rem;
        }
        .card-white {
            margin-bottom: 1.5rem;
            min-height: 0;
        }
    }
}