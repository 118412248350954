section.cubierta-fuera-de-red-de-farmacias-box-hand{
    padding-top: 0rem;
    .box-hand{
        position: absolute;
        top: 0rem;
        left: 1.5rem;
        background: url('../img/icons/box-hand.png');
        width: 65px;
        height: 57px;
    }
    .container{
        position: relative;
    }
}

section.cubierta-fuera-de-red-de-farmacias-main{
    padding-top: 3rem;
    
    h2{
        font-size: 3.6rem;
	line-height: 4.2rem;
        font-weight: 700;
        color: #047F85;
        margin-bottom: 3rem;
    }
    ul{
        li{
            font-size: 2rem;
	    font-weight: 400;
            color: #047F85;
            margin-bottom: 1.5rem;
            list-style: none !important;
            position: relative;
            padding-left: 3rem;
            i{
                position: absolute;
                left: 0rem;
                top: -0.2rem;
                font-size: 2.2rem;
            }
        }
    }
    p{
        font-size: 2rem;
        color: #047F85;
        line-height: 140%;
        margin-bottom: 3rem;
    }
    a.share{
        color: #003F44;
        font-size: 1.6rem;
        font-weight: 500;
        display: flex;
        margin-top: 2rem;
        align-items: center;
        text-align: right;
        justify-content: flex-end;
        i{
            font-size: 3rem;
            margin-right: 1rem;
        }
    }
}

@include media-breakpoint-down(sm){
    section.cubierta-fuera-de-red-de-farmacias-main{
        padding-top: 6rem;
        h2{
            font-size: 3rem;
            line-height: 120%;
            margin-bottom: 2.5rem;
        }
        ul{
            margin-bottom: 3rem;
            li{
                margin-bottom: 1.5rem;
                font-size: 1.8rem;
            }
        }
        p{
            font-size: 1.8rem;
        }
    }
}