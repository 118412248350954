/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 26-jul-2018, 16:00:40
    Author     : PC03-MAXI
*/


.md-radio{

    [type="radio"]:checked,
        [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
        [type="radio"]:not(:checked) + label
        {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        line-height: 25px;
	margin-bottom: 0;
        display: inline-block;
        color: white;
    }
    [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        border: 2px solid white;
        border-radius: 100%;
        background: transparent;
    }
    [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 12px;
        height: 12px;
        background: white;
        position: absolute;
        top: 6px;
        left: 6px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}