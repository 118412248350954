/* ------------------------------------------------------------ *\
        Base
\* ------------------------------------------------------------ */
html {
    font-size: 62.5%;

    @media only screen and (min-width : 1100px) and (max-width : 1360px) {
        font-size: 50%;
/*        zoom:0.8;*/
/*        -moz-transform: scale(0.8);
        -moz-transform-origin: 0 0;*/
    }
}

body {
    min-width: 320px;
    font-family: $base-font-family;
    font-size: 16px;
    line-height: 1.5;
    color: #333333;
    font-weight: 400;
    overflow-x: hidden;
    word-wrap: break-word;
}

a { color: inherit; }

a:focus,
a:active,
a:hover { text-decoration: none; color: inherit; }

a[href^="tel"] { text-decoration: none; }

img {
    width: auto; max-width: 100%; height: auto;
}

ul,
ol { padding: 0; margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6 { margin: 0; }

h1 { }
h2 { }
h2 small { }
h3 { }
h4 { }
h5 { }
h6 { }

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child { margin-bottom: 0; }

.text-underline a {
    text-decoration: underline !important;
}
/* Dispositivos Pequeños y Tablets vertical , Celulares horizontal min-width:576px  */
@include media-breakpoint-up(sm) {

}
/* Dispositivos Medianos , Tablets horizontal min-width:768px  */
@include media-breakpoint-up(md) {

}
/* Dispositivos grandes , min-width:992px */
@include media-breakpoint-up(lg) {

}
/* Escritorio , min-width:1200 px */
@include media-breakpoint-up(xl) {

}

