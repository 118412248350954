/*
/*
    Author     : MAXI
*/

.page-proveedores-farmacias{

    padding-bottom: 0;

    .document-item{
        display: block;
        margin-bottom: 0.3rem;
    }

    .busca-proveedores{
        p{
            color: #048086;
            font-size: 1.8rem;
            margin-bottom: 2.5rem;
        }
    }


    h1{
        margin-top:8.4rem;
        color:$color1;
        font-size:6.4rem;
        font-weight:500;
    }

    p.subtitle{
        font-size:1.8rem;
        color:#333333;
        max-width: 57rem;
    }

    .home-cards{
        margin-top: 4rem;
        margin-bottom: 6rem;
        background:transparent;

        .cards-1{
            .card{
                .card-body{
                    height:20rem;

                }
            }
        }
    }

    #comofunciona{
        h1{
            font-size: 5.4rem !important;
            &.borde-top{
                border-top: 1px solid #DDD;
                padding-top: 2rem;
            }
        }
        h3{
            margin-bottom: 1rem !important;
        }
        .legal-eoc{
            i{
                font-size: 3rem;
                margin-right: 1rem;
                color: $color1;
                position: relative;
                top: -1px;
            }
        }
    }

    .directorios-tabs{
        margin-bottom: 5rem;

        .section-tabs {
            border-bottom: 1px solid $color1;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+51,eeeeee+100 */
            background: rgb(255,255,255); /* Old browsers */
            background: -moz-linear-gradient(top, rgba(255,255,255,1) 51%, rgba(238,238,238,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(255,255,255,1) 51%,rgba(238,238,238,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(255,255,255,1) 51%,rgba(238,238,238,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eeeeee',GradientType=0 ); /* IE6-9 */
        }

        ul.nav-tabs{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-end;
            li{

                .nav-link{
                    font-size: 1.6rem;
                    line-height: 1.8rem;
                    border: 1px solid transparent;
                    color:#666666;
                    font-weight:500;
                    border-radius:0;
                    text-align:center;
                    padding-top:2rem;
                    padding-bottom:2rem;

                    &.active{
                        color: white;
                        background-color: $color1;
                        border-color: $color1 $color1 $color1;
                        border-radius:0;
                    }
                }

            }
        }

        .btn-open {
            margin-bottom: 1px;
            padding-left: 3rem;
            position: relative;

            &:before {
                content: '';
                display: block;
                height: 1px;
                background: rgba(#003F44, 0.12);
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                margin-top: -1px;
                z-index: 0;
            }
            &:hover {
                color: #FFF;
            }
            .btn {
                text-align: left;
                min-width: 51rem;
                color: #FFF;
                position: relative;
                svg {
                    margin-top: 0.75rem;
                    float: right;
                    margin-left: 3rem;
                    fill: #FFF;
                    width: 2.4rem;
                    height: 2.4rem;
                }
            }
        }

        #directoriosprov{
            ul{
                li{
                    margin-bottom: 1.5rem;
                }
            }
        }


        .tab-content{

            padding-top:8rem;
            padding-bottom:5rem;


            h3{
                color:$color1;
                font-size:3.4rem;
                font-weight: 500;
                margin-bottom: 3rem;
                max-width: 75rem;

            }


            h4{
                color:$color1;
                font-size:2.4rem;
                font-weight: 500;
                border-bottom:1px dotted #94ABB6;
                margin-bottom:3rem;
                padding-bottom: 1rem;
            }


            ul.list-circle{
                li{
                    a{
                        font-size:2rem;
                        font-weight: 400;
                        color:$color1;
                    }
                    ol {
                        margin-left: 6rem;

                        li {
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }


    }
    #collapseOne {

        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
    }
    .prod {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
    }
    #collapseOne .prod .col-lg-6 {
        flex: 0 0 70%;
        max-width: 70%;
    }

    @include media-breakpoint-down(sm) {
        #collapseOne {
            display: block;
        }
        h1 {
            margin-top: 3rem;
            font-size: 2.5rem;
        }
        .home-cards {
            margin-top: 3rem;
            margin-bottom: 1.5rem;
        }
        .directorios-tabs {
            .btn-open .btn {
                min-width: 0;
            }
            .tab-content {
                padding-top: 3rem;
                padding-bottom: 3rem;

                h3 {
                    font-size: 2rem;
                    margin-bottom: 1.5rem;
                }
                h4 {
                    font-size: 1.8rem;
                    margin-bottom: 1rem;
                }
                ul.list-circle li a {
                    font-size: 1.4rem;
                }
            }
            ul.nav-tabs {
                flex-direction: column;

                li .nav-link {
                    font-size: 1.4rem;
                    padding: 1rem;
                }
            }
        }
    }

}
