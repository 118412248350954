/* ------------------------------------------------------------ *\
        Form
\* ------------------------------------------------------------ */



.inline-fields{
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    .md-radio{
        margin-right: 15px;
    }
}

form{

    color:rgba(white,.54);
    font-size:1.3rem;

    label{
        font-size: 1.6rem;
        font-weight:500;
        color:white;
    }

    .no-label{
        margin-top: 2.4rem;
    }

    .form-control{
        color:rgba($pri,.94);
        font-size:1.3rem;
        border-radius:0;
        padding: 1.2rem 1.5rem;
    }


    .md-checkbox{
        label{

            color:rgba(white,.9);
            font-weight:400;

        }
    }

    .warning{
        display: flex;
        flex-direction: row;
        color:rgba(white,.9);

        i{
            font-size:2rem;
        }

        .text{
            margin-left:1rem;
        }

    }

    button{
        margin-top: 2rem;
        float: right;
    }

    select.form-control{

        height:auto!important;
    }
}

/* Dispositivos Pequeños y Tablets vertical , Celulares horizontal min-width:576px  */
@include media-breakpoint-up(sm) { 

}
/* Dispositivos Medianos , Tablets horizontal min-width:768px  */
@include media-breakpoint-up(md) { 

}
/* Dispositivos grandes , min-width:992px */
@include media-breakpoint-up(lg) { 

}
/* Escritorio , min-width:1200 px */
@include media-breakpoint-up(xl) { 

}

