/* ------------------------------------------------------------ *\
        Variables
\* ------------------------------------------------------------ */

// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Some example variables that you can uncomment:

// Enabling shadows and gradients
//$enable-shadows: true;
//$enable-gradients: true;

// Changing the body background and text
//$body-bg: #d3e9eb;
//$body-color: #151417;

// Changing the border radius of buttons
//$border-radius: 15px;

// Changing the theme colors
//$primary: #202f41;
//$secondary: #436296;
//$success: #2bc550;
//$info: #495dff;
//$warning: #ef8143;
//$danger: #ff293a;
//$light: #dfe6ee;
//$dark: #0f1319;


// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
//$theme-colors: (
//  "cool": #4d3fa3
//);






$va_dark_2: #004C5F;




$orange : #EBAB21;



$va_light2 : #04888E;




$gray_blue:#819CA9;
$gray : #819CA9;
$gray_light:#D9E1E5;
$gray_dark:#546E7A;
$mag : #AD1457;

$base-font-family: 'Roboto', sans-serif;

//$yellow: #A16812;
$yellow:#EB992F;
$blue: #004D60;
$blue_2: #03576F;
$va_dark : #003F44;

$va_light : #048086;

$header_h : 16.6rem;
$mobile: 360;

$pri: $va_light;
$sec: $va_dark;

$gold: #66522D;

$color1: $va_light;
$color2: $va_dark;
$color3: $blue;
$color4: $blue_2;


$purple : #884C74;
$purple_2: #774266;
$purple_3: #57314A;

$salmon: #E16059;
$salmon_2:#BA1810;
$salmon_3:#F05F57;
$salmon_4:#ED3E34;