.page-programa-calificaciones-por-estrellas{
    section{
        &.programa-calificaciones-por-estrellas-main{
            p{
                font-size: 1.8rem;
                color: #003F44;
                line-height: 140%;
                margin-bottom: 2rem;
                font-weight: 400;
                a{
                    color: #047F85;
                    text-decoration: underline;
                }
            }
            .files-cont{
                margin-bottom: 3rem;
                .files{
                    background: #F4F4F4;
                    padding: 3.5rem;
                    a{
                        color: #047F85;
                        position: relative;
                        /*padding-left: 4rem;*/
                        font-weight: 400;
                        line-height: 130%;
			align-items: center;
                        display: flex;
                        img{
/*                            position: absolute;
                            top: 0.5rem;
                            left: 0rem;*/
			    margin-right: 1rem;
                        }
                        strong{
                            display: block;
			    flex: 1;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .page-programa-calificaciones-por-estrellas{
        section{
            &.programa-calificaciones-por-estrellas-main{
                p{
                    font-size: 1.6rem;
                }
                .files-cont{
                    .files{
                        a{
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }
}