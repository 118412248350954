/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 10-jul-2018, 14:32:17
    Author     : PC03-MAXI
*/

.documents-container{
    min-height: 100%;
    border-right: 1px dotted #94ABB6;
    &.border-none{
        border: none;
    }
    
    .title{
        font-size:2.4rem;
        color:$color2;
        font-weight:500;
        margin-bottom:1.4rem;
    }

    .document-item{
        display:block;
        font-size:1.8rem;
        font-weight:500;
        color:#047F85;
        margin-bottom:0.8rem;
    }
    
    &.white {
	padding-top: 1rem;
	padding-left: 3rem;
	
	.document-item{
	    padding-bottom: 1rem;
	    color: #FFF;
	    
	    img {
		width: 1.8rem;
		height: auto;
		margin-right: 1rem;
	    }
	}
    }

    @include media-breakpoint-down(sm) {
	.title {
	    font-size: 1.6rem;
	    margin-bottom: 1rem;
	    margin-top: 0.5rem;
	}
	.document-item {
	    font-size: 1.6rem;
	}
    }
}