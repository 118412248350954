section.determinacion-organizacionales-box-file{
    padding-top: 0rem;
    .box-file{
        position: absolute;
        top: 0rem;
        left: 1.5rem;
        background: url('../img/icons/box-file.png');
        width: 65px;
        height: 57px;
    }
    .container{
        position: relative;
    }
}

section.determinacion-organizacionales-info{
    .nav-tabs.years {
        justify-content: flex-end;
        border-left: #FFF solid 1px;
        margin-left: -1px;
        border-bottom: #03576F solid 1px;
        margin-bottom: 3rem;
        margin-top: -3.5rem;

        .nav-link {
            border-color: transparent;
            color: #03576F;
            font-size: 1.4rem;
            font-weight: 600;
            padding: 0 1.5rem;
            line-height: 4rem;

            &.active {
                background: #03576F;
                color: #FFF;
            }
        }
    }

    .form-medicamentos {
        h2 {
            color: #026A6E;
            font-size: 2.4rem;
            font-weight: 500;
            margin-bottom: 1.5rem;
        }
        ul {
            color: #333333;
            font-size: 2rem;
            padding-left: 2rem;
            margin-bottom: 2rem;
        }
        p {
            color: #016A6D;
            font-size: 2rem;
            font-weight: 500;
            line-height: 2.4rem;
        }
    }
    h1{
        font-size: 6rem;
        font-weight: 500;
        color: #047F85;
        margin-bottom: 2rem;
    }
    p.subtitle{
        font-size: 2rem;
        font-weight: 500;
        line-height: 140%;
        color: #047F85;
    }
    .tab-content{
        border-left: 1px solid #444444;
        padding: 3.5rem 0rem;
        .card{
            border: none;
            .card-header{
                border: none;
                padding: 0;
                background: none;
                button{
                    transition: all .2s ease-out;
                    font-size: 2rem;
                    font-weight: 400;
                    color: #FFF;
                    padding: 0rem 1.25rem 0rem 2rem;
                    text-align: left;
                    background: #047F85;
                    line-height: 5.5rem;
                    height: auto;
                    width: 100%;
                    &:hover, &:focus{
                        text-decoration: none;
                    }
                    &:after{
                        content: ' ';
                        position: absolute;
                        background: #047F85;
                        transition: all .2s ease-out;
                        opacity: 1;
                        height: 100%;
                        width: 0.5rem;
                        left: -0.3rem;
                        top: 0;
                    }
                    &.collapsed{
                        color: #047F85;
                        background: none;
                        /*font-size: 2rem;*/
                        font-weight: 400;
                        /*padding-left: 1.5rem;*/
                        line-height: 110%;
                        margin-bottom: 1rem;
                        &:before{
                            content: "+";
                            font-size: 3rem;
                            font-weight: 300;
                            position: relative;
                            top: 0.3rem;
                            margin-right: 0.8rem;
                        }
                        &:after{
                            height: 0;
                        }
                    }
                }
            }
            .card-body{
                padding: 7rem 1.5rem 3rem 6rem;

                &.formulario {
                    padding: 3rem 1.5rem;
                }
                h2 {
                    margin: 0 0 0.5rem 0;
                    color: #047F85;
                    font-size: 2rem;
                    font-weight: 500;
                }
                h5{
                    font-size: 1.6rem;
                    font-weight: 300;
                    margin-top: 1rem;
                }
                ul {
                    color: #333333;
                    font-size: 1.8rem;
                    font-weight: 400;
                    margin: 0;
                    padding: 0;
                    margin-left: 8rem;
                    margin-bottom: 3rem;
                    list-style: none;

                    li {
                        margin-bottom: 0rem;
                        position: relative;

                        &:before {
                            content: '';
                            display: inline-block;
                            width: 1rem;
                            height: 1rem;
                            border: #047F85 solid 2px;
                            border-radius: 100%;
                            margin-left: -2.5rem;
                            float: left;
                            margin-top: 0.7rem;
                            opacity: 0.54;
                        }
                    }
                }
                h3{
                    font-size: 2.4rem;
                    font-weight: 500;
                    color: #003F44;
                    margin-bottom: 1.5rem;
                }
                h4{
                    font-size: 2rem;
                    font-weight: 500;
                    color: #047F85;
                    margin-bottom: 3rem;
                }
                p{
                    font-size: 1.8rem;
                    color: #333;
                    line-height: 140%;
                    a{
                        color: #047F85;
                        text-decoration: underline;
                    }
                }
                .faxs{
                    margin-top: 7rem;
                    .fax-item{
                        border-left: 1px dotted #94ABB6;
                        padding-top: 1rem;
                        padding-bottom: 5rem;
                        padding-left: 2.5rem;
                        &:first-child{
                            border: none;
                        }
                    }
                    p{
                        font-size: 2rem;
                        color: #003F44;
                        line-height: 120%;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .section-banner{
        h2{
            font-size: 2.2rem;
            line-height: 140%;
        }
    }

    section.determinacion-organizacionales-info{
        padding-top: 6rem;
        h1{
            font-size: 3rem;
            line-height: 120%;
        }
        p.subtitle{
            font-size: 1.8rem;
            line-height: 120%;
        }
        .tab-content{
            .card{
                .card-header{
                    button{
                        white-space: normal;
                        padding: 1rem 1.25rem 1rem 1.25rem;
                        line-height: 130%;
                    }
                }
                .card-body{
                    padding: 3rem 1.5rem 3rem 1.5rem;
                    p{
                        font-size: 1.6rem;
                    }
                    h4{
                        margin-bottom: 1.5rem;
                        margin-top: 4rem;
                    }
                    .faxs{
                        margin-top: 3rem;
                        .fax-item{
                            padding-bottom: 1.5rem;
                            h3{
                                font-size: 2.2rem;
                                margin-bottom: 0.5rem;
                            }
                            p{
                                font-size: 1.8rem;
                            }
                        }
                    }
                }
            }
        }
    }
}