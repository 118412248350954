/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 29-jun-2018, 15:40:30
    Author     : Rodri
*/

.section-banner {
    position: relative;
    background-size: cover;
    min-height: 48rem;
    display: flex;
    align-items: center;
    background-position: center center;

    .breadcrumb {
        position: absolute;
        top: 1rem;
        left: 2rem;
        background: transparent;
        color: #FFF;

        .breadcrumb-item + .breadcrumb-item::before {
            display: inline-block;
            padding-right: 0.75rem;
            padding-left: 0.75rem;
            color: #FFF;
            font-size: 2rem;
            line-height: 1.5rem;
            content: "\0203a";
        }

        .breadcrumb-item {
            line-height: 2rem;
            font-weight: 600;
            text-decoration: underline;
            font-size: 1.5rem;

            &.active {
                font-weight: 400;
                text-decoration: none;
                color: #FFF;
            }
        }
    }
    img.bg {
        display: none;
    }
    &:before {
        background: rgba(129, 156, 169, 0.94);
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 50%;
        z-index: 0;
    }
    h2 {
        color: white;
        font-size: 5.3rem;
        line-height: 5.6rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    h3 {
        color: rgba(#FFF, 0.92);
        font-size: 3.2rem;
        font-weight: 400;

        &.small {
            font-size: 2.4rem;
            font-weight: 400;
        }

        & + p {
            padding-top: 1.5rem;
            margin-top: 1.5rem;
            border-top: #FFF dotted 1px;
        }
    }
    p {
        color: rgba(#FFF, 0.92);
        font-size: 2.4rem;
        margin-bottom: 1.5rem;
        font-weight: 400;
        line-height: 3.4rem;
    }

    .btn {
        line-height: 6rem;
        padding: 0 3rem;
        height: 6rem;
        margin-top: 1.5rem;
    }
    
    .col-lg-6 {
	padding-right: 6rem;
    }

    @include media-breakpoint-down(sm) { 
        display: block;
        padding-top: 0;
        min-height: 0;
        background: rgba(129, 156, 169, 0.94) !important;

        &:before {
            display: none;
        }
	
	.col-lg-6 {
	    padding-right: 1.5rem;
	}
	
        img.bg {
            display: block;
            max-width: 100%;
            height: auto;
            margin-bottom: 1.5rem;
        }

        h2 {
            font-size: 3rem;
            line-height: 3.6rem;
        }

        h3 {
            font-size: 2.2rem;
            line-height: 2.6rem;
        }
        p {
            font-size: 2rem;
            line-height: 2.6rem;
        }
        .btn {
            line-height: 4.2rem;
            padding: 0 1.5rem;
            height: 4.2rem;
            margin-top: 0;
        }
        .breadcrumb {
            top: 0.5rem;
            left: 0.5rem;

            .breadcrumb-item + .breadcrumb-item::before {
                font-size: 1rem;
            }
            .breadcrumb-item {
                font-size: 1rem;
            }
        }
    }
}

@media only screen and (min-width: 990px) and(max-width: 1575px){
    .section-banner{
        .breadcrumb{
            width: 48vw;
        }
    }
}