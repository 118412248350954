/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 29-jun-2018, 16:40:12
    Author     : Rodri
*/
.section-afiliados {
    padding-top: 7rem;

    .container {
        position: relative;
    }
    .icon-top {
        position: absolute;
        top: -7rem;
        /* left: 5rem; */
        background: rgba(129, 156, 169, 0.2);
        padding: 1rem;
        z-index: 1000;
    }
    .asistente {
        position: relative;
        top: auto;
        right: auto;
        height: 4.2rem;
        width: 23.5rem;
        border-radius: 4rem;
        display: flex;

        font-size: 1.8rem;
        font-weight: 500;
        margin-left: 1.5rem;

        &:before {
            width: 3.3rem;
            height: 3.3rem;
            content: '';
            background: url(../img/asistente.svg);
            background-size: cover;
            margin-right: 1.3rem;
            
        }


        &:hover{
            color:white;
            background:rgba(#EB992F,.54);
        }
    }
    .berder-left {
        border-left: #94ABB6 dotted 1px;
    }
    h2 {
        color: #047F85;
        font-size: 3.4rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }
    h3 {
        color: #047F85;
        font-size: 2rem;
        line-height: 2.6rem;
        font-weight: 400;
        margin-bottom: 2rem;
    }
    h4 {
        color: $va-dark;
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
    }
    p {
        color: $va-dark;
        margin-bottom: 0.5rem;
        font-weight: 400;
        font-size: 1.6rem;

        strong {
            font-weight: bold;
            display: block;
        }

        &.box-green {
            line-height: 2rem;
            margin-top: 2rem;
            background: #048086;
            color: #FFF;
            text-align: center;
            padding: 1.5rem;

            strong {
                font-size: 2rem;
            }
        }
    }
}