/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 16-may-2018, 10:35:23
    Author     : PC03-MAXI
*/

article{


    margin-bottom:1.5rem;

    figure{

        border-top: 2px solid $pri;
        margin-bottom:1rem;
        img{

        }
    }


    .category{

        display: inline-block;
        padding: 0.5rem 1.5rem;
        background:$pri;
        font-size:1.6rem;
        color:white;
        font-weight:500;
        margin-bottom:1.8rem;
        margin-right: 0.6rem;

    }
    time.date{
        color:rgba(black,.54);
        font-size:1.6rem;
        font-weight: 400;
    }


    .title{
        color:rgba($pri,.92);
        font-weight: 500;
        font-size:2.4rem;
        margin-bottom:1.5rem;
        &:focus,&:hover,&:active{

            color:$sec;

        }
    }
    .cont{
        color:#333333;
        font-size:1.6rem;
        p{
            color:#333333;
            font-size:1.6rem;
        }
    }


    .btn{
        color:$pri;
        font-weight: 500;
        font-size:1.6rem;  

        &:focus,&:hover,&:active{

            color:$sec;

        }
    }







}