/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 24-jul-2018, 16:43:13
    Author     : Rodri
*/

.section-glosario {
    padding-top: 0;

    .form {
        z-index: 2;
        position: relative;
        margin-bottom: -6rem;
    }
    form {
        margin-bottom: 0;
        display: flex;
        width: 100%;

        input {
            flex: 1;
            background: #EEEEEE;
            border: 0;
            height: 5.4rem;
            padding: 0 1.5rem;
            color: #003F44;
            font-size: 1.6rem;
            font-weight: 500;
        }
        a.btn-close-search{
            position: absolute;
            color: #444;
            font-size: 2.5rem;
            top: 0.9rem;
            right: 8rem;
        }
        button {
            cursor: pointer;
            text-align: center;
            background: #003F44;
            border: 0;
            display: block;
            width: 5.2rem;
            height: 5.4rem;
            margin: 0;
            transition: all .2s ease-out;
            float: none;

            &:hover {
                background: #048086;
            }

            i {
                color: #FFF;
                font-size: 2.5rem;
                display: inline-block;
                line-height: 5.4rem;
            }
        }
    }
    .title {
        background: #003F44;
    }
    .nav-glosario {
        margin-bottom: 6rem;
        background: #048086;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: inline-block;
                color: rgba(#FFF, 0.92);
                font-size: 2.5rem;
                line-height: 6.1rem;
                margin-right: 1rem;
            }
        }
    }
    h1 {
        color: rgba(#FFF, 0.92);
        font-size: 2.5rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
        line-height: 6.1rem;
    }
    dl {
        padding-left: 4rem;
    }
    dt {
        color: #047F85;
        font-size: 2rem;
        font-weight: 400;
        margin-bottom: 1.5rem;
    }
    dd {
        color: #333333;
        font-size: 1.6rem;
        margin-bottom: 2rem;
    }
    .cintillo {
        position: relative;
        display: block;
        font-size: 5rem;
        color: rgba(#FFF, 0.92);
        width: 6rem;
        height: 6rem;
        text-align: center;
        line-height: 5.6rem;
        margin-bottom: 3.5rem;
        background: #048086;

        &:before {
            content: '';
            width: 1px;
            height: 14rem;
            background: #048086;
            left: 14px;
            top: -2.5rem;
            z-index: -1;
            position: absolute;
        }
        &:after {
            content: '';
            position: absolute;
            top: -14px;
            left: 0;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-top: 7px solid transparent;
            border-right: 7px solid #036A6F;
            border-bottom: 7px solid #036A6F;
        }
    }

    @include media-breakpoint-down(sm) {
        .nav-glosario ul li {
            font-size: 2rem;
            line-height: 5rem;
        }
        .form {
            margin-bottom: 4rem;
        }
        .nav-glosario {
            margin-bottom: 2rem;
        }
    }
}