/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 24-ago-2018, 11:25:46
    Author     : Rodri
*/

.container {
    @media (min-width: 578px) and (max-width: 767px) {
	max-width: 100%;
    }
}