/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 26-sep-2018, 13:31:31
    Author     : Rodri
*/

.ie11 {
    /* HEADERS */
    .section-banner .container {
	display: none;
    }
    
    /* HOME */
    section.home-main .content {
	position: absolute !important;
	bottom: 0 !important;
    }
    
    /* CARDS */
    .card-title,
    .card-subtitle,
    .afiliados-pago-primas .card-white p {
	max-width: 100%;
    }
    
    /* MENU */
    .navbar-nav li ul li ul {
	width: 40rem;
    }
    
    /* MEJORAMIENTO DE CALIDAD */
    section.programa-mejoramiento-calidad-objetivos .qip .box-qip::after,
    section.programa-mejoramiento-calidad-objetivos .qip .box-qip::before {
	top: 0;
    }
    
    /* PROVEEDORES */
    .section-proveedores ul.download a, 
    .section-proveedores ul.download.open li {
	display: flex;
	margin-bottom: 0.5rem;
	align-items: flex-start;
	
	span {
	    flex: 1;
	}
    }
    .section-proveedores ul.download.open li img {
	margin-right: 0.5rem;
    }
    
    /* UNETE */
    .section-requisitos .requisitos-detail-row .requisitos-list {
	display: block;
    }
}