/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 13-ago-2018, 12:47:15
    Author     : PC03-MAXI
*/
.page-detalle-plan{

    @include media-breakpoint-down(sm) {
        .links-bar {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin-right: 0rem;
            margin-top: 0rem;
            border-bottom: none;
            flex-direction: column;
        }
    }

    section.detalle-plan{

        @include media-breakpoint-down(sm) {
            p.category {
                font-size: 2.4rem !important;
            }
            h1 {
                font-size: 3.5rem !important;
            }
            p.description {
                margin-bottom: 2rem !important;
            }
        }

        h1{
            font-size:6.5rem;
            font-weight:500;
            margin-bottom:0;
            // color:$color1;
            color: $color1;
        }




        p.description{
            color:$color1;
            font-size:1.8rem;
            margin-bottom: 5rem;
        }



        p.category{
            color:#859FAC;
            font-weight:500;
            font-size:3.4rem;
            margin-bottom: 0;
        }

        p.extra-info{

            margin-top:3.3rem;
            font-size:1.6rem;
            color:#333333;
            margin-bottom:0rem;
            width: 75%;

            @include media-breakpoint-down(sm){
                margin-top: 7rem;
            }

            @include media-breakpoint-down(lg) {
                width: auto;

            }
        }

        .card-plan-caracteristicas{


            border: none;
            /*box-shadow: 0px 4px 14px 0 rgba(0, 0, 0, 0.1);*/
            height: 100%;




            .card-header:first-child {
                border-radius: 1px 1px 0 0;
            }
            .card-header{
                position:relative;
                background:$color2;
                color:white;
                height: 13.5rem;
                padding-top:3.8rem;
                padding-left: 7rem;
                padding-right: 7rem;
                text-align: center;

                h3{
                    font-size:2.4rem;
                    font-weight:500;
                }

                .clase{

                    font-weight:400;
                    font-size:2rem;

                }

                .price{

                    position: relative;
                    font-size: 6.5rem;
                    font-weight: 500;
                    color:$color2;
                    position: absolute;
                    bottom: -4rem;
                    z-index: 2;
                    left: 0;
                    right: 0;
                    margin: auto;
                    text-align: center;




                    &:before{

                        content: "$";
                        color:$color2;
                        /* position: relative; */
                        /* top: -11px; */
                        font-size: 2rem;
                        z-index: 1;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: -5rem;
                        right: 0;
                        margin: auto;
                        height: 3rem;

                    }

                }


                &:after{
                    content: " ";
                    background:#F4F4F4;
                    border-radius: 50%;
                    width: 20rem;
                    height: 20rem;
                    display: block;
                    position: absolute;
                    left:0;
                    right:0;
                    margin:auto;
                    bottom: -12rem;

                    z-index: 0;

                    display: none;
                }
                .type{
                    font-size:1.8rem;
                    color:$color2;
                    border-top: 1px solid $color2;
                    position: absolute;
                    bottom: -6.4rem;
                    z-index: 1;
                    left: 0;
                    right: 0;
                    margin: auto;
                    display: inline-block;
                    width: 15rem;
                }

                .icon{

                    position: relative;
                    margin-top: 2rem;
                    height: 5rem;


                    img{

                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        top: 4.2rem;
                        z-index: 2;
                    }
                    &:before{
                        content: " ";
                        background: white;
                        border-radius: 50%;
                        width: 10rem;
                        height: 10rem;
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: auto;
                        top: 9px;
                    }

                    &:after{
                        content: " ";
                        background: #003F44;
                        border-radius: 50%;
                        width: 6rem;
                        height: 6rem;
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: auto;
                        top: 25px;
                    }

                }
            }







            .card-body{
                padding-top: 2rem;
                padding-bottom: 0;
                position: relative;
                background:#F4F4F4;
                .desc{
                    font-size: 1.8rem;
                    color:$color2;
                    margin-bottom:2rem;
                    text-align: center;
                    font-weight: 500;
                }

                .beneficios-list{
                    list-style: none;

                    margin-bottom:4rem;
                    li{
                        color:$color1;
                        font-size:1.8rem;
                        font-weight:500;
                        display: flex;

                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                        border-bottom: 1px #94ABB6 dotted;


                        &:last-child{
                            border-bottom:none;
                        }
                        .left{

                            display: flex;

                            flex-direction: column;
                            width: 40%;



                            .value{
                                padding-left: 0.5rem;
                                font-size:3.6rem;
                            }

                            .desc{
                                margin-bottom: 0;
                                font-size:1.6rem;
                                color:$color1;
                                position: relative;
                                text-align: left;
                                top: -4px;

                            }

                            &.percent,&.price{


                            }

                            &.without-value {
                                .desc {
                                    padding: 26px 0;
                                    top: 0;
                                }
                            }

                            &.price{
                                .value{
                                    &:before{
                                        content: "$";
                                        position: relative;
                                        font-size: 1.6rem;
                                        top: -7px;
                                        left: -2px;
                                    }
                                }
                            }

                            &.percent{
                                .value{
                                    &:before{
                                        content: "%";
                                        position: relative;
                                        font-size: 1.6rem;
                                        top: -7px;
                                        left: -2px;
                                    }
                                }
                            }
                        }

                        .right{

                            text-align: right;
                            width:60%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            sup{
                                top: .5rem;
                            }

                        }
                        &.head{
                            color:$color2;
                            font-size:1.6rem;
                            font-weight:500;

                        }
                    }
                }


                .prima{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    align-items: center;
                    .sticker{
                        position: relative;
                        left: -12px;
                        background: $color2;
                        display: inline-block;
                        padding-left:1.8rem;
                        padding-right:1.8rem;
                        padding-top: 0.1rem;
                        padding-bottom: 0.1rem;
                        font-size:1.6rem;
                        color:white;
                        align-self: flex-start;
                    }

                    .price{
                        text-align: center;
                        font-size: 6.5rem;
                        font-weight: 500;
                        color:$color1;
                        small{
                            font-size: 3.4rem;
                            position: relative;
                            top: -25px;
                            left: -0.5rem;
                        }


                        &:before{
                            content: "$";
                            font-size: 2rem;
                            margin-right: 0.3rem;
                            position: relative;
                            top: -1rem;
                        }
                    }

                }
            }




            &.color3{

                .card-header{
                    background:$color1;


                    .price{

                        color:$color1;
                        &:before{
                            color:$color1;

                        }
                    }
                }

                .card-body{

                    .desc{
                        color:$color1;
                    }

                    .prima .sticker{
                        background: $color1;
                    }
                }

            }


            &.color2{

                .card-header{
                    background:$color3;
                    .price{

                        color:$color3;
                        &:before{
                            color:$color3;

                        }
                    }
                }

                .card-body{

                    .desc{
                        color:$color3;
                    }

                    .prima .sticker{
                        background: $color3;
                    }
                }

            }





        }



        /*        &.platino{
                    h1{
                        color: $salmon_3;
                    }
                    p.description{
                        color: $salmon_3;
                    }


                    .card-plan-caracteristicas{
                        .card-header{
                            background:$salmon_2;

                            .type{
                                color:$salmon_2;
                            }
                            .price{

                                color:$salmon_2;
                                &:before{
                                    color:$salmon_2;

                                }
                            }
                        }

                        .card-body{

                            .desc{
                                color:$salmon_2;
                            }

                        }

                        &.color2{

                            .card-header{
                                background:$salmon_4;


                                .price{

                                    color:$salmon_4;
                                    &:before{
                                        color:$salmon_4;

                                    }
                                }
                            }

                            .card-body{

                                .desc{
                                    color:$salmon_4;
                                }


                            }

                        }


                        &.color3{

                            .card-header{
                                background:$salmon_3;
                                .price{

                                    color:$salmon_3;
                                    &:before{
                                        color:$salmon_3;

                                    }
                                }
                            }

                            .card-body{

                                .desc{
                                    color:$salmon_3;
                                }


                            }

                        }

                    }
                }*/





    }

    section.downloads{
        padding-top:0;
        h2{
            color:#333333;
            font-size:2.2rem;
            font-weight: 500;

        }

        .autorizado{
            color:#333333;
            font-size:1.8rem;

        }


        .document-item{

            display: block;
            margin-bottom:0.3rem;
        }
    }

}
