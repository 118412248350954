/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 03-jul-2018, 16:37:54
    Author     : PC03-MAXI
*/

section.telefonos-horarios{
    color:white;
    padding-top: 10rem;
    padding-bottom: 3.2rem;
    background: #048086;

    .bg-green:before {
        display: none;
    }

    .icon-top {
        position: absolute;
        top: -10rem;
    }

    .bg-green{
        &:before{
            background: #048086;
            content: ' ';
            position: absolute;
            top: -10rem;
            bottom: 0;
            left: 0;
            z-index: 0;
            width: 100vw;
            height: calc(100% + 10rem + 3.2rem);
            z-index: -1;
        }
    }
    &:after{
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        background: url('../img/contacto/contact-info.jpg');
        background-position: center;
        width: 35%;
        height: 100%;
        z-index: 0;
    }

    .help{

        color:white;
        margin-bottom:3rem;
        font-size:2.4rem;

        span{
            font-weight:500;
        }

        strong {
            font-weight: bold;
        }
    }

    .tel-column{
        h4{
            font-size:2.4rem;
            margin-bottom:2rem;
        }
        ul{
            list-style:none;

            li{
                color:white;
                margin-bottom:1.4rem;
                font-size: 1.8rem;
                line-height: 125%;
                a{
                    font-weight:700;
                    display: block;
                }
            }
        }
    }


    .horario-share-cont{
        border-top: dotted 1px #94ABB6;
        border-bottom: dotted 1px #94ABB6;
        padding: 0;
        margin-left: 15px;
        margin-right: 15px;
        padding-top:1.5rem;
        padding-bottom:1.5rem;
        margin-bottom:3.3rem;
    }

    .horario{
        display: flex;

        i{
            font-size: 3rem;
            margin-right:1.2rem;
        }
        p{
            font-size: 2rem;
            font-weight: 700;
            color: #FFF;
            line-height: 120%;
        }
    }

    .share{
        p{
            font-size: 2rem;
            margin-bottom: 0;
            color: #FFF;
        }
        .social-net{
            display: flex;
            align-items: center;
            justify-content: flex-start;

        }
    }
    .asistente-chat{
        display: flex;
        .chat-with-us{
            font-size:1.8rem;
            font-weight:700;
            color: #FFF;
            flex: 1;
        }
    }

    @include media-breakpoint-down(sm) {
        padding-top: 2rem;
        padding-bottom: 2rem;

        .help {
            margin-bottom: 1.5rem;
            font-size: 1.6rem;
        }
        .tel-column {
            h4 {
                font-size: 1.8rem;
                margin-bottom: 1rem;
            }
            ul li {
                color: white;
                margin-bottom: 1.4rem;
                font-size: 1.6rem;
            }
        }
        .horario-share-cont {
            padding: 0 1.5rem; 
            margin-left: 0; 
            margin-right: 0; 
            padding-top: 1rem;
            padding-bottom: 1.5rem;
            margin-bottom: 2rem;
        }
        .horario, .asistente-chat {
            flex-direction: column;
        }
    }
}

section.form-contacto{
    background:$color2;
    color:white;
    padding-top: 8rem;
    padding-bottom: 7rem;

    .help-block-error {
        font-size: 1.2rem;
        color: #ff5252;
        padding-top: 0.5rem;
    }

    .help{
        font-size:2.4rem;
        color:white;
        margin-bottom:3rem;

        span{
            font-weight: 500;
        }
    }

    .form-group{
        label{
            font-size:1.6rem;
            font-weight:500;
            text-transform: uppercase;
        }

        &.required:not(.field-contactform-newsletter){
            label:after{
                margin-left:1rem;
                content:"*";
            } 
        }
    }

    .body-label{
        font-size:1.6rem;
        font-weight: 500;
    }

    .md-checkbox{
        label{
            text-transform: none;
        }
    }

    .field-contactform-verifycode{
        background:rgba(white,0.3);
        padding:1.3rem;
    }

    #contactform-plan{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 2rem;
        padding-bottom: 2rem;

        .help {
            margin-bottom: 2rem;
            font-size: 1.6rem;
        }
    }
}



section.contacto-links{
    background-color: rgba(#819CA9,0.1);
}


section.no-afiliado{
    background-color:$color1;
    color:white;
    padding-top: 12rem;
    padding-bottom: 8rem;

    p {
        color: white;

        .large {
            font-size: 2rem;
        }
    }

    .help{
        font-size: 3.4rem;
        color:white;
        margin-bottom:5rem;

        span{
            font-weight:700;
        }
        strong {
            font-weight: bold;
        }
    }

    .tel-row{
        padding-bottom: 3.9rem;
        border-bottom:1px #F2F5F6 dotted; 
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        margin-bottom:3.2rem;

        .tel-column{
            padding-left: 2.5rem;

            &.bordered{
                border-right:  1px #F2F5F6 dotted; 
            }

            h4{
                font-size:2.4rem;
                font-weight: 500;
                margin-bottom:1.3rem;
            }
            ul{
                list-style:none;

                li{
                    color:white;
                    margin-bottom:2rem;
                    font-size: 1.8rem;

                    a{
                        font-weight:500;
                        display:block;
                    }
                }
            }
        }  
    }

    .horario-column{
        border-right: 1px #F2F5F6 dotted; 
        border-left: 1px #F2F5F6 dotted; 
        padding-top: 1.6rem;
        padding-bottom:3.2rem;
        padding-left: 1.5rem;

        h4{
            font-size:2rem;
            font-weight: 700;
        }
    }

    .orientacion-column{
        p{
            font-size:1.8rem;
        }
    }

    @include media-breakpoint-down(sm) {
        padding-top: 2rem;
        padding-bottom: 2rem;

        .help {
            margin-bottom: 1.5rem;
            font-size: 1.6rem;
        }
        .tel-column {
            padding-left: 1.5rem !important;

            &.bordered.last {
                border-right: 0;
            }

            h4 {
                font-size: 1.8rem;
                margin-bottom: 1rem;
            }
            ul li {
                color: white;
                margin-bottom: 1.4rem;
                font-size: 1.6rem;
            }
        }
        .horario-column {
            padding-left: 1.5rem;
            border-right: none;
        }
    }
}

section.oficinas-map{
    position: relative;
    overflow:hidden;
    padding: 0;
    background-image: url(../img/mapbg.jpg);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $blue;
        mix-blend-mode: multiply;
        z-index: 0;
    }

    .container {
        z-index: 1;
        position: relative;
        padding-top:10rem;
        padding-bottom:7.2rem;
    }

    h2{
        color:white;
        font-size:3.4rem;
        margin-bottom:1.6rem;
    }

    .help{
        font-size: 2.2rem;
        line-height: 2.8rem;
        color:rgba(white,0.94);
    }

    .help-block {
        font-size: 1.6rem;
        padding-top: 0.5rem;
        color: white;
    }

    .fields-column{
        display:flex;
        align-items: flex-start;

        .form-group{
            width: 25.5rem;
            margin-left:1.5rem;
            margin-right:1.5rem;
            margin-bottom: 0;

            select {
                color: #003F44;
                font-size: 1.6rem;
                font-weight: 500;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background: #FFF url(../img/icon_select.jpg) right center no-repeat;
                height: 4.2rem !important;
                border: 0;
                padding: 0 1.5rem;
            }
        }

        label{
            font-size:1.6rem;
            color:white;
            font-weight:500;

        }

        .btn-open-map{
            padding-right: 3.5rem;
            padding-left:3.5rem;
            margin-left:2rem;
            flex: 1;
            margin-top: 2.9rem;
            &:focus{
                color: #212529 !important;
            }
        }
    }

    #map-contact {
        width: 100%; 
        height: 64rem;
    }

    .oficinas-map{



    }

    @include media-breakpoint-down(sm) {
        padding-top: 0;
        padding-bottom: 0;

        #map-contact {
            /*height: 20rem;*/
        }
        .container {
            padding-top: 2.5rem;
            padding-bottom: 3rem;
        }
        .fields-column {
            flex-direction: column;
            margin-top: 2rem;

            .form-group {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 0;
            }
            .btn-open-map {
                padding-right: 3.5rem;
                padding-left: 3.5rem;
                margin-left: 0;
                margin-top: 2.9rem;
                display: block;
                width: 100%;
            }
        }
    }
}

.infowindow {
    #infowindow {
        padding-top: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 1.6rem !important;

        ul {
            margin: 1rem 0 0 0;
            padding: 0;
            list-style: none;

            li {
                border-top: rgba(#FF5722, 0.12) dotted 1px;
                padding: 1rem 0;
            }
        }
    }
    /*    & > div:nth-child(1) {
            & > div:nth-child(4) {
                background: #EEE !important;
            }
        }*/
}

@media only screen and (min-width: 990px) and(max-width: 1575px){
    section.form-contacto{
        .form-group{
            label{
                font-size: 1.2rem;
            }
        }
    }
    section.telefonos-horarios{
        &:after{
            width: 28%;
        }
    }
}

@include media-breakpoint-down(md){
    section.telefonos-horarios{
        &:after{
            display: none;
        }
    }
}