/*
/*
    Author     : MAXI
*/

.afiliados-nombramiento-de-representante{



    .copy-designar-representante{

        font-size:1.8rem;
        color:#333333;

    }

    .copy{

        display:flex;
        margin-bottom:1.5rem;
        margin-top: 3rem;
        i{
            margin-right: 1.5rem;
            background: #003F44;
            width: 6.5rem;
            height: 5.7rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: none;
        }

        p{
            color:$color2;
            font-size:1.8rem;
        }
    }


    .descarga-col{
        a.btn{
            padding: 0rem 3rem;
        }
    }



    .ubicaciones{

        margin-top:0rem;
        /*border-top: 1px dotted #94ABB6;*/
        border-bottom: 1px dotted #94ABB6;
        padding-top:2.8rem;
        padding-bottom:2.8rem;

        .ubicaciones-col{

            padding-top:3.2rem;
            padding-bottom: 3.2rem;

            border-right: 1px dotted #94ABB6;

            &:last-child{
                border-right:none;
            }
            p{
                color:$color2;  
            }
            .title{
                font-size:2.4rem;
                font-weight:500;
                color:$color2;  
                margin-bottom:1rem;
            }
            .subtitle{
                font-size:2rem;
                font-weight:bold;
                margin-bottom:0;
            }


        }
    }
}

@include media-breakpoint-down(sm){
    .afiliados-nombramiento-de-representante{
        .ubicaciones{
            .ubicaciones-col{
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
            }
        }
    }
}