/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 21-ago-2018, 11:27:59
    Author     : Rodri
*/

.section-bienestar-integral-map {
    position: relative;
    padding: 0;
    background: #FFF;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 50%;
        background: #03576F;
        z-index: 0;
        margin-left: -10rem;
    }



    .beneficios-actividades{
        margin-top: 3rem;

        h3{
            font-size:2.8rem;
            margin-bottom:1rem;

        }
    }


    .map {
        position: absolute;
        top: 0;
        height: 42rem;
        right: 0;
        left: 50%;
        z-index: 2;
        margin-left: -10rem;

        iframe {
            position: relative;
            width: 100%;
            height: 100%;
        }

        p {
            color: #01576D;
            font-size: 2.2rem;
            font-weight: 500;
            position: absolute;
            max-width: 23.6rem;
            background: white;
            top: 6rem;
            left: 0;
            z-index: 2;
            padding: 1.5rem 1.5rem 1.5rem 3rem;
        }
    }

    h1 {
        color: #03576F;
        font-size: 6.5rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    h2 {
        color: #02576E;
        font-size: 2.8rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    h3 {
        color: #02576E;
        font-size: 2.2rem;
        font-weight: 500;
        margin-bottom: 3rem;
    }
    ul.beneficios {
        color: #01576D;
        font-size: 2rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-left: 2.5rem;
        list-style: none;

        li {
            margin-bottom: 0rem;
            position: relative;

            &:before {
                content: '';
                display: inline-block;
                width: 1rem;
                height: 1rem;
                border: #03576F solid 2px;
                border-radius: 100%;
                margin-left: -2.5rem;
                float: left;
                margin-top: 0.95rem;
                opacity: 0.54;
            }
        }
    }
    .col-left {
        padding-top: 6rem;
        padding-bottom: 7rem;
    }
    .col-right {
        position: relative;
        padding-top: 42rem;
        background: #03576F;

        &:before {
            content: '';
            left: -1.5rem;
            right: 0;
            height: 1px;
            top: 47.1rem;
            background: #FFF;
            position: absolute;
        }

        .nav {
            margin-bottom: 2.5rem;
            flex-wrap: nowrap !important;

            .nav-item {
                .nav-link {
                    transition: all .2s ease-out;
                    border-radius: 0;
                    font-size: 1.9rem;
                    font-weight: 500;
                    color: rgba(#FFFFFF, 0.74);
                    display: inline-block;
                    line-height: 5rem;
                    padding: 0 1rem;

                    &.active {
                        color: #EB992F;
                    }
                }
            }
        }

        p {
            color: #FFF;
            font-size: 1.6rem;
            font-weight: 400;

            .orange {
                color: #EB992F;
                font-size: 2.2rem;
                font-weight: 500;
                display: block;
                line-height: 2.6rem;
            }
        }

        a {
            svg {
                display: inline-block;
                vertical-align: middle;
                width: 2.4rem;
                height: 2.4rem;
                fill: #FFF;
                margin-right: 0.5rem;
                margin-top: -2px;
            }

            &:hover {
                svg {
                    fill: #212529;
                }
            }
        }
    }
    .embed-responsive {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        min-height: 0;
        padding: 2rem 0 0 0;

        &:before {
            display: none;
        }
        .map {
            display: none;
        }
        .embed-responsive {
            display: block;
            margin-bottom: 1rem;
        }
        .col-left {
            padding-top: 0rem;
            padding-bottom: 1.5rem;
        }
        .col-right {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            &:before {
                display: none;
            }
            .nav{
                flex-wrap: wrap !important;
            }
        }
        h1 {
            font-size: 2.5rem;
            line-height: 3.2rem;
        }
        h2 {
            font-size: 2.2rem;
            margin-bottom: 1rem;
        }
        h3 {
            font-size: 1.8rem;
            margin-bottom: 2rem;
        }
        ul.beneficios {
            font-size: 1.8rem;

            li:before {
                margin-top: 0.7rem;
            }
        }
    }
}

.swiper-container-a, .swiper-container-b {
    position: relative;

    .swiper-pagination-a, .swiper-pagination-b {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;

        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            background: #FFF;
            opacity: 1;
        }
        .swiper-pagination-bullet-active {
            background: #048086;
        }
    }
}

.section-patitas-calientes {
    background: #048086;
    color: #FFF;
    padding: 6rem 0 7rem 0;

    h2 {
        font-size: 6.5rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }
    p {
        font-size: 1.8rem;

        strong {
            font-size: 2rem;
            font-weight: 600;
            display: block;

            &.tel {
                font-size: 3rem;
                line-height: 4rem;
            }
        }

        &.line {
            border-top: #94ABB6 dotted 1px;
            padding-top: 1.5rem;
            margin-top: 2rem;
            font-size:1.4rem;
        }
    }
    h4 {
        margin-bottom: 1rem;
        font-size: 2.8rem;
        font-weight: 500;

        img {
            float: lefT;
            margin-right: 1.5rem;
            margin-top: 0.5rem;
        }
        span {
            font-weight: 400;
            display: block;
        }
    }
    ul.beneficios {
        font-size: 1.6rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-left: 2.5rem;
        list-style: none;

        li {
            margin-bottom: 0rem;
            position: relative;

            &:before {
                content: '';
                display: inline-block;
                width: 1rem;
                height: 1rem;
                border: #FFF solid 2px;
                border-radius: 100%;
                margin-left: -2.5rem;
                float: left;
                margin-top: 0.7rem;
                opacity: 0.54;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 1.5rem 0;

        h2 {
            font-size: 2.8rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }
        p {
            font-size: 1.6rem;
        }
        h4 {
            margin-bottom: 1rem;
            font-size: 2.2rem;
            font-weight: 500;
            overflow: hidden;
        }
        ul.beneficios {
            font-size: 1.8rem;

            li:before {
                margin-top: 0.7rem;
            }
        }
    }
}