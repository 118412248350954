/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 13-ago-2018, 12:47:15
    Author     : PC03-MAXI
*/


.page-planes-beneficios{

    .planes-category{
        .container{
            position: relative;
        }
    }

    .btn-solicita-orientacion{
        position: absolute;
        right: 1.5rem;
        top: 0rem;
        padding-left:6.6rem;
        padding-right:6.6rem;
        z-index: 2;
        color:$color1;
        font-weight:bold;

        @include media-breakpoint-down(lg) {
            position: relative;
            right: unset;
            margin-left: 1rem;
            margin-right: 1rem;
            /* left: 0; */
            /* margin: auto; */
            display: block;

        }

    }
    section.downloads{
        padding-bottom: 0;
        h2{
            font-size:2.2rem;
            font-weight:500;
            color:$color2;
            margin-bottom: 2rem;

        }

        p.autorizado{
            font-size:1.8rem;
            color:#333333;
            margin-top: 2rem;
        }

        .document-item{

            display: block;
            margin-bottom:0.5rem;
        }
    }




}

.links-bar{
    display: flex;
    justify-content: flex-end;
    margin-right: 3rem;
    margin-top: 3rem;
    border-bottom:none;
    a{
        color:$color1;
        font-size:1.5rem;
        font-weight:500;
        padding: 0.5rem 1.5rem;
        border-right: 1px solid $color1;
        transition: all .2s ease-out;
        &:hover{
            background: $color1;
            color: #FFF;
        }
        &:last-child{
            border-right:none;
        }
    }


}

.planes-category{

    .category{
        color:#869FAC;
        font-size:3.4rem;
        font-weight:500;
        margin-bottom:0;
    }
    .title{
        font-size:6.5rem;
        color:$color2;
        font-weight: 500;
        margin-bottom: 0rem;

        &.blue {
            color: #03576F;
        }
        &.green {
            color: #048086;
        }
    }


    .subtitle{

        font-size:2.2rem;
        color:$color1;
        font-weight: 500;
        margin-bottom: 3rem;

    }



    .card.card-planes-beneficios{

        &.platino{
            .card-body{
                padding-top: 3.8rem;
                z-index: 2;
            }
            .card-header{
                .icon{
                    margin-top: 0rem;
                    height: 3.4rem;
                }
            }
        }

        border: none;
        box-shadow: 0px 4px 14px 0 rgba(0, 0, 0, 0.1);
        /*        margin-bottom: 2rem;*/
        /*        height: 100%;*/

        .card-header:first-child {
            border-radius: calc(0.85rem - 1px) calc(0.85rem - 1px) 0 0;
        }
        .card-header{


            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#884c74+0,693a5a+100 */
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00636a+0,003f44+100 */
            background: rgb(0,99,106); /* Old browsers */
            background: -moz-linear-gradient(top, rgba(0,99,106,1) 0%, rgba(0,63,68,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(0,99,106,1) 0%,rgba(0,63,68,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(0,99,106,1) 0%,rgba(0,63,68,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00636a', endColorstr='#003f44',GradientType=0 ); /* IE6-9 */
            color:white;

            padding-top: 2rem;
            padding-bottom: 2rem;


            /*            padding-top:3.8rem;*/
            padding-left: 2rem;
            padding-right: 2rem;
            text-align: center;

            &.blue {
                background: #03576F;
            }
            &.green {
                background: #048086;
            }

            h3{
                font-size:2.6rem;
                font-weight:500;
            }

            .clase{
                font-weight:400;
                font-size:2rem;
                color: white;
            }

            .icon{

                position: relative;
                margin-top: 1rem;
                height: 5rem;


                img{

                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    top: 3.5rem;
                    z-index: 2;
                    height: 4rem;
                }
                &:before{
                    content: " ";
                    background: white;
                    border-radius: 50%;
                    width: 10rem;
                    height: 10rem;
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    top: 9px;
                }

                /*                                &:after{
                                                    content: " ";
                                                    background: #003F44;
                                                    border-radius: 50%;
                                                    width: 6rem;
                                                    height: 6rem;
                                                    display: block;
                                                    position: absolute;
                                                    left: 0;
                                                    right: 0;
                                                    margin: auto;
                                                    top: 25px;
                                                }*/

            }
        }






        .card-body{
            padding-top: 4.2rem;
            padding-bottom: 3.5rem;

            .desc{
                font-size: 1.8rem;
                color:#333;
                margin-bottom:2rem;
                font-weight: 400;
            }

            .beneficios-list{
                list-style: none;

                margin-bottom:1rem;
                li{
                    color:$color1;
                    font-size:1.8rem;
                    font-weight:500;
                    display: flex;
                    align-items: center;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    border-bottom: 1px #94ABB6 dotted;


                    &:last-child{
                        border-bottom:none;
                    }
                    .left{
/*                        display: flex;
                        align-items: center;*/
                        width:80%;
                    }

                    .right{
                        width: 40%;
                        text-align: right;
                        @media screen and (min-width: 900px) and (max-width: 1200px){
                            &:not(.price){
                                font-size: 1.3rem;
                            }
                        }


                        .label{
                            font-size: 1.6rem;
                            display: block;
                            /* margin-top: -1px; */
                            position: relative;
                            top: -1rem;

                        }


                        &.price{
                            font-size:3.6rem;

                            &:before{
                                content: "$";
                                position: relative;
                                font-size: 1.6rem;
                                top: -7px;
                                left: -2px;
                            }

                        }

                        &.without-value{
                            span {
                                padding: 26px 0;
                                top: 0;
                            }
                        }
                        &.percent{
                            font-size:3.6rem;
                            &:before{
                                content: "%";
                                position: relative;
                                font-size: 1.6rem;
                                top: -7px;
                                left: -2px;
                            }

                        }

                        &.other{
                            font-size:3.6rem;
                        }
                    }
                    &.head{
                        color:$color2;
                        font-size:1.6rem;
                        font-weight:500;

                    }
                }
            }


            .prima{
                display: flex;
                flex-direction: column;
                align-items: center;
                align-items: center;
                .sticker{
                    position: relative;
                    left: -12px;
                    background: $color2;
                    display: inline-block;
                    padding-left:1.8rem;
                    padding-right:1.8rem;
                    padding-top: 0.1rem;
                    padding-bottom: 0.1rem;
                    font-size:1.6rem;
                    color:white;
                    align-self: flex-start;
                }

                .price{
                    text-align: center;
                    font-size: 6.5rem;
                    font-weight: 500;
                    color:$color2;
                    small{
                        font-size: 3.4rem;
                        position: relative;
                        top: -25px;
                        left: -0.5rem;
                    }


                    &:before{
                        content: "$";
                        font-size: 2rem;
                        margin-right: 0.3rem;
                        position: relative;
                        top: -1rem;
                    }
                }

                .btn{
                    background:$color2;

                    &:hover,&:active,&:focus{
                        background: rgba($color2,.54)!important;
                    }
                }

            }
        }


        &.color1{
            .card-header{
                background:$color1;

                .icon{
                    &:after{
                        background:$color1;
                    }

                }
            }

            .card-body{
                .prima .sticker{
                    background: $color1;
                }

            }

        }


        &.platino{

            .card-header{
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e16059+0,be2b23+100 */
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00afaf+0,048086+100 */
                background: rgb(0,175,175); /* Old browsers */
                background: -moz-linear-gradient(top, rgba(0,175,175,1) 0%, rgba(4,128,134,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(0,175,175,1) 0%,rgba(4,128,134,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(0,175,175,1) 0%,rgba(4,128,134,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00afaf', endColorstr='#048086',GradientType=0 ); /* IE6-9 */

                .icon{
                    &:after{
                        background:$color1;
                    }

                }
            }

            .card-body{
                .prima .sticker{
                    background: $color1;
                }

                .prima .price{
                    color:$color1;
                }

                .prima .btn{
                    background: $color1;

                    &:hover,&:active,&:focus{
                        background: rgba($color1,.54)!important;
                    }

                }

            }

        }

        &.color3{
            .card-header{
                background:$color3;

                .icon{
                    &:after{
                        background:$color3;
                    }
                }
            }

            .card-body{
                .prima .sticker{
                    background: $color3;
                }

            }

        }
    }

    @include media-breakpoint-down(sm) {
	.category {
	    font-size: 2.4rem;
	}
	.btn-solicita-orientacion {
	    position: relative;
	    right: 0;
	    top: 0rem;
	    padding-left: 0rem;
	    padding-right: 0rem;
	    z-index: 2;
	    color: #048086;
	    font-weight: bold;
	}
    }

}
