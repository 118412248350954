/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 22-ago-2018, 10:45:17
    Author     : Rodri
*/

.section-tecnologia {
    padding: 7rem 0;

    h1 {
        color: #003F44;
        font-size: 6rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
    }
    .alerta-tecnologia h4 {

        color: #047F85;
        font-size: 3.4rem;
        font-weight: 500;
        margin-bottom: 2rem;
    }
    h3 {
        color: #003F44;
        font-size: 2rem;
        font-weight: 400;
        margin-bottom: 2rem;
    }
    img.img-phone{
        position: absolute;
        height: 54.3rem;
        width: auto;
        max-width: initial;
        left: -15.5rem;
    }
    @include media-breakpoint-up(md){
        .text-beside-phone{
            min-height: 50.5rem;
        }
    }
    .border-top {
        border-top: #94ABB6 dotted 1px;
        margin-top: 3.5rem;
        padding-top: 3rem;
    }
    .border-right {
        border-right: #94ABB6 dotted 1px;
    }
    .social-net li a {
        color: #333333;
        font-size: 2.5rem;
    }
    h2 {
        color: #003F44;
        font-size: 2.4rem;
        font-weight: 600;
        margin-bottom: 1rem;

        &.green {
            color: #048086;
        }
        &.title-aside {
            font-size: 6rem;
            color: #048086;
            line-height: 6rem;
            font-weight: 500;

            svg {
                display: block;
                width: 6rem;
                height: 6rem;
                fill: #048086;
            }

            &.orange {
                color: #EB992F;

                svg {
                    fill: #EB992F;
                }
            }
        }
    }
    p {
        color: #003F44;
        font-size: 1.8rem;
        font-weight: 400;
    }
    ul.beneficios {
        color: #003F44;
        font-size: 1.6rem;
        font-weight: 400;
        margin: 0 0 2rem 0;
        padding: 0;
        margin-left: 2.5rem;
        list-style: none;

        li {
            margin-bottom: 0rem;
            position: relative;

            &:before {
                content: '';
                display: inline-block;
                width: 1rem;
                height: 1rem;
                border: #003F44 solid 2px;
                border-radius: 100%;
                margin-left: -2.5rem;
                float: left;
                margin-top: 0.5rem;
                opacity: 0.54;
            }
        }
    }
    .asistente {
        position: relative;
        top: auto;
        right: auto;
        height: 4.2rem;
        width: 23.5rem;
        border-radius: 4rem;
        display: flex;
        &:before {
            width: 3.3rem;
            height: 3.3rem;
            content: '';
            background: url(../img/asistente.svg);
            background-size: cover;
        }

        span.label{
            font-size: 1.8rem;
            font-weight: 500;
            margin-left: 1.5rem;

        }



    }
    .box-gris {
        position: relative;
        background: #F4F4F4;
        margin-top: 6rem;
        padding: 3rem;
        /*margin-bottom: 3rem;*/

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -3rem;
            left: 6rem;
            width: 0;
            height: 0;
            border-left: 3rem solid transparent;
            border-bottom: 3rem solid #F4F4F4;
            border-right: 3rem solid transparent;
        }
    }
    form {
        display: flex;

        input {
            border: 0;
            padding: 0 1.5rem;
            background: #F4F4F4;
            color: #003F44;
            font-size: 1.6rem;
            flex: 1;
        }
        .btn {
            margin: 0;
        }
    }
    .alert {
        margin: 0 0 1.5rem 0;
        padding: 0;
        display: flex;

        svg {
            margin-top: 0.5rem;
        }
        span {
            margin-left: 1rem;
            flex: 1;
        }
    }
    .btns_downloads {
        margin-bottom: 2rem;
        display: flex;

        a {
            margin: 0 1px;
        }
    }

    .col-aside{

        p{
            font-size:1.7rem;

        }
    }

    .alerta-description {
        background-color: #116f71;
        padding: 5em;

        h2, p, h3 {
            color: #ffffff;
        }

        .icon, .text-description {
            padding-top: .5em;
            padding-bottom: .5em;
        }

        .text-description {
            display: flex;
            align-items: center;
        }

        @include media-breakpoint-down(md) {
            padding: 2em 0;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 3rem 0;

        h1 {
            font-size: 3rem;

            .btn {
                margin-top: 1rem;
            }
        }
        h3 {
            font-size: 1.8rem;
        }
        .box-gris {
            margin-top: 3rem;
            margin-bottom: 3rem;
        }
        h2.title-aside {
            font-size: 3rem;
            line-height: 3rem;
        }

        .alerta-tecnologia h4 {

            font-size: 2.5rem;
        }
    }
}
