/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 17-may-2018, 18:21:43
    Author     : codigowww
*/

.tooltip {
    font-size: 1.3rem;
    line-height: 1.6rem;
    
    .tooltip-inner {
	padding: 1.5rem 1.25rem;
	border-radius: 1rem;
    }
}

.popover{

    border:none;
    left: -2rem;

    .popover-header{
        display:none;

    }

    .popover-body{
        padding: 2rem;
        background:#EEEEEE;

        padding: 0;

    }
}


.callpopover{

    .close {
	margin-right: 1rem;
    }
    
    color:#546E7A;
    ul{
        list-style: none;

        padding: 1rem;
        padding-top: 0rem;



        li{

            padding-top:1.3rem;
            padding-bottom:1.3rem;
            padding: 1rem;

            h5{
                font-size:1.6rem;

                font-weight: bold;

            }

            p{
                font-size:1.4rem;

                margin-bottom:0;

            }
            border-bottom:1px solid rgba(#FF5722,.12);

            &:last-child{
                border-bottom:none;
            }
        }
    }


    .horarios{
        margin-top: 1rem;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
        background: #819CA9;
        color: white;

        h5{
            margin-bottom:1rem;
            margin-bottom:0;
            font-size:1.6rem;
            font-weight:bold;
        }
        p{
            margin-bottom:0;
            font-size:1.6rem;
            font-weight:400;

        }
    }
}