/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 10-jul-2018, 18:06:00
    Author     : PC03-MAXI
*/

.social-net{

    list-style: none;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    li{
        margin-right: 0.5rem;
        a{  
            color:white;
            font-size:2rem;

        }

        &:last-child{
            margin-right: 0rem;
        }
    }
}