section.cubierta-fuera-de-red-box-file{
    padding-top: 0rem;
    .box-file{
        position: absolute;
        top: 0rem;
        left: 1.5rem;
        background: url('../img/icons/box-file.png');
        width: 65px;
        height: 57px;
    }
    .container{
        position: relative;
    }
}

section.cubierta-fuera-de-red{
    padding-top: 6rem;
    padding-bottom: 10rem;
    h1{
        font-size: 6rem;
        font-weight: 500;
        color: #047F85;
        margin-bottom: 2rem;
    }
    p{
        margin-bottom: 2rem;
        font-size: 1.8rem;
        color: #333;
        a{
            color: #047F85;
            text-decoration: underline;
        }
    }
}

@include media-breakpoint-down(sm){
    section.cubierta-fuera-de-red{
        h1{
            font-size: 3rem;
        }
        p{
            font-size: 1.6rem;
        }
    }
}