.page-programa-de-cuidadores{
    section{
        &.programa-de-cuidadores-main{
            h3{
                font-size: 2.4rem;
                font-weight: 500;
                line-height: 130%;
                color: #047F85;
                margin-bottom: 3rem;
            }
            h2{
                font-size: 3.4rem;
                font-weight: 400;
                line-height: 110%;
                color: #047F85;
                margin-bottom: 1.5rem;
            }
            .ventajas{
                margin-bottom: 5rem;
                .col-ventaja{
                    border-top: 4px solid #47989D;
                    padding-top: 2.5rem;
                    h4{
                        font-size: 2.4rem;
                        font-weight: 500;
                        line-height: 120%;
                        color: #003F44;
                        margin-bottom: 1.5rem;
                    }
                    ul{
                        li{
                            font-size: 1.6rem;
                            font-weight: 400;
                            line-height: 120%;
                            color: #333;
                            margin-bottom: 1.5rem;
                            position: relative;
                            padding-left: 2rem;
                            padding-right: 2rem;
                            &:before{
                                position: absolute;
                                top: .5rem;
                                left: 0;
                            }
                            .info{
                                color: #048086;
                            }
                        }
                    }
                    a.btn{
                        margin-bottom: 1.5rem;
                        width: 100%;
                    }
                }
            }
            .mas{
                margin-bottom: 5rem;
                p{
                    font-size: 2.1rem;
                    font-weight: 400;
                    line-height: 140%;
                    text-align: center;
                    color: #333;
                    display: block;
                    width: 95%;
                    margin: 0px auto;
                }
            }
            .registrate{
                margin-top: 6rem;
                margin-bottom: 4rem;
                .box-registrate{
                    position: relative;
                    background: #003F44;
                    display: flex;
                    align-items: center;
                    height: 15rem;
                    &:before{
                        content: " ";
                        position: absolute;
                        left: 0;
                        width: 5.5rem;
                        height: 100%;
                        background: #47989D;
                    }
                    .hand-stop{
                        background: #003F44 url(../img/icons/hand-stop.png) no-repeat;
                        background-size: 28px;
                        background-position: center;
                        border-radius: 10rem;
                        position: absolute;
                        left: 1.8rem;
                        top: 4rem;
                        width: 7rem;
                        height: 7rem;
                    }
                    p{
                        width: 80%;
                        margin: 0px auto;
                        font-size: 1.8rem;
                        font-weight: 400;
                        line-height: 120%;
                        color: #FFF;
                        position: relative;
                        left: 2rem;
                        a{
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .page-programa-de-cuidadores{
        section{
            &.programa-de-cuidadores-main{
                h2{
                    font-size: 2.4rem;
                }
                h3{
                    font-size: 1.8rem;
                }
                .ventajas{
                    margin-bottom: 1rem;
                    .col-ventaja{
                        padding-bottom: 1.5rem;
                        h4{
                            font-size: 2.2rem;
                        }
                    }
                }
                .mas{
                    p{
                        font-size: 1.8rem;
                    }
                }
                .registrate{
                    margin-top: 3rem;
                    .box-registrate{
                        justify-content: center;
                        height: 81vw;
                        &:before{
                            content: " ";
                            position: absolute;
                            top: 0;
                            width: 100%;
                            height: 5.5rem;
                        }
                        .hand-stop{
                            left: auto;
                            top: 2rem;
                        }
                        p{
                            width: 100%;
                            margin: 0px auto;
                            position: relative;
                            padding: 9rem 1.5rem 1rem 1.5rem;
                            top: 0rem;
                            left: 0rem;
                        }
                    }
                }
            }
        }
    }
}