/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 21-ago-2018, 19:03:20
    Author     : Rodri
*/

.section-prevencion {
    padding: 7rem 0;
    
    h1 {
	color: #03576F;
	font-size: 6.5rem;
	font-weight: 500;
	margin-bottom: 1rem;
    }
    h3 {
	color: #03576F;
	font-size: 2.2rem;
	font-weight: 500;
	margin-bottom: 2rem;
    }
    h2 {
	color: #047F85;
	font-size: 2.8rem;
	font-weight: 500;
	
	&.large {
	    font-size: 3.4rem;
	}
    }
    p {
	color: #333333;
	font-size: 1.8rem;
	margin-bottom: 1rem;
    }
    ul {
	color: #047F85;
	font-size: 1.6rem;
	font-weight: 400;
	margin: 0;
	padding: 0;
	margin-left: 2.5rem;
	list-style: none;

	li {
	    margin-bottom: 0.5rem;
	    position: relative;

	    &:before {
		content: '';
		display: inline-block;
		width: 1rem;
		height: 1rem;
		border: #047F85 solid 2px;
		border-radius: 100%;
		margin-left: -2.5rem;
		float: left;
		margin-top: 0.7rem;
		opacity: 0.54;
	    }
	}
    }
    .box-green {
	margin-bottom: 1rem;
	background: #003F44;
	padding: 2rem;
	color: #FFF;
	
	p {
	    line-height: 2.6rem;
	    font-size: 2.2rem;
	    margin-bottom: 1.5rem;
	    color: #FFF;
	    
	    span {
		font-size: 1.8rem;
		line-height: 1.8rem;
	    }
	}
	a {
	    svg {
		display: inline-block;
		vertical-align: middle;
		width: 2.4rem;
		height: 2.4rem;
		fill: #FFF;
		margin-right: 0.5rem;
	    }
	    &:hover {
		svg {
		    fill: #003F44;
		}
	    }
	}
    }
    figure {
	position: relative;
	
	a {
	    position: absolute;
	    bottom: 2rem;
	    left: 2rem;
	    
	    svg {
		display: inline-block;
		vertical-align: middle;
		width: 2.4rem;
		height: 2.4rem;
		fill: #FFF;
		margin-right: 0.5rem;
	    }
	    &:hover {
		svg {
		    fill: #003F44;
		}
	    }
	}
	p {
	    position: absolute;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    background: #048086 url('../img/icons/icon_star.png') 2rem center no-repeat;
	    color: #FFF;
	    font-size: 2rem;
	    padding: 1.5rem 2rem 1.5rem 10rem;
	    margin-bottom: 0;
	}
    }
    .border-t {
	border-top: #94ABB6 dotted 1px;
	padding-top: 5rem;
	margin-top: 5rem;
    }
    
    @include media-breakpoint-down(sm) {
	padding: 3rem 0 3rem 0;
	
	h1 {
	    font-size: 3rem;
	}
	h3 {
	    font-size: 1.8rem;
	}
	h2 {
	    font-size: 2rem;
	}
	p {
	    font-size: 1.6rem;
	}
	ul {
	    margin-bottom: 2rem;
	}
	.box-green {
	    .btn {
		font-size: 1.3rem;
	    }
	    p {
		font-size: 1.8rem;
	    }
	}
	.border-t {
	    padding-top: 3rem;
	    margin-top: 2.5rem;
	}
	figure {
	    .btn {
		font-size: 1.3rem;
	    }
	    p {
		position: absolute;
		font-size: 1.4rem;
		padding: 1rem 1rem 1rem 7rem;
		background-size: 3.5rem;
	    }
	}
    }
}

.section-recompensando {
    padding-top: 8rem;
    background: #F4F4F4;
    
    h2 {
	color: #047F85;
	font-size: 3.4rem;
	font-weight: 500;
	margin-bottom: 1.5rem;
    }
    p {
	color: #333333;
	font-size: 1.8rem;
	
	&.green {
	    color: #047F85;
	    font-size: 2rem;
	    font-weight: 500;
	}
    }
    ul {
	color: #047F85;
	font-size: 1.6rem;
	font-weight: 400;
	margin: 0;
	padding: 0;
	margin-left: 2.5rem;
	list-style: none;

	li {
	    margin-bottom: 0.5rem;
	    position: relative;

	    &:before {
		content: '';
		display: inline-block;
		width: 1rem;
		height: 1rem;
		border: #047F85 solid 2px;
		border-radius: 100%;
		margin-left: -2.5rem;
		float: left;
		margin-top: 0.7rem;
		opacity: 0.54;
	    }
	    
	    ul {
		li {
		    &:before {
			border: transparent solid 2px;
			background: #048086;
		    }
		}
	    }
	}
    }
    
    @include media-breakpoint-down(sm) {
	h2 {
	    font-size: 2.4rem;
	}
	p {
	    font-size: 1.6rem;
	}
	p.green {
	    font-size: 1.8rem;
	}
    }
}

.section-educacion-salud {
    padding: 8rem 0 8rem 0;
    position: relative;
    background: #048086;
    color: #FFF;
    
    &:before {
	content: '';
	top: 0;
	bottom: 0;
	right: 0;
	left: 50%;
	position: absolute;
	background: url('../img/planes-medicos/prevencion-educacion/educacion.jpg') center center no-repeat;
	background-size: cover;
    }
    h2 {
	font-size: 3.4rem;
	font-weight: 600;
	margin-bottom: 2.5rem;
    }
    ul {
	color: #FFF;
	font-size: 1.8rem;
	font-weight: 400;
	margin: 0;
	padding: 0;
	margin-left: 2.5rem;
	list-style: none;

	li {
	    margin-bottom: 3rem;
	    position: relative;

	    &:before {
		content: '';
		display: inline-block;
		width: 1rem;
		height: 1rem;
		border: #FFF solid 2px;
		border-radius: 100%;
		margin-left: -2.5rem;
		float: left;
		margin-top: 0.7rem;
		opacity: 0.54;
	    }
	}
    }
    .btn-open {
	margin-top: 1.5rem;
	margin-bottom: 3px;
	padding-left: 3rem;
	position: relative;

	&:before {
	    content: '';
	    display: block;
	    height: 1px;
	    background: rgba(#FFF, 0.12);
	    position: absolute;
	    left: 0;
	    right: 0;
	    top: 50%;
	    margin-top: -1px;
	    z-index: 0;
	}
	
	.btn {
	    position: relative;
	    z-index: 1;
	    text-align: left;
	    min-width: 24rem;
	    background: #FFF;
	    color: #048086;
	    
	    &:hover, &:focus {
		color: #FFF;
		background:$va_dark!important;
	    }
	    svg {
		float: left;
		margin-right: 1rem;
		margin-top: 0.75rem;
		fill: #048086;
		width: 2.4rem;
		height: 2.4rem;
		
		&.arrow {
		    float: right;
		    margin-right: 0;
		    margin-left: 3rem;
		}
	    }
	}
    }
    
    @include media-breakpoint-down(sm) {
	padding: 0 0 3rem 0;
	
	&:before {
	    position: relative;
	    display: block;
	    left: 0;
	    height: 20rem;
	    margin-bottom: 1.5rem;
	}
	h2 {
	    font-size: 2.4rem;
	}
    }
}