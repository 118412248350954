.page-registro-de-cuidadores{
    section{
        &.registro-de-cuidadores-main{
            h3{
                font-size: 2.4rem;
                font-weight: 500;
                line-height: 130%;
                color: #047F85;
                margin-bottom: 3rem;
            }
            .the-table{
                border: 1px solid #036A6F;
                &-head{
                    &-cell{
                        background: rgba(3,106,111,1);
                        background: -moz-linear-gradient(top, rgba(3,106,111,1) 0%, rgba(0,63,68,1) 100%);
                        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(3,106,111,1)), color-stop(100%, rgba(0,63,68,1)));
                        background: -webkit-linear-gradient(top, rgba(3,106,111,1) 0%, rgba(0,63,68,1) 100%);
                        background: -o-linear-gradient(top, rgba(3,106,111,1) 0%, rgba(0,63,68,1) 100%);
                        background: -ms-linear-gradient(top, rgba(3,106,111,1) 0%, rgba(0,63,68,1) 100%);
                        background: linear-gradient(to bottom, rgba(3,106,111,1) 0%, rgba(0,63,68,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#036a6f', endColorstr='#003f44', GradientType=0 );
                        height: 6.8rem;
                        display: flex;
                        align-items: center;
                        .type-name{
                            display: inline-block;
                            background: #FFF;
                            font-size: 3.4rem;
                            font-weight: 400;
                            color: #036A6F;
                            line-height: 6.8rem;
                            text-align: center;
                            height: 100%;
                            flex: 0.3;
                        }
                        .type-info{
                            font-size: 2.1rem;
                            font-weight: 400;
                            color: #FFF;
                            text-align: center;
                            flex: 1;
                        }
                    }
                }
                &-body{
                    &-row{
                        border-bottom: 1px solid #EEE;
                        .table-cell{
                            padding: 3rem;
                            h5{
                                font-size: 2rem;
                                font-weight: 500;
                                line-height: 100%;
                                color: #003F44;
                                margin-bottom: 1rem;
                            }
                            p{
                                font-size: 1.6rem;
                                font-weight: 400;
                                line-height: 120%;
                                color: #666;
                            }
                        }
                        &>div:first-child{
                            border-right: 1px solid #EEE;
                        }
                    }
                }
            }
        }
        &.registro-de-cuidadores-extra-info{
            padding-top: 0rem;
            .first-column{
                .descargar-text{
                    display: flex;
                    padding-bottom: 5rem;
                    border-bottom: 1px dotted #94ABB6;
                    img{
                        margin-right: 2.5rem;
                    }
                    p{
                        font-size: 2rem;
                        font-weight: 400;
                        line-height: 100%;
                        color: #003F44;
                    }
                }
                .descargar-botones{
                    padding-top: 3.5rem;
                    padding-bottom: 5rem;
                    border-bottom: 1px dotted #94ABB6;
                    &.no-border{
                        border-bottom: none;
                    }
                    h4{
                        font-size: 2.1rem;
                        font-weight: 500;
                        line-height: 100%;
                        color: #003F44;
                        margin-bottom: .5rem;
                    }
                    p{
                        font-size: 2rem;
                        font-weight: 400;
                        color: #333;
                        margin-bottom: 1rem;
                    }
                    ul{
                        padding-left: 0rem !important;
                        li{
                            list-style: none;
                            color: #047F85;
                            padding-left: 0rem;
                            font-size: 1.8rem;
                            font-weight: 400;
                            color: #047F85;
                        }
                    }
                }
            }
            .second-column{
                border-left: 1px dotted #94ABB6;
                .info-item{
                    margin-bottom: 2.3rem;
                    p.large{
                        font-size: 2rem;
                        font-weight: 500;
                        color: #003F44;
                        margin-bottom: .3rem;
                    }
                    p{
                        font-size: 1.6rem;
                        font-weight: 400;
                        line-height: 120%;
                        color: #003F44;
                        strong{
                            font-weight: 500;
                        }
                    }
                    a{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .page-registro-de-cuidadores{
        section{
            &.registro-de-cuidadores-main{
                h3{
                    font-size: 1.8rem;
                }
                .the-table{
                    border: 1px solid #036A6F;
                    &-head{
                        &-cell{
                            .type-name{
                                font-size: 2.6rem;
                            }
                            .type-info{
                                font-size: 1.6rem;
                            }
                        }
                    }
                    &-body{
                        &-row{
                            border-bottom: 1px solid #EEE;
                            .table-cell{
                                padding: 2rem;
                                h5{
                                    font-size: 1.8rem;
                                }
                            }
                            &>div:first-child{
                                border-right: none;
                            }
                        }
                    }
                }
            }
            &.registro-de-cuidadores-extra-info{
                .first-column{
                    .descargar-text{
                        padding-bottom: 2.5rem;
                        align-items: center;
                        p{
                            font-size: 1.6rem;
                        }
                    }
                    .descargar-botones{
                        padding-bottom: 2.5rem;
                        ul{
                            margin-bottom: 1.5rem;
                        }
                        p{
                            font-size: 1.8rem;
                        }
                    }
                }
            }
        }
    }
}