/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 25-jul-2018, 18:59:53
    Author     : PC03-MAXI
*/

.newsletter{
    position:relative;


    .container{
        position:relative;
    }
    .triangle{
        position: absolute;
        top: -5rem;
        left: 2rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 2rem 2rem 0 2rem;
        border-color: #048086 transparent transparent transparent;
    }
    background:rgba($gray_blue,.3);
    padding-top:5rem;
    padding-bottom:5rem;
    p.suscribe{
        font-size:2rem;
        color:$color2;
        font-weight: 500;
    }

    p.authorize{
        margin-top:4rem;
        font-size:1.6rem;
        color: $color2;
        font-weight: 400;
    }

    form{

        .form-control{

            padding: 0 1.5rem;
            height: 6rem;
            font-size : 1.6rem;
            color: rgba($color2,.54);


            &::placeholder{
                color: rgba($color2,.54);
            }
        }



        .btn{
            padding: 0 4rem;
            height: 6rem;

            @include media-breakpoint-down(lg) { 
                padding: 0 2rem;
            }
        }


    }

}


@include media-breakpoint-down(lg) { 

    .newsletter{

        background:$gray_light;
    }
}


@include media-breakpoint-down(lg) { 

    .newsletter{

        background:$gray_light;
    }
}