/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
Created on : 06-sep-2018, 17:00:38
Author : Rodri
*/

.afiliados-desafiliacion {
    p {
	color: #003F44;
	font-size: 1.6rem;
	line-height: 2rem;
	font-weight: 400;

	&.medium {
	    font-size: 1.8rem;
	    line-height: 2.4rem;
	}
	&.large {
	    font-size: 2rem;
	    line-height: 2.6rem;
	}
    }
    h2 {
	color: #003F44;
	font-size: 3.4rem;
	font-weight: 500;
	margin-bottom: 1rem;

	&.green {
	    color: #047F85;
	}
	&.small {
	    font-size: 2.4rem;
	}
    }
    h3 {
	font-size: 2.8rem;
	color: #003F44;
	font-weight: 500;
	margin-bottom: 1rem;
    }
    h4 {
	color: #003F44;
	font-size: 2rem;
	font-weight: 500;
    }
    .alert {
	padding: 0;
	align-items: center;
	display: flex;

	i {
	    width: 6rem;
	    height: 6rem;
	    background: #003F44;
	    padding: 1.5rem;
	    margin-right: 1.5rem;
	}
	span {
	    flex: 1;
	}
    }
    .border-top {
	border-top: #94ABB6 dotted 1px;
	padding-top: 4rem;
	margin-top: 3rem;
    }
    .border-left {
	padding-left: 2.5rem;
	border-left: #94ABB6 dotted 1px;
    }
    .entregar-solicitud-title{
        font-size: 2rem;
    }
    .detalles-importantes-title{
        font-size: 2.4rem;
        margin-top: 1.5rem;
        color: #003F44;
    }
    .important {
	margin: 3rem 0;
	background: #EEEEEE;
	display: flex;
	align-items: center;
	border-right: #003F44 solid 4px;
	border-left: #003F44 solid 55px;
	padding: 1.5rem;

	&.white {
	    background: white;
	}

	img {
	    margin-left: -5rem;
	    margin-right: 1rem;
	}
	h3 {
	    color: #003F44;
	    font-size: 2.8rem;
	    font-weight: 600;
	    margin: 0 5rem 0 0;
	}
	p {
	    flex: 1;
	}
    }
    ul {
	color: #003F44;
	font-weight: 400;
	font-size: 1.8rem;
	margin: 0;
	list-style: none;
	padding: 0;

	&.download {
	    li {
		color: #047F85;
		padding-left: 0;

		a {
		    color: #047F85;
		}
		&:before {
		    display: none;
		}
	    }
	}
	li {
	    padding-left: 2.5rem;
	    position: relative;
	    margin-bottom: 0.5rem;

	    &:before {
		content: '';
		display: block;
		position: absolute;
		width: 10px;
		height: 10px;
		border: #003F44 solid 2px;
		border-radius: 100%;
		left: 0;
		top: 0.75rem;
	    }
	    ul {
		margin-top: 0.5rem;
		margin-left: 3rem;

		li {
		    &:before {
			background: #003F44;
			border-color: #FFF;
		    }
		}
	    }
	}
    }
    .section-gray {
	background: #F5F5F5;
	padding: 5rem 0;
	margin: 5rem 0 2rem 0;

	p {
	    font-size: 1.8rem;

	    strong, span {
		display: block;
	    }
	    span {
		font-weight: 600;
		font-size: 1.8rem;
	    }
	}
	h2 {
	    margin-bottom: 4rem;
	    font-size: 2.8rem;
	    font-weight: 500;

	    span {
		font-size: 1.8rem;
		font-weight: 400;
		display: block;
		line-height: 1.8rem;
	    }
	}
	h3 {
	    color: #048086;
	    font-size: 2.8rem;
	    font-weight: 500;
	    margin-bottom: 1rem;
	}
	h4 {
	    color: #003F44;
	    font-size: 2.4rem;
	    font-weight: 500;
	    margin-bottom: 0.5rem;
	}
    }
    .btn-open {
	margin-bottom: 1px;
	padding-left: 3rem;
	position: relative;
	&:before {
	    content: '';
	    display: block;
	    height: 1px;
	    background: rgba(#003F44, 0.12);
	    position: absolute;
	    left: 0;
	    right: 0;
	    top: 50%;
	    margin-top: -1px;
	    z-index: 0;
	}
	&:hover {
	    color: #FFF;
	}
	.btn {
	    text-align: left;
	    min-width: 51rem;
	    color: #FFF;
	    position: relative;
	    svg {
		margin-top: 0.75rem;
		float: right;
		margin-left: 3rem;
		fill: #FFF;
		width: 2.4rem;
		height: 2.4rem;
	    }
	}
    }
}


@include media-breakpoint-down(sm){
    .afiliados-desafiliacion{
	.important{
	    flex-direction: column;
	    border-left: none;
	    border-right: none;
	    border-top: #003F44 solid 55px;
	    border-bottom: #003F44 solid 4px;
	    h3{
		margin: 0 0 2rem 0;
	    }
	    img{
		margin-left: 0rem;
		margin-right: 0rem;
		margin-top: -5rem;
		margin-bottom: 2rem;
	    }
	}
	.btn-open{
	    padding-left: 0rem;
	    &:before{
		display: none;
	    }
	    .btn{
		min-width: 100%;
		font-size: 1rem;
	    }
	}
	.border-left{
	    margin-top: 3.5rem;
	    border: none;
	    padding-left: 1.5rem;
	    padding-right: 1.5rem;
	}
    }
}