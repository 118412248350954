/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 03-sep-2018, 16:18:29
    Author     : Rodri
*/

.afiliados-solicitud-materiales {
    padding-top: 10rem;

    h1 {
        color: #047F85;
        font-size: 4.6rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }

    p {
        color: #048086;
        font-size: 2rem;
        margin-bottom: 2.5rem;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 7rem;

        h1 {
            font-size: 2.5rem;
        }
        .btn {
            margin-bottom: 1.5rem;
        }
    }
}

.afiliados-solicitud-materiales-form {
    background: rgba(#EAEEF1, 0.5);


    .help-block{
        color: #dc3535;

    }
    h2 {
        color: #003F44;
        font-size: 2.4rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    h3 {
        font-size: 2rem;
        color: #003F44;
        font-weight: 400;
        margin-bottom: 2rem;
    }
    form {
        .form-group {
            margin-bottom: 1.5rem;
        }
        .md-checkbox {
            margin: 0;
        }
        label {
            line-height: 1.8rem;
            color: rgba(#003F44, 0.94);
            font-size: 1.6rem;
            font-weight: 500;

            &:before {
                top: 1px;
                border-color: #003F44;
            }
        }
        input[type="checkbox"] {
            margin-right: 15px;
        }
        .form-control {
            border: #003F44 solid 1px;
            background: #FFF;
            color: rgba(#003F44, 0.94);
            font-size: 1.6rem;
            padding: 0 1.5rem;
            height: 4rem !important;
        }
    }
}