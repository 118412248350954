/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 16-ago-2018, 16:44:41
    Author     : PC03-MAXI
*/

ul.list-circle{
    list-style:none;
    li{
        &:before{
            content: " ";
            margin-right: 1rem;
            border-radius: 50%;
            border: 2px solid white;
            width: 1rem;
            height: 1rem;
            display: inline-block;
            position: relative;
            top: -1px;
        }
    }

    &.list-circle-gray{
        li{
            &:before{
                border:2px solid rgba(#333333,.54);
            }
        }
    }
    &.list-circle-green{
        li{
            &:before{
                border:2px solid $color1;
            }
        }
    }

}

ul.list-check{
    list-style: none;
    li{
        margin-bottom:2rem;


        &:before{

            font: normal normal normal 24px/1 "Material Design Icons";
            content: mdi('check-circle');
            color:white;
            border: none;
            margin-right: 1rem;
            width: auto;
            height: auto;
            position: relative;
            top: 2px;
        }
    }


    &.list-check-green{
        li{
            &:before{
                color:$color1;
            }
        }
    }

    &.list-check-gray{
        li{
            &:before{
                color:rgba(#333333,.54);
            }
        }
    }
}