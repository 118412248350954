/* ------------------------------------------------------------ *\
        Pager
\* ------------------------------------------------------------ */
.pagination {
    justify-content: center;
    margin-top: 0rem;
    margin-bottom: 1.5rem !important;
}


/* Dispositivos Pequeños y Tablets vertical , Celulares horizontal min-width:576px  */
@include media-breakpoint-up(sm) { 

}
/* Dispositivos Medianos , Tablets horizontal min-width:768px  */
@include media-breakpoint-up(md) { 

}
/* Dispositivos grandes , min-width:992px */
@include media-breakpoint-up(lg) { 

}
/* Escritorio , min-width:1200 px */
@include media-breakpoint-up(xl) { 

}

