/*
    Created on : 21-ago-2018, 16:16:31
    Author     : Rodri
*/

.section-haciendo-contacto {
    padding: 8.5rem 0 7rem 0;
    background: #EAEEF1;

    h1 {
        line-height: 6.5rem;
        color: #02576E;
        font-size: 6.5rem;
        margin-bottom: 2rem;
        font-weight: 500;

        i {
            width: 12.7rem;
            height: 12.7rem;
            background: #03576F;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            float: left;
            margin-right: 2.5rem;

            svg {
                width: 5rem;
                height: 5rem;
                fill: #FFF;
            }
        }
    }
    p {
        color: #01576D;
        font-size: 2.2rem;

        strong {
            font-weight: 500;
        }

        &.green {
            position: relative;
            background: #048086;
            color: #FFF;
            font-size: 1.8rem;
            /*text-align: center;*/
            margin: 0;
            padding: 2rem;
            font-weight: 400;
            border-top: #FABE00 solid 0.6rem;

            strong {
                /*display: block;*/
                /*font-size: 2.2rem;*/
                font-weight: 500;
            }
            img {
                float: left;
                margin-right: 1.5rem;
                margin-top: 1rem;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 3rem 0 3rem 0;

        h1 {
            display: flex;
            font-size: 2.5rem;
            align-items: center;

            i {
                width: 5rem;
                height: 5rem;
                float: none;
                margin-right: 1.5rem;

                svg {
                    width: 3rem;
                    height: 3rem;
                }
            }
        }
        p {
            font-size: 1.8rem;

            &.green {
                font-size: 1.6rem;
                /*padding: 5.6rem 2.5rem 3rem 2.5rem;*/
                font-weight: 400;
                text-align: center;
                /*border-top: #FABE00 solid 1.5rem;*/
                margin-top: 3rem;

                img {
                    display: block;
                    margin: 0 auto 1rem auto;
                    float: none;
                }
                strong {
                    /*font-size: 1.8rem;*/
                }
            }
        }
    }
}

.section-cuidandote {
    padding-top: 6rem;

    .border-t {
        border-top: #004E61 dotted 1px;
        margin-top: 3rem;
        padding-top: 4rem;
    }
    h2 {
        color: #03576F;
        font-size: 6.5rem;
        font-weight: 600;
        line-height: 6.7rem;
        margin-bottom: 1rem;
    }
    h3 {
        color: #03576F;
        font-size: 2.8rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    p {
        font-size: 1.8rem;
        color: #333333;
        margin-bottom: 1rem;

        strong {
            color: #02576E;
            display: block;
            font-weight: 600;
        }
    }
    ul {
        color: #333333;
        font-size: 1.6rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-left: 2.5rem;
        list-style: none;

        li {
            margin-bottom: 0.5rem;
            position: relative;

            &:before {
                content: '';
                display: inline-block;
                width: 1rem;
                height: 1rem;
                border: #333333 solid 2px;
                border-radius: 100%;
                margin-left: -2.5rem;
                float: left;
                margin-top: 0.7rem;
                opacity: 0.54;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 3rem 0 4rem 0;

        h2 {
            font-size: 2.5rem;
            line-height: 3rem;
        }
        h3 {
            font-size: 2.5rem;
        }
        p {
            font-size: 1.6rem;
        }
        .border-t {
            margin-top: 1.5rem;
            padding-top: 2rem;
        }
    }
}

.section-gold-service {
    padding: 8rem 0 8rem 0;
    position: relative;
    background: #048086;
    color: #FFF;

    &:before {
        content: '';
        top: 0;
        bottom: 0;
        right: 0;
        left: 50%;
        position: absolute;
        background: url('../img/planes-medicos/cuidado-personalizado/gold-service.jpg') center center no-repeat;
        background-size: cover;
    }
    h2 {
        font-size: 6.5rem;
        font-weight: 600;
    }
    h3 {
        font-size: 2.8rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
    }
    ul {
        color: #FFF;
        font-size: 1.6rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-left: 2.5rem;
        list-style: none;

        li {
            margin-bottom: 0.5rem;
            position: relative;

            &:before {
                content: '';
                display: inline-block;
                width: 1rem;
                height: 1rem;
                border: #FFF solid 2px;
                border-radius: 100%;
                margin-left: -2.5rem;
                float: left;
                margin-top: 0.7rem;
                opacity: 0.54;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 0 0 3rem 0;

        &:before {
            display: block;
            position: relative;
            left: 0;
            height: 20rem;
            margin-bottom: 1.5rem;
        }
        h2 {
            font-size: 2.5rem;
        }
        h3 {
            font-size: 2rem;
        }
    }
}