/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 18-may-2018, 11:08:38
    Author     : PC03-MAXI
*/

/* ------------------------------------------------------------ *\
        Header
\* ------------------------------------------------------------ */

body > header{
    box-shadow: 0 1px 10px 5px rgba(0,0,0,0.1);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;

    .top-bar{
        transition: all 0.5s ease;
        position:relative;
        display: flex;
        background:$color2;
        color:white;
        height: 5rem;
        visibility: visible;
        opacity: 1;

        .rate-container{
            display:flex;
            align-items: center;

            .rate-points{
                color:$orange;
                font-size:2.1rem;
                font-weight:700;
                margin-right: 1.5rem;


            }

            p{
                font-size: 1.6rem;
                font-weight:500;
            }


            .rate-stars{
                background:$va_light;
                height: 100%;
                display: flex;
                align-items: center;
                margin-right: 3.5rem;
                padding: 0rem 1.5rem;


                &:after {
                    content:"";
                    position:absolute;
                    width: 0;

                    right: -3rem;
                    top: 0;
                    height: 0;
                    border-top: 3rem solid $va_light;
                    border-right: 3rem solid transparent;
                }

                &:before{
                    content:"";

                    position:absolute;

                    right: -3rem;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-bottom: 3rem solid $va_light;
                    border-right: 3rem solid transparent;
                }
            }

        }

        .right-content{
            display:flex;
            margin-left:auto;
            margin-right: 5rem;
        }

        .social-net,.lang-selector,.font-size-selector{
            display: flex;
            list-style: none;
            align-items: center;
            font-size:1.4rem;
            color:white;
            font-weight:500;
            li{
                //border-left :1px solid $gray_blue;
                height: 100%;
                display: flex;
                align-items: center;
                overflow:hidden;
                a{
                    padding: 2rem 1.5rem;
                    &.a-small{
                        padding-top: 2.8rem;
                    }

                    &.active{
                        background:$va_light;
                    }


                    &:hover,&:active,&:focus{
                        background:$va_light;

                    }
                }
            }
        }

        .lang-selector,.font-size-selector{
            li{
                border-right :1px solid $gray_blue;
            }
        }

        .font-size-selector{
            font-size: 2.5rem;
        }

        .social-net{
            font-size: 2rem;
            li{
                a{
                    padding: 2rem 0.5rem;
                }
            }


        }

    }





    nav.navbar{
        transition: all 0.5s ease;
        background: rgba(white,0.95);
        padding: 0 3.2rem;
        height:11.6rem;
        .navbar-brand{
            /*            margin-right: 3rem;
                        margin-left: 3rem;*/
            margin-top:1rem;
            margin-bottom: 1rem;
        }


        .btn-call,.btn-search,.btn-share,.btn-lang-m{

            padding: 1.5rem 1rem;

            i{
                margin-left:0;
                font-size: 2.8rem;
            }


        }

        .navbar-toggler{
            border: none;
            font-size: 2rem;

        }

        .btn-search{
            margin-left: auto;
        }


        .btn-call,.btn-search,.btn-lang-m{
            i{
                margin-left:0;
                font-size: 2.8rem;
            }
        }

        .navbar-toggler{
            border: none;
            font-size: 2rem;

        }

        .btn-search{
            margin-left: auto;
        }
        .navbar-nav{
            padding: 0 2rem;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .nav-item{
                .nav-link{
                    transition: all .2s ease-out;
                    font-size:1.5rem;
                    font-weight:500;
                    line-height: 4.2rem;
                    padding: 0 1.5rem;
                    color:rgba($color3,.94);

                    svg {
                        width:18px;
                        height:18px;
                        vertical-align: middle;
                        fill:rgba($color3,.94);
                    }

                    &.active {
                        background: #03576F;
                        color: rgba(#FFF, 0.94);

                        svg {
                            fill: rgba(#FFF, 0.94);
                        }
                    }
                }

                ul {
                    display: none;
                    margin: 0;
                    padding: 1.5rem 0;
                    list-style: none;
                    position: absolute;
                    background: #03576F;
                    box-shadow: rgba(0,0,0,.2) 5px 5px 5px;

                    li {
                        position: relative;
                        &>ul{
                            padding: 0;
                        }
                        &:hover {
                            ul {
                                display: block;
                            }
                            & > a {
                                background: #DDDDDD;
                                color: #02576E;

                                svg {
                                    fill: #02576E;
                                }
                            }
                        }

                        a {
                            transition: all .2s ease-out;
                            display: block;
                            line-height: 3.6rem;
                            padding: 1rem 3.5rem .6rem;
                            color: #DDDDDD;
                            font-size: 1.6rem;
                            text-transform: none;
                            svg {
                                fill: #DDDDDD;
                                width: 1.5rem;
                                height: 1.5rem;
                                vertical-align: middle;
                                position: relative;
                                top: -1px;
                            }
                        }

                        ul {
                            position: absolute;
                            background: #DDDDDD;
                            left: 100%;
                            top: 0;
                            width: max-content;
                            @include media-breakpoint-down(sm){
                                width: 100% !important;
                            }
                            li {
                                a {
                                    color: #03576F;

                                    svg {
                                        fill: #03576F;
                                    }

                                    &:hover {
                                        background: #03576F;
                                        color: #DDDDDD;

                                        svg {
                                            fill: #DDDDDD;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover {
                    .nav-link{
                        background: #03576F;
                        color: rgba(#FFF, 0.94);

                        svg {
                            fill: rgba(#FFF, 0.94);
                        }
                    }

                    & > ul {
                        display: block;
                    }
                }
            }


            @include media-breakpoint-down(sm) {
                padding: 0 !important;


                .nav-item {
                    display: block;
                    width: 100%;



                    .nav-link {
                        position:relative;
                        background: rgba(#819CA9,0.1);
                        display: block;
                        width: 100%;
                        padding: 0.6rem 1.5rem;
                        border-bottom: 1px solid rgba(black,0.12);
                        display: flex;
                        align-items: center;
                        svg{
                            position: absolute;
                            right: 2rem;
                        }

                        /*                        &:hover{
                                                    background: rgba(#819CA9,0.1);
                                                }*/
                    }
                    &:last-child{

                        .nav-link {
                            border-bottom:none;
                        }
                    }
                    ul {
                        display: none !important;
                        left: 0 !important;
                        position: relative !important;
                        padding: 0.5rem 0;

                        &.active {
                            display: block !important;
                        }

                        li {
                            a {
                                padding: 0 1.5rem;
                            }
                        }
                    }
                }
            }
        }



        #searchCollapse{

            background:$color4;
            padding: 1.5rem;
        }


        #langCollapse{
            padding: 2rem 1rem;
            background: rgb(3,106,111);
            .lang-selector{
                list-style: none;
                display: flex;
                justify-content: flex-end;
                li{
                    padding: 0.5rem 3rem;
                    /*border: 1px solid $color2;*/
                    background: #FFF;
                    color:$color2;
                    margin: 0rem .5rem;
                }

            }

        }
        #sidebarCall{



            .call-head{
                background:$color1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top:1rem;
                padding-bottom:1rem;
                padding-left:1.5rem;
                padding-right:1.5rem;
                color: white;
                h4{
                    margin-right: 8rem;
                    font-size:2rem;
                }

                button{
                    color: white;
                    border:none;
                }
            }
            min-width: 100vw;
            max-width: 100vw;
            position: fixed;
            top: 0;
            left: -100vw;
            background: white;
            /* margin-left: -33vw; */
            height: 100vh;
            transition:all ease 0.3s;
            z-index: 99;
            &.open{

                left: 0;
            }


            .btn.btn-link.close{
                display:none;

            }
        }

        #shareCollapse{

            .top-bar{
                position: relative;
                display: flex;
                flex-wrap: wrap;
                background: #003F44;
                color: white;
                height: auto;


                .rate-container{

                    width: 100%;
                    padding: 0rem;
                    padding-right: 1rem;
                    .rate-stars{
                        height: 5rem;
                        background: none;
                        margin-right: 0rem;
                        &:after, &:before{
                            display: none;
                        }
                    }
                    .rate-points{
                        display: none;
                    }

                    p{
                        font-size:1.1rem;
                        text-align:center;
                    }

                }


                .share-content{
                    width: 100%;
                    border-top: 1px solid rgba(white,.12);
                    .social-net{
                        justify-content: center;
                        text-align: center;
                        flex-direction: initial;

                        li{
                            a{
                                font-size: 2.5rem;
                                color:rgba(white,.54);
                                padding: 1rem 0.5rem;
                            }
                        }
                    }

                }
            }

        }
        .search-content {
            margin-right:1.7rem;
            span{


                color: white;
                display: flex;
                align-items: center;
                font-size: 2.5rem;
                z-index: 2;
                background:$color4;
                padding-right: 1.8rem;
            }

            input.form-control{
                border-radius:0;
                border: none;
                height: 4.2rem;
                padding: 0 1.5rem;
                font-size:1.6rem;
                font-weight: 500;
                color:white;
                background:$color4;

                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color:white;
                    text-transform:uppercase;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    color:white;
                    text-transform:uppercase;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                    color:white;
                    text-transform:uppercase;
                }
                &:-moz-placeholder { /* Firefox 18- */
                    color:white;
                    text-transform:uppercase;
                }

                &:active,&:focus{
                    outline: none;
                }

            }

            &.search-content-mobile{

                margin-right: 0;
                input.form-control{

                    background:white;
                    color:$color4;

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color:$color4;
                        text-transform:uppercase;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color:$color4;
                        text-transform:uppercase;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color:$color4;
                        text-transform:uppercase;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color:$color4;
                        text-transform:uppercase;
                    }

                }

                span{
                    background:white;

                    i{
                        color: $color4;

                    }
                }
            }

        }


    }


    &.sticky{

        .top-bar{
            //display:none!important;
            position:absolute;
            visibility: hidden;
            opacity: 0;
        }


        nav.navbar{
            background:white;
            height: 9rem;
        }

    }




    @include media-breakpoint-down(lg) {
        position: relative;





        nav.navbar{
            padding: 0 !important;


            button, a.btn.btn-link{
                border:1px solid $color2;
                width: 4rem;
                height: 4rem;
                &:last-child{
                    margin-right:0.5rem;
                }

                &:hover{
                    border:1px solid $color2;

                }

            }


            .navbar-toggler{
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }
            .navbar-brand{
                margin-right: 2rem;
                img{
                    max-width: 20vw;
                }
            }

            height:auto;
            justify-content: flex-start;
            .navbar-nav{
                padding-top:3rem;
                padding-bottom:2rem;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;

            }


        }
    }


    &.sticky{

        .top-bar{
            //display:none!important;
            position:absolute;
            visibility: hidden;
            opacity: 0;
        }


        nav.navbar{
            height: 9rem;
        }

    }




    @include media-breakpoint-down(lg) {
        position: relative;

        nav.navbar{

            padding: 0 !important;
            .navbar-brand{
                margin-right: 2rem;
            }
            height:auto;
            justify-content: flex-start;
            .navbar-nav{
                padding-top:3rem;
                padding-bottom:2rem;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;

            }


        }
    }


}


header {
    .lang-selector {
        font-size: 1.4rem;
    }

    .locale-switcher {
        margin-right: 15px;
        padding-right: 15px;
        border-right: 1px solid rgba($color3, .94);

        li:first-child {
            margin: 0 !important;
        }

        li {
            a {
                padding: 8px;
                background: rgba($color3, .94);
                color: #ffffff;
            }
        }
    }
}

/* Dispositivos Pequeños y Tablets vertical , Celulares horizontal min-width:576px  */
@include media-breakpoint-down(sm) {
    body > header nav.navbar {
        padding: 0 1.5rem;

        .btn-search, .btn-call , .btn-share , .btn-lang-m{
            padding: 0;
        }
        .btn-call, .btn-share , .btn-lang-m {
            margin-left: 1rem;
        }

        .btn-lang-m {

            margin-right:1.5rem;
        }
    }
}
/* Dispositivos Medianos , Tablets horizontal min-width:768px  */
@include media-breakpoint-up(md) {

}
/* Dispositivos grandes , min-width:992px */
@include media-breakpoint-up(lg) {

}
/* Escritorio , min-width:1200 px */
@include media-breakpoint-up(xl) {
}


@media only screen and (min-width: 990px) and(max-width: 1575px){
    body{
        header{
            nav.navbar{
                .navbar-nav{
                    .nav-item{
                        .nav-link{
                            height: 4.2rem;
                            line-height: 100%;
                            display: flex;
                            align-items: center;
                            padding: 0 1rem;
                            font-size: 1.2rem;
                        }
                    }
                }
                form.form-inline{
                    flex: 0.2;
                    .search-content{
                        flex: 0.2;
                        input.form-control{
                            font-size: 1.2rem;
                        }
                    }
                }
                button.call{
                    font-size: 1.2rem;
                }
            }
            .top-bar{
                .rate-container{
                    p{
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and(max-width: 1200px){
    body > header nav.navbar {
        padding: 0 1.5rem;

        .btn-search, .btn-call , .btn-share{
            padding: 0;
        }
        .btn-call, .btn-share {
            margin-left: 1rem;
        }

        .btn-share{

            margin-right:1.5rem;
        }
    }
}
