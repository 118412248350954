.page-responsabilidad-social-corporativa{
    .go-next {
        position: absolute;
        left: 0;
        top: 44rem;
        z-index: 1;
        display: flex;
        width: 11.6rem;
        height: 11.6rem;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: rgba(#47989D, 0.2) solid 2.3rem;

        span {
            transition: all .4s ease-out;
            background: #FFF;
            display: flex;
            width: 7rem;
            height: 7rem;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
        }
    } 
    .section-banner {
        background-color: #1D868B;
        background-size: auto 100%;
        background-repeat: no-repeat;
        min-height: 50rem;
        background-position: right center;

        &:before {
            display: none;
        }
        h2 {
            font-size: 5.4rem;
        }
        p {
            margin: 0;
            font-size: 2.1rem;
            line-height: 2.6rem;
        }
    }

    article {
        position: relative;
        margin: 0;
        background-size: auto 100%;
        background-position: center right;
        background-repeat: no-repeat;
        overflow: hidden;
        text-align: right;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 60%;
            z-index: 0;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+90,ffffff+100&1+0,0.78+90,0+100 */
            background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0.78) 90%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0.78) 90%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0.78) 90%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
        }

        .bg {
            display: none;
        }
        h2 {
            color: #003F44; 
            font-size: 4rem;
        }
        h3 {
            color: #003F44;
            font-size: 2.1rem;
        }
        .cont-text {
            transition: all .4s ease-out;
            position: relative;
            padding: 10rem 0 20rem 0;

            .descripcion {
                display: none;
                padding-top: 2rem;
            }
            .text {
                position: relative;
                z-index: 1;
            }
            ul{
                padding-left: 2rem;
                list-style-position: inside;
                direction: rtl;
                li{
                    margin-bottom: .2rem;
                }
            }
            &:before {
                transition: all .4s ease-out;
                content: '';
                background: rgba(#EEEEEE, .9);
                position: absolute;
                right: -4rem;
                top: 0;
                bottom: 0;
                width: 100vw;
                z-index: 0;
            }
            .more {
                transition: all .4s ease-out;
                position: absolute;
                right: -11.8rem;
                /*bottom: -14.6rem;*/
                top: 8.8rem;
                align-items: center;
                display: flex;
                transform: translate3d(0,0,0);
                span {
                    transition-delay: .2s;
                    font-size: 1.6rem;
                    font-weight: bold;
                    color: #003F44;
                }
                i {
                    margin: 2rem;
                    display: flex;
                    width: 11.6rem;
                    height: 11.6rem;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                    border: rgba(#47989D, 0.2) solid 2.3rem;

                    span {
                        transition: all .4s ease-out;
                        background: #FFF;
                        display: flex;
                        width: 7rem;
                        height: 7rem;
                        justify-content: center;
                        align-items: center;
                        border-radius: 100%;
                    }
                } 
            }
        }

        &.article-right {
            background-position: center left;
            text-align: left;

            &:before {
                left: auto;
                right: 0;
                transform: rotate(180deg);
                width: 60%;
            }
            .cont-text {
                &:before {
                    content: '';
                    background: rgba(#EEEEEE, .9);
                    position: absolute;
                    left: -4rem;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 100vw;
                    z-index: 0;
                }
                ul{
                    list-style-position: inside;
                    direction: ltr;
                }
                .more {
                    right: auto;
                    left: -11.8rem; 
                    /*left: -13.6rem;*/ 
                    flex-direction: row-reverse;
                }
            }

            &.active{
                .cont-text{
                    .more{
                        transform: translate3d(0, -19rem, 0);
                    }
                }
            }
        }

        &.active {
            .cont-text {
                padding-bottom: 10rem;
                &:before {
                    right: -6rem;
                    background: #FFF;
                }
                .more {
                    /*top: 4rem;*/
                    /*right: -13.6rem;*/
                    /*bottom: 100%;*/
                    /*                    bottom: auto;
                                        top: auto;*/
                    /*transform: translateY(-5rem);*/
                    transform: translate3d(2rem, -19rem, 0);
                    & > span {
                        transition-delay: 0s;
                        opacity: 0;
                        pointer-events: none;
                    }
                    i {
                        border: 0;
                        span {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
    }
}

#plataforma-sensibilidad{
    display: none;
    width: 100rem;
    padding: 0rem;
    padding-right: 1.5rem;
    @include media-breakpoint-down(sm){
        padding: 1.5rem;
    }
    h2{
        font-size: 2.4rem;
        font-weight: 500;
        color: #006A6C;
        margin-bottom: 1.5rem;
    }
    p{
        font-size: 1.8rem;
        color: #333;
        margin-bottom: 2rem;
        line-height: 120%;
        font-weight: 400;
    }
}

@include media-breakpoint-down(sm){
    .page-responsabilidad-social-corporativa{
        .section-banner{
            h2{
                font-size: 2.2rem;
            }
        }
        .go-next{
            left: auto;
            /*right: 2rem;*/
        }
        article{
            text-align: left;
            padding: 0rem 2rem;
            /*            &#first-step{
                            .cont-text{
                                padding-top: 14rem;
                            }
                        }*/
            &:before{
                display: none;
            }
            &.active{
                .cont-text{
                    &:before{
                        right: -2rem;
                    }
                    .more{
                        position: absolute;
                        top: -12rem;
                        right: -4rem;
                        justify-content: flex-end;
                        flex-direction: row;
                        transform: translate3d(0,0,0) !important;
                    }
                }
            }
            &.article-right{
                .cont-text{
                    &:before{
                        left: -2rem;
                    }
                    .more{
                        left: -2.5rem;
                    }
                }
            }
            h2{
                line-height: 105%;
                margin-bottom: 1.5rem;
                font-size: 3rem;
                margin-bottom: 1.5rem;
            }
            h3{
                font-size: 1.8rem;
            }
            .cont-text{
                padding: 8rem 0rem 6rem;
                &:before{
                    right: -2rem;
                    background: rgba(238, 238, 238, 0.85);
                }
                .more{
                    position: relative;
                    flex-direction: row-reverse;
                    transition: unset;
                    left: -2rem;
                    top: 1rem;
                    justify-content: flex-end;
                }
            }
        }
    }
}