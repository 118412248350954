/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 16-oct-2018, 16:15:41
    Author     : Rodri
*/

.section-centro-prensa {
    h1 {
        color: #003F44;
        font-size: 6rem;
        font-weight: 500;
    }
    h3 {
        color: #047F85;
        font-size: 2.4rem;
        font-weight: 500;
    }
    .gray {
        padding: 2rem;
        background: #EEEEEE;
    }
    p {
        a:not(.tel){
            color: #047F85;
        }
    }
    a.mailto{
        text-decoration: underline;
    }
}

.list-noticias{
    #infscr-loading {
        clear: both;
        text-align: center;
        padding-top: 2rem;
        width: 100%;
        margin-bottom: 2rem;
    }
    article{
        margin-bottom: 3rem;
    }
}


@include media-breakpoint-down(sm){
    section.section-centro-prensa{
        h1{
            font-size: 3rem;
        }
        h3{
            font-size: 2.2rem;
        }
    }
}