/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 17-may-2018, 17:19:10
    Author     : codigowww
*/

.asistente{
    position:fixed;
    z-index: 10;
    background: $yellow;
    border-top-left-radius: 4rem;
    border-bottom-left-radius: 4rem;
    width: 24.5rem;
    height:6.5rem;
    top: 55%;
    right: 0;
    align-items: center;
    transition: all ease 0.3s;
    cursor: pointer;
    &:before{
        content: url(../img/asistente.svg);;
        //border-radius: 50%;
        //background:white;
        position: relative;
        margin-left: 0.5rem;
        left:0;
        width:5.3rem;
        height:5.3rem;
        display:block;
    }
    .copy,.copy_hov{
        color:black;
        margin-left:1.5rem;
        font-size:1.8rem;
        font-weight:500;
        margin-bottom:0;
        opacity:1;
    }
    .copy_hov{
        /*        display:none;*/
        position:absolute;
        opacity:0;
        font-size:1rem;
        transition: all 0.3s ease-out 0s;
    }
    &:hover{
        width: 47rem;
        .copy{
            display:none;
            opacity:0;
        }
        .copy_hov{
            /*            display:block;*/
            position:relative;
            font-size:1.8rem;
	    line-height: 2.2rem;
            opacity:1;
            transition: all 0.1s ease-out 0.3s;
        }
    }

    @include media-breakpoint-down(lg) { 
        display:none;   
    }
}
.btn-asistente{
    background: $yellow;
    border-radius: 4rem;
    width: auto;
    height: 4.7rem;
    display: flex;
    align-items: center;
    color:#1F1F1F;
    text-transform: none;
    font-size:2rem;
    &:before{
        content: " ";
        background: url(../img/asistente.svg);
        background-size: 3.3rem;
        position: relative;
        margin-left: 0;
        left: -1rem;
        width: 3.3rem;
        height: 3.3rem;
        display: block;
    }
}

