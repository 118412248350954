.page-cuidado-y-servicio-medico{
    h2{
        font-size: 3.4rem;
        font-weight: 400;
        line-height: 120%;
        color: #003F44;
        margin-bottom: 6rem;
    }
    .card{
        .card-body{
            .card-title{
                font-size: 2.8rem;
            }
        }
    }
}