/* ------------------------------------------------------------ *\
        Footer
\* ------------------------------------------------------------ */

footer{
    padding-top:5rem;
    padding-bottom:5rem;
    background:$va_dark;
    color: rgba(white,.54);
    font-size:1.3rem;
    font-weight:500;


    .top-row{


        padding-bottom: 3rem;
        .border-left{
            border-left : 1px dotted rgba(white,.42)!important;
            padding-left:6rem;
        }


        .brand-cont{

            display:flex;
            .brand{

                flex: 0 0 25%;
                max-width: 25%;
            }
            p{
                flex: 0 0 64%;
                max-width: 64%;
            }
        }


        p{
            color:white;
        }

        .nav-footer{

            list-style:none;
            list-style: none;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li{

                padding: 1rem 0.8rem;
                a{

                    color:white;
                }
            }
        }

    }


    .mid-row{
        border-bottom : 1px dotted rgba(white,.42);
        border-top : 1px dotted rgba(white,.42);
        padding-bottom: 3rem;
        padding-top: 3rem;
        p{
            font-size:1.3rem;

        }

        .extra-links{

            list-style: none;
            display:flex;

            flex-direction: row;
            flex-wrap: wrap;
            li{

                margin-top: .8rem;
                margin-bottom: .8rem;
                &:after{
                    content:"|";
                }

                a{

                    &:hover,&:focus,&:active{
                        color:white;
                    }
                }

                &:last-child{
                    &:after{
                        content:"";
                    }
                }
            }


            a{
                padding-left:0.5rem;
                padding-right:0.5rem;
            }
        }

        .social-cont{
            margin-left: auto;



            p{
                margin-bottom:0;
            }

        }

    }

    .bot-row{

        padding-top:3rem;
        p{
            font-size:1.3rem;

            a {
                color: rgba(white,.54);
            }
        }
    }


    @include media-breakpoint-down(lg) { 
        .top-row{

            padding-bottom:0;
            .border-left{
                padding-left:1.5rem;
                padding-top:1.25rem;
                padding-bottom:0;
            }
            .brand-cont{
                display:block;

                border-bottom : 1px dotted rgba(white,.42);

                padding-bottom:1.5rem;
                .brand{
                    margin-bottom:1.5rem;
                    display: block;
                }
                p{
                    max-width:100%;
                }

            }
        }

        .mid-row{

            border-bottom : 1px dotted rgba(white,.42);

            border-top : none;

            .social-cont{
                margin-left: 1.5rem;
                p{
                    font-size:1.2rem;
                    margin-bottom:1.5rem;
                }
                .social-net > li > a{
                    margin-left:3rem;
                    font-size:2.5rem; 
                }



            }

        }



    }

    @include media-breakpoint-down(sm) {
        .top-row{
            .border-left{
                border-left: 0 !important;
            }
        }
        .social-cont {
            display: flex;
            align-items: center;

            p {
                margin-bottom: 0 !important;
            }
        }
	.extra-links {
	    margin-bottom: 3rem;
	    
	    li:after {
		display: none;
	    }
	}
    }
}


