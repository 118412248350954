#fbLiveChatModalDlg{
    /*position: relative;*/
    /*    display: flex;
        align-items: center;
        justify-content: center;*/
    .modal-dialog{
        max-width: 780px;
        margin: 0rem auto 0rem;
        transform: translate(0%, -50%);
        top: 50%;
        .modal-content{
            border-radius: 0;
            padding: 5rem;
            padding-bottom: 7rem;
            .alert.hide{
                display: none;
            }
            .alert-danger{
                top: 1.5rem;
                width: 50%;
            }
            .modal-header{
                border-radius: 0;
                border-bottom: none;
                position: absolute;
                right: 0rem;
                top: 0rem;
                .close{
                    width: 5rem;
                    height: 5rem;
                    background: #003F44;
                    color: #FFF;
                    font-size: 1.4rem;
                    opacity: 1;
                    span{
                        font-size: 2rem;
                    }
                }
            }
            .modal-body{
                padding: 0rem;
                h4{
                    font-size: 3.4rem;
                    font-weight: 400;
                    line-height: 110%;
                    color: #047F85;
                    width: 80%;
                    margin-bottom: .5rem;
                }
                h5{
                    font-size: 2rem;
                    font-weight: 300;
                    line-height: 120%;
                    color: #333;
                    margin-bottom: 2rem;
                }
                label{
                    font-size: 1.4rem;
                    font-weight: 400;
                    line-height: 120%;
                    color: #666;
                    display: block;
                    margin-bottom: 0.2rem;
                }
                input[type="text"], input[type="email"], textarea{
                    font-size: 1.6rem;
                    font-weight: 400;
                    line-height: 4.2rem;
                    color: #003F44;
                    border: 1px solid;
                    padding: 0rem 1.5rem 0rem 1rem;
                    width: 100%;
                }
                textarea{
                    line-height: 120%;
                    padding-top: 1rem;
                    resize: none;
                    height: 9.3rem;
                }
                .radio-buttons{
                    .md-radio{
                        display: inline-block;
                        margin-right: 2rem;
                        label{
                            cursor: pointer;
                            line-height: 2.4rem;
                            padding-left: 3rem;
                        }
                    }
                    input{
                        margin-right: .75rem;
                    }
                    .md-radio [type="radio"]:checked + label:after, .md-radio [type="radio"]:not(:checked) + label:after{
                        background: #666;
                    }
                    .md-radio [type="radio"]:checked + label:before, .md-radio [type="radio"]:not(:checked) + label:before{
                        border-color: #666;
                    }
                }
                .comunicarse{
                    p{
                        font-size: 1.5rem;
                        font-weight: 400;
                        line-height: 140%;
                        color: #333;

                    }
                }
                .required-fields{
                    position: absolute;
                    right: 0rem;
                    font-size: 1.2rem;
                    color: #666;
                    font-style: italic;
                }
            }
            .modal-footer{
                border-top: none;
                position: absolute;
                bottom: 0rem;
                right: 0rem;
                padding: 0rem;
                .btn{
                    font-size: 1.6rem;
                    font-weight: 700;
                    line-height: 4.2rem;
                    &-submit{
                        border: 1px solid #47989D;
                        width: 19rem;
                        color: #036A6F;
                        background: #FFF;
                        &:hover{
                            background: #47989D;
                            color: #FFF;
                        }
                    }
                    &-close{
                        color: #666;
                        background: #FFF;
                        width: 12rem;
                        &:hover{
                            color: #111;
                        }
                    }
                }
            }
        }
    }
}

#surveysubmit{
    display: flex !important;
    margin-top: 2rem;
    #aicsurveysend{
        margin-left: 0rem;
        margin-right: 1rem;
    }
    #aicsurveycancel{
        color: #003f44;
    }
}

/*#av_chat_header{
    position: fixed;
    top: 0rem;
    z-index: 99999;
    width: 35rem;
}*/

@include media-breakpoint-down(sm){
    #fbLiveChatModalDlg .modal-dialog .modal-content .modal-footer{
        bottom: -6rem;
    }
}