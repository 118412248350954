/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 04-jul-2018, 11:16:59
    Author     : PC03-MAXI
*/

.representantes-ventas,
.solicitud-afiliacion,
.oficinas-correo {
    background: #EEE;
    
    .border-bottom {
	padding-bottom: 5rem;
	border-bottom: #94ABB6 dotted 1px !important;
    }
    .oficina-item p, p {
	font-size: 1.6rem !important;
	line-height: 2rem !important;
    }
}

.section-requisitos{

    padding-top:10rem;
    color: $color1;
    h2{

        font-weight:700;
        font-size:4.6rem;
        color: $color1;
        margin-bottom:2.5rem;
    }

    h4{
        font-weight:500;
        font-size:2rem;
        color: $color1;
    }

    p{
        font-weight:400;
        font-size:2rem;
        color: $color1;
        margin-bottom:0;
    }

    .cumple{

        margin-bottom:5rem;
    }

    .requisitos-detail-row{
	border-bottom: #036A6F dotted 1px;
	padding-bottom: 5rem;
        margin-bottom: 5rem;


        .requisitos-list{
            margin-left: 2rem;
            list-style:none;
            display:flex;
            flex-direction:column;
            align-items: flex-start;
            height: 100%;
            justify-content: space-between;
            li{
                padding-left: 1rem;
                padding-right: 1rem;
                color: $color1;
                font-size: 2rem;

                &:before{
                    font: normal normal normal 24px/1 "Material Design Icons";
                    content: mdi('check-circle');
                    margin-right:1rem;
                    color: $color1;
                    float: left;
                    margin-left: -3.5rem;
                    margin-top: 0.25rem;
                }
            }
        }


        .requisitos-especificos{

            color: $color2;


            h3{
                font-size:2.4rem;
                font-weight:500;
                color: $color2;
		padding-bottom: 1.5rem;
                margin-bottom: 2rem;
		border-bottom: #036A6F dotted 1px;
            }

            h4{

                font-size:2rem;
                font-weight: 700;
                color: $color2;
                margin-bottom:1.7rem;
            }        

            p{
                color: $color2;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding-top: 7rem;

        h2 {
            font-size: 3.2rem;
            margin-bottom: 1.5rem;
        }
        h4, p {
            font-size: 1.8rem;
        }
        .cumple {
            margin-bottom: 2rem;
        }
        .requisitos-detail-row {
            margin-bottom: 1rem;

            .requisitos-list {
                li {
                    font-size: 1.6rem;

                    &:before {
                        margin-top: 0;
                    }
                }
            }
            .requisitos-especificos {
                padding-top: 2rem;

                h3 {
                    font-size: 1.8rem;
                    margin-bottom: 1rem;
                }
                h4 {
                    margin-bottom: 1rem;
                }
                p {
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
}


section.representantes-ventas{


    .tip{
        margin-bottom: 5.8rem;
    }


    .representante-item{

        border-right:1px dotted  #94ABB6;

        h4{
            font-size:2.4rem;
            font-weight:500;
            color:$color2;
            margin-bottom:1.2rem;

        }


        p{
            font-size:1.8rem;
            color:$color2;
            font-weight:500;
            a.tel{
                font-size:1.8rem;
                font-weight:500;
                margin-bottom:2rem;
            }
        }
    }


    .horario{

        p{
            font-weight:500;   
        }

    }
    @include media-breakpoint-down(sm) {
        .tip {
            margin-bottom: 2rem;
        }
        .representante-item {
            h4 {
                font-size: 1.8rem;
                margin-bottom: 1rem;
            }
            p {
                font-size: 1.6rem;
                margin-bottom: 1.5rem;

                a {
                    font-size: 1.6rem;
                }
            }
        }
    }
}

section.solicitud-afiliacion{
    .tip{
        margin-bottom: 3rem;
    }  

    .help{
        margin-bottom: 5rem;
        font-size:2rem;
        color:$color2;
    }

    @include media-breakpoint-down(sm) {
        .tip {
            margin-bottom: 2rem;
        }
        .help {
            margin-bottom: 2rem;
            font-size: 1.6rem;
        }
    }
}

section.oficinas-correo{


    .tip{
        margin-bottom: 5.8rem;
    } 

    p{
        font-size:1.8rem;
        color:$color2;
    }

    .oficina-item{

        border-right:1px dotted #94ABB6;


        h4{
            font-size:2.4rem;
            font-weight:500;
            color:$color2;
            margin-bottom:1.2rem;

        }

        p{
            font-size:1.8rem;
            color:$color2;


        }
    }

    @include media-breakpoint-down(sm) {
        .tip {
            margin-bottom: 2rem;
        }
        .oficina-item {
            h4 {
                font-size: 1.8rem;
                margin-bottom: 1rem;
            }
            p {
                font-size: 1.6rem;
                margin-bottom: 1.5rem;

                a {
                    font-size: 1.6rem;
                }
            }
        }
    }
}