/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 10-jul-2018, 15:50:16
    Author     : PC03-MAXI
*/

section.section-orientacion{
    background:$color2;
    position:relative;
    padding-bottom: 5rem;
    padding-top: 4.5rem;

    .tooltip{
        font-size: 3rem !important;
    }

    .triangle{
        width: 0;
        height: 0;
        border-top: 30px solid transparent;
        border-right: 30px solid $color2;
        border-bottom: 30px solid transparent;
        z-index: 32;
        position: absolute;
        right: 50%;
        top: 25%;
    }

    .help-block {
        padding-top: 0.5rem;
        font-size: 1.2rem;
        color: rgba(red, 0.92);
    }

    .title{
        font-size: 5rem;
        line-height: 5rem;
        font-weight:500;
        color:white;
        /*font-weight:bold;*/
        padding-bottom: 2rem;
        margin-bottom:2.8rem;
        border-bottom : 1px dotted rgba(white,.94);
        width:100%;
    }




    .subtitle{
        margin-bottom:0 !important;
        color:#FFF !important;
        font-weight:400 !important;
        font-size: 2.4rem !important;
    }


    form{

/*        input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus, 
            input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset;
        }*/

        .req-fields{
            color:rgba(white,.50);
            font-size:1.6rem;
            font-weight: 500;
            /*            position: relative;
                        top: -2rem;*/
            @include media-breakpoint-down(lg) { 
                top: 0;
            }

        }
        #orientacionform-telefono_caracteristica{
            width: 6rem;
        }

        .md-checkbox label:before{
            top: 5px;
        }

        .fields-telefono{
            margin-top: 2rem;
        }

        /*        .field-email{
                    margin-bottom: 1rem;
                }*/

        .info-links{
            color: #FFF;
            display: flex;
            flex-direction: column;
            a{
                margin-bottom:.5rem;
                font-size:1.6rem;
                font-weight:500;
                cursor: auto;
                i{
                    margin-left:1.4rem;
                    cursor: pointer;
                }
            }

        }

        .btn.btn-block{
            margin-top: 0rem;
        }


        .textarea-cont{
            border-top : 1px dotted rgba(white,.94);
            padding-top: 2.8rem;
            margin-top: 2rem;
            margin-bottom: 3rem;
            position:relative;
            textarea{
                border:none;
                background:transparent;
                width:100%;
                height:7rem;
                color:rgba(white,.54);
                font-size:1.6rem;
                font-weight:400;
                resize: none;

                &:focus,&:active,&:hover{
                    outline: none;
                }

            }

            /*            &:after{
                            content: " ";
                            pointer-events: none;
                            width: 100%;
                            height: 11rem;
                            position: absolute;
                            bottom: 0;
                             Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#003f44+0,003f44+100&0+0,1+100 
                            background: -moz-linear-gradient(top, rgba(0,63,68,0) 0%, rgba(0,63,68,1) 100%);  FF3.6-15 
                            background: -webkit-linear-gradient(top, rgba(0,63,68,0) 0%,rgba(0,63,68,1) 100%);  Chrome10-25,Safari5.1-6 
                            background: linear-gradient(to bottom, rgba(0,63,68,0) 0%,rgba(0,63,68,1) 100%);  W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ 
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00003f44', endColorstr='#003f44',GradientType=0 );  IE6-9 
                        }*/

        }



        .warning{
            align-items: center;
            i{
                font-size: 2.6rem;
            }
            .text{
                font-size:1.6rem;
                color:white;
                font-weight:500;
                p{
                    font-size: 1.4rem;
                    font-weight: 500;
                }
            }
        }


    }

    @include media-breakpoint-down(lg) { 

        .title{
            margin-top: 2rem;
            font-size:3.4rem;

        }

        .subtitle{
            font-size:2rem;
            margin-bottom: 2rem;
        }
    }
    .bg{
        position:absolute;
        width:50%;
        left:0;
        top:0;
        background:url(../img/bg/contact.jpg);
        background-size:cover;
        background-repeat:no-repeat;
        background-position:bottom center;
        height: 100%;


        .bg-title{
            height: 36.1rem;
            background: rgba($color1,.90);
            width: 100%;
            position: absolute;
            bottom: 0;
        }
    }

    .left-text-cont{
        justify-content: flex-end;
    }

    @include media-breakpoint-down(sm){
        section.section.orientacion{
            padding-top: 0rem;
        }
    }

    @media only screen and (min-width: 990px) and(max-width: 1200px){
        form{
            label{
                font-size: 1.2rem !important;
            }
        }
    }

    /*@media only screen and (min-width: 990px) and(max-width: 1575px){*/
    form{
        label{
            font-size: 1.4rem;
        }
    }
    .bg{
        background-position: center 17vw;
        @media only screen and (min-width: 1800px) and(max-width: 1920px){
            background-position: center 8vw;
        }
        .bg-title{
            top: 0;
            bottom: auto;
            background: rgba(4, 128, 134, 1);
        }
    }
    .left-text-cont{
        justify-content: flex-start;
    }
    /*}*/

}