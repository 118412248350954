/* ------------------------------------------------------------ *\
        HOME
\* ------------------------------------------------------------ */

section.home-main{
    /*background:url(../img/bg/header.jpg);*/
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #003f44;

    display: flex;
    padding-top:0;
    padding-bottom:0;

    .content{
        width:100%;
        position:relative;
    }

    .container-fluid{
        position:relative;
        z-index: 1;
        padding-top: 2.5rem;
        padding-bottom: 3.2rem;
        @include media-breakpoint-down(sm){
            padding-top: 0rem;
        }
    }
    .bg{
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNS8xNS8xOFGbqDYAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAGElEQVQImQXBAQEAAAABIEPtdyMVFAuGHoR+DnmrFX1FAAAAAElFTkSuQmCC');
        background-repeat: repeat;
        position:absolute;
        width:50%;
        height: 100%;
        z-index: 0;
    }
    .bg-l{
        left:0;
        background:rgba(#819CA9,.85);
        //background:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNS8xNS8xOFGbqDYAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAGklEQVQImWNsnLMyhoGBoYuJgYGhi4GBoQwAKgIDsBvbuVcAAAAASUVORK5CYII=');
        background-repeat: repeat;
    }
    .bg-r{
        left:50%;
        background:rgba(#1D3038,.8);
        //background:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNS8xNS8xOFGbqDYAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAGklEQVQImWOUNbCIYWBg6GJiYGDoYmBgKAMAFf8Cbwq1ZygAAAAASUVORK5CYII=');
        background-repeat: repeat;
    }

    h2{
        font-size: 4.8rem;
        color:white;
        font-weight:500;
        margin-bottom: .5rem;
    }

    p{
        font-size:2.8rem;
        color:rgba(white,.92);
        font-weight: 500;
        margin-bottom: 0rem;
        line-height: 3rem;
    }

    .cont-box-home{
        padding: 1rem 3rem 1.5rem 3rem;
    }

    span.llama{
        font-size:2rem;
        color:white;
        font-weight:500;
        margin-bottom:1rem;
        margin-top:1rem;
    }

    .telefono{

        font-size:3.4rem;
        font-weight:bold;
        padding: 0rem;
    }

    btn{

        font-size:1.4rem;
        font-weight:500;

    }

    .col-l{
        /*padding-right: 5rem;*/

    }

    .col-r{
        /*padding-left: 5rem;*/
    }

    .cont-box-home {
	padding: 0 3rem 0 3rem;
    }


    @include media-breakpoint-down(lg) {
        height:auto;
        background-image: none!important;
        .container{
            padding-top:0;
            padding-bottom: 0;
        }

        h2{

            font-size:3.3rem;
            margin-bottom: 1.5rem;
        }

        p{
            font-size:2rem;
            margin-bottom: 2rem;
        }
        .col-l{
            background:$gray;
            padding-top:3rem;
            padding-bottom:3rem;
            padding-left:1.5rem;
            padding-right:1.5rem;
            .btn{
                margin-bottom:1.5rem;
            }
        }
        .col-r{
            padding-left:1.5rem;
            background:#546E7A;
            padding-top:3rem;
            padding-bottom:3rem;
            padding-right:1.5rem;
        }
    }

    @include media-breakpoint-down(sm) {
        .col-l, .col-r {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;

            h2 {
                font-size: 2.8rem;
                margin-bottom: 1rem;
            }
            p {
                font-size: 2rem;
                line-height: 2.4rem;
                margin-bottom: 1.5rem;
            }
            .btn {
                margin-bottom: 0;
                line-height: 4.2rem;
                height: 4.2rem;
            }
        }
    }
}




section.home-cards{

    h2.title {
        font-size: 3.4rem;
        font-weight: 400;
        color: #003F44;
        margin-bottom: 3rem;

        @include media-breakpoint-down(sm) {
            font-size: 2rem;
            margin-bottom: 2rem;
        }
    }

    background:rgba($gray_blue,.2);

    @include media-breakpoint-down(sm) {
        .btn-outline-primary {
            margin: 0 0 1rem 0 !important;
            width: 100%;
            display: block;
        }
    }

}






section.home-servicios{

    background:rgba($gray_blue,.1);


    .title{
        font-size:3.4rem;
        font-weight:bold;
        color:$va_light;
        margin-bottom:4rem;
    }


    .card{
        padding-right: 3rem;
        min-height: 16rem;
        .card-body{
            padding-bottom: 2rem;
        }
        .card-title{
            margin-bottom: 2rem;
        }
        .card-link{
            font-size: 1.4rem;
        }
    }



}



section.home-links{
    background:#EEEEEE;


    .card{
        padding-bottom: 0rem;
        height: 100%;

        .card-body{
            align-items: flex-start;
            display: flex;
            flex-direction: column;

            padding: 2rem 2rem;

        }
        .card-title{
            margin-bottom: 1rem;
            font-size: 2rem;
        }

        .card-subtitle{
            font-size:1.6rem;
            margin-bottom: 2rem !important;
        }

        .card-link{
            font-size: 1.4rem;
        }
    }


    @include media-breakpoint-down(lg) {
        .card{


            height:auto;

            .card-subtitle{
                margin-bottom:3rem!important;
            }

            .btn{
                max-width: 70%;
            }

        }

    }


    @include media-breakpoint-down(lg) {
        .card{


            height:auto;

            .card-subtitle{
                margin-bottom:3rem!important;
            }

            .btn{
                max-width: 70%;
            }
        }

    }
}


section.home-articulos{
    padding-top:0;
    position:relative;

    &.icon-white {
        .icon-top{
            background: #FFF;
        }
    }
    .container{
        position:relative;
    }
    .icon-top{
        position: absolute;
        top: -10rem;
        /* left: 5rem; */
        background: #EEEEEE;
        padding: 1rem;
        z-index: 1000;
    }
    header{
        padding-top:10rem;
        padding-bottom:6.5rem;
        background: url(../img/siempresaludable.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        background-color: #819CA9;

        .title{
            font-size:3.4rem;
            color:white;
            font-weight: 400;
            margin-bottom: 1rem;
        }

        .subtitle{
            font-size:2rem;
            color:white;
            margin-bottom:2.5rem;
            max-width: 41rem;
        }
    }


    @include media-breakpoint-down(lg) {
        header{
            .title{
                font-size:2.4rem;
                margin-bottom:1rem;
            }
            .subtitle{

                font-size:1.6rem;
            }
            background: $gray;
            padding-top:3.4rem;
            padding-bottom:5rem;

        }
    }


    @include media-breakpoint-down(lg) {
        header{
            .title{
                font-size:2.4rem;
                margin-bottom:1rem;
            }
            .subtitle{

                font-size:1.6rem;
            }
            background: $gray;
            padding-top:3.4rem;
            padding-bottom:5rem;
        }
    }


    ul.pagination{
        position: relative;
        /* align-items: center; */
        /* text-align: center; */
        /* display: flex; */
        /* align-items: center; */
        /* flex-direction: row; */
        justify-content: center;
        &:after{
            content: '';
            width: 100%;
            height: 1px;
            background-color: #444;
            position: absolute;
            left: 0;
            bottom: 50%;
            z-index: 0;
        }

        .next{
            z-index: 2;

            a{
                color: white!important;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }


    @include media-breakpoint-down(lg) {
        ul.pagination{

            &:after{
                display:none;
            }

            .next {
                width:100%;
                a{
                    display:block;
                }
            }
        }
    }


    .articles{

        padding-top:3rem;
        padding-bottom:3rem;
    }



}
