/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 25-jul-2018, 16:01:43
    Author     : Rodri
*/

.section-faqs {
    .header-border {
        border-bottom: #048086 solid 1px;
        padding-bottom: 3rem;
        margin-bottom: 3rem;
    }
    h1 {
        color: rgba(#003F44, 0.92);
        font-size: 2.5rem;
        line-height: 6.1rem;
    }
    h2 {
        color: #047F85;
        font-size: 6rem;
        font-weight: 300;
        margin-bottom: 3rem;
        margin-left: 2rem;
        text-transform: capitalize;
    }
    form {
        margin-bottom: 0;
        display: flex;
        width: 100%;

        input {
            flex: 1;
            background: #EEEEEE;
            border: 0;
            height: 5.4rem;
            padding: 0 1.5rem;
            color: #003F44;
            font-size: 1.6rem;
            font-weight: 500;
        }
        button {
            cursor: pointer;
            text-align: center;
            background: #003F44;
            border: 0;
            display: block;
            width: 5.2rem;
            height: 5.4rem;
            margin: 0;
            transition: all .2s ease-out;
            float: none;

            &:hover {
                background: #048086;
            }

            i {
                color: #FFF;
                font-size: 2.5rem;
                display: inline-block;
                line-height: 5.4rem;
            }
        }
    }
    .nav-aside {
        display: block;
        margin: 2rem 0 0 0;
        padding: 0;
        list-style: none;
        font-size: 2rem;

        li {
            display: block;
        }
        a {
            display: block;
            color: #047F85;
            margin-bottom: 2rem;
            font-weight: 400;
            text-transform: capitalize;

            &.active {
                font-weight: bold;
            }
        }
    }
    .card {
        position: relative;
        margin-bottom: 1rem;
        border: 0;

        .card-header {
            background: transparent;
            border: 0;
            padding: 0;

            button {
                white-space: normal;
                height: auto;
                text-transform: none;
                padding: 0;
                color: #047F85;
                font-weight: bold;
                font-size: 2rem;

                &.collapsed {
                    color: #666666;
                    font-weight: 400;

                    &:before {
                        content: '-';
                    }
                    &:after {
                        opacity: 0;
                    }
                }

                &:before {
                    content: '+';
                    margin-right: 1rem;
                }

                &:after {
                    transition: all .2s ease-out;
                    opacity: 1;
                    content: '+';
                    position: absolute;
                    background: #047F85;
                    height: 100%;
                    width: 0.5rem;
                    left: -1.8rem;
                    top: 0;
                }

                &:hover, &:focus {
                    color: #047F85;
                    text-decoration: none;
                }
            }
        }
        .card-body {
            padding: 1.5rem 2.75rem;
            color: #666666;
            font-size: 1.8rem;
        }
    }
    .border-left {
        border-left: #444444 solid 1px !important;
    }

    @include media-breakpoint-down(sm) {
        h2 {
            font-size: 4rem;
            margin-bottom: 2rem;
            margin-left: 0;
            border-top: #CCC dotted 1px;
            padding-top: 1.5rem;
        }
        .card {
            .card-header {
                button {
                    display: flex;
                    line-height: 2.4rem;
                    text-align: left;
                }
            }
            .card-body {
                padding: 1.5rem 2.3rem !important;
                font-size: 1.6rem;
            }
        }
    }
}