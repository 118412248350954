.page-empleos{
    section.empleos-main{
        padding-bottom: 0rem;
        .separador{
            border-bottom: 1px dotted #94ABB6;
            padding-top: 6rem;
        }
        h2{
            font-size: 3.6rem;
            font-weight: 500;
            color: #006A6C;
            line-height: 100%;
            margin-bottom: 2rem;
        }
        h3 {
            font-size: 3.2rem;
        }
        p, ul {
            margin-left: 2rem;
            font-size: 2rem;
            font-weight: 400;
            color: #333;
        }

        ul {
            li {
                margin: 0.5rem 0;
            }
        }

        .empleos-blocks {
            h2 {
                font-size: 2.6rem;
                font-weight: 400;
            }
        }

        .img-bot{
            background: #EB992F;
            display: flex;
            align-items: center;
            padding: 2rem;
            img{
                margin-right: 3rem;
            }
            p{
                color: #FFF;
                font-size: 2.4rem;
                font-weight: 500;
                line-height: 120%;
                a{
                    text-decoration: underline;
                    color: #FFF;
                }
            }
        }
    }
    section.empleos-more-info{
        background: #367e84;

        h3{
            font-size: 3.2rem;
            font-weight: 500;
            line-height: 120%;
            color: #fff;
            margin-bottom: 2rem;
            border-bottom: 1px solid #fff;
            padding-bottom: 1rem;
        }
        p{
            font-weight: 400;
            line-height: 130%;
            color: #fff;
            margin-bottom: 2rem;
            a{
                font-weight: 500;
            }
        }
    }

    section.empleos-case-study {
        h3 {
            font-size: 3.2rem;
        }
    }

    section.empleos-prizes {
        background: #163e43;

        h2{
            font-size: 3.6rem;
            font-weight: 500;
            color: #fff;
            line-height: 100%;
        }

        .mejores-patronos {
            max-width: 40%;
            margin: 0 auto;
            display: block;

            @include media-breakpoint-down(sm) {
                max-width: 60%;
            }
        }
        .line {
            position: relative;
            padding: 2rem 5rem 0;
            margin-top: 3rem;

            .swiper-button-next,
            .swiper-button-prev {
                background: none;
                width: 5rem;
                height: 5rem;
                margin-top: 0;
                top: 4.7rem;
            }

            .swiper-button-next {
                right: 0;
            }

            .swiper-button-prev {
                left: 0;
            }

            .swiper-slide-active {
                ul li {
                    padding-top: 3.5rem;

                    .detail {
                        display: block;
                    }

                    &:before {
                        width: 9rem;
                        height: 9rem;
                        top: 0.5rem;
                        margin-left: -4.5rem;
                        border-color: #EB992F;
                    }
                }
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: 0;
                left: 5rem;
                right: 5rem;
                height: 1px;
                top: 7.2rem;
                background: #FFF;
            }

            ul {
                position: relative;
                list-style: none;
                text-align: center;
                padding: 0;
                margin: 0;

                li {
                    transition: all .2s ease-out;
                    display: inline-block;
                    z-index: 1;
                    margin: 0 4rem;
                    padding-bottom: 4rem;
                    cursor: pointer;
                    vertical-align: top;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        width: 1px;
                        height: 2rem;
                        background: #FFF;
                        top: 3rem;
                        left: 50%;
                        z-index: 1;
                    }

                    &:before {
                        z-index: 2;
                        content: '';
                        position: absolute;
                        display: block;
                        width: 2.4rem;
                        height: 2.4rem;
                        background: #048086;
                        border: #FFF solid 4px;
                        border-radius: 100%;
                        top: 4rem;
                        left: 50%;
                        margin-left: -1.2rem;
                    }

                    .detail {
                        display: none;
                        position: absolute;
                        width: 35rem;
                        background: #048086;
                        color: #FFF;
                        padding: 2rem;
                        left: 50%;
                        margin-left: -17.5rem;
                        top: 13rem;

                        &:before {
                            content: '';
                            width: 1px;
                            height: 15rem;
                            background: #048086;
                            position: absolute;
                            z-index: 0;
                            top: -9rem;
                            left: 50%;
                        }

                        p {
                            /*margin: 0;*/
                            color: #FFF;
                            font-size: 1.6rem;
                            font-weight: 400;
                            font-style: italic;
                        }

                        @include media-breakpoint-down(sm) {
                            width: 26rem;
                            margin-left: -13rem;
                        }
                    }

                    .year {
                        position: relative;
                        z-index: 3;
                        color: #FFF;
                        font-size: 2rem;
                        font-weight: 500;
                    }

                    &.active {
                        padding-top: 3.5rem;

                        .detail {
                            display: block;
                        }

                        &:before {
                            width: 9rem;
                            height: 9rem;
                            top: 0.5rem;
                            margin-left: -4.5rem;
                            border-color: #EB992F;
                        }
                    }
                }
            }
        }
    }

    section.empleos-jobs-links {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }

    section.empleos-igualdad {
        background: #f2f2f2;
        padding-top: 7rem;
        padding-bottom: 7rem;
    }

    .jobs-links {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;

        li {
            margin-right: 1.5rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .swiper-empleos {
        width: 100%;
        height: 100%;

        .swiper-wrapper {
            align-items: stretch;
        }

        .swiper-pagination-bullet-active {
            background: #ffffff;
        }

        .swiper-slide {
            background: #367e84;
            color: #fff;
            padding: 3rem 1.8rem;
            height: auto;

            &.dark {
                background: #163e43;
            }

            h3 {
                font-size: 2.4rem;
            }

            p {
                margin: .6em 0;
                color: #fff;
            }
            ul {
                color: #fff;
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .page-empleos{
        section.empleos-main{
            .img-bot{
                p{
                    font-size: 1.8rem;
                }
            }
        }
        section.empleos-more-info{
            padding-top: 3rem;
        }
    }
}
