/*
/*
    Author     : MAXI
*/

.afiliados-notificacion-privacidad {
    p {
        color: #003F44;
        font-weight: 400;
        margin-bottom: 1.5rem;
        line-height: 2.4rem;
        font-size: 1.8rem;

        &.large {
            line-height: 2.6rem;
            font-size: 2rem;
        }
        &.gray {
            background: #F4F4F4;
            padding: 3rem 3.5rem;
        }
        span {
            font-size: 1.6rem;
        }
    }
    a{
        text-decoration: underline;
    }
    .border-top {
        border-top: #94ABB6 dotted 1px;
        padding-top: 3rem;
        margin-top: 3rem;
    }
    .border-left {
        border-left: #94ABB6 dotted 1px;
        padding-left: 2rem;
    }
    h2 {
        color: #003F44;
        font-size: 3.4rem;
        font-weight: 500;
        margin-bottom: 2.5rem;
    }
    h3 {
        color: #003F44;
        font-size: 2.8rem;
        font-weight: 400;
        margin-bottom: 2rem;
        display: flex;
        align-items: center;

        img {
            margin-right: 1.5rem;
        }
        span {
            flex: 1;
        }
    }
    h4 {
        color: #003F44;
        font-size: 2.4rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    h5 {
        color: #003F44;
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 1rem;

        &.sep-top {
            border-top: #94ABB6 dotted 1px;
            padding-top: 1.5rem;
            margin-top: 1.5rem;
        }
    }
    ul {
        color: #003F44;
        font-size: 1.8rem;
        font-weight: 400;
        margin: 0 0 1.5rem 0;
        list-style: none;
        padding: 0;


        &.black {
            li {
                &:before {
                    background: #003F44;
                    border-color: #FFF;
                }
            }
        }
        li {
            padding-left: 2.5rem;
            position: relative;
            margin-bottom: 1rem;

            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 10px;
                height: 10px;
                border: #003F44 solid 2px;
                border-radius: 100%;
                left: 0;
                top: 0.75rem;
            }
            ul {
                margin-top: 1rem;
                margin-left: 1.5rem;

                li {
                    &:before {
                        background: #003F44;
                        border-color: #FFF;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .afiliados-notificacion-privacidad{
        h2{
            font-size: 2.4rem;
        }
        h3{
            font-size: 2rem;
        }
        p{
            font-size: 1.6rem;
        }
        ul{
            font-size: 1.6rem;
        }
        .border-left{
            padding-left: 1.5rem;
            border-left: none !important;
            border-top: 1px solid #dee2e6 !important;
            padding-bottom: 2rem;
            padding-top: 2rem;
        }
    }
}