section.politica-de-transicion-suplido{
    padding-top: 6rem;
    padding-bottom: 10rem;
    h1{
        font-size: 6.4rem;
        font-weight: 500;
        color: #048086;
        margin-bottom: 1rem;
        line-height: 100%;
        span{
            font-size: 2.4rem;
            display: block;
            opacity: .92;
            line-height: 100%;
            margin-bottom: 1rem;
            margin-top: 1rem;
        }
    }
    p{
        font-size: 1.8rem;
        color: #333333;
        font-weight: 400;
    }
}

section.politica-de-transicion-info{
    background: #F4F7F8;
    .document-item{
        display: block;
        margin-bottom: 0.3rem;
    }
    h3{
        color: #047F85;
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 4rem;
    }
    #list-pdf{
        h3{
            margin-bottom: 1rem;
            font-size: 2.4rem !important;
        }
    }
    p.subtitle{
        color: #333;
        font-size: 1.8rem;
        line-height: 140%;
        margin-bottom: 3rem;
        font-weight: 500;
    }
    ul{
        li{
            &:before{
                content: " ";
                border: 2px solid #048086;
                border-radius: 10rem;
                width: 10px;
                height: 10px;
                position: absolute;
                left: 0;
                top: 0.8rem;
            }
            list-style: none;
            position: relative;
            padding-left: 2rem;
            margin-bottom: 4.5rem;
            p{
                font-size: 1.8rem;
                color: #333;
                line-height: 140%;
                font-weight: 400;
                margin-bottom: 3rem;
            }
        }
    }
    .lee-aqui{
        p{
            font-size: 2rem;
            line-height: 100%;
            color: #333;
            font-weight: 400;
        }
        a{
            color: #047F85;
            text-decoration: underline;
            display: flex;
            align-items: center;
            font-size: 1.8rem;
            svg{
                margin-right: 1rem;
            }
        }
    }
}

@include media-breakpoint-down(sm){
    section.politica-de-transicion-suplido{
        padding-top: 3rem;
        padding-bottom: 4rem;
        h1{
            font-size: 3rem;
            span{
                font-size: 1.8rem;
            }
        }
        p{
            font-size: 1.6rem;
        }
        svg{
            margin: 0px auto;
            display: block;
            margin-bottom: 3rem;
            width: 40%;
            height: auto;
        }
    }

    section.politica-de-transicion-info{
        ul{
            li{
                p{
                    font-size: 1.6rem;
                    margin-bottom: 1.5rem;
                }
            }
        }
        a.btn{
            width: 100%;
            margin-bottom: 1.5rem;
        }
        .lee-aqui{
            p{
                font-size: 1.8rem;
                margin-top: 2rem;
            }
        }
    }
}