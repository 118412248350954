/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 13-ago-2018, 16:05:51
    Author     : Rodri
*/

.section-medicare-video {
    min-height: 52.2rem;
    position: relative;
    padding: 6rem 0 6rem 0;

    .video-zone {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 50%;
        margin-left: -17rem;

        iframe {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    .text-video {
        padding-top: 4rem;
        /*padding-right: 17rem;*/
    }

    h1 {
        color: #048086;
        font-size: 6.5rem;
        line-height: 7rem;
        font-weight: 500;
        margin: 0 0 1.5rem 0;
    }

    h3 {
        border-top: #048086 dotted 1px;
        padding-top: 2rem;
        margin: 0;
        color: #048086;
        font-size: 2.2rem;
        font-weight: 500;
    }
    /*    .embed-responsive {
            display: none;
        }*/

    @include media-breakpoint-down(sm) {
        min-height: 0;
        padding: 8rem 0 2rem 0;

        .video-zone {
            display: none;
        }
        /*	.embed-responsive {
                    display: block;
                }*/
        .text-video {
            padding-top: 0;
            /*padding-right: 0;*/
        }
        h1 {
            font-size: 3.5rem;
            line-height: 4rem;
        }
        h3 {
            font-size: 2rem;
            margin-bottom: 2rem;
        }
    }
}

.section-medicare-cubierta {
    background: #F8F8F8;
    padding: 7rem 0;

    h2 {
        color: #03576F;
        font-size: 3.4rem;
        font-weight: bold;
        margin-bottom: 4rem;
    }

    .item-cubirta {
        margin-bottom: 3rem;
        align-items: center;
        display: flex;

        p {
            flex: 1;
            color: #003F44;
            font-size: 2rem;
            font-weight: 400;
            margin: 0 0 0 1.5rem;

            strong {
                display: block;
                font-size: 2.2rem;
                line-height: 2.2rem;
            }
        }
    }

    .cols {
        & > div:nth-child(1) {
            border-right: #94ABB6 dotted 1px;
        }
        & > div:nth-child(2) {
            padding-left: 7rem;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 2rem 0 0 0;

        h2 {
            font-size: 2.5rem;
            margin-bottom: 1.5rem;
        }
        .cols > div:nth-child(2) {
            padding-left: 1.5rem;
        }
    }
}

.section-medicare-articles {
    article {
        margin-bottom: 3rem;

        figure {
            border-top: #FFF solid 1px;
        }
        h2 {
            &.green {
                padding: 1.5rem;
                background: #048086;
                color: #FFF;
                line-height: 2.4rem;
                font-size: 2.4rem;
                font-weight: 400;

                strong {
                    font-size: 4rem;
                    line-height: 4rem;
                    font-weight: 500;
                    display: block;
                }
            }
            &.line {
                color: #048086;
                font-size: 3.4rem;
                border-bottom: #94ABB6 dotted 1px;
                padding-bottom: 1.5rem;
                padding-top: 0.5rem;
                margin-bottom: 1.5rem;
                font-weight: 400;

                strong {
                    font-weight: 500;
                }
            }
        }
        p {
            color: #333333;
            font-size: 2rem;

            strong {
                color: #047F85;
                font-weight: bold;
            }
        }
        ul {
            color: #333333;
            font-size: 2rem;
            margin-left: 2rem;
            margin-bottom: 1.5rem;
        }
    }
}

.section-medicare-elegible {
    position: relative;
    padding: 0;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 50%;
        background: url('../img/planes-medicos/sobre-medicare/elegibilidad.jpg') center center no-repeat;
        background-size: cover;
        z-index: 0;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        right: 0;
        background: #F8F8F8;
        z-index: 0;
    }
    .container {
        position: relative;
        z-index: 1;

        .col-12 {
            background: #F8F8F8;
            padding: 4rem 5rem;
        }
    }
    h2 {
        color: #047F85;
        font-size: 3.4rem;
        font-weight: 500;
        margin-bottom: 2rem;
    }
    h3 {
        color: #047F85;
        font-size: 2.4rem;
        font-weight: 500;
        margin-bottom: 2rem;
    }
    ul {
        color: #003F44;
        font-size: 1.8rem;
        margin-bottom: 2rem;
        margin-left: 2rem;

        li {
            margin-bottom: 1rem;
        }
    }

    @include media-breakpoint-down(sm) {
        .container .col-12 {
            background: #F8F8F8;
            padding: 1.5rem;
        }
        h2 {
            font-size: 2.5rem;
        }
        .btn {
            font-size: 1.2rem;
        }
    }
}