.page-afiliados-reportar-fraude{
    
    section.reportar-fraude-info{
        .borde-bot{
            border-bottom: 1px dotted #94ABB6;
            padding-bottom: 5rem;
            padding-top: 6rem;
        }
        .head-info{
            p{
                font-size: 2rem;
                color: #333;
                font-weight: 400;
                line-height: 130%;
            }
        }
        .fraudes-tipos{
            .box-text{
                padding: 2rem 1.5rem;
                h3{
                    font-size: 3.2rem;
                    font-weight: 500;
                    margin-bottom: 1rem;
                    color: #047F85;
                }
                p{
                    color: #003F44;
                    font-size: 1.8rem;
                    font-weight: 400;
                    line-height: 120%;
                }
            }
        }
        .ejemplos{
            h2{
                font-size: 3.4rem;
                font-weight: 500;
                color: #047F85;
                margin-bottom: 3rem;
            }
            ul{
                padding-left: 1.5rem;
                li{
                    list-style: none !important;
                    font-size: 1.8rem;
                    font-weight: 400;
                    line-height: 130%;
                    color: #003F44;
                    margin-bottom: 1.5rem;
                    padding-left: 1.2rem;
                    &:before {
                        content: '';
                        display: inline-block;
                        width: 1.2rem;
                        height: 1.2rem;
                        border: #003F44 solid 2px;
                        border-radius: 100%;
                        margin-left: -2.5rem;
                        float: left;
                        margin-top: 0.5rem;
                    }
                }
            }
        }
        .opciones{
            &-head{
                padding-top: 5rem;
                h4{
                    img{
                        display: inline-block;
                        margin-right: 1rem;
                    }
                    font-size: 2.8rem;
                    font-weight: 400;
                    color: #003F44;
                    line-height: 110%;
                    display: flex;
                    margin-bottom: 4rem;
                }
                h5{
                    font-size: 2.8rem;
                    font-weight: 500;
                    line-height: 100%;
                    color: #003F44;
                    margin-bottom: 1rem;
                }
            }
            &-body{
                margin-bottom: 4rem;
                .box-info{
                    height: 20rem;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    padding-left: 2.5rem;
                    padding-right: 2.5rem;
                    &:first-child{
                        padding-left: 5rem;
                    }
                    &:nth-child(2){
                        border-left: 1px dotted #94ABB6;
                        border-right: 1px dotted #94ABB6;
                    }
                    p{
                        font-size: 2rem;
                        font-weight: 400;
                        color: #003F44;
                        line-height: 120%;
                        a, a:hover{
                            &.fz-small{
                                font-size: 1.8rem;
                            }
                            font-weight: 700;
                            text-decoration: none;
                            display: block;
                        }
                        strong{
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .page-afiliados-reportar-fraude{
        padding-bottom: 3rem;
        section.reportar-fraude-info{
            padding-top: 5rem;
            .borde-bot{
                padding-top: 3rem;
                padding-bottom: 3rem;
            }
            .head-info{
                p{
                    font-size: 1.8rem;
                }
            }
            .fraudes-tipos{
                .box-text{
                    padding: 0rem;
                    h3{
                        font-size: 2.6rem;
                    }
                }
            }
            .ejemplos{
                h2{
                    font-size: 2.8rem;
                }
                ul{
                    li{
                        font-size: 1.6rem;
                    }
                }
            }
            .opciones{
                &-head{
                    padding-top: 3rem;
                    h4{
                        font-size: 1.8rem;
                        align-items: center;
                        margin-bottom: 3rem;
                    }
                    h5{
                        font-size: 2.4rem;
                        margin-bottom: 1rem;
                    }
                }
                &-body{
                    margin-bottom: 3rem;
                    .box-info{
                        height: auto;
                        padding: 2rem 1.5rem !important;
                        &:nth-child(2){
                            border-left: none;
                            border-right: none;
                            border-top: 1px dotted #94ABB6;
                            border-bottom: 1px dotted #94ABB6;
                        }
                    }
                    p{
                        font-size: 1.8rem;
                        a.fz-small{
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }
}