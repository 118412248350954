/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 30-jul-2018, 12:54:11
    Author     : PC03-MAXI
*/

ul.download{

    padding-left: 1rem!important;
    li{
        padding-left:1rem;
        a{
            img{
                margin-right: 1rem;
            }


            &.link-external{
                display: flex;

                &.no-link{
                    cursor: auto;
                    color: #333;
                    text-decoration: none;
                }

                img{
                    align-self: flex-start;
                }

            }
        }
    }
}