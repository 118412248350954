/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 29-jun-2018, 14:47:11
    Author     : Rodri
*/

.section-comunicate {
    background: transparent url(../img/icon_comunicate.png) center center no-repeat;
    position: relative;
    &.has_asistente_before{
        border-top: 1px solid #FFF;
    }
    &.has_orientacion_after{
        border-bottom: 1px solid #FFF;
    }

    .icon-middle{
        position:absolute;
        top:0;
        bottom:0;
        margin:auto;
        left: 50%;
	margin-left: -6.8rem;
    }


    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 50%;
        background: $va_light;
        z-index: -1;
    }
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        right: 0;
        background: white;
        z-index: -1;
    }

    h2 {
        color: #FFF !important;
        font-size: 6rem !important;
        font-weight: 500 !important;
        margin-bottom: 0 !important;
	line-height: 6.4rem !important; 
    }
    h3 {
        color: #FFF;
        font-size: 2.4rem;
        font-weight: 400;
    }
    h4 {
        font-size: 2rem;
        font-weight: bold;
        color: $va_dark;
        margin-bottom: 1rem;
    }
    p {
        font-size: 1.8rem;
        font-weight: 400;
        color: $va_dark;
        margin-bottom: 1rem;

        strong {
            display: block;
        }
    }
    
    @include media-breakpoint-down(sm) {
	background: $va_light;
	
	&:before, .icon-middle {
	    display: none;
	}
	
	h2 {
	    font-size: 4rem;
	}
	
	h3 {
	    font-size: 1.8rem;
	    margin-bottom: 1.5rem;
	}
	
	h4, p {
	    color: rgba(#FFF, 0.92);
	}
    }
}