/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 21-may-2018, 14:42:35
    Author     : PC03-MAXI
*/

.mdi.mdi-youtube{
    position: relative;
    top: 0.2rem;
}