@charset "UTF-8";
/* ------------------------------------------------------------ *        Main
\* ------------------------------------------------------------ */
/*
 *  ----------------- VARIABLES & B4 -----------------
 */
/*
@import "../plugins/bootstrap/scss/root";
@import "../plugins/bootstrap/scss/reboot";
@import "../plugins/bootstrap/scss/type";
@import "../plugins/bootstrap/scss/images";
@import "../plugins/bootstrap/scss/code";
@import "../plugins/bootstrap/scss/grid";
@import "../plugins/bootstrap/scss/tables";
@import "../plugins/bootstrap/scss/forms";
@import "../plugins/bootstrap/scss/buttons";
@import "../plugins/bootstrap/scss/transitions";
@import "../plugins/bootstrap/scss/dropdown";
@import "../plugins/bootstrap/scss/button-group";
@import "../plugins/bootstrap/scss/input-group";
@import "../plugins/bootstrap/scss/custom-forms";
@import "../plugins/bootstrap/scss/nav";
@import "../plugins/bootstrap/scss/navbar";
@import "../plugins/bootstrap/scss/card";
@import "../plugins/bootstrap/scss/breadcrumb";
@import "../plugins/bootstrap/scss/pagination";
@import "../plugins/bootstrap/scss/badge";
@import "../plugins/bootstrap/scss/jumbotron";
@import "../plugins/bootstrap/scss/alert";
@import "../plugins/bootstrap/scss/progress";
@import "../plugins/bootstrap/scss/media";
@import "../plugins/bootstrap/scss/list-group";
@import "../plugins/bootstrap/scss/close";
@import "../plugins/bootstrap/scss/modal";
@import "../plugins/bootstrap/scss/tooltip";
@import "../plugins/bootstrap/scss/popover";
@import "../plugins/bootstrap/scss/carousel";
@import "../plugins/bootstrap/scss/utilities";
@import "../plugins/bootstrap/scss/print";*/
/*
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/input-group";
@import "../node_modules/bootstrap/scss/custom-forms";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/jumbotron";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/media";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/print";*/
/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("../fonts/materialdesignicons-webfont.eot?v=2.3.54");
  src: url("../fonts/materialdesignicons-webfont.eot?#iefix&v=2.3.54") format("embedded-opentype"), url("../fonts/materialdesignicons-webfont.woff2?v=2.3.54") format("woff2"), url("../fonts/materialdesignicons-webfont.woff?v=2.3.54") format("woff"), url("../fonts/materialdesignicons-webfont.ttf?v=2.3.54") format("truetype"), url("../fonts/materialdesignicons-webfont.svg?v=2.3.54#materialdesigniconsregular") format("svg");
  font-weight: normal;
  font-style: normal; }

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.mdi-access-point:before {
  content: "\F002"; }

.mdi-access-point-network:before {
  content: "\F003"; }

.mdi-account:before {
  content: "\F004"; }

.mdi-account-alert:before {
  content: "\F005"; }

.mdi-account-box:before {
  content: "\F006"; }

.mdi-account-box-outline:before {
  content: "\F007"; }

.mdi-account-card-details:before {
  content: "\F5D2"; }

.mdi-account-check:before {
  content: "\F008"; }

.mdi-account-circle:before {
  content: "\F009"; }

.mdi-account-convert:before {
  content: "\F00A"; }

.mdi-account-edit:before {
  content: "\F6BB"; }

.mdi-account-group:before {
  content: "\F848"; }

.mdi-account-heart:before {
  content: "\F898"; }

.mdi-account-key:before {
  content: "\F00B"; }

.mdi-account-location:before {
  content: "\F00C"; }

.mdi-account-minus:before {
  content: "\F00D"; }

.mdi-account-multiple:before {
  content: "\F00E"; }

.mdi-account-multiple-check:before {
  content: "\F8C4"; }

.mdi-account-multiple-minus:before {
  content: "\F5D3"; }

.mdi-account-multiple-outline:before {
  content: "\F00F"; }

.mdi-account-multiple-plus:before {
  content: "\F010"; }

.mdi-account-multiple-plus-outline:before {
  content: "\F7FF"; }

.mdi-account-network:before {
  content: "\F011"; }

.mdi-account-off:before {
  content: "\F012"; }

.mdi-account-outline:before {
  content: "\F013"; }

.mdi-account-plus:before {
  content: "\F014"; }

.mdi-account-plus-outline:before {
  content: "\F800"; }

.mdi-account-remove:before {
  content: "\F015"; }

.mdi-account-search:before {
  content: "\F016"; }

.mdi-account-settings:before {
  content: "\F630"; }

.mdi-account-settings-variant:before {
  content: "\F631"; }

.mdi-account-star:before {
  content: "\F017"; }

.mdi-account-switch:before {
  content: "\F019"; }

.mdi-accusoft:before {
  content: "\F849"; }

.mdi-adjust:before {
  content: "\F01A"; }

.mdi-air-conditioner:before {
  content: "\F01B"; }

.mdi-airballoon:before {
  content: "\F01C"; }

.mdi-airplane:before {
  content: "\F01D"; }

.mdi-airplane-landing:before {
  content: "\F5D4"; }

.mdi-airplane-off:before {
  content: "\F01E"; }

.mdi-airplane-takeoff:before {
  content: "\F5D5"; }

.mdi-airplay:before {
  content: "\F01F"; }

.mdi-airport:before {
  content: "\F84A"; }

.mdi-alarm:before {
  content: "\F020"; }

.mdi-alarm-bell:before {
  content: "\F78D"; }

.mdi-alarm-check:before {
  content: "\F021"; }

.mdi-alarm-light:before {
  content: "\F78E"; }

.mdi-alarm-multiple:before {
  content: "\F022"; }

.mdi-alarm-off:before {
  content: "\F023"; }

.mdi-alarm-plus:before {
  content: "\F024"; }

.mdi-alarm-snooze:before {
  content: "\F68D"; }

.mdi-album:before {
  content: "\F025"; }

.mdi-alert:before {
  content: "\F026"; }

.mdi-alert-box:before {
  content: "\F027"; }

.mdi-alert-circle:before {
  content: "\F028"; }

.mdi-alert-circle-outline:before {
  content: "\F5D6"; }

.mdi-alert-decagram:before {
  content: "\F6BC"; }

.mdi-alert-octagon:before {
  content: "\F029"; }

.mdi-alert-octagram:before {
  content: "\F766"; }

.mdi-alert-outline:before {
  content: "\F02A"; }

.mdi-alien:before {
  content: "\F899"; }

.mdi-all-inclusive:before {
  content: "\F6BD"; }

.mdi-alpha:before {
  content: "\F02B"; }

.mdi-alphabetical:before {
  content: "\F02C"; }

.mdi-altimeter:before {
  content: "\F5D7"; }

.mdi-amazon:before {
  content: "\F02D"; }

.mdi-amazon-alexa:before {
  content: "\F8C5"; }

.mdi-amazon-drive:before {
  content: "\F02E"; }

.mdi-ambulance:before {
  content: "\F02F"; }

.mdi-amplifier:before {
  content: "\F030"; }

.mdi-anchor:before {
  content: "\F031"; }

.mdi-android:before {
  content: "\F032"; }

.mdi-android-debug-bridge:before {
  content: "\F033"; }

.mdi-android-head:before {
  content: "\F78F"; }

.mdi-android-studio:before {
  content: "\F034"; }

.mdi-angular:before {
  content: "\F6B1"; }

.mdi-angularjs:before {
  content: "\F6BE"; }

.mdi-animation:before {
  content: "\F5D8"; }

.mdi-anvil:before {
  content: "\F89A"; }

.mdi-apple:before {
  content: "\F035"; }

.mdi-apple-finder:before {
  content: "\F036"; }

.mdi-apple-icloud:before {
  content: "\F038"; }

.mdi-apple-ios:before {
  content: "\F037"; }

.mdi-apple-keyboard-caps:before {
  content: "\F632"; }

.mdi-apple-keyboard-command:before {
  content: "\F633"; }

.mdi-apple-keyboard-control:before {
  content: "\F634"; }

.mdi-apple-keyboard-option:before {
  content: "\F635"; }

.mdi-apple-keyboard-shift:before {
  content: "\F636"; }

.mdi-apple-safari:before {
  content: "\F039"; }

.mdi-application:before {
  content: "\F614"; }

.mdi-approval:before {
  content: "\F790"; }

.mdi-apps:before {
  content: "\F03B"; }

.mdi-arch:before {
  content: "\F8C6"; }

.mdi-archive:before {
  content: "\F03C"; }

.mdi-arrange-bring-forward:before {
  content: "\F03D"; }

.mdi-arrange-bring-to-front:before {
  content: "\F03E"; }

.mdi-arrange-send-backward:before {
  content: "\F03F"; }

.mdi-arrange-send-to-back:before {
  content: "\F040"; }

.mdi-arrow-all:before {
  content: "\F041"; }

.mdi-arrow-bottom-left:before {
  content: "\F042"; }

.mdi-arrow-bottom-right:before {
  content: "\F043"; }

.mdi-arrow-collapse:before {
  content: "\F615"; }

.mdi-arrow-collapse-all:before {
  content: "\F044"; }

.mdi-arrow-collapse-down:before {
  content: "\F791"; }

.mdi-arrow-collapse-horizontal:before {
  content: "\F84B"; }

.mdi-arrow-collapse-left:before {
  content: "\F792"; }

.mdi-arrow-collapse-right:before {
  content: "\F793"; }

.mdi-arrow-collapse-up:before {
  content: "\F794"; }

.mdi-arrow-collapse-vertical:before {
  content: "\F84C"; }

.mdi-arrow-down:before {
  content: "\F045"; }

.mdi-arrow-down-bold:before {
  content: "\F72D"; }

.mdi-arrow-down-bold-box:before {
  content: "\F72E"; }

.mdi-arrow-down-bold-box-outline:before {
  content: "\F72F"; }

.mdi-arrow-down-bold-circle:before {
  content: "\F047"; }

.mdi-arrow-down-bold-circle-outline:before {
  content: "\F048"; }

.mdi-arrow-down-bold-hexagon-outline:before {
  content: "\F049"; }

.mdi-arrow-down-box:before {
  content: "\F6BF"; }

.mdi-arrow-down-drop-circle:before {
  content: "\F04A"; }

.mdi-arrow-down-drop-circle-outline:before {
  content: "\F04B"; }

.mdi-arrow-down-thick:before {
  content: "\F046"; }

.mdi-arrow-expand:before {
  content: "\F616"; }

.mdi-arrow-expand-all:before {
  content: "\F04C"; }

.mdi-arrow-expand-down:before {
  content: "\F795"; }

.mdi-arrow-expand-horizontal:before {
  content: "\F84D"; }

.mdi-arrow-expand-left:before {
  content: "\F796"; }

.mdi-arrow-expand-right:before {
  content: "\F797"; }

.mdi-arrow-expand-up:before {
  content: "\F798"; }

.mdi-arrow-expand-vertical:before {
  content: "\F84E"; }

.mdi-arrow-left:before {
  content: "\F04D"; }

.mdi-arrow-left-bold:before {
  content: "\F730"; }

.mdi-arrow-left-bold-box:before {
  content: "\F731"; }

.mdi-arrow-left-bold-box-outline:before {
  content: "\F732"; }

.mdi-arrow-left-bold-circle:before {
  content: "\F04F"; }

.mdi-arrow-left-bold-circle-outline:before {
  content: "\F050"; }

.mdi-arrow-left-bold-hexagon-outline:before {
  content: "\F051"; }

.mdi-arrow-left-box:before {
  content: "\F6C0"; }

.mdi-arrow-left-drop-circle:before {
  content: "\F052"; }

.mdi-arrow-left-drop-circle-outline:before {
  content: "\F053"; }

.mdi-arrow-left-thick:before {
  content: "\F04E"; }

.mdi-arrow-right:before {
  content: "\F054"; }

.mdi-arrow-right-bold:before {
  content: "\F733"; }

.mdi-arrow-right-bold-box:before {
  content: "\F734"; }

.mdi-arrow-right-bold-box-outline:before {
  content: "\F735"; }

.mdi-arrow-right-bold-circle:before {
  content: "\F056"; }

.mdi-arrow-right-bold-circle-outline:before {
  content: "\F057"; }

.mdi-arrow-right-bold-hexagon-outline:before {
  content: "\F058"; }

.mdi-arrow-right-box:before {
  content: "\F6C1"; }

.mdi-arrow-right-drop-circle:before {
  content: "\F059"; }

.mdi-arrow-right-drop-circle-outline:before {
  content: "\F05A"; }

.mdi-arrow-right-thick:before {
  content: "\F055"; }

.mdi-arrow-top-left:before {
  content: "\F05B"; }

.mdi-arrow-top-right:before {
  content: "\F05C"; }

.mdi-arrow-up:before {
  content: "\F05D"; }

.mdi-arrow-up-bold:before {
  content: "\F736"; }

.mdi-arrow-up-bold-box:before {
  content: "\F737"; }

.mdi-arrow-up-bold-box-outline:before {
  content: "\F738"; }

.mdi-arrow-up-bold-circle:before {
  content: "\F05F"; }

.mdi-arrow-up-bold-circle-outline:before {
  content: "\F060"; }

.mdi-arrow-up-bold-hexagon-outline:before {
  content: "\F061"; }

.mdi-arrow-up-box:before {
  content: "\F6C2"; }

.mdi-arrow-up-drop-circle:before {
  content: "\F062"; }

.mdi-arrow-up-drop-circle-outline:before {
  content: "\F063"; }

.mdi-arrow-up-thick:before {
  content: "\F05E"; }

.mdi-artist:before {
  content: "\F802"; }

.mdi-assistant:before {
  content: "\F064"; }

.mdi-asterisk:before {
  content: "\F6C3"; }

.mdi-at:before {
  content: "\F065"; }

.mdi-atlassian:before {
  content: "\F803"; }

.mdi-atom:before {
  content: "\F767"; }

.mdi-attachment:before {
  content: "\F066"; }

.mdi-audiobook:before {
  content: "\F067"; }

.mdi-augmented-reality:before {
  content: "\F84F"; }

.mdi-auto-fix:before {
  content: "\F068"; }

.mdi-auto-upload:before {
  content: "\F069"; }

.mdi-autorenew:before {
  content: "\F06A"; }

.mdi-av-timer:before {
  content: "\F06B"; }

.mdi-axe:before {
  content: "\F8C7"; }

.mdi-azure:before {
  content: "\F804"; }

.mdi-baby:before {
  content: "\F06C"; }

.mdi-baby-buggy:before {
  content: "\F68E"; }

.mdi-backburger:before {
  content: "\F06D"; }

.mdi-backspace:before {
  content: "\F06E"; }

.mdi-backup-restore:before {
  content: "\F06F"; }

.mdi-badminton:before {
  content: "\F850"; }

.mdi-bandcamp:before {
  content: "\F674"; }

.mdi-bank:before {
  content: "\F070"; }

.mdi-barcode:before {
  content: "\F071"; }

.mdi-barcode-scan:before {
  content: "\F072"; }

.mdi-barley:before {
  content: "\F073"; }

.mdi-barrel:before {
  content: "\F074"; }

.mdi-baseball:before {
  content: "\F851"; }

.mdi-baseball-bat:before {
  content: "\F852"; }

.mdi-basecamp:before {
  content: "\F075"; }

.mdi-basket:before {
  content: "\F076"; }

.mdi-basket-fill:before {
  content: "\F077"; }

.mdi-basket-unfill:before {
  content: "\F078"; }

.mdi-basketball:before {
  content: "\F805"; }

.mdi-battery:before {
  content: "\F079"; }

.mdi-battery-10:before {
  content: "\F07A"; }

.mdi-battery-20:before {
  content: "\F07B"; }

.mdi-battery-30:before {
  content: "\F07C"; }

.mdi-battery-40:before {
  content: "\F07D"; }

.mdi-battery-50:before {
  content: "\F07E"; }

.mdi-battery-60:before {
  content: "\F07F"; }

.mdi-battery-70:before {
  content: "\F080"; }

.mdi-battery-80:before {
  content: "\F081"; }

.mdi-battery-90:before {
  content: "\F082"; }

.mdi-battery-alert:before {
  content: "\F083"; }

.mdi-battery-charging:before {
  content: "\F084"; }

.mdi-battery-charging-10:before {
  content: "\F89B"; }

.mdi-battery-charging-100:before {
  content: "\F085"; }

.mdi-battery-charging-20:before {
  content: "\F086"; }

.mdi-battery-charging-30:before {
  content: "\F087"; }

.mdi-battery-charging-40:before {
  content: "\F088"; }

.mdi-battery-charging-50:before {
  content: "\F89C"; }

.mdi-battery-charging-60:before {
  content: "\F089"; }

.mdi-battery-charging-70:before {
  content: "\F89D"; }

.mdi-battery-charging-80:before {
  content: "\F08A"; }

.mdi-battery-charging-90:before {
  content: "\F08B"; }

.mdi-battery-charging-outline:before {
  content: "\F89E"; }

.mdi-battery-charging-wireless:before {
  content: "\F806"; }

.mdi-battery-charging-wireless-10:before {
  content: "\F807"; }

.mdi-battery-charging-wireless-20:before {
  content: "\F808"; }

.mdi-battery-charging-wireless-30:before {
  content: "\F809"; }

.mdi-battery-charging-wireless-40:before {
  content: "\F80A"; }

.mdi-battery-charging-wireless-50:before {
  content: "\F80B"; }

.mdi-battery-charging-wireless-60:before {
  content: "\F80C"; }

.mdi-battery-charging-wireless-70:before {
  content: "\F80D"; }

.mdi-battery-charging-wireless-80:before {
  content: "\F80E"; }

.mdi-battery-charging-wireless-90:before {
  content: "\F80F"; }

.mdi-battery-charging-wireless-alert:before {
  content: "\F810"; }

.mdi-battery-charging-wireless-outline:before {
  content: "\F811"; }

.mdi-battery-minus:before {
  content: "\F08C"; }

.mdi-battery-negative:before {
  content: "\F08D"; }

.mdi-battery-outline:before {
  content: "\F08E"; }

.mdi-battery-plus:before {
  content: "\F08F"; }

.mdi-battery-positive:before {
  content: "\F090"; }

.mdi-battery-unknown:before {
  content: "\F091"; }

.mdi-beach:before {
  content: "\F092"; }

.mdi-beaker:before {
  content: "\F68F"; }

.mdi-beats:before {
  content: "\F097"; }

.mdi-bed-empty:before {
  content: "\F89F"; }

.mdi-beer:before {
  content: "\F098"; }

.mdi-behance:before {
  content: "\F099"; }

.mdi-bell:before {
  content: "\F09A"; }

.mdi-bell-off:before {
  content: "\F09B"; }

.mdi-bell-outline:before {
  content: "\F09C"; }

.mdi-bell-plus:before {
  content: "\F09D"; }

.mdi-bell-ring:before {
  content: "\F09E"; }

.mdi-bell-ring-outline:before {
  content: "\F09F"; }

.mdi-bell-sleep:before {
  content: "\F0A0"; }

.mdi-beta:before {
  content: "\F0A1"; }

.mdi-bible:before {
  content: "\F0A2"; }

.mdi-bike:before {
  content: "\F0A3"; }

.mdi-bing:before {
  content: "\F0A4"; }

.mdi-binoculars:before {
  content: "\F0A5"; }

.mdi-bio:before {
  content: "\F0A6"; }

.mdi-biohazard:before {
  content: "\F0A7"; }

.mdi-bitbucket:before {
  content: "\F0A8"; }

.mdi-bitcoin:before {
  content: "\F812"; }

.mdi-black-mesa:before {
  content: "\F0A9"; }

.mdi-blackberry:before {
  content: "\F0AA"; }

.mdi-blender:before {
  content: "\F0AB"; }

.mdi-blinds:before {
  content: "\F0AC"; }

.mdi-block-helper:before {
  content: "\F0AD"; }

.mdi-blogger:before {
  content: "\F0AE"; }

.mdi-bluetooth:before {
  content: "\F0AF"; }

.mdi-bluetooth-audio:before {
  content: "\F0B0"; }

.mdi-bluetooth-connect:before {
  content: "\F0B1"; }

.mdi-bluetooth-off:before {
  content: "\F0B2"; }

.mdi-bluetooth-settings:before {
  content: "\F0B3"; }

.mdi-bluetooth-transfer:before {
  content: "\F0B4"; }

.mdi-blur:before {
  content: "\F0B5"; }

.mdi-blur-linear:before {
  content: "\F0B6"; }

.mdi-blur-off:before {
  content: "\F0B7"; }

.mdi-blur-radial:before {
  content: "\F0B8"; }

.mdi-bomb:before {
  content: "\F690"; }

.mdi-bomb-off:before {
  content: "\F6C4"; }

.mdi-bone:before {
  content: "\F0B9"; }

.mdi-book:before {
  content: "\F0BA"; }

.mdi-book-minus:before {
  content: "\F5D9"; }

.mdi-book-multiple:before {
  content: "\F0BB"; }

.mdi-book-multiple-variant:before {
  content: "\F0BC"; }

.mdi-book-open:before {
  content: "\F0BD"; }

.mdi-book-open-page-variant:before {
  content: "\F5DA"; }

.mdi-book-open-variant:before {
  content: "\F0BE"; }

.mdi-book-plus:before {
  content: "\F5DB"; }

.mdi-book-secure:before {
  content: "\F799"; }

.mdi-book-unsecure:before {
  content: "\F79A"; }

.mdi-book-variant:before {
  content: "\F0BF"; }

.mdi-bookmark:before {
  content: "\F0C0"; }

.mdi-bookmark-check:before {
  content: "\F0C1"; }

.mdi-bookmark-music:before {
  content: "\F0C2"; }

.mdi-bookmark-outline:before {
  content: "\F0C3"; }

.mdi-bookmark-plus:before {
  content: "\F0C5"; }

.mdi-bookmark-plus-outline:before {
  content: "\F0C4"; }

.mdi-bookmark-remove:before {
  content: "\F0C6"; }

.mdi-boombox:before {
  content: "\F5DC"; }

.mdi-bootstrap:before {
  content: "\F6C5"; }

.mdi-border-all:before {
  content: "\F0C7"; }

.mdi-border-all-variant:before {
  content: "\F8A0"; }

.mdi-border-bottom:before {
  content: "\F0C8"; }

.mdi-border-bottom-variant:before {
  content: "\F8A1"; }

.mdi-border-color:before {
  content: "\F0C9"; }

.mdi-border-horizontal:before {
  content: "\F0CA"; }

.mdi-border-inside:before {
  content: "\F0CB"; }

.mdi-border-left:before {
  content: "\F0CC"; }

.mdi-border-left-variant:before {
  content: "\F8A2"; }

.mdi-border-none:before {
  content: "\F0CD"; }

.mdi-border-none-variant:before {
  content: "\F8A3"; }

.mdi-border-outside:before {
  content: "\F0CE"; }

.mdi-border-right:before {
  content: "\F0CF"; }

.mdi-border-right-variant:before {
  content: "\F8A4"; }

.mdi-border-style:before {
  content: "\F0D0"; }

.mdi-border-top:before {
  content: "\F0D1"; }

.mdi-border-top-variant:before {
  content: "\F8A5"; }

.mdi-border-vertical:before {
  content: "\F0D2"; }

.mdi-bottle-wine:before {
  content: "\F853"; }

.mdi-bow-tie:before {
  content: "\F677"; }

.mdi-bowl:before {
  content: "\F617"; }

.mdi-bowling:before {
  content: "\F0D3"; }

.mdi-box:before {
  content: "\F0D4"; }

.mdi-box-cutter:before {
  content: "\F0D5"; }

.mdi-box-shadow:before {
  content: "\F637"; }

.mdi-bridge:before {
  content: "\F618"; }

.mdi-briefcase:before {
  content: "\F0D6"; }

.mdi-briefcase-check:before {
  content: "\F0D7"; }

.mdi-briefcase-download:before {
  content: "\F0D8"; }

.mdi-briefcase-outline:before {
  content: "\F813"; }

.mdi-briefcase-upload:before {
  content: "\F0D9"; }

.mdi-brightness-1:before {
  content: "\F0DA"; }

.mdi-brightness-2:before {
  content: "\F0DB"; }

.mdi-brightness-3:before {
  content: "\F0DC"; }

.mdi-brightness-4:before {
  content: "\F0DD"; }

.mdi-brightness-5:before {
  content: "\F0DE"; }

.mdi-brightness-6:before {
  content: "\F0DF"; }

.mdi-brightness-7:before {
  content: "\F0E0"; }

.mdi-brightness-auto:before {
  content: "\F0E1"; }

.mdi-broom:before {
  content: "\F0E2"; }

.mdi-brush:before {
  content: "\F0E3"; }

.mdi-buffer:before {
  content: "\F619"; }

.mdi-bug:before {
  content: "\F0E4"; }

.mdi-bulletin-board:before {
  content: "\F0E5"; }

.mdi-bullhorn:before {
  content: "\F0E6"; }

.mdi-bullseye:before {
  content: "\F5DD"; }

.mdi-bullseye-arrow:before {
  content: "\F8C8"; }

.mdi-bus:before {
  content: "\F0E7"; }

.mdi-bus-articulated-end:before {
  content: "\F79B"; }

.mdi-bus-articulated-front:before {
  content: "\F79C"; }

.mdi-bus-clock:before {
  content: "\F8C9"; }

.mdi-bus-double-decker:before {
  content: "\F79D"; }

.mdi-bus-school:before {
  content: "\F79E"; }

.mdi-bus-side:before {
  content: "\F79F"; }

.mdi-cached:before {
  content: "\F0E8"; }

.mdi-cake:before {
  content: "\F0E9"; }

.mdi-cake-layered:before {
  content: "\F0EA"; }

.mdi-cake-variant:before {
  content: "\F0EB"; }

.mdi-calculator:before {
  content: "\F0EC"; }

.mdi-calendar:before {
  content: "\F0ED"; }

.mdi-calendar-blank:before {
  content: "\F0EE"; }

.mdi-calendar-check:before {
  content: "\F0EF"; }

.mdi-calendar-clock:before {
  content: "\F0F0"; }

.mdi-calendar-edit:before {
  content: "\F8A6"; }

.mdi-calendar-multiple:before {
  content: "\F0F1"; }

.mdi-calendar-multiple-check:before {
  content: "\F0F2"; }

.mdi-calendar-plus:before {
  content: "\F0F3"; }

.mdi-calendar-question:before {
  content: "\F691"; }

.mdi-calendar-range:before {
  content: "\F678"; }

.mdi-calendar-remove:before {
  content: "\F0F4"; }

.mdi-calendar-text:before {
  content: "\F0F5"; }

.mdi-calendar-today:before {
  content: "\F0F6"; }

.mdi-call-made:before {
  content: "\F0F7"; }

.mdi-call-merge:before {
  content: "\F0F8"; }

.mdi-call-missed:before {
  content: "\F0F9"; }

.mdi-call-received:before {
  content: "\F0FA"; }

.mdi-call-split:before {
  content: "\F0FB"; }

.mdi-camcorder:before {
  content: "\F0FC"; }

.mdi-camcorder-box:before {
  content: "\F0FD"; }

.mdi-camcorder-box-off:before {
  content: "\F0FE"; }

.mdi-camcorder-off:before {
  content: "\F0FF"; }

.mdi-camera:before {
  content: "\F100"; }

.mdi-camera-account:before {
  content: "\F8CA"; }

.mdi-camera-burst:before {
  content: "\F692"; }

.mdi-camera-enhance:before {
  content: "\F101"; }

.mdi-camera-front:before {
  content: "\F102"; }

.mdi-camera-front-variant:before {
  content: "\F103"; }

.mdi-camera-gopro:before {
  content: "\F7A0"; }

.mdi-camera-image:before {
  content: "\F8CB"; }

.mdi-camera-iris:before {
  content: "\F104"; }

.mdi-camera-metering-center:before {
  content: "\F7A1"; }

.mdi-camera-metering-matrix:before {
  content: "\F7A2"; }

.mdi-camera-metering-partial:before {
  content: "\F7A3"; }

.mdi-camera-metering-spot:before {
  content: "\F7A4"; }

.mdi-camera-off:before {
  content: "\F5DF"; }

.mdi-camera-party-mode:before {
  content: "\F105"; }

.mdi-camera-rear:before {
  content: "\F106"; }

.mdi-camera-rear-variant:before {
  content: "\F107"; }

.mdi-camera-switch:before {
  content: "\F108"; }

.mdi-camera-timer:before {
  content: "\F109"; }

.mdi-cancel:before {
  content: "\F739"; }

.mdi-candle:before {
  content: "\F5E2"; }

.mdi-candycane:before {
  content: "\F10A"; }

.mdi-cannabis:before {
  content: "\F7A5"; }

.mdi-car:before {
  content: "\F10B"; }

.mdi-car-battery:before {
  content: "\F10C"; }

.mdi-car-connected:before {
  content: "\F10D"; }

.mdi-car-convertible:before {
  content: "\F7A6"; }

.mdi-car-estate:before {
  content: "\F7A7"; }

.mdi-car-hatchback:before {
  content: "\F7A8"; }

.mdi-car-limousine:before {
  content: "\F8CC"; }

.mdi-car-pickup:before {
  content: "\F7A9"; }

.mdi-car-side:before {
  content: "\F7AA"; }

.mdi-car-sports:before {
  content: "\F7AB"; }

.mdi-car-wash:before {
  content: "\F10E"; }

.mdi-caravan:before {
  content: "\F7AC"; }

.mdi-cards:before {
  content: "\F638"; }

.mdi-cards-club:before {
  content: "\F8CD"; }

.mdi-cards-diamond:before {
  content: "\F8CE"; }

.mdi-cards-heart:before {
  content: "\F8CF"; }

.mdi-cards-outline:before {
  content: "\F639"; }

.mdi-cards-playing-outline:before {
  content: "\F63A"; }

.mdi-cards-spade:before {
  content: "\F8D0"; }

.mdi-cards-variant:before {
  content: "\F6C6"; }

.mdi-carrot:before {
  content: "\F10F"; }

.mdi-cart:before {
  content: "\F110"; }

.mdi-cart-off:before {
  content: "\F66B"; }

.mdi-cart-outline:before {
  content: "\F111"; }

.mdi-cart-plus:before {
  content: "\F112"; }

.mdi-case-sensitive-alt:before {
  content: "\F113"; }

.mdi-cash:before {
  content: "\F114"; }

.mdi-cash-100:before {
  content: "\F115"; }

.mdi-cash-multiple:before {
  content: "\F116"; }

.mdi-cash-usd:before {
  content: "\F117"; }

.mdi-cast:before {
  content: "\F118"; }

.mdi-cast-connected:before {
  content: "\F119"; }

.mdi-cast-off:before {
  content: "\F789"; }

.mdi-castle:before {
  content: "\F11A"; }

.mdi-cat:before {
  content: "\F11B"; }

.mdi-cctv:before {
  content: "\F7AD"; }

.mdi-ceiling-light:before {
  content: "\F768"; }

.mdi-cellphone:before {
  content: "\F11C"; }

.mdi-cellphone-android:before {
  content: "\F11D"; }

.mdi-cellphone-basic:before {
  content: "\F11E"; }

.mdi-cellphone-dock:before {
  content: "\F11F"; }

.mdi-cellphone-iphone:before {
  content: "\F120"; }

.mdi-cellphone-link:before {
  content: "\F121"; }

.mdi-cellphone-link-off:before {
  content: "\F122"; }

.mdi-cellphone-message:before {
  content: "\F8D2"; }

.mdi-cellphone-settings:before {
  content: "\F123"; }

.mdi-cellphone-text:before {
  content: "\F8D1"; }

.mdi-cellphone-wireless:before {
  content: "\F814"; }

.mdi-certificate:before {
  content: "\F124"; }

.mdi-chair-school:before {
  content: "\F125"; }

.mdi-chart-arc:before {
  content: "\F126"; }

.mdi-chart-areaspline:before {
  content: "\F127"; }

.mdi-chart-bar:before {
  content: "\F128"; }

.mdi-chart-bar-stacked:before {
  content: "\F769"; }

.mdi-chart-bubble:before {
  content: "\F5E3"; }

.mdi-chart-donut:before {
  content: "\F7AE"; }

.mdi-chart-donut-variant:before {
  content: "\F7AF"; }

.mdi-chart-gantt:before {
  content: "\F66C"; }

.mdi-chart-histogram:before {
  content: "\F129"; }

.mdi-chart-line:before {
  content: "\F12A"; }

.mdi-chart-line-stacked:before {
  content: "\F76A"; }

.mdi-chart-line-variant:before {
  content: "\F7B0"; }

.mdi-chart-multiline:before {
  content: "\F8D3"; }

.mdi-chart-pie:before {
  content: "\F12B"; }

.mdi-chart-scatterplot-hexbin:before {
  content: "\F66D"; }

.mdi-chart-timeline:before {
  content: "\F66E"; }

.mdi-check:before {
  content: "\F12C"; }

.mdi-check-all:before {
  content: "\F12D"; }

.mdi-check-circle:before {
  content: "\F5E0"; }

.mdi-check-circle-outline:before {
  content: "\F5E1"; }

.mdi-check-outline:before {
  content: "\F854"; }

.mdi-checkbox-blank:before {
  content: "\F12E"; }

.mdi-checkbox-blank-circle:before {
  content: "\F12F"; }

.mdi-checkbox-blank-circle-outline:before {
  content: "\F130"; }

.mdi-checkbox-blank-outline:before {
  content: "\F131"; }

.mdi-checkbox-intermediate:before {
  content: "\F855"; }

.mdi-checkbox-marked:before {
  content: "\F132"; }

.mdi-checkbox-marked-circle:before {
  content: "\F133"; }

.mdi-checkbox-marked-circle-outline:before {
  content: "\F134"; }

.mdi-checkbox-marked-outline:before {
  content: "\F135"; }

.mdi-checkbox-multiple-blank:before {
  content: "\F136"; }

.mdi-checkbox-multiple-blank-circle:before {
  content: "\F63B"; }

.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "\F63C"; }

.mdi-checkbox-multiple-blank-outline:before {
  content: "\F137"; }

.mdi-checkbox-multiple-marked:before {
  content: "\F138"; }

.mdi-checkbox-multiple-marked-circle:before {
  content: "\F63D"; }

.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "\F63E"; }

.mdi-checkbox-multiple-marked-outline:before {
  content: "\F139"; }

.mdi-checkerboard:before {
  content: "\F13A"; }

.mdi-chemical-weapon:before {
  content: "\F13B"; }

.mdi-chess-bishop:before {
  content: "\F85B"; }

.mdi-chess-king:before {
  content: "\F856"; }

.mdi-chess-knight:before {
  content: "\F857"; }

.mdi-chess-pawn:before {
  content: "\F858"; }

.mdi-chess-queen:before {
  content: "\F859"; }

.mdi-chess-rook:before {
  content: "\F85A"; }

.mdi-chevron-double-down:before {
  content: "\F13C"; }

.mdi-chevron-double-left:before {
  content: "\F13D"; }

.mdi-chevron-double-right:before {
  content: "\F13E"; }

.mdi-chevron-double-up:before {
  content: "\F13F"; }

.mdi-chevron-down:before {
  content: "\F140"; }

.mdi-chevron-left:before {
  content: "\F141"; }

.mdi-chevron-right:before {
  content: "\F142"; }

.mdi-chevron-up:before {
  content: "\F143"; }

.mdi-chili-hot:before {
  content: "\F7B1"; }

.mdi-chili-medium:before {
  content: "\F7B2"; }

.mdi-chili-mild:before {
  content: "\F7B3"; }

.mdi-chip:before {
  content: "\F61A"; }

.mdi-church:before {
  content: "\F144"; }

.mdi-circle:before {
  content: "\F764"; }

.mdi-circle-edit-outline:before {
  content: "\F8D4"; }

.mdi-circle-outline:before {
  content: "\F765"; }

.mdi-cisco-webex:before {
  content: "\F145"; }

.mdi-city:before {
  content: "\F146"; }

.mdi-clipboard:before {
  content: "\F147"; }

.mdi-clipboard-account:before {
  content: "\F148"; }

.mdi-clipboard-alert:before {
  content: "\F149"; }

.mdi-clipboard-arrow-down:before {
  content: "\F14A"; }

.mdi-clipboard-arrow-left:before {
  content: "\F14B"; }

.mdi-clipboard-check:before {
  content: "\F14C"; }

.mdi-clipboard-check-outline:before {
  content: "\F8A7"; }

.mdi-clipboard-flow:before {
  content: "\F6C7"; }

.mdi-clipboard-outline:before {
  content: "\F14D"; }

.mdi-clipboard-plus:before {
  content: "\F750"; }

.mdi-clipboard-pulse:before {
  content: "\F85C"; }

.mdi-clipboard-pulse-outline:before {
  content: "\F85D"; }

.mdi-clipboard-text:before {
  content: "\F14E"; }

.mdi-clippy:before {
  content: "\F14F"; }

.mdi-clock:before {
  content: "\F150"; }

.mdi-clock-alert:before {
  content: "\F5CE"; }

.mdi-clock-end:before {
  content: "\F151"; }

.mdi-clock-fast:before {
  content: "\F152"; }

.mdi-clock-in:before {
  content: "\F153"; }

.mdi-clock-out:before {
  content: "\F154"; }

.mdi-clock-start:before {
  content: "\F155"; }

.mdi-close:before {
  content: "\F156"; }

.mdi-close-box:before {
  content: "\F157"; }

.mdi-close-box-outline:before {
  content: "\F158"; }

.mdi-close-circle:before {
  content: "\F159"; }

.mdi-close-circle-outline:before {
  content: "\F15A"; }

.mdi-close-network:before {
  content: "\F15B"; }

.mdi-close-octagon:before {
  content: "\F15C"; }

.mdi-close-octagon-outline:before {
  content: "\F15D"; }

.mdi-close-outline:before {
  content: "\F6C8"; }

.mdi-closed-caption:before {
  content: "\F15E"; }

.mdi-cloud:before {
  content: "\F15F"; }

.mdi-cloud-braces:before {
  content: "\F7B4"; }

.mdi-cloud-check:before {
  content: "\F160"; }

.mdi-cloud-circle:before {
  content: "\F161"; }

.mdi-cloud-download:before {
  content: "\F162"; }

.mdi-cloud-off-outline:before {
  content: "\F164"; }

.mdi-cloud-outline:before {
  content: "\F163"; }

.mdi-cloud-print:before {
  content: "\F165"; }

.mdi-cloud-print-outline:before {
  content: "\F166"; }

.mdi-cloud-sync:before {
  content: "\F63F"; }

.mdi-cloud-tags:before {
  content: "\F7B5"; }

.mdi-cloud-upload:before {
  content: "\F167"; }

.mdi-clover:before {
  content: "\F815"; }

.mdi-code-array:before {
  content: "\F168"; }

.mdi-code-braces:before {
  content: "\F169"; }

.mdi-code-brackets:before {
  content: "\F16A"; }

.mdi-code-equal:before {
  content: "\F16B"; }

.mdi-code-greater-than:before {
  content: "\F16C"; }

.mdi-code-greater-than-or-equal:before {
  content: "\F16D"; }

.mdi-code-less-than:before {
  content: "\F16E"; }

.mdi-code-less-than-or-equal:before {
  content: "\F16F"; }

.mdi-code-not-equal:before {
  content: "\F170"; }

.mdi-code-not-equal-variant:before {
  content: "\F171"; }

.mdi-code-parentheses:before {
  content: "\F172"; }

.mdi-code-string:before {
  content: "\F173"; }

.mdi-code-tags:before {
  content: "\F174"; }

.mdi-code-tags-check:before {
  content: "\F693"; }

.mdi-codepen:before {
  content: "\F175"; }

.mdi-coffee:before {
  content: "\F176"; }

.mdi-coffee-outline:before {
  content: "\F6C9"; }

.mdi-coffee-to-go:before {
  content: "\F177"; }

.mdi-cogs:before {
  content: "\F8D5"; }

.mdi-coin:before {
  content: "\F178"; }

.mdi-coins:before {
  content: "\F694"; }

.mdi-collage:before {
  content: "\F640"; }

.mdi-color-helper:before {
  content: "\F179"; }

.mdi-comment:before {
  content: "\F17A"; }

.mdi-comment-account:before {
  content: "\F17B"; }

.mdi-comment-account-outline:before {
  content: "\F17C"; }

.mdi-comment-alert:before {
  content: "\F17D"; }

.mdi-comment-alert-outline:before {
  content: "\F17E"; }

.mdi-comment-check:before {
  content: "\F17F"; }

.mdi-comment-check-outline:before {
  content: "\F180"; }

.mdi-comment-multiple:before {
  content: "\F85E"; }

.mdi-comment-multiple-outline:before {
  content: "\F181"; }

.mdi-comment-outline:before {
  content: "\F182"; }

.mdi-comment-plus-outline:before {
  content: "\F183"; }

.mdi-comment-processing:before {
  content: "\F184"; }

.mdi-comment-processing-outline:before {
  content: "\F185"; }

.mdi-comment-question:before {
  content: "\F816"; }

.mdi-comment-question-outline:before {
  content: "\F186"; }

.mdi-comment-remove:before {
  content: "\F5DE"; }

.mdi-comment-remove-outline:before {
  content: "\F187"; }

.mdi-comment-text:before {
  content: "\F188"; }

.mdi-comment-text-multiple:before {
  content: "\F85F"; }

.mdi-comment-text-multiple-outline:before {
  content: "\F860"; }

.mdi-comment-text-outline:before {
  content: "\F189"; }

.mdi-compare:before {
  content: "\F18A"; }

.mdi-compass:before {
  content: "\F18B"; }

.mdi-compass-outline:before {
  content: "\F18C"; }

.mdi-console:before {
  content: "\F18D"; }

.mdi-console-line:before {
  content: "\F7B6"; }

.mdi-console-network:before {
  content: "\F8A8"; }

.mdi-contact-mail:before {
  content: "\F18E"; }

.mdi-contacts:before {
  content: "\F6CA"; }

.mdi-content-copy:before {
  content: "\F18F"; }

.mdi-content-cut:before {
  content: "\F190"; }

.mdi-content-duplicate:before {
  content: "\F191"; }

.mdi-content-paste:before {
  content: "\F192"; }

.mdi-content-save:before {
  content: "\F193"; }

.mdi-content-save-all:before {
  content: "\F194"; }

.mdi-content-save-outline:before {
  content: "\F817"; }

.mdi-content-save-settings:before {
  content: "\F61B"; }

.mdi-contrast:before {
  content: "\F195"; }

.mdi-contrast-box:before {
  content: "\F196"; }

.mdi-contrast-circle:before {
  content: "\F197"; }

.mdi-cookie:before {
  content: "\F198"; }

.mdi-copyright:before {
  content: "\F5E6"; }

.mdi-corn:before {
  content: "\F7B7"; }

.mdi-counter:before {
  content: "\F199"; }

.mdi-cow:before {
  content: "\F19A"; }

.mdi-crane:before {
  content: "\F861"; }

.mdi-creation:before {
  content: "\F1C9"; }

.mdi-credit-card:before {
  content: "\F19B"; }

.mdi-credit-card-multiple:before {
  content: "\F19C"; }

.mdi-credit-card-off:before {
  content: "\F5E4"; }

.mdi-credit-card-plus:before {
  content: "\F675"; }

.mdi-credit-card-scan:before {
  content: "\F19D"; }

.mdi-credit-card-settings:before {
  content: "\F8D6"; }

.mdi-crop:before {
  content: "\F19E"; }

.mdi-crop-free:before {
  content: "\F19F"; }

.mdi-crop-landscape:before {
  content: "\F1A0"; }

.mdi-crop-portrait:before {
  content: "\F1A1"; }

.mdi-crop-rotate:before {
  content: "\F695"; }

.mdi-crop-square:before {
  content: "\F1A2"; }

.mdi-crosshairs:before {
  content: "\F1A3"; }

.mdi-crosshairs-gps:before {
  content: "\F1A4"; }

.mdi-crown:before {
  content: "\F1A5"; }

.mdi-cube:before {
  content: "\F1A6"; }

.mdi-cube-outline:before {
  content: "\F1A7"; }

.mdi-cube-send:before {
  content: "\F1A8"; }

.mdi-cube-unfolded:before {
  content: "\F1A9"; }

.mdi-cup:before {
  content: "\F1AA"; }

.mdi-cup-off:before {
  content: "\F5E5"; }

.mdi-cup-water:before {
  content: "\F1AB"; }

.mdi-curling:before {
  content: "\F862"; }

.mdi-currency-bdt:before {
  content: "\F863"; }

.mdi-currency-btc:before {
  content: "\F1AC"; }

.mdi-currency-chf:before {
  content: "\F7B8"; }

.mdi-currency-cny:before {
  content: "\F7B9"; }

.mdi-currency-eth:before {
  content: "\F7BA"; }

.mdi-currency-eur:before {
  content: "\F1AD"; }

.mdi-currency-gbp:before {
  content: "\F1AE"; }

.mdi-currency-inr:before {
  content: "\F1AF"; }

.mdi-currency-jpy:before {
  content: "\F7BB"; }

.mdi-currency-krw:before {
  content: "\F7BC"; }

.mdi-currency-kzt:before {
  content: "\F864"; }

.mdi-currency-ngn:before {
  content: "\F1B0"; }

.mdi-currency-rub:before {
  content: "\F1B1"; }

.mdi-currency-sign:before {
  content: "\F7BD"; }

.mdi-currency-try:before {
  content: "\F1B2"; }

.mdi-currency-twd:before {
  content: "\F7BE"; }

.mdi-currency-usd:before {
  content: "\F1B3"; }

.mdi-currency-usd-off:before {
  content: "\F679"; }

.mdi-cursor-default:before {
  content: "\F1B4"; }

.mdi-cursor-default-outline:before {
  content: "\F1B5"; }

.mdi-cursor-move:before {
  content: "\F1B6"; }

.mdi-cursor-pointer:before {
  content: "\F1B7"; }

.mdi-cursor-text:before {
  content: "\F5E7"; }

.mdi-database:before {
  content: "\F1B8"; }

.mdi-database-minus:before {
  content: "\F1B9"; }

.mdi-database-plus:before {
  content: "\F1BA"; }

.mdi-database-search:before {
  content: "\F865"; }

.mdi-death-star:before {
  content: "\F8D7"; }

.mdi-death-star-variant:before {
  content: "\F8D8"; }

.mdi-debian:before {
  content: "\F8D9"; }

.mdi-debug-step-into:before {
  content: "\F1BB"; }

.mdi-debug-step-out:before {
  content: "\F1BC"; }

.mdi-debug-step-over:before {
  content: "\F1BD"; }

.mdi-decagram:before {
  content: "\F76B"; }

.mdi-decagram-outline:before {
  content: "\F76C"; }

.mdi-decimal-decrease:before {
  content: "\F1BE"; }

.mdi-decimal-increase:before {
  content: "\F1BF"; }

.mdi-delete:before {
  content: "\F1C0"; }

.mdi-delete-circle:before {
  content: "\F682"; }

.mdi-delete-empty:before {
  content: "\F6CB"; }

.mdi-delete-forever:before {
  content: "\F5E8"; }

.mdi-delete-restore:before {
  content: "\F818"; }

.mdi-delete-sweep:before {
  content: "\F5E9"; }

.mdi-delete-variant:before {
  content: "\F1C1"; }

.mdi-delta:before {
  content: "\F1C2"; }

.mdi-deskphone:before {
  content: "\F1C3"; }

.mdi-desktop-classic:before {
  content: "\F7BF"; }

.mdi-desktop-mac:before {
  content: "\F1C4"; }

.mdi-desktop-tower:before {
  content: "\F1C5"; }

.mdi-details:before {
  content: "\F1C6"; }

.mdi-developer-board:before {
  content: "\F696"; }

.mdi-deviantart:before {
  content: "\F1C7"; }

.mdi-dialpad:before {
  content: "\F61C"; }

.mdi-diamond:before {
  content: "\F1C8"; }

.mdi-dice-1:before {
  content: "\F1CA"; }

.mdi-dice-2:before {
  content: "\F1CB"; }

.mdi-dice-3:before {
  content: "\F1CC"; }

.mdi-dice-4:before {
  content: "\F1CD"; }

.mdi-dice-5:before {
  content: "\F1CE"; }

.mdi-dice-6:before {
  content: "\F1CF"; }

.mdi-dice-d10:before {
  content: "\F76E"; }

.mdi-dice-d12:before {
  content: "\F866"; }

.mdi-dice-d20:before {
  content: "\F5EA"; }

.mdi-dice-d4:before {
  content: "\F5EB"; }

.mdi-dice-d6:before {
  content: "\F5EC"; }

.mdi-dice-d8:before {
  content: "\F5ED"; }

.mdi-dice-multiple:before {
  content: "\F76D"; }

.mdi-dictionary:before {
  content: "\F61D"; }

.mdi-dip-switch:before {
  content: "\F7C0"; }

.mdi-directions:before {
  content: "\F1D0"; }

.mdi-directions-fork:before {
  content: "\F641"; }

.mdi-discord:before {
  content: "\F66F"; }

.mdi-disk:before {
  content: "\F5EE"; }

.mdi-disk-alert:before {
  content: "\F1D1"; }

.mdi-disqus:before {
  content: "\F1D2"; }

.mdi-disqus-outline:before {
  content: "\F1D3"; }

.mdi-division:before {
  content: "\F1D4"; }

.mdi-division-box:before {
  content: "\F1D5"; }

.mdi-dna:before {
  content: "\F683"; }

.mdi-dns:before {
  content: "\F1D6"; }

.mdi-do-not-disturb:before {
  content: "\F697"; }

.mdi-do-not-disturb-off:before {
  content: "\F698"; }

.mdi-docker:before {
  content: "\F867"; }

.mdi-dolby:before {
  content: "\F6B2"; }

.mdi-domain:before {
  content: "\F1D7"; }

.mdi-donkey:before {
  content: "\F7C1"; }

.mdi-door:before {
  content: "\F819"; }

.mdi-door-closed:before {
  content: "\F81A"; }

.mdi-door-open:before {
  content: "\F81B"; }

.mdi-doorbell-video:before {
  content: "\F868"; }

.mdi-dots-horizontal:before {
  content: "\F1D8"; }

.mdi-dots-horizontal-circle:before {
  content: "\F7C2"; }

.mdi-dots-vertical:before {
  content: "\F1D9"; }

.mdi-dots-vertical-circle:before {
  content: "\F7C3"; }

.mdi-douban:before {
  content: "\F699"; }

.mdi-download:before {
  content: "\F1DA"; }

.mdi-download-network:before {
  content: "\F6F3"; }

.mdi-drag:before {
  content: "\F1DB"; }

.mdi-drag-horizontal:before {
  content: "\F1DC"; }

.mdi-drag-vertical:before {
  content: "\F1DD"; }

.mdi-drawing:before {
  content: "\F1DE"; }

.mdi-drawing-box:before {
  content: "\F1DF"; }

.mdi-dribbble:before {
  content: "\F1E0"; }

.mdi-dribbble-box:before {
  content: "\F1E1"; }

.mdi-drone:before {
  content: "\F1E2"; }

.mdi-dropbox:before {
  content: "\F1E3"; }

.mdi-drupal:before {
  content: "\F1E4"; }

.mdi-duck:before {
  content: "\F1E5"; }

.mdi-dumbbell:before {
  content: "\F1E6"; }

.mdi-ear-hearing:before {
  content: "\F7C4"; }

.mdi-earth:before {
  content: "\F1E7"; }

.mdi-earth-box:before {
  content: "\F6CC"; }

.mdi-earth-box-off:before {
  content: "\F6CD"; }

.mdi-earth-off:before {
  content: "\F1E8"; }

.mdi-edge:before {
  content: "\F1E9"; }

.mdi-eject:before {
  content: "\F1EA"; }

.mdi-elephant:before {
  content: "\F7C5"; }

.mdi-elevation-decline:before {
  content: "\F1EB"; }

.mdi-elevation-rise:before {
  content: "\F1EC"; }

.mdi-elevator:before {
  content: "\F1ED"; }

.mdi-email:before {
  content: "\F1EE"; }

.mdi-email-alert:before {
  content: "\F6CE"; }

.mdi-email-open:before {
  content: "\F1EF"; }

.mdi-email-open-outline:before {
  content: "\F5EF"; }

.mdi-email-outline:before {
  content: "\F1F0"; }

.mdi-email-secure:before {
  content: "\F1F1"; }

.mdi-email-variant:before {
  content: "\F5F0"; }

.mdi-emby:before {
  content: "\F6B3"; }

.mdi-emoticon:before {
  content: "\F1F2"; }

.mdi-emoticon-cool:before {
  content: "\F1F3"; }

.mdi-emoticon-dead:before {
  content: "\F69A"; }

.mdi-emoticon-devil:before {
  content: "\F1F4"; }

.mdi-emoticon-excited:before {
  content: "\F69B"; }

.mdi-emoticon-happy:before {
  content: "\F1F5"; }

.mdi-emoticon-neutral:before {
  content: "\F1F6"; }

.mdi-emoticon-poop:before {
  content: "\F1F7"; }

.mdi-emoticon-sad:before {
  content: "\F1F8"; }

.mdi-emoticon-tongue:before {
  content: "\F1F9"; }

.mdi-engine:before {
  content: "\F1FA"; }

.mdi-engine-outline:before {
  content: "\F1FB"; }

.mdi-equal:before {
  content: "\F1FC"; }

.mdi-equal-box:before {
  content: "\F1FD"; }

.mdi-eraser:before {
  content: "\F1FE"; }

.mdi-eraser-variant:before {
  content: "\F642"; }

.mdi-escalator:before {
  content: "\F1FF"; }

.mdi-ethereum:before {
  content: "\F869"; }

.mdi-ethernet:before {
  content: "\F200"; }

.mdi-ethernet-cable:before {
  content: "\F201"; }

.mdi-ethernet-cable-off:before {
  content: "\F202"; }

.mdi-etsy:before {
  content: "\F203"; }

.mdi-ev-station:before {
  content: "\F5F1"; }

.mdi-eventbrite:before {
  content: "\F7C6"; }

.mdi-evernote:before {
  content: "\F204"; }

.mdi-exclamation:before {
  content: "\F205"; }

.mdi-exit-to-app:before {
  content: "\F206"; }

.mdi-export:before {
  content: "\F207"; }

.mdi-eye:before {
  content: "\F208"; }

.mdi-eye-off:before {
  content: "\F209"; }

.mdi-eye-off-outline:before {
  content: "\F6D0"; }

.mdi-eye-outline:before {
  content: "\F6CF"; }

.mdi-eye-plus:before {
  content: "\F86A"; }

.mdi-eye-plus-outline:before {
  content: "\F86B"; }

.mdi-eye-settings:before {
  content: "\F86C"; }

.mdi-eye-settings-outline:before {
  content: "\F86D"; }

.mdi-eyedropper:before {
  content: "\F20A"; }

.mdi-eyedropper-variant:before {
  content: "\F20B"; }

.mdi-face:before {
  content: "\F643"; }

.mdi-face-profile:before {
  content: "\F644"; }

.mdi-facebook:before {
  content: "\F20C"; }

.mdi-facebook-box:before {
  content: "\F20D"; }

.mdi-facebook-messenger:before {
  content: "\F20E"; }

.mdi-factory:before {
  content: "\F20F"; }

.mdi-fan:before {
  content: "\F210"; }

.mdi-fan-off:before {
  content: "\F81C"; }

.mdi-fast-forward:before {
  content: "\F211"; }

.mdi-fast-forward-outline:before {
  content: "\F6D1"; }

.mdi-fax:before {
  content: "\F212"; }

.mdi-feather:before {
  content: "\F6D2"; }

.mdi-fedora:before {
  content: "\F8DA"; }

.mdi-ferry:before {
  content: "\F213"; }

.mdi-file:before {
  content: "\F214"; }

.mdi-file-account:before {
  content: "\F73A"; }

.mdi-file-chart:before {
  content: "\F215"; }

.mdi-file-check:before {
  content: "\F216"; }

.mdi-file-cloud:before {
  content: "\F217"; }

.mdi-file-compare:before {
  content: "\F8A9"; }

.mdi-file-delimited:before {
  content: "\F218"; }

.mdi-file-document:before {
  content: "\F219"; }

.mdi-file-document-box:before {
  content: "\F21A"; }

.mdi-file-excel:before {
  content: "\F21B"; }

.mdi-file-excel-box:before {
  content: "\F21C"; }

.mdi-file-export:before {
  content: "\F21D"; }

.mdi-file-find:before {
  content: "\F21E"; }

.mdi-file-hidden:before {
  content: "\F613"; }

.mdi-file-image:before {
  content: "\F21F"; }

.mdi-file-import:before {
  content: "\F220"; }

.mdi-file-lock:before {
  content: "\F221"; }

.mdi-file-multiple:before {
  content: "\F222"; }

.mdi-file-music:before {
  content: "\F223"; }

.mdi-file-outline:before {
  content: "\F224"; }

.mdi-file-pdf:before {
  content: "\F225"; }

.mdi-file-pdf-box:before {
  content: "\F226"; }

.mdi-file-percent:before {
  content: "\F81D"; }

.mdi-file-plus:before {
  content: "\F751"; }

.mdi-file-powerpoint:before {
  content: "\F227"; }

.mdi-file-powerpoint-box:before {
  content: "\F228"; }

.mdi-file-presentation-box:before {
  content: "\F229"; }

.mdi-file-question:before {
  content: "\F86E"; }

.mdi-file-restore:before {
  content: "\F670"; }

.mdi-file-send:before {
  content: "\F22A"; }

.mdi-file-tree:before {
  content: "\F645"; }

.mdi-file-undo:before {
  content: "\F8DB"; }

.mdi-file-video:before {
  content: "\F22B"; }

.mdi-file-word:before {
  content: "\F22C"; }

.mdi-file-word-box:before {
  content: "\F22D"; }

.mdi-file-xml:before {
  content: "\F22E"; }

.mdi-film:before {
  content: "\F22F"; }

.mdi-filmstrip:before {
  content: "\F230"; }

.mdi-filmstrip-off:before {
  content: "\F231"; }

.mdi-filter:before {
  content: "\F232"; }

.mdi-filter-outline:before {
  content: "\F233"; }

.mdi-filter-remove:before {
  content: "\F234"; }

.mdi-filter-remove-outline:before {
  content: "\F235"; }

.mdi-filter-variant:before {
  content: "\F236"; }

.mdi-finance:before {
  content: "\F81E"; }

.mdi-find-replace:before {
  content: "\F6D3"; }

.mdi-fingerprint:before {
  content: "\F237"; }

.mdi-fire:before {
  content: "\F238"; }

.mdi-fire-truck:before {
  content: "\F8AA"; }

.mdi-firefox:before {
  content: "\F239"; }

.mdi-fish:before {
  content: "\F23A"; }

.mdi-flag:before {
  content: "\F23B"; }

.mdi-flag-checkered:before {
  content: "\F23C"; }

.mdi-flag-outline:before {
  content: "\F23D"; }

.mdi-flag-triangle:before {
  content: "\F23F"; }

.mdi-flag-variant:before {
  content: "\F240"; }

.mdi-flag-variant-outline:before {
  content: "\F23E"; }

.mdi-flash:before {
  content: "\F241"; }

.mdi-flash-auto:before {
  content: "\F242"; }

.mdi-flash-circle:before {
  content: "\F81F"; }

.mdi-flash-off:before {
  content: "\F243"; }

.mdi-flash-outline:before {
  content: "\F6D4"; }

.mdi-flash-red-eye:before {
  content: "\F67A"; }

.mdi-flashlight:before {
  content: "\F244"; }

.mdi-flashlight-off:before {
  content: "\F245"; }

.mdi-flask:before {
  content: "\F093"; }

.mdi-flask-empty:before {
  content: "\F094"; }

.mdi-flask-empty-outline:before {
  content: "\F095"; }

.mdi-flask-outline:before {
  content: "\F096"; }

.mdi-flattr:before {
  content: "\F246"; }

.mdi-flip-to-back:before {
  content: "\F247"; }

.mdi-flip-to-front:before {
  content: "\F248"; }

.mdi-floor-lamp:before {
  content: "\F8DC"; }

.mdi-floor-plan:before {
  content: "\F820"; }

.mdi-floppy:before {
  content: "\F249"; }

.mdi-flower:before {
  content: "\F24A"; }

.mdi-folder:before {
  content: "\F24B"; }

.mdi-folder-account:before {
  content: "\F24C"; }

.mdi-folder-download:before {
  content: "\F24D"; }

.mdi-folder-edit:before {
  content: "\F8DD"; }

.mdi-folder-google-drive:before {
  content: "\F24E"; }

.mdi-folder-image:before {
  content: "\F24F"; }

.mdi-folder-key:before {
  content: "\F8AB"; }

.mdi-folder-key-network:before {
  content: "\F8AC"; }

.mdi-folder-lock:before {
  content: "\F250"; }

.mdi-folder-lock-open:before {
  content: "\F251"; }

.mdi-folder-move:before {
  content: "\F252"; }

.mdi-folder-multiple:before {
  content: "\F253"; }

.mdi-folder-multiple-image:before {
  content: "\F254"; }

.mdi-folder-multiple-outline:before {
  content: "\F255"; }

.mdi-folder-network:before {
  content: "\F86F"; }

.mdi-folder-open:before {
  content: "\F76F"; }

.mdi-folder-outline:before {
  content: "\F256"; }

.mdi-folder-plus:before {
  content: "\F257"; }

.mdi-folder-remove:before {
  content: "\F258"; }

.mdi-folder-star:before {
  content: "\F69C"; }

.mdi-folder-upload:before {
  content: "\F259"; }

.mdi-font-awesome:before {
  content: "\F03A"; }

.mdi-food:before {
  content: "\F25A"; }

.mdi-food-apple:before {
  content: "\F25B"; }

.mdi-food-croissant:before {
  content: "\F7C7"; }

.mdi-food-fork-drink:before {
  content: "\F5F2"; }

.mdi-food-off:before {
  content: "\F5F3"; }

.mdi-food-variant:before {
  content: "\F25C"; }

.mdi-football:before {
  content: "\F25D"; }

.mdi-football-australian:before {
  content: "\F25E"; }

.mdi-football-helmet:before {
  content: "\F25F"; }

.mdi-forklift:before {
  content: "\F7C8"; }

.mdi-format-align-bottom:before {
  content: "\F752"; }

.mdi-format-align-center:before {
  content: "\F260"; }

.mdi-format-align-justify:before {
  content: "\F261"; }

.mdi-format-align-left:before {
  content: "\F262"; }

.mdi-format-align-middle:before {
  content: "\F753"; }

.mdi-format-align-right:before {
  content: "\F263"; }

.mdi-format-align-top:before {
  content: "\F754"; }

.mdi-format-annotation-plus:before {
  content: "\F646"; }

.mdi-format-bold:before {
  content: "\F264"; }

.mdi-format-clear:before {
  content: "\F265"; }

.mdi-format-color-fill:before {
  content: "\F266"; }

.mdi-format-color-text:before {
  content: "\F69D"; }

.mdi-format-columns:before {
  content: "\F8DE"; }

.mdi-format-float-center:before {
  content: "\F267"; }

.mdi-format-float-left:before {
  content: "\F268"; }

.mdi-format-float-none:before {
  content: "\F269"; }

.mdi-format-float-right:before {
  content: "\F26A"; }

.mdi-format-font:before {
  content: "\F6D5"; }

.mdi-format-header-1:before {
  content: "\F26B"; }

.mdi-format-header-2:before {
  content: "\F26C"; }

.mdi-format-header-3:before {
  content: "\F26D"; }

.mdi-format-header-4:before {
  content: "\F26E"; }

.mdi-format-header-5:before {
  content: "\F26F"; }

.mdi-format-header-6:before {
  content: "\F270"; }

.mdi-format-header-decrease:before {
  content: "\F271"; }

.mdi-format-header-equal:before {
  content: "\F272"; }

.mdi-format-header-increase:before {
  content: "\F273"; }

.mdi-format-header-pound:before {
  content: "\F274"; }

.mdi-format-horizontal-align-center:before {
  content: "\F61E"; }

.mdi-format-horizontal-align-left:before {
  content: "\F61F"; }

.mdi-format-horizontal-align-right:before {
  content: "\F620"; }

.mdi-format-indent-decrease:before {
  content: "\F275"; }

.mdi-format-indent-increase:before {
  content: "\F276"; }

.mdi-format-italic:before {
  content: "\F277"; }

.mdi-format-line-spacing:before {
  content: "\F278"; }

.mdi-format-line-style:before {
  content: "\F5C8"; }

.mdi-format-line-weight:before {
  content: "\F5C9"; }

.mdi-format-list-bulleted:before {
  content: "\F279"; }

.mdi-format-list-bulleted-type:before {
  content: "\F27A"; }

.mdi-format-list-checks:before {
  content: "\F755"; }

.mdi-format-list-numbers:before {
  content: "\F27B"; }

.mdi-format-page-break:before {
  content: "\F6D6"; }

.mdi-format-paint:before {
  content: "\F27C"; }

.mdi-format-paragraph:before {
  content: "\F27D"; }

.mdi-format-pilcrow:before {
  content: "\F6D7"; }

.mdi-format-quote-close:before {
  content: "\F27E"; }

.mdi-format-quote-open:before {
  content: "\F756"; }

.mdi-format-rotate-90:before {
  content: "\F6A9"; }

.mdi-format-section:before {
  content: "\F69E"; }

.mdi-format-size:before {
  content: "\F27F"; }

.mdi-format-strikethrough:before {
  content: "\F280"; }

.mdi-format-strikethrough-variant:before {
  content: "\F281"; }

.mdi-format-subscript:before {
  content: "\F282"; }

.mdi-format-superscript:before {
  content: "\F283"; }

.mdi-format-text:before {
  content: "\F284"; }

.mdi-format-textdirection-l-to-r:before {
  content: "\F285"; }

.mdi-format-textdirection-r-to-l:before {
  content: "\F286"; }

.mdi-format-title:before {
  content: "\F5F4"; }

.mdi-format-underline:before {
  content: "\F287"; }

.mdi-format-vertical-align-bottom:before {
  content: "\F621"; }

.mdi-format-vertical-align-center:before {
  content: "\F622"; }

.mdi-format-vertical-align-top:before {
  content: "\F623"; }

.mdi-format-wrap-inline:before {
  content: "\F288"; }

.mdi-format-wrap-square:before {
  content: "\F289"; }

.mdi-format-wrap-tight:before {
  content: "\F28A"; }

.mdi-format-wrap-top-bottom:before {
  content: "\F28B"; }

.mdi-forum:before {
  content: "\F28C"; }

.mdi-forum-outline:before {
  content: "\F821"; }

.mdi-forward:before {
  content: "\F28D"; }

.mdi-foursquare:before {
  content: "\F28E"; }

.mdi-freebsd:before {
  content: "\F8DF"; }

.mdi-fridge:before {
  content: "\F28F"; }

.mdi-fridge-filled:before {
  content: "\F290"; }

.mdi-fridge-filled-bottom:before {
  content: "\F291"; }

.mdi-fridge-filled-top:before {
  content: "\F292"; }

.mdi-fuel:before {
  content: "\F7C9"; }

.mdi-fullscreen:before {
  content: "\F293"; }

.mdi-fullscreen-exit:before {
  content: "\F294"; }

.mdi-function:before {
  content: "\F295"; }

.mdi-function-variant:before {
  content: "\F870"; }

.mdi-gamepad:before {
  content: "\F296"; }

.mdi-gamepad-variant:before {
  content: "\F297"; }

.mdi-garage:before {
  content: "\F6D8"; }

.mdi-garage-alert:before {
  content: "\F871"; }

.mdi-garage-open:before {
  content: "\F6D9"; }

.mdi-gas-cylinder:before {
  content: "\F647"; }

.mdi-gas-station:before {
  content: "\F298"; }

.mdi-gate:before {
  content: "\F299"; }

.mdi-gate-and:before {
  content: "\F8E0"; }

.mdi-gate-nand:before {
  content: "\F8E1"; }

.mdi-gate-nor:before {
  content: "\F8E2"; }

.mdi-gate-not:before {
  content: "\F8E3"; }

.mdi-gate-or:before {
  content: "\F8E4"; }

.mdi-gate-xnor:before {
  content: "\F8E5"; }

.mdi-gate-xor:before {
  content: "\F8E6"; }

.mdi-gauge:before {
  content: "\F29A"; }

.mdi-gauge-empty:before {
  content: "\F872"; }

.mdi-gauge-full:before {
  content: "\F873"; }

.mdi-gauge-low:before {
  content: "\F874"; }

.mdi-gavel:before {
  content: "\F29B"; }

.mdi-gender-female:before {
  content: "\F29C"; }

.mdi-gender-male:before {
  content: "\F29D"; }

.mdi-gender-male-female:before {
  content: "\F29E"; }

.mdi-gender-transgender:before {
  content: "\F29F"; }

.mdi-gentoo:before {
  content: "\F8E7"; }

.mdi-gesture:before {
  content: "\F7CA"; }

.mdi-gesture-double-tap:before {
  content: "\F73B"; }

.mdi-gesture-swipe-down:before {
  content: "\F73C"; }

.mdi-gesture-swipe-left:before {
  content: "\F73D"; }

.mdi-gesture-swipe-right:before {
  content: "\F73E"; }

.mdi-gesture-swipe-up:before {
  content: "\F73F"; }

.mdi-gesture-tap:before {
  content: "\F740"; }

.mdi-gesture-two-double-tap:before {
  content: "\F741"; }

.mdi-gesture-two-tap:before {
  content: "\F742"; }

.mdi-ghost:before {
  content: "\F2A0"; }

.mdi-gift:before {
  content: "\F2A1"; }

.mdi-git:before {
  content: "\F2A2"; }

.mdi-github-box:before {
  content: "\F2A3"; }

.mdi-github-circle:before {
  content: "\F2A4"; }

.mdi-github-face:before {
  content: "\F6DA"; }

.mdi-glass-cocktail:before {
  content: "\F356"; }

.mdi-glass-flute:before {
  content: "\F2A5"; }

.mdi-glass-mug:before {
  content: "\F2A6"; }

.mdi-glass-stange:before {
  content: "\F2A7"; }

.mdi-glass-tulip:before {
  content: "\F2A8"; }

.mdi-glass-wine:before {
  content: "\F875"; }

.mdi-glassdoor:before {
  content: "\F2A9"; }

.mdi-glasses:before {
  content: "\F2AA"; }

.mdi-globe-model:before {
  content: "\F8E8"; }

.mdi-gmail:before {
  content: "\F2AB"; }

.mdi-gnome:before {
  content: "\F2AC"; }

.mdi-golf:before {
  content: "\F822"; }

.mdi-gondola:before {
  content: "\F685"; }

.mdi-google:before {
  content: "\F2AD"; }

.mdi-google-allo:before {
  content: "\F801"; }

.mdi-google-analytics:before {
  content: "\F7CB"; }

.mdi-google-assistant:before {
  content: "\F7CC"; }

.mdi-google-cardboard:before {
  content: "\F2AE"; }

.mdi-google-chrome:before {
  content: "\F2AF"; }

.mdi-google-circles:before {
  content: "\F2B0"; }

.mdi-google-circles-communities:before {
  content: "\F2B1"; }

.mdi-google-circles-extended:before {
  content: "\F2B2"; }

.mdi-google-circles-group:before {
  content: "\F2B3"; }

.mdi-google-controller:before {
  content: "\F2B4"; }

.mdi-google-controller-off:before {
  content: "\F2B5"; }

.mdi-google-drive:before {
  content: "\F2B6"; }

.mdi-google-earth:before {
  content: "\F2B7"; }

.mdi-google-glass:before {
  content: "\F2B8"; }

.mdi-google-hangouts:before {
  content: "\F2C9"; }

.mdi-google-home:before {
  content: "\F823"; }

.mdi-google-keep:before {
  content: "\F6DB"; }

.mdi-google-maps:before {
  content: "\F5F5"; }

.mdi-google-nearby:before {
  content: "\F2B9"; }

.mdi-google-pages:before {
  content: "\F2BA"; }

.mdi-google-photos:before {
  content: "\F6DC"; }

.mdi-google-physical-web:before {
  content: "\F2BB"; }

.mdi-google-play:before {
  content: "\F2BC"; }

.mdi-google-plus:before {
  content: "\F2BD"; }

.mdi-google-plus-box:before {
  content: "\F2BE"; }

.mdi-google-translate:before {
  content: "\F2BF"; }

.mdi-google-wallet:before {
  content: "\F2C0"; }

.mdi-gpu:before {
  content: "\F8AD"; }

.mdi-gradient:before {
  content: "\F69F"; }

.mdi-graphql:before {
  content: "\F876"; }

.mdi-grease-pencil:before {
  content: "\F648"; }

.mdi-grid:before {
  content: "\F2C1"; }

.mdi-grid-large:before {
  content: "\F757"; }

.mdi-grid-off:before {
  content: "\F2C2"; }

.mdi-group:before {
  content: "\F2C3"; }

.mdi-guitar-acoustic:before {
  content: "\F770"; }

.mdi-guitar-electric:before {
  content: "\F2C4"; }

.mdi-guitar-pick:before {
  content: "\F2C5"; }

.mdi-guitar-pick-outline:before {
  content: "\F2C6"; }

.mdi-guy-fawkes-mask:before {
  content: "\F824"; }

.mdi-hackernews:before {
  content: "\F624"; }

.mdi-hamburger:before {
  content: "\F684"; }

.mdi-hammer:before {
  content: "\F8E9"; }

.mdi-hand-pointing-right:before {
  content: "\F2C7"; }

.mdi-hanger:before {
  content: "\F2C8"; }

.mdi-harddisk:before {
  content: "\F2CA"; }

.mdi-headphones:before {
  content: "\F2CB"; }

.mdi-headphones-box:before {
  content: "\F2CC"; }

.mdi-headphones-off:before {
  content: "\F7CD"; }

.mdi-headphones-settings:before {
  content: "\F2CD"; }

.mdi-headset:before {
  content: "\F2CE"; }

.mdi-headset-dock:before {
  content: "\F2CF"; }

.mdi-headset-off:before {
  content: "\F2D0"; }

.mdi-heart:before {
  content: "\F2D1"; }

.mdi-heart-box:before {
  content: "\F2D2"; }

.mdi-heart-box-outline:before {
  content: "\F2D3"; }

.mdi-heart-broken:before {
  content: "\F2D4"; }

.mdi-heart-half:before {
  content: "\F6DE"; }

.mdi-heart-half-full:before {
  content: "\F6DD"; }

.mdi-heart-half-outline:before {
  content: "\F6DF"; }

.mdi-heart-off:before {
  content: "\F758"; }

.mdi-heart-outline:before {
  content: "\F2D5"; }

.mdi-heart-pulse:before {
  content: "\F5F6"; }

.mdi-help:before {
  content: "\F2D6"; }

.mdi-help-box:before {
  content: "\F78A"; }

.mdi-help-circle:before {
  content: "\F2D7"; }

.mdi-help-circle-outline:before {
  content: "\F625"; }

.mdi-help-network:before {
  content: "\F6F4"; }

.mdi-hexagon:before {
  content: "\F2D8"; }

.mdi-hexagon-multiple:before {
  content: "\F6E0"; }

.mdi-hexagon-outline:before {
  content: "\F2D9"; }

.mdi-high-definition:before {
  content: "\F7CE"; }

.mdi-high-definition-box:before {
  content: "\F877"; }

.mdi-highway:before {
  content: "\F5F7"; }

.mdi-history:before {
  content: "\F2DA"; }

.mdi-hockey-puck:before {
  content: "\F878"; }

.mdi-hockey-sticks:before {
  content: "\F879"; }

.mdi-hololens:before {
  content: "\F2DB"; }

.mdi-home:before {
  content: "\F2DC"; }

.mdi-home-account:before {
  content: "\F825"; }

.mdi-home-alert:before {
  content: "\F87A"; }

.mdi-home-assistant:before {
  content: "\F7CF"; }

.mdi-home-automation:before {
  content: "\F7D0"; }

.mdi-home-circle:before {
  content: "\F7D1"; }

.mdi-home-currency-usd:before {
  content: "\F8AE"; }

.mdi-home-heart:before {
  content: "\F826"; }

.mdi-home-lock:before {
  content: "\F8EA"; }

.mdi-home-lock-open:before {
  content: "\F8EB"; }

.mdi-home-map-marker:before {
  content: "\F5F8"; }

.mdi-home-modern:before {
  content: "\F2DD"; }

.mdi-home-outline:before {
  content: "\F6A0"; }

.mdi-home-variant:before {
  content: "\F2DE"; }

.mdi-hook:before {
  content: "\F6E1"; }

.mdi-hook-off:before {
  content: "\F6E2"; }

.mdi-hops:before {
  content: "\F2DF"; }

.mdi-hospital:before {
  content: "\F2E0"; }

.mdi-hospital-building:before {
  content: "\F2E1"; }

.mdi-hospital-marker:before {
  content: "\F2E2"; }

.mdi-hot-tub:before {
  content: "\F827"; }

.mdi-hotel:before {
  content: "\F2E3"; }

.mdi-houzz:before {
  content: "\F2E4"; }

.mdi-houzz-box:before {
  content: "\F2E5"; }

.mdi-hulu:before {
  content: "\F828"; }

.mdi-human:before {
  content: "\F2E6"; }

.mdi-human-child:before {
  content: "\F2E7"; }

.mdi-human-female:before {
  content: "\F649"; }

.mdi-human-greeting:before {
  content: "\F64A"; }

.mdi-human-handsdown:before {
  content: "\F64B"; }

.mdi-human-handsup:before {
  content: "\F64C"; }

.mdi-human-male:before {
  content: "\F64D"; }

.mdi-human-male-female:before {
  content: "\F2E8"; }

.mdi-human-pregnant:before {
  content: "\F5CF"; }

.mdi-humble-bundle:before {
  content: "\F743"; }

.mdi-ice-cream:before {
  content: "\F829"; }

.mdi-image:before {
  content: "\F2E9"; }

.mdi-image-album:before {
  content: "\F2EA"; }

.mdi-image-area:before {
  content: "\F2EB"; }

.mdi-image-area-close:before {
  content: "\F2EC"; }

.mdi-image-broken:before {
  content: "\F2ED"; }

.mdi-image-broken-variant:before {
  content: "\F2EE"; }

.mdi-image-filter:before {
  content: "\F2EF"; }

.mdi-image-filter-black-white:before {
  content: "\F2F0"; }

.mdi-image-filter-center-focus:before {
  content: "\F2F1"; }

.mdi-image-filter-center-focus-weak:before {
  content: "\F2F2"; }

.mdi-image-filter-drama:before {
  content: "\F2F3"; }

.mdi-image-filter-frames:before {
  content: "\F2F4"; }

.mdi-image-filter-hdr:before {
  content: "\F2F5"; }

.mdi-image-filter-none:before {
  content: "\F2F6"; }

.mdi-image-filter-tilt-shift:before {
  content: "\F2F7"; }

.mdi-image-filter-vintage:before {
  content: "\F2F8"; }

.mdi-image-multiple:before {
  content: "\F2F9"; }

.mdi-image-off:before {
  content: "\F82A"; }

.mdi-image-plus:before {
  content: "\F87B"; }

.mdi-import:before {
  content: "\F2FA"; }

.mdi-inbox:before {
  content: "\F686"; }

.mdi-inbox-arrow-down:before {
  content: "\F2FB"; }

.mdi-inbox-arrow-up:before {
  content: "\F3D1"; }

.mdi-inbox-multiple:before {
  content: "\F8AF"; }

.mdi-incognito:before {
  content: "\F5F9"; }

.mdi-infinity:before {
  content: "\F6E3"; }

.mdi-information:before {
  content: "\F2FC"; }

.mdi-information-outline:before {
  content: "\F2FD"; }

.mdi-information-variant:before {
  content: "\F64E"; }

.mdi-instagram:before {
  content: "\F2FE"; }

.mdi-instapaper:before {
  content: "\F2FF"; }

.mdi-internet-explorer:before {
  content: "\F300"; }

.mdi-invert-colors:before {
  content: "\F301"; }

.mdi-itunes:before {
  content: "\F676"; }

.mdi-jeepney:before {
  content: "\F302"; }

.mdi-jira:before {
  content: "\F303"; }

.mdi-jquery:before {
  content: "\F87C"; }

.mdi-jsfiddle:before {
  content: "\F304"; }

.mdi-json:before {
  content: "\F626"; }

.mdi-karate:before {
  content: "\F82B"; }

.mdi-keg:before {
  content: "\F305"; }

.mdi-kettle:before {
  content: "\F5FA"; }

.mdi-key:before {
  content: "\F306"; }

.mdi-key-change:before {
  content: "\F307"; }

.mdi-key-minus:before {
  content: "\F308"; }

.mdi-key-plus:before {
  content: "\F309"; }

.mdi-key-remove:before {
  content: "\F30A"; }

.mdi-key-variant:before {
  content: "\F30B"; }

.mdi-keyboard:before {
  content: "\F30C"; }

.mdi-keyboard-backspace:before {
  content: "\F30D"; }

.mdi-keyboard-caps:before {
  content: "\F30E"; }

.mdi-keyboard-close:before {
  content: "\F30F"; }

.mdi-keyboard-off:before {
  content: "\F310"; }

.mdi-keyboard-return:before {
  content: "\F311"; }

.mdi-keyboard-tab:before {
  content: "\F312"; }

.mdi-keyboard-variant:before {
  content: "\F313"; }

.mdi-kickstarter:before {
  content: "\F744"; }

.mdi-kodi:before {
  content: "\F314"; }

.mdi-label:before {
  content: "\F315"; }

.mdi-label-outline:before {
  content: "\F316"; }

.mdi-ladybug:before {
  content: "\F82C"; }

.mdi-lambda:before {
  content: "\F627"; }

.mdi-lamp:before {
  content: "\F6B4"; }

.mdi-lan:before {
  content: "\F317"; }

.mdi-lan-connect:before {
  content: "\F318"; }

.mdi-lan-disconnect:before {
  content: "\F319"; }

.mdi-lan-pending:before {
  content: "\F31A"; }

.mdi-language-c:before {
  content: "\F671"; }

.mdi-language-cpp:before {
  content: "\F672"; }

.mdi-language-csharp:before {
  content: "\F31B"; }

.mdi-language-css3:before {
  content: "\F31C"; }

.mdi-language-go:before {
  content: "\F7D2"; }

.mdi-language-html5:before {
  content: "\F31D"; }

.mdi-language-javascript:before {
  content: "\F31E"; }

.mdi-language-lua:before {
  content: "\F8B0"; }

.mdi-language-php:before {
  content: "\F31F"; }

.mdi-language-python:before {
  content: "\F320"; }

.mdi-language-python-text:before {
  content: "\F321"; }

.mdi-language-r:before {
  content: "\F7D3"; }

.mdi-language-swift:before {
  content: "\F6E4"; }

.mdi-language-typescript:before {
  content: "\F6E5"; }

.mdi-laptop:before {
  content: "\F322"; }

.mdi-laptop-chromebook:before {
  content: "\F323"; }

.mdi-laptop-mac:before {
  content: "\F324"; }

.mdi-laptop-off:before {
  content: "\F6E6"; }

.mdi-laptop-windows:before {
  content: "\F325"; }

.mdi-lastfm:before {
  content: "\F326"; }

.mdi-lastpass:before {
  content: "\F446"; }

.mdi-launch:before {
  content: "\F327"; }

.mdi-lava-lamp:before {
  content: "\F7D4"; }

.mdi-layers:before {
  content: "\F328"; }

.mdi-layers-off:before {
  content: "\F329"; }

.mdi-lead-pencil:before {
  content: "\F64F"; }

.mdi-leaf:before {
  content: "\F32A"; }

.mdi-led-off:before {
  content: "\F32B"; }

.mdi-led-on:before {
  content: "\F32C"; }

.mdi-led-outline:before {
  content: "\F32D"; }

.mdi-led-strip:before {
  content: "\F7D5"; }

.mdi-led-variant-off:before {
  content: "\F32E"; }

.mdi-led-variant-on:before {
  content: "\F32F"; }

.mdi-led-variant-outline:before {
  content: "\F330"; }

.mdi-library:before {
  content: "\F331"; }

.mdi-library-books:before {
  content: "\F332"; }

.mdi-library-music:before {
  content: "\F333"; }

.mdi-library-plus:before {
  content: "\F334"; }

.mdi-lifebuoy:before {
  content: "\F87D"; }

.mdi-lightbulb:before {
  content: "\F335"; }

.mdi-lightbulb-on:before {
  content: "\F6E7"; }

.mdi-lightbulb-on-outline:before {
  content: "\F6E8"; }

.mdi-lightbulb-outline:before {
  content: "\F336"; }

.mdi-link:before {
  content: "\F337"; }

.mdi-link-off:before {
  content: "\F338"; }

.mdi-link-variant:before {
  content: "\F339"; }

.mdi-link-variant-off:before {
  content: "\F33A"; }

.mdi-linkedin:before {
  content: "\F33B"; }

.mdi-linkedin-box:before {
  content: "\F33C"; }

.mdi-linux:before {
  content: "\F33D"; }

.mdi-linux-mint:before {
  content: "\F8EC"; }

.mdi-loading:before {
  content: "\F771"; }

.mdi-lock:before {
  content: "\F33E"; }

.mdi-lock-alert:before {
  content: "\F8ED"; }

.mdi-lock-open:before {
  content: "\F33F"; }

.mdi-lock-open-outline:before {
  content: "\F340"; }

.mdi-lock-outline:before {
  content: "\F341"; }

.mdi-lock-pattern:before {
  content: "\F6E9"; }

.mdi-lock-plus:before {
  content: "\F5FB"; }

.mdi-lock-question:before {
  content: "\F8EE"; }

.mdi-lock-reset:before {
  content: "\F772"; }

.mdi-lock-smart:before {
  content: "\F8B1"; }

.mdi-locker:before {
  content: "\F7D6"; }

.mdi-locker-multiple:before {
  content: "\F7D7"; }

.mdi-login:before {
  content: "\F342"; }

.mdi-login-variant:before {
  content: "\F5FC"; }

.mdi-logout:before {
  content: "\F343"; }

.mdi-logout-variant:before {
  content: "\F5FD"; }

.mdi-looks:before {
  content: "\F344"; }

.mdi-loop:before {
  content: "\F6EA"; }

.mdi-loupe:before {
  content: "\F345"; }

.mdi-lumx:before {
  content: "\F346"; }

.mdi-magnet:before {
  content: "\F347"; }

.mdi-magnet-on:before {
  content: "\F348"; }

.mdi-magnify:before {
  content: "\F349"; }

.mdi-magnify-minus:before {
  content: "\F34A"; }

.mdi-magnify-minus-outline:before {
  content: "\F6EB"; }

.mdi-magnify-plus:before {
  content: "\F34B"; }

.mdi-magnify-plus-outline:before {
  content: "\F6EC"; }

.mdi-mail-ru:before {
  content: "\F34C"; }

.mdi-mailbox:before {
  content: "\F6ED"; }

.mdi-map:before {
  content: "\F34D"; }

.mdi-map-marker:before {
  content: "\F34E"; }

.mdi-map-marker-circle:before {
  content: "\F34F"; }

.mdi-map-marker-distance:before {
  content: "\F8EF"; }

.mdi-map-marker-minus:before {
  content: "\F650"; }

.mdi-map-marker-multiple:before {
  content: "\F350"; }

.mdi-map-marker-off:before {
  content: "\F351"; }

.mdi-map-marker-outline:before {
  content: "\F7D8"; }

.mdi-map-marker-plus:before {
  content: "\F651"; }

.mdi-map-marker-radius:before {
  content: "\F352"; }

.mdi-margin:before {
  content: "\F353"; }

.mdi-markdown:before {
  content: "\F354"; }

.mdi-marker:before {
  content: "\F652"; }

.mdi-marker-check:before {
  content: "\F355"; }

.mdi-material-ui:before {
  content: "\F357"; }

.mdi-math-compass:before {
  content: "\F358"; }

.mdi-matrix:before {
  content: "\F628"; }

.mdi-maxcdn:before {
  content: "\F359"; }

.mdi-medical-bag:before {
  content: "\F6EE"; }

.mdi-medium:before {
  content: "\F35A"; }

.mdi-memory:before {
  content: "\F35B"; }

.mdi-menu:before {
  content: "\F35C"; }

.mdi-menu-down:before {
  content: "\F35D"; }

.mdi-menu-down-outline:before {
  content: "\F6B5"; }

.mdi-menu-left:before {
  content: "\F35E"; }

.mdi-menu-right:before {
  content: "\F35F"; }

.mdi-menu-up:before {
  content: "\F360"; }

.mdi-menu-up-outline:before {
  content: "\F6B6"; }

.mdi-message:before {
  content: "\F361"; }

.mdi-message-alert:before {
  content: "\F362"; }

.mdi-message-bulleted:before {
  content: "\F6A1"; }

.mdi-message-bulleted-off:before {
  content: "\F6A2"; }

.mdi-message-draw:before {
  content: "\F363"; }

.mdi-message-image:before {
  content: "\F364"; }

.mdi-message-outline:before {
  content: "\F365"; }

.mdi-message-plus:before {
  content: "\F653"; }

.mdi-message-processing:before {
  content: "\F366"; }

.mdi-message-reply:before {
  content: "\F367"; }

.mdi-message-reply-text:before {
  content: "\F368"; }

.mdi-message-settings:before {
  content: "\F6EF"; }

.mdi-message-settings-variant:before {
  content: "\F6F0"; }

.mdi-message-text:before {
  content: "\F369"; }

.mdi-message-text-outline:before {
  content: "\F36A"; }

.mdi-message-video:before {
  content: "\F36B"; }

.mdi-meteor:before {
  content: "\F629"; }

.mdi-metronome:before {
  content: "\F7D9"; }

.mdi-metronome-tick:before {
  content: "\F7DA"; }

.mdi-micro-sd:before {
  content: "\F7DB"; }

.mdi-microphone:before {
  content: "\F36C"; }

.mdi-microphone-minus:before {
  content: "\F8B2"; }

.mdi-microphone-off:before {
  content: "\F36D"; }

.mdi-microphone-outline:before {
  content: "\F36E"; }

.mdi-microphone-plus:before {
  content: "\F8B3"; }

.mdi-microphone-settings:before {
  content: "\F36F"; }

.mdi-microphone-variant:before {
  content: "\F370"; }

.mdi-microphone-variant-off:before {
  content: "\F371"; }

.mdi-microscope:before {
  content: "\F654"; }

.mdi-microsoft:before {
  content: "\F372"; }

.mdi-midi:before {
  content: "\F8F0"; }

.mdi-midi-port:before {
  content: "\F8F1"; }

.mdi-minecraft:before {
  content: "\F373"; }

.mdi-minus:before {
  content: "\F374"; }

.mdi-minus-box:before {
  content: "\F375"; }

.mdi-minus-box-outline:before {
  content: "\F6F1"; }

.mdi-minus-circle:before {
  content: "\F376"; }

.mdi-minus-circle-outline:before {
  content: "\F377"; }

.mdi-minus-network:before {
  content: "\F378"; }

.mdi-mixcloud:before {
  content: "\F62A"; }

.mdi-mixed-reality:before {
  content: "\F87E"; }

.mdi-mixer:before {
  content: "\F7DC"; }

.mdi-monitor:before {
  content: "\F379"; }

.mdi-monitor-multiple:before {
  content: "\F37A"; }

.mdi-more:before {
  content: "\F37B"; }

.mdi-motorbike:before {
  content: "\F37C"; }

.mdi-mouse:before {
  content: "\F37D"; }

.mdi-mouse-off:before {
  content: "\F37E"; }

.mdi-mouse-variant:before {
  content: "\F37F"; }

.mdi-mouse-variant-off:before {
  content: "\F380"; }

.mdi-move-resize:before {
  content: "\F655"; }

.mdi-move-resize-variant:before {
  content: "\F656"; }

.mdi-movie:before {
  content: "\F381"; }

.mdi-movie-roll:before {
  content: "\F7DD"; }

.mdi-multiplication:before {
  content: "\F382"; }

.mdi-multiplication-box:before {
  content: "\F383"; }

.mdi-mushroom:before {
  content: "\F7DE"; }

.mdi-mushroom-outline:before {
  content: "\F7DF"; }

.mdi-music:before {
  content: "\F759"; }

.mdi-music-box:before {
  content: "\F384"; }

.mdi-music-box-outline:before {
  content: "\F385"; }

.mdi-music-circle:before {
  content: "\F386"; }

.mdi-music-note:before {
  content: "\F387"; }

.mdi-music-note-bluetooth:before {
  content: "\F5FE"; }

.mdi-music-note-bluetooth-off:before {
  content: "\F5FF"; }

.mdi-music-note-eighth:before {
  content: "\F388"; }

.mdi-music-note-half:before {
  content: "\F389"; }

.mdi-music-note-off:before {
  content: "\F38A"; }

.mdi-music-note-quarter:before {
  content: "\F38B"; }

.mdi-music-note-sixteenth:before {
  content: "\F38C"; }

.mdi-music-note-whole:before {
  content: "\F38D"; }

.mdi-music-off:before {
  content: "\F75A"; }

.mdi-nas:before {
  content: "\F8F2"; }

.mdi-nativescript:before {
  content: "\F87F"; }

.mdi-nature:before {
  content: "\F38E"; }

.mdi-nature-people:before {
  content: "\F38F"; }

.mdi-navigation:before {
  content: "\F390"; }

.mdi-near-me:before {
  content: "\F5CD"; }

.mdi-needle:before {
  content: "\F391"; }

.mdi-netflix:before {
  content: "\F745"; }

.mdi-network:before {
  content: "\F6F2"; }

.mdi-network-strength-1:before {
  content: "\F8F3"; }

.mdi-network-strength-1-alert:before {
  content: "\F8F4"; }

.mdi-network-strength-2:before {
  content: "\F8F5"; }

.mdi-network-strength-2-alert:before {
  content: "\F8F6"; }

.mdi-network-strength-3:before {
  content: "\F8F7"; }

.mdi-network-strength-3-alert:before {
  content: "\F8F8"; }

.mdi-network-strength-4:before {
  content: "\F8F9"; }

.mdi-network-strength-4-alert:before {
  content: "\F8FA"; }

.mdi-network-strength-off:before {
  content: "\F8FB"; }

.mdi-network-strength-off-outline:before {
  content: "\F8FC"; }

.mdi-network-strength-outline:before {
  content: "\F8FD"; }

.mdi-new-box:before {
  content: "\F394"; }

.mdi-newspaper:before {
  content: "\F395"; }

.mdi-nfc:before {
  content: "\F396"; }

.mdi-nfc-tap:before {
  content: "\F397"; }

.mdi-nfc-variant:before {
  content: "\F398"; }

.mdi-ninja:before {
  content: "\F773"; }

.mdi-nintendo-switch:before {
  content: "\F7E0"; }

.mdi-nodejs:before {
  content: "\F399"; }

.mdi-note:before {
  content: "\F39A"; }

.mdi-note-multiple:before {
  content: "\F6B7"; }

.mdi-note-multiple-outline:before {
  content: "\F6B8"; }

.mdi-note-outline:before {
  content: "\F39B"; }

.mdi-note-plus:before {
  content: "\F39C"; }

.mdi-note-plus-outline:before {
  content: "\F39D"; }

.mdi-note-text:before {
  content: "\F39E"; }

.mdi-notebook:before {
  content: "\F82D"; }

.mdi-notification-clear-all:before {
  content: "\F39F"; }

.mdi-npm:before {
  content: "\F6F6"; }

.mdi-nuke:before {
  content: "\F6A3"; }

.mdi-null:before {
  content: "\F7E1"; }

.mdi-numeric:before {
  content: "\F3A0"; }

.mdi-numeric-0-box:before {
  content: "\F3A1"; }

.mdi-numeric-0-box-multiple-outline:before {
  content: "\F3A2"; }

.mdi-numeric-0-box-outline:before {
  content: "\F3A3"; }

.mdi-numeric-1-box:before {
  content: "\F3A4"; }

.mdi-numeric-1-box-multiple-outline:before {
  content: "\F3A5"; }

.mdi-numeric-1-box-outline:before {
  content: "\F3A6"; }

.mdi-numeric-2-box:before {
  content: "\F3A7"; }

.mdi-numeric-2-box-multiple-outline:before {
  content: "\F3A8"; }

.mdi-numeric-2-box-outline:before {
  content: "\F3A9"; }

.mdi-numeric-3-box:before {
  content: "\F3AA"; }

.mdi-numeric-3-box-multiple-outline:before {
  content: "\F3AB"; }

.mdi-numeric-3-box-outline:before {
  content: "\F3AC"; }

.mdi-numeric-4-box:before {
  content: "\F3AD"; }

.mdi-numeric-4-box-multiple-outline:before {
  content: "\F3AE"; }

.mdi-numeric-4-box-outline:before {
  content: "\F3AF"; }

.mdi-numeric-5-box:before {
  content: "\F3B0"; }

.mdi-numeric-5-box-multiple-outline:before {
  content: "\F3B1"; }

.mdi-numeric-5-box-outline:before {
  content: "\F3B2"; }

.mdi-numeric-6-box:before {
  content: "\F3B3"; }

.mdi-numeric-6-box-multiple-outline:before {
  content: "\F3B4"; }

.mdi-numeric-6-box-outline:before {
  content: "\F3B5"; }

.mdi-numeric-7-box:before {
  content: "\F3B6"; }

.mdi-numeric-7-box-multiple-outline:before {
  content: "\F3B7"; }

.mdi-numeric-7-box-outline:before {
  content: "\F3B8"; }

.mdi-numeric-8-box:before {
  content: "\F3B9"; }

.mdi-numeric-8-box-multiple-outline:before {
  content: "\F3BA"; }

.mdi-numeric-8-box-outline:before {
  content: "\F3BB"; }

.mdi-numeric-9-box:before {
  content: "\F3BC"; }

.mdi-numeric-9-box-multiple-outline:before {
  content: "\F3BD"; }

.mdi-numeric-9-box-outline:before {
  content: "\F3BE"; }

.mdi-numeric-9-plus-box:before {
  content: "\F3BF"; }

.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "\F3C0"; }

.mdi-numeric-9-plus-box-outline:before {
  content: "\F3C1"; }

.mdi-nut:before {
  content: "\F6F7"; }

.mdi-nutrition:before {
  content: "\F3C2"; }

.mdi-oar:before {
  content: "\F67B"; }

.mdi-octagon:before {
  content: "\F3C3"; }

.mdi-octagon-outline:before {
  content: "\F3C4"; }

.mdi-octagram:before {
  content: "\F6F8"; }

.mdi-octagram-outline:before {
  content: "\F774"; }

.mdi-odnoklassniki:before {
  content: "\F3C5"; }

.mdi-office:before {
  content: "\F3C6"; }

.mdi-oil:before {
  content: "\F3C7"; }

.mdi-oil-temperature:before {
  content: "\F3C8"; }

.mdi-omega:before {
  content: "\F3C9"; }

.mdi-onedrive:before {
  content: "\F3CA"; }

.mdi-onenote:before {
  content: "\F746"; }

.mdi-onepassword:before {
  content: "\F880"; }

.mdi-opacity:before {
  content: "\F5CC"; }

.mdi-open-in-app:before {
  content: "\F3CB"; }

.mdi-open-in-new:before {
  content: "\F3CC"; }

.mdi-openid:before {
  content: "\F3CD"; }

.mdi-opera:before {
  content: "\F3CE"; }

.mdi-orbit:before {
  content: "\F018"; }

.mdi-ornament:before {
  content: "\F3CF"; }

.mdi-ornament-variant:before {
  content: "\F3D0"; }

.mdi-owl:before {
  content: "\F3D2"; }

.mdi-package:before {
  content: "\F3D3"; }

.mdi-package-down:before {
  content: "\F3D4"; }

.mdi-package-up:before {
  content: "\F3D5"; }

.mdi-package-variant:before {
  content: "\F3D6"; }

.mdi-package-variant-closed:before {
  content: "\F3D7"; }

.mdi-page-first:before {
  content: "\F600"; }

.mdi-page-last:before {
  content: "\F601"; }

.mdi-page-layout-body:before {
  content: "\F6F9"; }

.mdi-page-layout-footer:before {
  content: "\F6FA"; }

.mdi-page-layout-header:before {
  content: "\F6FB"; }

.mdi-page-layout-sidebar-left:before {
  content: "\F6FC"; }

.mdi-page-layout-sidebar-right:before {
  content: "\F6FD"; }

.mdi-palette:before {
  content: "\F3D8"; }

.mdi-palette-advanced:before {
  content: "\F3D9"; }

.mdi-palette-swatch:before {
  content: "\F8B4"; }

.mdi-panda:before {
  content: "\F3DA"; }

.mdi-pandora:before {
  content: "\F3DB"; }

.mdi-panorama:before {
  content: "\F3DC"; }

.mdi-panorama-fisheye:before {
  content: "\F3DD"; }

.mdi-panorama-horizontal:before {
  content: "\F3DE"; }

.mdi-panorama-vertical:before {
  content: "\F3DF"; }

.mdi-panorama-wide-angle:before {
  content: "\F3E0"; }

.mdi-paper-cut-vertical:before {
  content: "\F3E1"; }

.mdi-paperclip:before {
  content: "\F3E2"; }

.mdi-parking:before {
  content: "\F3E3"; }

.mdi-passport:before {
  content: "\F7E2"; }

.mdi-patreon:before {
  content: "\F881"; }

.mdi-pause:before {
  content: "\F3E4"; }

.mdi-pause-circle:before {
  content: "\F3E5"; }

.mdi-pause-circle-outline:before {
  content: "\F3E6"; }

.mdi-pause-octagon:before {
  content: "\F3E7"; }

.mdi-pause-octagon-outline:before {
  content: "\F3E8"; }

.mdi-paw:before {
  content: "\F3E9"; }

.mdi-paw-off:before {
  content: "\F657"; }

.mdi-paypal:before {
  content: "\F882"; }

.mdi-peace:before {
  content: "\F883"; }

.mdi-pen:before {
  content: "\F3EA"; }

.mdi-pencil:before {
  content: "\F3EB"; }

.mdi-pencil-box:before {
  content: "\F3EC"; }

.mdi-pencil-box-outline:before {
  content: "\F3ED"; }

.mdi-pencil-circle:before {
  content: "\F6FE"; }

.mdi-pencil-circle-outline:before {
  content: "\F775"; }

.mdi-pencil-lock:before {
  content: "\F3EE"; }

.mdi-pencil-off:before {
  content: "\F3EF"; }

.mdi-pentagon:before {
  content: "\F6FF"; }

.mdi-pentagon-outline:before {
  content: "\F700"; }

.mdi-percent:before {
  content: "\F3F0"; }

.mdi-periodic-table:before {
  content: "\F8B5"; }

.mdi-periodic-table-co2:before {
  content: "\F7E3"; }

.mdi-periscope:before {
  content: "\F747"; }

.mdi-pharmacy:before {
  content: "\F3F1"; }

.mdi-phone:before {
  content: "\F3F2"; }

.mdi-phone-bluetooth:before {
  content: "\F3F3"; }

.mdi-phone-classic:before {
  content: "\F602"; }

.mdi-phone-forward:before {
  content: "\F3F4"; }

.mdi-phone-hangup:before {
  content: "\F3F5"; }

.mdi-phone-in-talk:before {
  content: "\F3F6"; }

.mdi-phone-incoming:before {
  content: "\F3F7"; }

.mdi-phone-locked:before {
  content: "\F3F8"; }

.mdi-phone-log:before {
  content: "\F3F9"; }

.mdi-phone-minus:before {
  content: "\F658"; }

.mdi-phone-missed:before {
  content: "\F3FA"; }

.mdi-phone-outgoing:before {
  content: "\F3FB"; }

.mdi-phone-paused:before {
  content: "\F3FC"; }

.mdi-phone-plus:before {
  content: "\F659"; }

.mdi-phone-return:before {
  content: "\F82E"; }

.mdi-phone-rotate-landscape:before {
  content: "\F884"; }

.mdi-phone-rotate-portrait:before {
  content: "\F885"; }

.mdi-phone-settings:before {
  content: "\F3FD"; }

.mdi-phone-voip:before {
  content: "\F3FE"; }

.mdi-pi:before {
  content: "\F3FF"; }

.mdi-pi-box:before {
  content: "\F400"; }

.mdi-piano:before {
  content: "\F67C"; }

.mdi-pickaxe:before {
  content: "\F8B6"; }

.mdi-pier:before {
  content: "\F886"; }

.mdi-pier-crane:before {
  content: "\F887"; }

.mdi-pig:before {
  content: "\F401"; }

.mdi-pill:before {
  content: "\F402"; }

.mdi-pillar:before {
  content: "\F701"; }

.mdi-pin:before {
  content: "\F403"; }

.mdi-pin-off:before {
  content: "\F404"; }

.mdi-pin-off-outline:before {
  content: "\F92F"; }

.mdi-pin-outline:before {
  content: "\F930"; }

.mdi-pine-tree:before {
  content: "\F405"; }

.mdi-pine-tree-box:before {
  content: "\F406"; }

.mdi-pinterest:before {
  content: "\F407"; }

.mdi-pinterest-box:before {
  content: "\F408"; }

.mdi-pipe:before {
  content: "\F7E4"; }

.mdi-pipe-disconnected:before {
  content: "\F7E5"; }

.mdi-pipe-leak:before {
  content: "\F888"; }

.mdi-pistol:before {
  content: "\F702"; }

.mdi-piston:before {
  content: "\F889"; }

.mdi-pizza:before {
  content: "\F409"; }

.mdi-plane-shield:before {
  content: "\F6BA"; }

.mdi-play:before {
  content: "\F40A"; }

.mdi-play-box-outline:before {
  content: "\F40B"; }

.mdi-play-circle:before {
  content: "\F40C"; }

.mdi-play-circle-outline:before {
  content: "\F40D"; }

.mdi-play-network:before {
  content: "\F88A"; }

.mdi-play-pause:before {
  content: "\F40E"; }

.mdi-play-protected-content:before {
  content: "\F40F"; }

.mdi-play-speed:before {
  content: "\F8FE"; }

.mdi-playlist-check:before {
  content: "\F5C7"; }

.mdi-playlist-edit:before {
  content: "\F8FF"; }

.mdi-playlist-minus:before {
  content: "\F410"; }

.mdi-playlist-play:before {
  content: "\F411"; }

.mdi-playlist-plus:before {
  content: "\F412"; }

.mdi-playlist-remove:before {
  content: "\F413"; }

.mdi-playstation:before {
  content: "\F414"; }

.mdi-plex:before {
  content: "\F6B9"; }

.mdi-plus:before {
  content: "\F415"; }

.mdi-plus-box:before {
  content: "\F416"; }

.mdi-plus-box-outline:before {
  content: "\F703"; }

.mdi-plus-circle:before {
  content: "\F417"; }

.mdi-plus-circle-multiple-outline:before {
  content: "\F418"; }

.mdi-plus-circle-outline:before {
  content: "\F419"; }

.mdi-plus-network:before {
  content: "\F41A"; }

.mdi-plus-one:before {
  content: "\F41B"; }

.mdi-plus-outline:before {
  content: "\F704"; }

.mdi-pocket:before {
  content: "\F41C"; }

.mdi-pokeball:before {
  content: "\F41D"; }

.mdi-poker-chip:before {
  content: "\F82F"; }

.mdi-polaroid:before {
  content: "\F41E"; }

.mdi-poll:before {
  content: "\F41F"; }

.mdi-poll-box:before {
  content: "\F420"; }

.mdi-polymer:before {
  content: "\F421"; }

.mdi-pool:before {
  content: "\F606"; }

.mdi-popcorn:before {
  content: "\F422"; }

.mdi-pot:before {
  content: "\F65A"; }

.mdi-pot-mix:before {
  content: "\F65B"; }

.mdi-pound:before {
  content: "\F423"; }

.mdi-pound-box:before {
  content: "\F424"; }

.mdi-power:before {
  content: "\F425"; }

.mdi-power-cycle:before {
  content: "\F900"; }

.mdi-power-off:before {
  content: "\F901"; }

.mdi-power-on:before {
  content: "\F902"; }

.mdi-power-plug:before {
  content: "\F6A4"; }

.mdi-power-plug-off:before {
  content: "\F6A5"; }

.mdi-power-settings:before {
  content: "\F426"; }

.mdi-power-sleep:before {
  content: "\F903"; }

.mdi-power-socket:before {
  content: "\F427"; }

.mdi-power-socket-au:before {
  content: "\F904"; }

.mdi-power-socket-eu:before {
  content: "\F7E6"; }

.mdi-power-socket-uk:before {
  content: "\F7E7"; }

.mdi-power-socket-us:before {
  content: "\F7E8"; }

.mdi-power-standby:before {
  content: "\F905"; }

.mdi-prescription:before {
  content: "\F705"; }

.mdi-presentation:before {
  content: "\F428"; }

.mdi-presentation-play:before {
  content: "\F429"; }

.mdi-printer:before {
  content: "\F42A"; }

.mdi-printer-3d:before {
  content: "\F42B"; }

.mdi-printer-alert:before {
  content: "\F42C"; }

.mdi-printer-settings:before {
  content: "\F706"; }

.mdi-priority-high:before {
  content: "\F603"; }

.mdi-priority-low:before {
  content: "\F604"; }

.mdi-professional-hexagon:before {
  content: "\F42D"; }

.mdi-projector:before {
  content: "\F42E"; }

.mdi-projector-screen:before {
  content: "\F42F"; }

.mdi-publish:before {
  content: "\F6A6"; }

.mdi-pulse:before {
  content: "\F430"; }

.mdi-puzzle:before {
  content: "\F431"; }

.mdi-qqchat:before {
  content: "\F605"; }

.mdi-qrcode:before {
  content: "\F432"; }

.mdi-qrcode-edit:before {
  content: "\F8B7"; }

.mdi-qrcode-scan:before {
  content: "\F433"; }

.mdi-quadcopter:before {
  content: "\F434"; }

.mdi-quality-high:before {
  content: "\F435"; }

.mdi-quicktime:before {
  content: "\F436"; }

.mdi-rabbit:before {
  content: "\F906"; }

.mdi-radar:before {
  content: "\F437"; }

.mdi-radiator:before {
  content: "\F438"; }

.mdi-radio:before {
  content: "\F439"; }

.mdi-radio-handheld:before {
  content: "\F43A"; }

.mdi-radio-tower:before {
  content: "\F43B"; }

.mdi-radioactive:before {
  content: "\F43C"; }

.mdi-radiobox-blank:before {
  content: "\F43D"; }

.mdi-radiobox-marked:before {
  content: "\F43E"; }

.mdi-raspberrypi:before {
  content: "\F43F"; }

.mdi-ray-end:before {
  content: "\F440"; }

.mdi-ray-end-arrow:before {
  content: "\F441"; }

.mdi-ray-start:before {
  content: "\F442"; }

.mdi-ray-start-arrow:before {
  content: "\F443"; }

.mdi-ray-start-end:before {
  content: "\F444"; }

.mdi-ray-vertex:before {
  content: "\F445"; }

.mdi-react:before {
  content: "\F707"; }

.mdi-read:before {
  content: "\F447"; }

.mdi-receipt:before {
  content: "\F449"; }

.mdi-record:before {
  content: "\F44A"; }

.mdi-record-rec:before {
  content: "\F44B"; }

.mdi-recycle:before {
  content: "\F44C"; }

.mdi-reddit:before {
  content: "\F44D"; }

.mdi-redo:before {
  content: "\F44E"; }

.mdi-redo-variant:before {
  content: "\F44F"; }

.mdi-refresh:before {
  content: "\F450"; }

.mdi-regex:before {
  content: "\F451"; }

.mdi-relative-scale:before {
  content: "\F452"; }

.mdi-reload:before {
  content: "\F453"; }

.mdi-reminder:before {
  content: "\F88B"; }

.mdi-remote:before {
  content: "\F454"; }

.mdi-remote-desktop:before {
  content: "\F8B8"; }

.mdi-rename-box:before {
  content: "\F455"; }

.mdi-reorder-horizontal:before {
  content: "\F687"; }

.mdi-reorder-vertical:before {
  content: "\F688"; }

.mdi-repeat:before {
  content: "\F456"; }

.mdi-repeat-off:before {
  content: "\F457"; }

.mdi-repeat-once:before {
  content: "\F458"; }

.mdi-replay:before {
  content: "\F459"; }

.mdi-reply:before {
  content: "\F45A"; }

.mdi-reply-all:before {
  content: "\F45B"; }

.mdi-reproduction:before {
  content: "\F45C"; }

.mdi-resize-bottom-right:before {
  content: "\F45D"; }

.mdi-responsive:before {
  content: "\F45E"; }

.mdi-restart:before {
  content: "\F708"; }

.mdi-restore:before {
  content: "\F6A7"; }

.mdi-rewind:before {
  content: "\F45F"; }

.mdi-rewind-outline:before {
  content: "\F709"; }

.mdi-rhombus:before {
  content: "\F70A"; }

.mdi-rhombus-outline:before {
  content: "\F70B"; }

.mdi-ribbon:before {
  content: "\F460"; }

.mdi-rice:before {
  content: "\F7E9"; }

.mdi-ring:before {
  content: "\F7EA"; }

.mdi-road:before {
  content: "\F461"; }

.mdi-road-variant:before {
  content: "\F462"; }

.mdi-robot:before {
  content: "\F6A8"; }

.mdi-robot-vacuum:before {
  content: "\F70C"; }

.mdi-robot-vacuum-variant:before {
  content: "\F907"; }

.mdi-rocket:before {
  content: "\F463"; }

.mdi-room-service:before {
  content: "\F88C"; }

.mdi-rotate-3d:before {
  content: "\F464"; }

.mdi-rotate-left:before {
  content: "\F465"; }

.mdi-rotate-left-variant:before {
  content: "\F466"; }

.mdi-rotate-right:before {
  content: "\F467"; }

.mdi-rotate-right-variant:before {
  content: "\F468"; }

.mdi-rounded-corner:before {
  content: "\F607"; }

.mdi-router-wireless:before {
  content: "\F469"; }

.mdi-routes:before {
  content: "\F46A"; }

.mdi-rowing:before {
  content: "\F608"; }

.mdi-rss:before {
  content: "\F46B"; }

.mdi-rss-box:before {
  content: "\F46C"; }

.mdi-ruler:before {
  content: "\F46D"; }

.mdi-run:before {
  content: "\F70D"; }

.mdi-run-fast:before {
  content: "\F46E"; }

.mdi-sale:before {
  content: "\F46F"; }

.mdi-salesforce:before {
  content: "\F88D"; }

.mdi-sass:before {
  content: "\F7EB"; }

.mdi-satellite:before {
  content: "\F470"; }

.mdi-satellite-uplink:before {
  content: "\F908"; }

.mdi-satellite-variant:before {
  content: "\F471"; }

.mdi-sausage:before {
  content: "\F8B9"; }

.mdi-saxophone:before {
  content: "\F609"; }

.mdi-scale:before {
  content: "\F472"; }

.mdi-scale-balance:before {
  content: "\F5D1"; }

.mdi-scale-bathroom:before {
  content: "\F473"; }

.mdi-scanner:before {
  content: "\F6AA"; }

.mdi-scanner-off:before {
  content: "\F909"; }

.mdi-school:before {
  content: "\F474"; }

.mdi-screen-rotation:before {
  content: "\F475"; }

.mdi-screen-rotation-lock:before {
  content: "\F476"; }

.mdi-screwdriver:before {
  content: "\F477"; }

.mdi-script:before {
  content: "\F478"; }

.mdi-sd:before {
  content: "\F479"; }

.mdi-seal:before {
  content: "\F47A"; }

.mdi-search-web:before {
  content: "\F70E"; }

.mdi-seat-flat:before {
  content: "\F47B"; }

.mdi-seat-flat-angled:before {
  content: "\F47C"; }

.mdi-seat-individual-suite:before {
  content: "\F47D"; }

.mdi-seat-legroom-extra:before {
  content: "\F47E"; }

.mdi-seat-legroom-normal:before {
  content: "\F47F"; }

.mdi-seat-legroom-reduced:before {
  content: "\F480"; }

.mdi-seat-recline-extra:before {
  content: "\F481"; }

.mdi-seat-recline-normal:before {
  content: "\F482"; }

.mdi-security:before {
  content: "\F483"; }

.mdi-security-account:before {
  content: "\F88E"; }

.mdi-security-home:before {
  content: "\F689"; }

.mdi-security-network:before {
  content: "\F484"; }

.mdi-select:before {
  content: "\F485"; }

.mdi-select-all:before {
  content: "\F486"; }

.mdi-select-inverse:before {
  content: "\F487"; }

.mdi-select-off:before {
  content: "\F488"; }

.mdi-selection:before {
  content: "\F489"; }

.mdi-selection-off:before {
  content: "\F776"; }

.mdi-send:before {
  content: "\F48A"; }

.mdi-send-secure:before {
  content: "\F7EC"; }

.mdi-serial-port:before {
  content: "\F65C"; }

.mdi-server:before {
  content: "\F48B"; }

.mdi-server-minus:before {
  content: "\F48C"; }

.mdi-server-network:before {
  content: "\F48D"; }

.mdi-server-network-off:before {
  content: "\F48E"; }

.mdi-server-off:before {
  content: "\F48F"; }

.mdi-server-plus:before {
  content: "\F490"; }

.mdi-server-remove:before {
  content: "\F491"; }

.mdi-server-security:before {
  content: "\F492"; }

.mdi-set-all:before {
  content: "\F777"; }

.mdi-set-center:before {
  content: "\F778"; }

.mdi-set-center-right:before {
  content: "\F779"; }

.mdi-set-left:before {
  content: "\F77A"; }

.mdi-set-left-center:before {
  content: "\F77B"; }

.mdi-set-left-right:before {
  content: "\F77C"; }

.mdi-set-none:before {
  content: "\F77D"; }

.mdi-set-right:before {
  content: "\F77E"; }

.mdi-settings:before {
  content: "\F493"; }

.mdi-settings-box:before {
  content: "\F494"; }

.mdi-settings-outline:before {
  content: "\F8BA"; }

.mdi-shape:before {
  content: "\F830"; }

.mdi-shape-circle-plus:before {
  content: "\F65D"; }

.mdi-shape-outline:before {
  content: "\F831"; }

.mdi-shape-plus:before {
  content: "\F495"; }

.mdi-shape-polygon-plus:before {
  content: "\F65E"; }

.mdi-shape-rectangle-plus:before {
  content: "\F65F"; }

.mdi-shape-square-plus:before {
  content: "\F660"; }

.mdi-share:before {
  content: "\F496"; }

.mdi-share-outline:before {
  content: "\F931"; }

.mdi-share-variant:before {
  content: "\F497"; }

.mdi-shield:before {
  content: "\F498"; }

.mdi-shield-half-full:before {
  content: "\F77F"; }

.mdi-shield-outline:before {
  content: "\F499"; }

.mdi-ship-wheel:before {
  content: "\F832"; }

.mdi-shopping:before {
  content: "\F49A"; }

.mdi-shopping-music:before {
  content: "\F49B"; }

.mdi-shovel:before {
  content: "\F70F"; }

.mdi-shovel-off:before {
  content: "\F710"; }

.mdi-shredder:before {
  content: "\F49C"; }

.mdi-shuffle:before {
  content: "\F49D"; }

.mdi-shuffle-disabled:before {
  content: "\F49E"; }

.mdi-shuffle-variant:before {
  content: "\F49F"; }

.mdi-sigma:before {
  content: "\F4A0"; }

.mdi-sigma-lower:before {
  content: "\F62B"; }

.mdi-sign-caution:before {
  content: "\F4A1"; }

.mdi-sign-direction:before {
  content: "\F780"; }

.mdi-sign-text:before {
  content: "\F781"; }

.mdi-signal:before {
  content: "\F4A2"; }

.mdi-signal-2g:before {
  content: "\F711"; }

.mdi-signal-3g:before {
  content: "\F712"; }

.mdi-signal-4g:before {
  content: "\F713"; }

.mdi-signal-cellular-1:before {
  content: "\F8BB"; }

.mdi-signal-cellular-2:before {
  content: "\F8BC"; }

.mdi-signal-cellular-3:before {
  content: "\F8BD"; }

.mdi-signal-cellular-outline:before {
  content: "\F8BE"; }

.mdi-signal-hspa:before {
  content: "\F714"; }

.mdi-signal-hspa-plus:before {
  content: "\F715"; }

.mdi-signal-off:before {
  content: "\F782"; }

.mdi-signal-variant:before {
  content: "\F60A"; }

.mdi-silverware:before {
  content: "\F4A3"; }

.mdi-silverware-fork:before {
  content: "\F4A4"; }

.mdi-silverware-spoon:before {
  content: "\F4A5"; }

.mdi-silverware-variant:before {
  content: "\F4A6"; }

.mdi-sim:before {
  content: "\F4A7"; }

.mdi-sim-alert:before {
  content: "\F4A8"; }

.mdi-sim-off:before {
  content: "\F4A9"; }

.mdi-sitemap:before {
  content: "\F4AA"; }

.mdi-skip-backward:before {
  content: "\F4AB"; }

.mdi-skip-forward:before {
  content: "\F4AC"; }

.mdi-skip-next:before {
  content: "\F4AD"; }

.mdi-skip-next-circle:before {
  content: "\F661"; }

.mdi-skip-next-circle-outline:before {
  content: "\F662"; }

.mdi-skip-previous:before {
  content: "\F4AE"; }

.mdi-skip-previous-circle:before {
  content: "\F663"; }

.mdi-skip-previous-circle-outline:before {
  content: "\F664"; }

.mdi-skull:before {
  content: "\F68B"; }

.mdi-skype:before {
  content: "\F4AF"; }

.mdi-skype-business:before {
  content: "\F4B0"; }

.mdi-slack:before {
  content: "\F4B1"; }

.mdi-slackware:before {
  content: "\F90A"; }

.mdi-sleep:before {
  content: "\F4B2"; }

.mdi-sleep-off:before {
  content: "\F4B3"; }

.mdi-smoke-detector:before {
  content: "\F392"; }

.mdi-smoking:before {
  content: "\F4B4"; }

.mdi-smoking-off:before {
  content: "\F4B5"; }

.mdi-snapchat:before {
  content: "\F4B6"; }

.mdi-snowflake:before {
  content: "\F716"; }

.mdi-snowman:before {
  content: "\F4B7"; }

.mdi-soccer:before {
  content: "\F4B8"; }

.mdi-soccer-field:before {
  content: "\F833"; }

.mdi-sofa:before {
  content: "\F4B9"; }

.mdi-solid:before {
  content: "\F68C"; }

.mdi-sort:before {
  content: "\F4BA"; }

.mdi-sort-alphabetical:before {
  content: "\F4BB"; }

.mdi-sort-ascending:before {
  content: "\F4BC"; }

.mdi-sort-descending:before {
  content: "\F4BD"; }

.mdi-sort-numeric:before {
  content: "\F4BE"; }

.mdi-sort-variant:before {
  content: "\F4BF"; }

.mdi-soundcloud:before {
  content: "\F4C0"; }

.mdi-source-branch:before {
  content: "\F62C"; }

.mdi-source-commit:before {
  content: "\F717"; }

.mdi-source-commit-end:before {
  content: "\F718"; }

.mdi-source-commit-end-local:before {
  content: "\F719"; }

.mdi-source-commit-local:before {
  content: "\F71A"; }

.mdi-source-commit-next-local:before {
  content: "\F71B"; }

.mdi-source-commit-start:before {
  content: "\F71C"; }

.mdi-source-commit-start-next-local:before {
  content: "\F71D"; }

.mdi-source-fork:before {
  content: "\F4C1"; }

.mdi-source-merge:before {
  content: "\F62D"; }

.mdi-source-pull:before {
  content: "\F4C2"; }

.mdi-soy-sauce:before {
  content: "\F7ED"; }

.mdi-speaker:before {
  content: "\F4C3"; }

.mdi-speaker-off:before {
  content: "\F4C4"; }

.mdi-speaker-wireless:before {
  content: "\F71E"; }

.mdi-speedometer:before {
  content: "\F4C5"; }

.mdi-spellcheck:before {
  content: "\F4C6"; }

.mdi-spotify:before {
  content: "\F4C7"; }

.mdi-spotlight:before {
  content: "\F4C8"; }

.mdi-spotlight-beam:before {
  content: "\F4C9"; }

.mdi-spray:before {
  content: "\F665"; }

.mdi-square:before {
  content: "\F763"; }

.mdi-square-edit-outline:before {
  content: "\F90B"; }

.mdi-square-inc:before {
  content: "\F4CA"; }

.mdi-square-inc-cash:before {
  content: "\F4CB"; }

.mdi-square-outline:before {
  content: "\F762"; }

.mdi-square-root:before {
  content: "\F783"; }

.mdi-ssh:before {
  content: "\F8BF"; }

.mdi-stack-exchange:before {
  content: "\F60B"; }

.mdi-stack-overflow:before {
  content: "\F4CC"; }

.mdi-stadium:before {
  content: "\F71F"; }

.mdi-stairs:before {
  content: "\F4CD"; }

.mdi-standard-definition:before {
  content: "\F7EE"; }

.mdi-star:before {
  content: "\F4CE"; }

.mdi-star-circle:before {
  content: "\F4CF"; }

.mdi-star-half:before {
  content: "\F4D0"; }

.mdi-star-off:before {
  content: "\F4D1"; }

.mdi-star-outline:before {
  content: "\F4D2"; }

.mdi-steam:before {
  content: "\F4D3"; }

.mdi-steam-box:before {
  content: "\F90C"; }

.mdi-steering:before {
  content: "\F4D4"; }

.mdi-steering-off:before {
  content: "\F90D"; }

.mdi-step-backward:before {
  content: "\F4D5"; }

.mdi-step-backward-2:before {
  content: "\F4D6"; }

.mdi-step-forward:before {
  content: "\F4D7"; }

.mdi-step-forward-2:before {
  content: "\F4D8"; }

.mdi-stethoscope:before {
  content: "\F4D9"; }

.mdi-sticker:before {
  content: "\F5D0"; }

.mdi-sticker-emoji:before {
  content: "\F784"; }

.mdi-stocking:before {
  content: "\F4DA"; }

.mdi-stop:before {
  content: "\F4DB"; }

.mdi-stop-circle:before {
  content: "\F666"; }

.mdi-stop-circle-outline:before {
  content: "\F667"; }

.mdi-store:before {
  content: "\F4DC"; }

.mdi-store-24-hour:before {
  content: "\F4DD"; }

.mdi-stove:before {
  content: "\F4DE"; }

.mdi-subdirectory-arrow-left:before {
  content: "\F60C"; }

.mdi-subdirectory-arrow-right:before {
  content: "\F60D"; }

.mdi-subway:before {
  content: "\F6AB"; }

.mdi-subway-variant:before {
  content: "\F4DF"; }

.mdi-summit:before {
  content: "\F785"; }

.mdi-sunglasses:before {
  content: "\F4E0"; }

.mdi-surround-sound:before {
  content: "\F5C5"; }

.mdi-surround-sound-2-0:before {
  content: "\F7EF"; }

.mdi-surround-sound-3-1:before {
  content: "\F7F0"; }

.mdi-surround-sound-5-1:before {
  content: "\F7F1"; }

.mdi-surround-sound-7-1:before {
  content: "\F7F2"; }

.mdi-svg:before {
  content: "\F720"; }

.mdi-swap-horizontal:before {
  content: "\F4E1"; }

.mdi-swap-horizontal-variant:before {
  content: "\F8C0"; }

.mdi-swap-vertical:before {
  content: "\F4E2"; }

.mdi-swap-vertical-variant:before {
  content: "\F8C1"; }

.mdi-swim:before {
  content: "\F4E3"; }

.mdi-switch:before {
  content: "\F4E4"; }

.mdi-sword:before {
  content: "\F4E5"; }

.mdi-sword-cross:before {
  content: "\F786"; }

.mdi-sync:before {
  content: "\F4E6"; }

.mdi-sync-alert:before {
  content: "\F4E7"; }

.mdi-sync-off:before {
  content: "\F4E8"; }

.mdi-tab:before {
  content: "\F4E9"; }

.mdi-tab-plus:before {
  content: "\F75B"; }

.mdi-tab-unselected:before {
  content: "\F4EA"; }

.mdi-table:before {
  content: "\F4EB"; }

.mdi-table-column:before {
  content: "\F834"; }

.mdi-table-column-plus-after:before {
  content: "\F4EC"; }

.mdi-table-column-plus-before:before {
  content: "\F4ED"; }

.mdi-table-column-remove:before {
  content: "\F4EE"; }

.mdi-table-column-width:before {
  content: "\F4EF"; }

.mdi-table-edit:before {
  content: "\F4F0"; }

.mdi-table-large:before {
  content: "\F4F1"; }

.mdi-table-of-contents:before {
  content: "\F835"; }

.mdi-table-row:before {
  content: "\F836"; }

.mdi-table-row-height:before {
  content: "\F4F2"; }

.mdi-table-row-plus-after:before {
  content: "\F4F3"; }

.mdi-table-row-plus-before:before {
  content: "\F4F4"; }

.mdi-table-row-remove:before {
  content: "\F4F5"; }

.mdi-table-search:before {
  content: "\F90E"; }

.mdi-table-settings:before {
  content: "\F837"; }

.mdi-tablet:before {
  content: "\F4F6"; }

.mdi-tablet-android:before {
  content: "\F4F7"; }

.mdi-tablet-ipad:before {
  content: "\F4F8"; }

.mdi-taco:before {
  content: "\F761"; }

.mdi-tag:before {
  content: "\F4F9"; }

.mdi-tag-faces:before {
  content: "\F4FA"; }

.mdi-tag-heart:before {
  content: "\F68A"; }

.mdi-tag-minus:before {
  content: "\F90F"; }

.mdi-tag-multiple:before {
  content: "\F4FB"; }

.mdi-tag-outline:before {
  content: "\F4FC"; }

.mdi-tag-plus:before {
  content: "\F721"; }

.mdi-tag-remove:before {
  content: "\F722"; }

.mdi-tag-text-outline:before {
  content: "\F4FD"; }

.mdi-target:before {
  content: "\F4FE"; }

.mdi-taxi:before {
  content: "\F4FF"; }

.mdi-teach:before {
  content: "\F88F"; }

.mdi-teamviewer:before {
  content: "\F500"; }

.mdi-telegram:before {
  content: "\F501"; }

.mdi-television:before {
  content: "\F502"; }

.mdi-television-box:before {
  content: "\F838"; }

.mdi-television-classic:before {
  content: "\F7F3"; }

.mdi-television-classic-off:before {
  content: "\F839"; }

.mdi-television-guide:before {
  content: "\F503"; }

.mdi-television-off:before {
  content: "\F83A"; }

.mdi-temperature-celsius:before {
  content: "\F504"; }

.mdi-temperature-fahrenheit:before {
  content: "\F505"; }

.mdi-temperature-kelvin:before {
  content: "\F506"; }

.mdi-tennis:before {
  content: "\F507"; }

.mdi-tent:before {
  content: "\F508"; }

.mdi-terrain:before {
  content: "\F509"; }

.mdi-test-tube:before {
  content: "\F668"; }

.mdi-test-tube-empty:before {
  content: "\F910"; }

.mdi-test-tube-off:before {
  content: "\F911"; }

.mdi-text-shadow:before {
  content: "\F669"; }

.mdi-text-to-speech:before {
  content: "\F50A"; }

.mdi-text-to-speech-off:before {
  content: "\F50B"; }

.mdi-textbox:before {
  content: "\F60E"; }

.mdi-textbox-password:before {
  content: "\F7F4"; }

.mdi-texture:before {
  content: "\F50C"; }

.mdi-theater:before {
  content: "\F50D"; }

.mdi-theme-light-dark:before {
  content: "\F50E"; }

.mdi-thermometer:before {
  content: "\F50F"; }

.mdi-thermometer-lines:before {
  content: "\F510"; }

.mdi-thermostat:before {
  content: "\F393"; }

.mdi-thermostat-box:before {
  content: "\F890"; }

.mdi-thought-bubble:before {
  content: "\F7F5"; }

.mdi-thought-bubble-outline:before {
  content: "\F7F6"; }

.mdi-thumb-down:before {
  content: "\F511"; }

.mdi-thumb-down-outline:before {
  content: "\F512"; }

.mdi-thumb-up:before {
  content: "\F513"; }

.mdi-thumb-up-outline:before {
  content: "\F514"; }

.mdi-thumbs-up-down:before {
  content: "\F515"; }

.mdi-ticket:before {
  content: "\F516"; }

.mdi-ticket-account:before {
  content: "\F517"; }

.mdi-ticket-confirmation:before {
  content: "\F518"; }

.mdi-ticket-outline:before {
  content: "\F912"; }

.mdi-ticket-percent:before {
  content: "\F723"; }

.mdi-tie:before {
  content: "\F519"; }

.mdi-tilde:before {
  content: "\F724"; }

.mdi-timelapse:before {
  content: "\F51A"; }

.mdi-timer:before {
  content: "\F51B"; }

.mdi-timer-10:before {
  content: "\F51C"; }

.mdi-timer-3:before {
  content: "\F51D"; }

.mdi-timer-off:before {
  content: "\F51E"; }

.mdi-timer-sand:before {
  content: "\F51F"; }

.mdi-timer-sand-empty:before {
  content: "\F6AC"; }

.mdi-timer-sand-full:before {
  content: "\F78B"; }

.mdi-timetable:before {
  content: "\F520"; }

.mdi-toggle-switch:before {
  content: "\F521"; }

.mdi-toggle-switch-off:before {
  content: "\F522"; }

.mdi-tooltip:before {
  content: "\F523"; }

.mdi-tooltip-edit:before {
  content: "\F524"; }

.mdi-tooltip-image:before {
  content: "\F525"; }

.mdi-tooltip-outline:before {
  content: "\F526"; }

.mdi-tooltip-outline-plus:before {
  content: "\F527"; }

.mdi-tooltip-text:before {
  content: "\F528"; }

.mdi-tooth:before {
  content: "\F8C2"; }

.mdi-tooth-outline:before {
  content: "\F529"; }

.mdi-tor:before {
  content: "\F52A"; }

.mdi-tower-beach:before {
  content: "\F680"; }

.mdi-tower-fire:before {
  content: "\F681"; }

.mdi-towing:before {
  content: "\F83B"; }

.mdi-track-light:before {
  content: "\F913"; }

.mdi-trackpad:before {
  content: "\F7F7"; }

.mdi-trackpad-lock:before {
  content: "\F932"; }

.mdi-tractor:before {
  content: "\F891"; }

.mdi-traffic-light:before {
  content: "\F52B"; }

.mdi-train:before {
  content: "\F52C"; }

.mdi-train-variant:before {
  content: "\F8C3"; }

.mdi-tram:before {
  content: "\F52D"; }

.mdi-transcribe:before {
  content: "\F52E"; }

.mdi-transcribe-close:before {
  content: "\F52F"; }

.mdi-transfer:before {
  content: "\F530"; }

.mdi-transit-transfer:before {
  content: "\F6AD"; }

.mdi-transition:before {
  content: "\F914"; }

.mdi-transition-masked:before {
  content: "\F915"; }

.mdi-translate:before {
  content: "\F5CA"; }

.mdi-treasure-chest:before {
  content: "\F725"; }

.mdi-tree:before {
  content: "\F531"; }

.mdi-trello:before {
  content: "\F532"; }

.mdi-trending-down:before {
  content: "\F533"; }

.mdi-trending-neutral:before {
  content: "\F534"; }

.mdi-trending-up:before {
  content: "\F535"; }

.mdi-triangle:before {
  content: "\F536"; }

.mdi-triangle-outline:before {
  content: "\F537"; }

.mdi-trophy:before {
  content: "\F538"; }

.mdi-trophy-award:before {
  content: "\F539"; }

.mdi-trophy-outline:before {
  content: "\F53A"; }

.mdi-trophy-variant:before {
  content: "\F53B"; }

.mdi-trophy-variant-outline:before {
  content: "\F53C"; }

.mdi-truck:before {
  content: "\F53D"; }

.mdi-truck-delivery:before {
  content: "\F53E"; }

.mdi-truck-fast:before {
  content: "\F787"; }

.mdi-truck-trailer:before {
  content: "\F726"; }

.mdi-tshirt-crew:before {
  content: "\F53F"; }

.mdi-tshirt-v:before {
  content: "\F540"; }

.mdi-tumble-dryer:before {
  content: "\F916"; }

.mdi-tumblr:before {
  content: "\F541"; }

.mdi-tumblr-box:before {
  content: "\F917"; }

.mdi-tumblr-reblog:before {
  content: "\F542"; }

.mdi-tune:before {
  content: "\F62E"; }

.mdi-tune-vertical:before {
  content: "\F66A"; }

.mdi-twitch:before {
  content: "\F543"; }

.mdi-twitter:before {
  content: "\F544"; }

.mdi-twitter-box:before {
  content: "\F545"; }

.mdi-twitter-circle:before {
  content: "\F546"; }

.mdi-twitter-retweet:before {
  content: "\F547"; }

.mdi-uber:before {
  content: "\F748"; }

.mdi-ubuntu:before {
  content: "\F548"; }

.mdi-ultra-high-definition:before {
  content: "\F7F8"; }

.mdi-umbraco:before {
  content: "\F549"; }

.mdi-umbrella:before {
  content: "\F54A"; }

.mdi-umbrella-outline:before {
  content: "\F54B"; }

.mdi-undo:before {
  content: "\F54C"; }

.mdi-undo-variant:before {
  content: "\F54D"; }

.mdi-unfold-less-horizontal:before {
  content: "\F54E"; }

.mdi-unfold-less-vertical:before {
  content: "\F75F"; }

.mdi-unfold-more-horizontal:before {
  content: "\F54F"; }

.mdi-unfold-more-vertical:before {
  content: "\F760"; }

.mdi-ungroup:before {
  content: "\F550"; }

.mdi-unity:before {
  content: "\F6AE"; }

.mdi-untappd:before {
  content: "\F551"; }

.mdi-update:before {
  content: "\F6AF"; }

.mdi-upload:before {
  content: "\F552"; }

.mdi-upload-multiple:before {
  content: "\F83C"; }

.mdi-upload-network:before {
  content: "\F6F5"; }

.mdi-usb:before {
  content: "\F553"; }

.mdi-van-passenger:before {
  content: "\F7F9"; }

.mdi-van-utility:before {
  content: "\F7FA"; }

.mdi-vanish:before {
  content: "\F7FB"; }

.mdi-vector-arrange-above:before {
  content: "\F554"; }

.mdi-vector-arrange-below:before {
  content: "\F555"; }

.mdi-vector-circle:before {
  content: "\F556"; }

.mdi-vector-circle-variant:before {
  content: "\F557"; }

.mdi-vector-combine:before {
  content: "\F558"; }

.mdi-vector-curve:before {
  content: "\F559"; }

.mdi-vector-difference:before {
  content: "\F55A"; }

.mdi-vector-difference-ab:before {
  content: "\F55B"; }

.mdi-vector-difference-ba:before {
  content: "\F55C"; }

.mdi-vector-ellipse:before {
  content: "\F892"; }

.mdi-vector-intersection:before {
  content: "\F55D"; }

.mdi-vector-line:before {
  content: "\F55E"; }

.mdi-vector-point:before {
  content: "\F55F"; }

.mdi-vector-polygon:before {
  content: "\F560"; }

.mdi-vector-polyline:before {
  content: "\F561"; }

.mdi-vector-radius:before {
  content: "\F749"; }

.mdi-vector-rectangle:before {
  content: "\F5C6"; }

.mdi-vector-selection:before {
  content: "\F562"; }

.mdi-vector-square:before {
  content: "\F001"; }

.mdi-vector-triangle:before {
  content: "\F563"; }

.mdi-vector-union:before {
  content: "\F564"; }

.mdi-venmo:before {
  content: "\F578"; }

.mdi-verified:before {
  content: "\F565"; }

.mdi-vibrate:before {
  content: "\F566"; }

.mdi-video:before {
  content: "\F567"; }

.mdi-video-3d:before {
  content: "\F7FC"; }

.mdi-video-4k-box:before {
  content: "\F83D"; }

.mdi-video-account:before {
  content: "\F918"; }

.mdi-video-image:before {
  content: "\F919"; }

.mdi-video-input-antenna:before {
  content: "\F83E"; }

.mdi-video-input-component:before {
  content: "\F83F"; }

.mdi-video-input-hdmi:before {
  content: "\F840"; }

.mdi-video-input-svideo:before {
  content: "\F841"; }

.mdi-video-off:before {
  content: "\F568"; }

.mdi-video-stabilization:before {
  content: "\F91A"; }

.mdi-video-switch:before {
  content: "\F569"; }

.mdi-view-agenda:before {
  content: "\F56A"; }

.mdi-view-array:before {
  content: "\F56B"; }

.mdi-view-carousel:before {
  content: "\F56C"; }

.mdi-view-column:before {
  content: "\F56D"; }

.mdi-view-dashboard:before {
  content: "\F56E"; }

.mdi-view-dashboard-variant:before {
  content: "\F842"; }

.mdi-view-day:before {
  content: "\F56F"; }

.mdi-view-grid:before {
  content: "\F570"; }

.mdi-view-headline:before {
  content: "\F571"; }

.mdi-view-list:before {
  content: "\F572"; }

.mdi-view-module:before {
  content: "\F573"; }

.mdi-view-parallel:before {
  content: "\F727"; }

.mdi-view-quilt:before {
  content: "\F574"; }

.mdi-view-sequential:before {
  content: "\F728"; }

.mdi-view-stream:before {
  content: "\F575"; }

.mdi-view-week:before {
  content: "\F576"; }

.mdi-vimeo:before {
  content: "\F577"; }

.mdi-violin:before {
  content: "\F60F"; }

.mdi-virtual-reality:before {
  content: "\F893"; }

.mdi-visualstudio:before {
  content: "\F610"; }

.mdi-vk:before {
  content: "\F579"; }

.mdi-vk-box:before {
  content: "\F57A"; }

.mdi-vk-circle:before {
  content: "\F57B"; }

.mdi-vlc:before {
  content: "\F57C"; }

.mdi-voice:before {
  content: "\F5CB"; }

.mdi-voicemail:before {
  content: "\F57D"; }

.mdi-volume-high:before {
  content: "\F57E"; }

.mdi-volume-low:before {
  content: "\F57F"; }

.mdi-volume-medium:before {
  content: "\F580"; }

.mdi-volume-minus:before {
  content: "\F75D"; }

.mdi-volume-mute:before {
  content: "\F75E"; }

.mdi-volume-off:before {
  content: "\F581"; }

.mdi-volume-plus:before {
  content: "\F75C"; }

.mdi-vpn:before {
  content: "\F582"; }

.mdi-vuejs:before {
  content: "\F843"; }

.mdi-walk:before {
  content: "\F583"; }

.mdi-wall:before {
  content: "\F7FD"; }

.mdi-wall-sconce:before {
  content: "\F91B"; }

.mdi-wall-sconce-flat:before {
  content: "\F91C"; }

.mdi-wall-sconce-variant:before {
  content: "\F91D"; }

.mdi-wallet:before {
  content: "\F584"; }

.mdi-wallet-giftcard:before {
  content: "\F585"; }

.mdi-wallet-membership:before {
  content: "\F586"; }

.mdi-wallet-travel:before {
  content: "\F587"; }

.mdi-wan:before {
  content: "\F588"; }

.mdi-washing-machine:before {
  content: "\F729"; }

.mdi-watch:before {
  content: "\F589"; }

.mdi-watch-export:before {
  content: "\F58A"; }

.mdi-watch-export-variant:before {
  content: "\F894"; }

.mdi-watch-import:before {
  content: "\F58B"; }

.mdi-watch-import-variant:before {
  content: "\F895"; }

.mdi-watch-variant:before {
  content: "\F896"; }

.mdi-watch-vibrate:before {
  content: "\F6B0"; }

.mdi-water:before {
  content: "\F58C"; }

.mdi-water-off:before {
  content: "\F58D"; }

.mdi-water-percent:before {
  content: "\F58E"; }

.mdi-water-pump:before {
  content: "\F58F"; }

.mdi-watermark:before {
  content: "\F612"; }

.mdi-waves:before {
  content: "\F78C"; }

.mdi-weather-cloudy:before {
  content: "\F590"; }

.mdi-weather-fog:before {
  content: "\F591"; }

.mdi-weather-hail:before {
  content: "\F592"; }

.mdi-weather-hurricane:before {
  content: "\F897"; }

.mdi-weather-lightning:before {
  content: "\F593"; }

.mdi-weather-lightning-rainy:before {
  content: "\F67D"; }

.mdi-weather-night:before {
  content: "\F594"; }

.mdi-weather-partlycloudy:before {
  content: "\F595"; }

.mdi-weather-pouring:before {
  content: "\F596"; }

.mdi-weather-rainy:before {
  content: "\F597"; }

.mdi-weather-snowy:before {
  content: "\F598"; }

.mdi-weather-snowy-rainy:before {
  content: "\F67E"; }

.mdi-weather-sunny:before {
  content: "\F599"; }

.mdi-weather-sunset:before {
  content: "\F59A"; }

.mdi-weather-sunset-down:before {
  content: "\F59B"; }

.mdi-weather-sunset-up:before {
  content: "\F59C"; }

.mdi-weather-windy:before {
  content: "\F59D"; }

.mdi-weather-windy-variant:before {
  content: "\F59E"; }

.mdi-web:before {
  content: "\F59F"; }

.mdi-webcam:before {
  content: "\F5A0"; }

.mdi-webhook:before {
  content: "\F62F"; }

.mdi-webpack:before {
  content: "\F72A"; }

.mdi-wechat:before {
  content: "\F611"; }

.mdi-weight:before {
  content: "\F5A1"; }

.mdi-weight-kilogram:before {
  content: "\F5A2"; }

.mdi-whatsapp:before {
  content: "\F5A3"; }

.mdi-wheelchair-accessibility:before {
  content: "\F5A4"; }

.mdi-white-balance-auto:before {
  content: "\F5A5"; }

.mdi-white-balance-incandescent:before {
  content: "\F5A6"; }

.mdi-white-balance-iridescent:before {
  content: "\F5A7"; }

.mdi-white-balance-sunny:before {
  content: "\F5A8"; }

.mdi-widgets:before {
  content: "\F72B"; }

.mdi-wifi:before {
  content: "\F5A9"; }

.mdi-wifi-off:before {
  content: "\F5AA"; }

.mdi-wifi-strength-1:before {
  content: "\F91E"; }

.mdi-wifi-strength-1-alert:before {
  content: "\F91F"; }

.mdi-wifi-strength-1-lock:before {
  content: "\F920"; }

.mdi-wifi-strength-2:before {
  content: "\F921"; }

.mdi-wifi-strength-2-alert:before {
  content: "\F922"; }

.mdi-wifi-strength-2-lock:before {
  content: "\F923"; }

.mdi-wifi-strength-3:before {
  content: "\F924"; }

.mdi-wifi-strength-3-alert:before {
  content: "\F925"; }

.mdi-wifi-strength-3-lock:before {
  content: "\F926"; }

.mdi-wifi-strength-4:before {
  content: "\F927"; }

.mdi-wifi-strength-4-alert:before {
  content: "\F928"; }

.mdi-wifi-strength-4-lock:before {
  content: "\F929"; }

.mdi-wifi-strength-alert-outline:before {
  content: "\F92A"; }

.mdi-wifi-strength-lock-outline:before {
  content: "\F92B"; }

.mdi-wifi-strength-off:before {
  content: "\F92C"; }

.mdi-wifi-strength-off-outline:before {
  content: "\F92D"; }

.mdi-wifi-strength-outline:before {
  content: "\F92E"; }

.mdi-wii:before {
  content: "\F5AB"; }

.mdi-wiiu:before {
  content: "\F72C"; }

.mdi-wikipedia:before {
  content: "\F5AC"; }

.mdi-window-close:before {
  content: "\F5AD"; }

.mdi-window-closed:before {
  content: "\F5AE"; }

.mdi-window-maximize:before {
  content: "\F5AF"; }

.mdi-window-minimize:before {
  content: "\F5B0"; }

.mdi-window-open:before {
  content: "\F5B1"; }

.mdi-window-restore:before {
  content: "\F5B2"; }

.mdi-windows:before {
  content: "\F5B3"; }

.mdi-wordpress:before {
  content: "\F5B4"; }

.mdi-worker:before {
  content: "\F5B5"; }

.mdi-wrap:before {
  content: "\F5B6"; }

.mdi-wrench:before {
  content: "\F5B7"; }

.mdi-wunderlist:before {
  content: "\F5B8"; }

.mdi-xamarin:before {
  content: "\F844"; }

.mdi-xamarin-outline:before {
  content: "\F845"; }

.mdi-xaml:before {
  content: "\F673"; }

.mdi-xbox:before {
  content: "\F5B9"; }

.mdi-xbox-controller:before {
  content: "\F5BA"; }

.mdi-xbox-controller-battery-alert:before {
  content: "\F74A"; }

.mdi-xbox-controller-battery-empty:before {
  content: "\F74B"; }

.mdi-xbox-controller-battery-full:before {
  content: "\F74C"; }

.mdi-xbox-controller-battery-low:before {
  content: "\F74D"; }

.mdi-xbox-controller-battery-medium:before {
  content: "\F74E"; }

.mdi-xbox-controller-battery-unknown:before {
  content: "\F74F"; }

.mdi-xbox-controller-off:before {
  content: "\F5BB"; }

.mdi-xda:before {
  content: "\F5BC"; }

.mdi-xing:before {
  content: "\F5BD"; }

.mdi-xing-box:before {
  content: "\F5BE"; }

.mdi-xing-circle:before {
  content: "\F5BF"; }

.mdi-xml:before {
  content: "\F5C0"; }

.mdi-xmpp:before {
  content: "\F7FE"; }

.mdi-yammer:before {
  content: "\F788"; }

.mdi-yeast:before {
  content: "\F5C1"; }

.mdi-yelp:before {
  content: "\F5C2"; }

.mdi-yin-yang:before {
  content: "\F67F"; }

.mdi-youtube:before {
  content: "\F5C3"; }

.mdi-youtube-creator-studio:before {
  content: "\F846"; }

.mdi-youtube-gaming:before {
  content: "\F847"; }

.mdi-youtube-tv:before {
  content: "\F448"; }

.mdi-zip-box:before {
  content: "\F5C4"; }

.mdi-blank:before {
  content: "\F68C";
  visibility: hidden; }

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px; }

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px; }

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px; }

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px; }

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54); }

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26); }

.mdi-light:before {
  color: white; }

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3); }

.mdi-rotate-45 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(45deg);
            transform: scaleX(-1) rotate(45deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: scaleY(-1) rotate(45deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-45:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.mdi-rotate-90 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(90deg);
            transform: scaleX(-1) rotate(90deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: scaleY(-1) rotate(90deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-90:before {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }

.mdi-rotate-135 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(135deg);
            transform: scaleX(-1) rotate(135deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: scaleY(-1) rotate(135deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-135:before {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg); }

.mdi-rotate-180 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(180deg);
            transform: scaleX(-1) rotate(180deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: scaleY(-1) rotate(180deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-180:before {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }

.mdi-rotate-225 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(225deg);
            transform: scaleX(-1) rotate(225deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: scaleY(-1) rotate(225deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-225:before {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg); }

.mdi-rotate-270 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(270deg);
            transform: scaleX(-1) rotate(270deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: scaleY(-1) rotate(270deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-270:before {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg); }

.mdi-rotate-315 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(315deg);
            transform: scaleX(-1) rotate(315deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(315deg);
            -ms-transform: rotate(315deg);
            transform: scaleY(-1) rotate(315deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-315:before {
    -webkit-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    transform: rotate(315deg); }

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH"; }

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV"; }

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear; }

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* ------------------------------------------------------------ *        Variables
\* ------------------------------------------------------------ */
/*
 *  ----------------- PLUGINS -----------------
 */
/*
 *  ----------------- UTILS -----------------
 */
/* ------------------------------------------------------------ *	Mixins
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *	Helpers
\* ------------------------------------------------------------ */
/*  Clear  */
.clear:after {
  content: '';
  line-height: 0;
  display: table;
  clear: both; }

/*  Notext  */
.notext {
  white-space: nowrap;
  text-indent: 100%;
  text-shadow: none;
  overflow: hidden; }

.notext-old {
  font-size: 0;
  line-height: 0;
  text-indent: -4000px; }

/*  Hidden  */
[hidden],
.hidden {
  display: none; }

/*  Alignleft  */
.alignleft {
  float: left; }

/*  Alignright  */
.alignright {
  float: right; }

/*  Disabled  */
[disabled],
.disabled {
  cursor: default; }

/*  Background  */
.container-fullsize {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

/*  Animations  */
.animations {
  opacity: 0; }

.animations.animated {
  opacity: 1; }

/*  Grid  */
.cols {
  margin: 0 -15px; }

.cols-flex {
  display: flex; }

.cols-flex .col {
  width: auto; }

.row:after,
.cols:after {
  content: '';
  line-height: 0;
  display: table;
  clear: both; }

.col {
  float: left;
  width: 100%;
  padding: 0 15px; }

.col-1of2 {
  width: 50%; }

/*  Mobile only  */
.mobile-only {
  display: none; }

@media (max-width: 360) {
  .desktop-only {
    display: none; }
  .mobile-only {
    display: block; }
  .animations {
    opacity: 1; } }

/*
 *  ----------------- LAYOUT -----------------
 */
/* ------------------------------------------------------------ *        Base
\* ------------------------------------------------------------ */
html {
  font-size: 62.5%; }
  @media only screen and (min-width: 1100px) and (max-width: 1360px) {
    html {
      font-size: 50%;
      /*        zoom:0.8;*/
      /*        -moz-transform: scale(0.8);
        -moz-transform-origin: 0 0;*/ } }

body {
  min-width: 320px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
  font-weight: 400;
  overflow-x: hidden;
  word-wrap: break-word; }

a {
  color: inherit; }

a:focus,
a:active,
a:hover {
  text-decoration: none;
  color: inherit; }

a[href^="tel"] {
  text-decoration: none; }

img {
  width: auto;
  max-width: 100%;
  height: auto; }

ul,
ol {
  padding: 0;
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0; }

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
  margin-bottom: 0; }

.text-underline a {
  text-decoration: underline !important; }

/* Dispositivos Pequeños y Tablets vertical , Celulares horizontal min-width:576px  */
/* Dispositivos Medianos , Tablets horizontal min-width:768px  */
/* Dispositivos grandes , min-width:992px */
/* Escritorio , min-width:1200 px */
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 18-may-2018, 11:08:38
    Author     : PC03-MAXI
*/
/* ------------------------------------------------------------ *        Header
\* ------------------------------------------------------------ */
body > header {
  box-shadow: 0 1px 10px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }
  body > header .top-bar {
    transition: all 0.5s ease;
    position: relative;
    display: flex;
    background: #003F44;
    color: white;
    height: 5rem;
    visibility: visible;
    opacity: 1; }
    body > header .top-bar .rate-container {
      display: flex;
      align-items: center; }
      body > header .top-bar .rate-container .rate-points {
        color: #EBAB21;
        font-size: 2.1rem;
        font-weight: 700;
        margin-right: 1.5rem; }
      body > header .top-bar .rate-container p {
        font-size: 1.6rem;
        font-weight: 500; }
      body > header .top-bar .rate-container .rate-stars {
        background: #048086;
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 3.5rem;
        padding: 0rem 1.5rem; }
        body > header .top-bar .rate-container .rate-stars:after {
          content: "";
          position: absolute;
          width: 0;
          right: -3rem;
          top: 0;
          height: 0;
          border-top: 3rem solid #048086;
          border-right: 3rem solid transparent; }
        body > header .top-bar .rate-container .rate-stars:before {
          content: "";
          position: absolute;
          right: -3rem;
          bottom: 0;
          width: 0;
          height: 0;
          border-bottom: 3rem solid #048086;
          border-right: 3rem solid transparent; }
    body > header .top-bar .right-content {
      display: flex;
      margin-left: auto;
      margin-right: 5rem; }
    body > header .top-bar .social-net, body > header .top-bar .lang-selector, body > header .top-bar .font-size-selector {
      display: flex;
      list-style: none;
      align-items: center;
      font-size: 1.4rem;
      color: white;
      font-weight: 500; }
      body > header .top-bar .social-net li, body > header .top-bar .lang-selector li, body > header .top-bar .font-size-selector li {
        height: 100%;
        display: flex;
        align-items: center;
        overflow: hidden; }
        body > header .top-bar .social-net li a, body > header .top-bar .lang-selector li a, body > header .top-bar .font-size-selector li a {
          padding: 2rem 1.5rem; }
          body > header .top-bar .social-net li a.a-small, body > header .top-bar .lang-selector li a.a-small, body > header .top-bar .font-size-selector li a.a-small {
            padding-top: 2.8rem; }
          body > header .top-bar .social-net li a.active, body > header .top-bar .lang-selector li a.active, body > header .top-bar .font-size-selector li a.active {
            background: #048086; }
          body > header .top-bar .social-net li a:hover, body > header .top-bar .social-net li a:active, body > header .top-bar .social-net li a:focus, body > header .top-bar .lang-selector li a:hover, body > header .top-bar .lang-selector li a:active, body > header .top-bar .lang-selector li a:focus, body > header .top-bar .font-size-selector li a:hover, body > header .top-bar .font-size-selector li a:active, body > header .top-bar .font-size-selector li a:focus {
            background: #048086; }
    body > header .top-bar .lang-selector li, body > header .top-bar .font-size-selector li {
      border-right: 1px solid #819CA9; }
    body > header .top-bar .font-size-selector {
      font-size: 2.5rem; }
    body > header .top-bar .social-net {
      font-size: 2rem; }
      body > header .top-bar .social-net li a {
        padding: 2rem 0.5rem; }
  body > header nav.navbar {
    transition: all 0.5s ease;
    background: rgba(255, 255, 255, 0.95);
    padding: 0 3.2rem;
    height: 11.6rem; }
    body > header nav.navbar .navbar-brand {
      /*            margin-right: 3rem;
                        margin-left: 3rem;*/
      margin-top: 1rem;
      margin-bottom: 1rem; }
    body > header nav.navbar .btn-call, body > header nav.navbar .btn-search, body > header nav.navbar .btn-share, body > header nav.navbar .btn-lang-m {
      padding: 1.5rem 1rem; }
      body > header nav.navbar .btn-call i, body > header nav.navbar .btn-search i, body > header nav.navbar .btn-share i, body > header nav.navbar .btn-lang-m i {
        margin-left: 0;
        font-size: 2.8rem; }
    body > header nav.navbar .navbar-toggler {
      border: none;
      font-size: 2rem; }
    body > header nav.navbar .btn-search {
      margin-left: auto; }
    body > header nav.navbar .btn-call i, body > header nav.navbar .btn-search i, body > header nav.navbar .btn-lang-m i {
      margin-left: 0;
      font-size: 2.8rem; }
    body > header nav.navbar .navbar-toggler {
      border: none;
      font-size: 2rem; }
    body > header nav.navbar .btn-search {
      margin-left: auto; }
    body > header nav.navbar .navbar-nav {
      padding: 0 2rem;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      body > header nav.navbar .navbar-nav .nav-item .nav-link {
        transition: all .2s ease-out;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 4.2rem;
        padding: 0 1.5rem;
        color: rgba(0, 77, 96, 0.94); }
        body > header nav.navbar .navbar-nav .nav-item .nav-link svg {
          width: 18px;
          height: 18px;
          vertical-align: middle;
          fill: rgba(0, 77, 96, 0.94); }
        body > header nav.navbar .navbar-nav .nav-item .nav-link.active {
          background: #03576F;
          color: rgba(255, 255, 255, 0.94); }
          body > header nav.navbar .navbar-nav .nav-item .nav-link.active svg {
            fill: rgba(255, 255, 255, 0.94); }
      body > header nav.navbar .navbar-nav .nav-item ul {
        display: none;
        margin: 0;
        padding: 1.5rem 0;
        list-style: none;
        position: absolute;
        background: #03576F;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 5px; }
        body > header nav.navbar .navbar-nav .nav-item ul li {
          position: relative; }
          body > header nav.navbar .navbar-nav .nav-item ul li > ul {
            padding: 0; }
          body > header nav.navbar .navbar-nav .nav-item ul li:hover ul {
            display: block; }
          body > header nav.navbar .navbar-nav .nav-item ul li:hover > a {
            background: #DDDDDD;
            color: #02576E; }
            body > header nav.navbar .navbar-nav .nav-item ul li:hover > a svg {
              fill: #02576E; }
          body > header nav.navbar .navbar-nav .nav-item ul li a {
            transition: all .2s ease-out;
            display: block;
            line-height: 3.6rem;
            padding: 1rem 3.5rem .6rem;
            color: #DDDDDD;
            font-size: 1.6rem;
            text-transform: none; }
            body > header nav.navbar .navbar-nav .nav-item ul li a svg {
              fill: #DDDDDD;
              width: 1.5rem;
              height: 1.5rem;
              vertical-align: middle;
              position: relative;
              top: -1px; }
          body > header nav.navbar .navbar-nav .nav-item ul li ul {
            position: absolute;
            background: #DDDDDD;
            left: 100%;
            top: 0;
            width: max-content; }
            @media (max-width: 767.98px) {
              body > header nav.navbar .navbar-nav .nav-item ul li ul {
                width: 100% !important; } }
            body > header nav.navbar .navbar-nav .nav-item ul li ul li a {
              color: #03576F; }
              body > header nav.navbar .navbar-nav .nav-item ul li ul li a svg {
                fill: #03576F; }
              body > header nav.navbar .navbar-nav .nav-item ul li ul li a:hover {
                background: #03576F;
                color: #DDDDDD; }
                body > header nav.navbar .navbar-nav .nav-item ul li ul li a:hover svg {
                  fill: #DDDDDD; }
      body > header nav.navbar .navbar-nav .nav-item:hover .nav-link {
        background: #03576F;
        color: rgba(255, 255, 255, 0.94); }
        body > header nav.navbar .navbar-nav .nav-item:hover .nav-link svg {
          fill: rgba(255, 255, 255, 0.94); }
      body > header nav.navbar .navbar-nav .nav-item:hover > ul {
        display: block; }
      @media (max-width: 767.98px) {
        body > header nav.navbar .navbar-nav {
          padding: 0 !important; }
          body > header nav.navbar .navbar-nav .nav-item {
            display: block;
            width: 100%; }
            body > header nav.navbar .navbar-nav .nav-item .nav-link {
              position: relative;
              background: rgba(129, 156, 169, 0.1);
              display: block;
              width: 100%;
              padding: 0.6rem 1.5rem;
              border-bottom: 1px solid rgba(0, 0, 0, 0.12);
              display: flex;
              align-items: center;
              /*                        &:hover{
                                                    background: rgba(#819CA9,0.1);
                                                }*/ }
              body > header nav.navbar .navbar-nav .nav-item .nav-link svg {
                position: absolute;
                right: 2rem; }
            body > header nav.navbar .navbar-nav .nav-item:last-child .nav-link {
              border-bottom: none; }
            body > header nav.navbar .navbar-nav .nav-item ul {
              display: none !important;
              left: 0 !important;
              position: relative !important;
              padding: 0.5rem 0; }
              body > header nav.navbar .navbar-nav .nav-item ul.active {
                display: block !important; }
              body > header nav.navbar .navbar-nav .nav-item ul li a {
                padding: 0 1.5rem; } }
    body > header nav.navbar #searchCollapse {
      background: #03576F;
      padding: 1.5rem; }
    body > header nav.navbar #langCollapse {
      padding: 2rem 1rem;
      background: #036a6f; }
      body > header nav.navbar #langCollapse .lang-selector {
        list-style: none;
        display: flex;
        justify-content: flex-end; }
        body > header nav.navbar #langCollapse .lang-selector li {
          padding: 0.5rem 3rem;
          /*border: 1px solid $color2;*/
          background: #FFF;
          color: #003F44;
          margin: 0rem .5rem; }
    body > header nav.navbar #sidebarCall {
      min-width: 100vw;
      max-width: 100vw;
      position: fixed;
      top: 0;
      left: -100vw;
      background: white;
      /* margin-left: -33vw; */
      height: 100vh;
      transition: all ease 0.3s;
      z-index: 99; }
      body > header nav.navbar #sidebarCall .call-head {
        background: #048086;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        color: white; }
        body > header nav.navbar #sidebarCall .call-head h4 {
          margin-right: 8rem;
          font-size: 2rem; }
        body > header nav.navbar #sidebarCall .call-head button {
          color: white;
          border: none; }
      body > header nav.navbar #sidebarCall.open {
        left: 0; }
      body > header nav.navbar #sidebarCall .btn.btn-link.close {
        display: none; }
    body > header nav.navbar #shareCollapse .top-bar {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      background: #003F44;
      color: white;
      height: auto; }
      body > header nav.navbar #shareCollapse .top-bar .rate-container {
        width: 100%;
        padding: 0rem;
        padding-right: 1rem; }
        body > header nav.navbar #shareCollapse .top-bar .rate-container .rate-stars {
          height: 5rem;
          background: none;
          margin-right: 0rem; }
          body > header nav.navbar #shareCollapse .top-bar .rate-container .rate-stars:after, body > header nav.navbar #shareCollapse .top-bar .rate-container .rate-stars:before {
            display: none; }
        body > header nav.navbar #shareCollapse .top-bar .rate-container .rate-points {
          display: none; }
        body > header nav.navbar #shareCollapse .top-bar .rate-container p {
          font-size: 1.1rem;
          text-align: center; }
      body > header nav.navbar #shareCollapse .top-bar .share-content {
        width: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.12); }
        body > header nav.navbar #shareCollapse .top-bar .share-content .social-net {
          justify-content: center;
          text-align: center;
          flex-direction: initial; }
          body > header nav.navbar #shareCollapse .top-bar .share-content .social-net li a {
            font-size: 2.5rem;
            color: rgba(255, 255, 255, 0.54);
            padding: 1rem 0.5rem; }
    body > header nav.navbar .search-content {
      margin-right: 1.7rem; }
      body > header nav.navbar .search-content span {
        color: white;
        display: flex;
        align-items: center;
        font-size: 2.5rem;
        z-index: 2;
        background: #03576F;
        padding-right: 1.8rem; }
      body > header nav.navbar .search-content input.form-control {
        border-radius: 0;
        border: none;
        height: 4.2rem;
        padding: 0 1.5rem;
        font-size: 1.6rem;
        font-weight: 500;
        color: white;
        background: #03576F; }
        body > header nav.navbar .search-content input.form-control::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: white;
          text-transform: uppercase; }
        body > header nav.navbar .search-content input.form-control::-moz-placeholder {
          /* Firefox 19+ */
          color: white;
          text-transform: uppercase; }
        body > header nav.navbar .search-content input.form-control:-ms-input-placeholder {
          /* IE 10+ */
          color: white;
          text-transform: uppercase; }
        body > header nav.navbar .search-content input.form-control:-moz-placeholder {
          /* Firefox 18- */
          color: white;
          text-transform: uppercase; }
        body > header nav.navbar .search-content input.form-control:active, body > header nav.navbar .search-content input.form-control:focus {
          outline: none; }
      body > header nav.navbar .search-content.search-content-mobile {
        margin-right: 0; }
        body > header nav.navbar .search-content.search-content-mobile input.form-control {
          background: white;
          color: #03576F; }
          body > header nav.navbar .search-content.search-content-mobile input.form-control::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #03576F;
            text-transform: uppercase; }
          body > header nav.navbar .search-content.search-content-mobile input.form-control::-moz-placeholder {
            /* Firefox 19+ */
            color: #03576F;
            text-transform: uppercase; }
          body > header nav.navbar .search-content.search-content-mobile input.form-control:-ms-input-placeholder {
            /* IE 10+ */
            color: #03576F;
            text-transform: uppercase; }
          body > header nav.navbar .search-content.search-content-mobile input.form-control:-moz-placeholder {
            /* Firefox 18- */
            color: #03576F;
            text-transform: uppercase; }
        body > header nav.navbar .search-content.search-content-mobile span {
          background: white; }
          body > header nav.navbar .search-content.search-content-mobile span i {
            color: #03576F; }
  body > header.sticky .top-bar {
    position: absolute;
    visibility: hidden;
    opacity: 0; }
  body > header.sticky nav.navbar {
    background: white;
    height: 9rem; }
  @media (max-width: 1199.98px) {
    body > header {
      position: relative; }
      body > header nav.navbar {
        padding: 0 !important;
        height: auto;
        justify-content: flex-start; }
        body > header nav.navbar button, body > header nav.navbar a.btn.btn-link {
          border: 1px solid #003F44;
          width: 4rem;
          height: 4rem; }
          body > header nav.navbar button:last-child, body > header nav.navbar a.btn.btn-link:last-child {
            margin-right: 0.5rem; }
          body > header nav.navbar button:hover, body > header nav.navbar a.btn.btn-link:hover {
            border: 1px solid #003F44; }
        body > header nav.navbar .navbar-toggler {
          margin-left: 0.5rem;
          margin-right: 0.5rem; }
        body > header nav.navbar .navbar-brand {
          margin-right: 2rem; }
          body > header nav.navbar .navbar-brand img {
            max-width: 20vw; }
        body > header nav.navbar .navbar-nav {
          padding-top: 3rem;
          padding-bottom: 2rem;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around; } }
  body > header.sticky .top-bar {
    position: absolute;
    visibility: hidden;
    opacity: 0; }
  body > header.sticky nav.navbar {
    height: 9rem; }
  @media (max-width: 1199.98px) {
    body > header {
      position: relative; }
      body > header nav.navbar {
        padding: 0 !important;
        height: auto;
        justify-content: flex-start; }
        body > header nav.navbar .navbar-brand {
          margin-right: 2rem; }
        body > header nav.navbar .navbar-nav {
          padding-top: 3rem;
          padding-bottom: 2rem;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around; } }

header .lang-selector {
  font-size: 1.4rem; }

header .locale-switcher {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(0, 77, 96, 0.94); }
  header .locale-switcher li:first-child {
    margin: 0 !important; }
  header .locale-switcher li a {
    padding: 8px;
    background: rgba(0, 77, 96, 0.94);
    color: #ffffff; }

/* Dispositivos Pequeños y Tablets vertical , Celulares horizontal min-width:576px  */
@media (max-width: 767.98px) {
  body > header nav.navbar {
    padding: 0 1.5rem; }
    body > header nav.navbar .btn-search, body > header nav.navbar .btn-call, body > header nav.navbar .btn-share, body > header nav.navbar .btn-lang-m {
      padding: 0; }
    body > header nav.navbar .btn-call, body > header nav.navbar .btn-share, body > header nav.navbar .btn-lang-m {
      margin-left: 1rem; }
    body > header nav.navbar .btn-lang-m {
      margin-right: 1.5rem; } }

/* Dispositivos Medianos , Tablets horizontal min-width:768px  */
/* Dispositivos grandes , min-width:992px */
/* Escritorio , min-width:1200 px */
@media only screen and (min-width: 990px) and (max-width: 1575px) {
  body header nav.navbar .navbar-nav .nav-item .nav-link {
    height: 4.2rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-size: 1.2rem; }
  body header nav.navbar form.form-inline {
    flex: 0.2; }
    body header nav.navbar form.form-inline .search-content {
      flex: 0.2; }
      body header nav.navbar form.form-inline .search-content input.form-control {
        font-size: 1.2rem; }
  body header nav.navbar button.call {
    font-size: 1.2rem; }
  body header .top-bar .rate-container p {
    font-size: 1.4rem; } }

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  body > header nav.navbar {
    padding: 0 1.5rem; }
    body > header nav.navbar .btn-search, body > header nav.navbar .btn-call, body > header nav.navbar .btn-share {
      padding: 0; }
    body > header nav.navbar .btn-call, body > header nav.navbar .btn-share {
      margin-left: 1rem; }
    body > header nav.navbar .btn-share {
      margin-right: 1.5rem; } }

/* ------------------------------------------------------------ *        NAV BAR
\* ------------------------------------------------------------ */
/* Dispositivos Pequeños y Tablets vertical , Celulares horizontal min-width:576px  */
/* Dispositivos Medianos , Tablets horizontal min-width:768px  */
/* Dispositivos grandes , min-width:992px */
/* Escritorio , min-width:1200 px */
/* ------------------------------------------------------------ *        Footer
\* ------------------------------------------------------------ */
footer {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: #003F44;
  color: rgba(255, 255, 255, 0.54);
  font-size: 1.3rem;
  font-weight: 500; }
  footer .top-row {
    padding-bottom: 3rem; }
    footer .top-row .border-left {
      border-left: 1px dotted rgba(255, 255, 255, 0.42) !important;
      padding-left: 6rem; }
    footer .top-row .brand-cont {
      display: flex; }
      footer .top-row .brand-cont .brand {
        flex: 0 0 25%;
        max-width: 25%; }
      footer .top-row .brand-cont p {
        flex: 0 0 64%;
        max-width: 64%; }
    footer .top-row p {
      color: white; }
    footer .top-row .nav-footer {
      list-style: none;
      list-style: none;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      footer .top-row .nav-footer li {
        padding: 1rem 0.8rem; }
        footer .top-row .nav-footer li a {
          color: white; }
  footer .mid-row {
    border-bottom: 1px dotted rgba(255, 255, 255, 0.42);
    border-top: 1px dotted rgba(255, 255, 255, 0.42);
    padding-bottom: 3rem;
    padding-top: 3rem; }
    footer .mid-row p {
      font-size: 1.3rem; }
    footer .mid-row .extra-links {
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      footer .mid-row .extra-links li {
        margin-top: .8rem;
        margin-bottom: .8rem; }
        footer .mid-row .extra-links li:after {
          content: "|"; }
        footer .mid-row .extra-links li a:hover, footer .mid-row .extra-links li a:focus, footer .mid-row .extra-links li a:active {
          color: white; }
        footer .mid-row .extra-links li:last-child:after {
          content: ""; }
      footer .mid-row .extra-links a {
        padding-left: 0.5rem;
        padding-right: 0.5rem; }
    footer .mid-row .social-cont {
      margin-left: auto; }
      footer .mid-row .social-cont p {
        margin-bottom: 0; }
  footer .bot-row {
    padding-top: 3rem; }
    footer .bot-row p {
      font-size: 1.3rem; }
      footer .bot-row p a {
        color: rgba(255, 255, 255, 0.54); }
  @media (max-width: 1199.98px) {
    footer .top-row {
      padding-bottom: 0; }
      footer .top-row .border-left {
        padding-left: 1.5rem;
        padding-top: 1.25rem;
        padding-bottom: 0; }
      footer .top-row .brand-cont {
        display: block;
        border-bottom: 1px dotted rgba(255, 255, 255, 0.42);
        padding-bottom: 1.5rem; }
        footer .top-row .brand-cont .brand {
          margin-bottom: 1.5rem;
          display: block; }
        footer .top-row .brand-cont p {
          max-width: 100%; }
    footer .mid-row {
      border-bottom: 1px dotted rgba(255, 255, 255, 0.42);
      border-top: none; }
      footer .mid-row .social-cont {
        margin-left: 1.5rem; }
        footer .mid-row .social-cont p {
          font-size: 1.2rem;
          margin-bottom: 1.5rem; }
        footer .mid-row .social-cont .social-net > li > a {
          margin-left: 3rem;
          font-size: 2.5rem; } }
  @media (max-width: 767.98px) {
    footer .top-row .border-left {
      border-left: 0 !important; }
    footer .social-cont {
      display: flex;
      align-items: center; }
      footer .social-cont p {
        margin-bottom: 0 !important; }
    footer .extra-links {
      margin-bottom: 3rem; }
      footer .extra-links li:after {
        display: none; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 21-may-2018, 14:42:35
    Author     : PC03-MAXI
*/
.mdi.mdi-youtube {
  position: relative;
  top: 0.2rem; }

/*@import 'layout/icomoon';*/
/* ------------------------------------------------------------ *        Iconmd
\* ------------------------------------------------------------ */
/*podes definir los tamaños standars*/
.material-icons.md-18 {
  font-size: 18px; }

.material-icons.md-24 {
  font-size: 24px; }

.material-icons.md-36 {
  font-size: 36px; }

.material-icons.md-48 {
  font-size: 48px; }

.material-icons.md-64 {
  font-size: 64px; }

/*definimos colorete*/
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54); }

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26); }

.material-icons.md-light {
  color: white; }

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3); }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 26-sep-2018, 13:31:31
    Author     : Rodri
*/
.ie11 {
  /* HEADERS */
  /* HOME */
  /* CARDS */
  /* MENU */
  /* MEJORAMIENTO DE CALIDAD */
  /* PROVEEDORES */
  /* UNETE */ }
  .ie11 .section-banner .container {
    display: none; }
  .ie11 section.home-main .content {
    position: absolute !important;
    bottom: 0 !important; }
  .ie11 .card-title,
  .ie11 .card-subtitle,
  .ie11 .afiliados-pago-primas .card-white p {
    max-width: 100%; }
  .ie11 .navbar-nav li ul li ul {
    width: 40rem; }
  .ie11 section.programa-mejoramiento-calidad-objetivos .qip .box-qip::after,
  .ie11 section.programa-mejoramiento-calidad-objetivos .qip .box-qip::before {
    top: 0; }
  .ie11 .section-proveedores ul.download a,
  .ie11 .section-proveedores ul.download.open li {
    display: flex;
    margin-bottom: 0.5rem;
    align-items: flex-start; }
    .ie11 .section-proveedores ul.download a span,
    .ie11 .section-proveedores ul.download.open li span {
      flex: 1; }
  .ie11 .section-proveedores ul.download.open li img {
    margin-right: 0.5rem; }
  .ie11 .section-requisitos .requisitos-detail-row .requisitos-list {
    display: block; }

/*
 *  ----------------- COMPONENTES -----------------
 */
/* ------------------------------------------------------------ *        Btn
\* ------------------------------------------------------------ */
.btn {
  line-height: 4.2rem;
  border-radius: 0;
  padding: 0 1.5rem;
  height: 4.2rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  /* &:hover,&:focus,&:active{
         background:$pri!important;

     }*/ }
  .btn.btn-icon-left svg {
    display: inline-block;
    vertical-align: middle;
    width: 2.4rem;
    height: 2.4rem;
    fill: #FFF;
    margin-right: 0.5rem;
    margin-bottom: 0.4rem; }
  .btn.btn-icon {
    display: flex;
    align-items: center;
    padding: 0 1.5rem; }
    .btn.btn-icon i {
      font-size: 2.5rem;
      margin-left: 1.5rem; }
  .btn.green {
    color: #003F44 !important; }
    .btn.green i {
      margin-right: 0.5rem; }
  .btn.white-text {
    color: #fff; }
  .btn.btn-lg {
    line-height: 5.2rem;
    height: 5.2rem; }

.btn-outline-primary {
  border-color: #003F44;
  color: #003F44; }
  .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
    background: #003F44 !important;
    color: white !important;
    border-color: #003F44; }
    .btn-outline-primary:hover.btn-icon-left svg, .btn-outline-primary:focus.btn-icon-left svg, .btn-outline-primary:active.btn-icon-left svg {
      fill: white !important; }
  .btn-outline-primary.btn-icon-left svg {
    fill: #003F44; }

.btn-outline-secondary {
  border-color: #048086;
  color: #048086; }
  .btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active {
    background: #048086 !important;
    color: white !important;
    border-color: #048086; }
    .btn-outline-secondary:hover.btn-icon-left svg, .btn-outline-secondary:focus.btn-icon-left svg, .btn-outline-secondary:active.btn-icon-left svg {
      fill: white !important; }
  .btn-outline-secondary.btn-icon-left svg {
    fill: #048086; }

.btn-outline-primary-light {
  border-color: #048086;
  color: #048086; }
  .btn-outline-primary-light:hover, .btn-outline-primary-light:focus, .btn-outline-primary-light:active {
    background: #048086 !important;
    color: white !important;
    border-color: #048086; }
    .btn-outline-primary-light:hover.btn-icon-left svg, .btn-outline-primary-light:focus.btn-icon-left svg, .btn-outline-primary-light:active.btn-icon-left svg {
      fill: white !important; }
  .btn-outline-primary-light.btn-icon-left svg {
    fill: #048086; }

.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active {
  background: white !important; }
  .btn-outline-light:hover svg, .btn-outline-light:focus svg, .btn-outline-light:active svg {
    fill: #003F44 !important; }

.btn-primary {
  background: #003F44;
  border: none;
  color: white; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    background: #048086 !important; }

.btn-link {
  color: #003F44; }

.btn-long {
  padding: 1.5rem 5.5rem; }

.btn-link {
  color: #003F44; }

.btn-secondary {
  background: #048086;
  border: none;
  color: white; }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
    background: #003F44 !important; }

.btn-tertiary {
  background: #004D60; }
  .btn-tertiary:hover, .btn-tertiary:focus, .btn-tertiary:active {
    background: #819CA9 !important; }

.btn-white {
  background: white;
  color: #678796; }
  .btn-white:hover, .btn-white:focus, .btn-white:active {
    color: white;
    background: #819CA9 !important; }

.btnLlamanos {
  background-color: #E29B46 !important; }
  .btnLlamanos i {
    margin-left: 15px;
    font-size: 1.3em; }

/* ------------------------------------------------------------ *        Form
\* ------------------------------------------------------------ */
.inline-fields {
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .inline-fields .md-radio {
    margin-right: 15px; }

form {
  color: rgba(255, 255, 255, 0.54);
  font-size: 1.3rem; }
  form label {
    font-size: 1.6rem;
    font-weight: 500;
    color: white; }
  form .no-label {
    margin-top: 2.4rem; }
  form .form-control {
    color: rgba(4, 128, 134, 0.94);
    font-size: 1.3rem;
    border-radius: 0;
    padding: 1.2rem 1.5rem; }
  form .md-checkbox label {
    color: rgba(255, 255, 255, 0.9);
    font-weight: 400; }
  form .warning {
    display: flex;
    flex-direction: row;
    color: rgba(255, 255, 255, 0.9); }
    form .warning i {
      font-size: 2rem; }
    form .warning .text {
      margin-left: 1rem; }
  form button {
    margin-top: 2rem;
    float: right; }
  form select.form-control {
    height: auto !important; }

/* Dispositivos Pequeños y Tablets vertical , Celulares horizontal min-width:576px  */
/* Dispositivos Medianos , Tablets horizontal min-width:768px  */
/* Dispositivos grandes , min-width:992px */
/* Escritorio , min-width:1200 px */
/* ------------------------------------------------------------ *        Pager
\* ------------------------------------------------------------ */
.pagination {
  justify-content: center;
  margin-top: 0rem;
  margin-bottom: 1.5rem !important; }

/* Dispositivos Pequeños y Tablets vertical , Celulares horizontal min-width:576px  */
/* Dispositivos Medianos , Tablets horizontal min-width:768px  */
/* Dispositivos grandes , min-width:992px */
/* Escritorio , min-width:1200 px */
/* ------------------------------------------------------------ *        Slider
\* ------------------------------------------------------------ */
/* Dispositivos Pequeños y Tablets vertical , Celulares horizontal min-width:576px  */
/* Dispositivos Medianos , Tablets horizontal min-width:768px  */
/* Dispositivos grandes , min-width:992px */
/* Escritorio , min-width:1200 px */
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 17-may-2018, 18:21:43
    Author     : codigowww
*/
.tooltip {
  font-size: 1.3rem;
  line-height: 1.6rem; }
  .tooltip .tooltip-inner {
    padding: 1.5rem 1.25rem;
    border-radius: 1rem; }

.popover {
  border: none;
  left: -2rem; }
  .popover .popover-header {
    display: none; }
  .popover .popover-body {
    padding: 2rem;
    background: #EEEEEE;
    padding: 0; }

.callpopover {
  color: #546E7A; }
  .callpopover .close {
    margin-right: 1rem; }
  .callpopover ul {
    list-style: none;
    padding: 1rem;
    padding-top: 0rem; }
    .callpopover ul li {
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      padding: 1rem;
      border-bottom: 1px solid rgba(255, 87, 34, 0.12); }
      .callpopover ul li h5 {
        font-size: 1.6rem;
        font-weight: bold; }
      .callpopover ul li p {
        font-size: 1.4rem;
        margin-bottom: 0; }
      .callpopover ul li:last-child {
        border-bottom: none; }
  .callpopover .horarios {
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    background: #819CA9;
    color: white; }
    .callpopover .horarios h5 {
      margin-bottom: 1rem;
      margin-bottom: 0;
      font-size: 1.6rem;
      font-weight: bold; }
    .callpopover .horarios p {
      margin-bottom: 0;
      font-size: 1.6rem;
      font-weight: 400; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 16-ago-2018, 16:44:41
    Author     : PC03-MAXI
*/
ul.list-circle {
  list-style: none; }
  ul.list-circle li:before {
    content: " ";
    margin-right: 1rem;
    border-radius: 50%;
    border: 2px solid white;
    width: 1rem;
    height: 1rem;
    display: inline-block;
    position: relative;
    top: -1px; }
  ul.list-circle.list-circle-gray li:before {
    border: 2px solid rgba(51, 51, 51, 0.54); }
  ul.list-circle.list-circle-green li:before {
    border: 2px solid #048086; }

ul.list-check {
  list-style: none; }
  ul.list-check li {
    margin-bottom: 2rem; }
    ul.list-check li:before {
      font: normal normal normal 24px/1 "Material Design Icons";
      content: "\F5E0";
      color: white;
      border: none;
      margin-right: 1rem;
      width: auto;
      height: auto;
      position: relative;
      top: 2px; }
  ul.list-check.list-check-green li:before {
    color: #048086; }
  ul.list-check.list-check-gray li:before {
    color: rgba(51, 51, 51, 0.54); }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 15-may-2018, 17:15:24
    Author     : PC03-MAXI
*/
.cards-1 .card {
  border: 0;
  /*        position: relative;
                padding-bottom: 3rem;
                border-radius: 0;
                border-color: transparent;
                background-color: transparent;
                height: 100%;
                overflow:hidden;*/
  position: relative;
  /* padding-bottom: 3rem; */
  border-radius: 0;
  border-color: transparent;
  background-color: transparent;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .cards-1 .card .img-background {
    position: absolute;
    z-index: 0;
    position: absolute;
    height: auto;
    max-width: unset;
    min-height: 100%;
    display: none;
    /*width: 100%;*/
    /*            padding-bottom: 3rem;*/ }
  .cards-1 .card .card-body {
    position: relative;
    width: 52%;
    max-width: 35rem;
    background: rgba(0, 63, 68, 0.9);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    height: 35rem; }
    @media (max-width: 767.98px) {
      .cards-1 .card .card-body {
        width: 55%; } }
  .cards-1 .card .card-title {
    font-size: 1.8rem;
    color: rgba(255, 255, 255, 0.92);
    font-weight: 500;
    margin-bottom: 1.5rem;
    line-height: 2rem;
    width: 100%; }
  .cards-1 .card .card-subtitle {
    font-size: 1.5rem;
    color: white;
    font-weight: 500;
    margin-bottom: 1.5rem !important;
    line-height: 1.7rem; }
  .cards-1 .card p {
    color: white;
    margin-bottom: 0;
    font-weight: 500; }
  .cards-1 .card ul {
    color: white;
    list-style-position: inside;
    font-weight: 500; }
  .cards-1 .card .card-link {
    background: #048086;
    color: white;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: -2rem;
    margin-top: auto;
    align-self: flex-start; }
    .cards-1 .card .card-link:hover {
      background: #FFF !important;
      color: #003F44 !important; }
  .cards-1 .card.right-content {
    display: flex;
    height: 100% !important;
    /*            flex-direction: row;*/ }
    .cards-1 .card.right-content .icon-top {
      position: absolute;
      top: 0;
      left: 2rem;
      background: #EEEEEE;
      padding: 1rem;
      z-index: 1000; }
    .cards-1 .card.right-content .card-title {
      font-size: 3rem;
      line-height: 3.8rem;
      margin-bottom: 1rem; }
    .cards-1 .card.right-content .img-background {
      width: auto; }
    .cards-1 .card.right-content .card-body {
      /*              position: relative;
                                right: 0;
                                width: 70%;
                                background: $color2;
                                max-width: none;*/
      position: relative;
      /* right: 0; */
      /* width: 32%; */
      /* max-width: 33rem; */
      /* float: right; */
      background: #003F44;
      /* max-width: none; */
      align-self: flex-end;
      max-width: 50rem; }
      .cards-1 .card.right-content .card-body p {
        font-size: 1.4rem; }
      .cards-1 .card.right-content .card-body ul li {
        font-size: 1.4rem; }
    .cards-1 .card.right-content .card-subtitle {
      width: 80%; }
    .cards-1 .card.right-content .btn {
      align-self: flex-start;
      margin-top: auto;
      font-size: 1.4rem; }
    @media (max-width: 1199.98px) {
      .cards-1 .card.right-content {
        flex-direction: column; }
        .cards-1 .card.right-content .card-title {
          font-size: 2.4rem; }
        .cards-1 .card.right-content .card-body {
          position: relative;
          left: 0;
          width: 100%;
          max-width: unset; }
          .cards-1 .card.right-content .card-body .btn {
            margin-top: 2rem; }
        .cards-1 .card.right-content .card-subtitle {
          width: 100%; }
        .cards-1 .card.right-content .img-background {
          position: relative;
          width: 100%;
          height: auto;
          min-height: auto; } }
    @media (max-width: 767.98px) {
      .cards-1 .card.right-content .card-body {
        height: 100%;
        padding-top: 6rem; } }
  @media (max-width: 767.98px) {
    .cards-1 .card {
      height: auto !important;
      margin-bottom: 1.5rem; }
      .cards-1 .card .card-title {
        font-size: 2rem;
        line-height: 2.6rem; }
      .cards-1 .card .card-subtitle {
        font-size: 1.4rem; }
      .cards-1 .card .card-body {
        height: 100%; } }

.swipper-slider-card {
  width: 100%;
  height: 100%; }
  .swipper-slider-card .swiper-wrapper {
    max-height: 35rem; }
  .swipper-slider-card .swiper-slide {
    max-height: 35rem; }
  .swipper-slider-card .swiper-pagination {
    right: 2rem;
    left: auto;
    /* bottom: 0; */
    /* margin: auto; */
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .swipper-slider-card .swiper-pagination .swiper-pagination-bullet {
      margin: 4px 0px;
      background: white;
      opacity: 1; }
    .swipper-slider-card .swiper-pagination .swiper-pagination-bullet-active {
      background: #048086; }
  @media (max-width: 1199.98px) {
    .swipper-slider-card .swipper-slider-card .swiper-pagination {
      position: relative;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      align-items: center;
      justify-content: center; }
    .swipper-slider-card .swipper-slider-card .swiper-pagination-bullet {
      margin: 0px 4px !important; } }

.swipper-slider-card ul li {
  padding-right: 2rem; }

.cards-2 .card-col {
  margin-bottom: 3rem; }
  @media (max-width: 767.98px) {
    .cards-2 .card-col {
      margin-bottom: 0; } }

.cards-2 .card {
  position: relative;
  min-height: 23rem;
  border-radius: 0;
  border-color: transparent;
  /*        display: flex;
                flex-direction: row;*/
  height: 100%;
  /*   .img-background{
               position:absolute;
           }*/ }
  @media (max-width: 1199.98px) {
    .cards-2 .card {
      margin-bottom: 2rem; } }
  .cards-2 .card .card-title {
    font-size: 1.8rem;
    color: #048086;
    font-weight: 500;
    margin-bottom: 4rem; }
  .cards-2 .card .card-subtitle {
    color: #000;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 2rem !important; }
    .cards-2 .card .card-subtitle a {
      color: #047F85;
      text-transform: none; }
      .cards-2 .card .card-subtitle a:hover {
        text-decoration: underline; }
  .cards-2 .card .icono {
    position: absolute;
    right: 3rem;
    bottom: 2rem; }
  .cards-2 .card .card-link {
    margin-left: 0;
    position: relative;
    left: -21px;
    margin-top: auto;
    align-self: flex-start;
    font-size: 1.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    font-weight: 700; }
  .cards-2 .card .card-body {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .cards-2 .card.green {
    background: #003F44;
    color: white; }
    .cards-2 .card.green .card-body {
      padding-top: 2rem;
      align-items: flex-start;
      flex-direction: column; }
    .cards-2 .card.green .card-title, .cards-2 .card.green .card-subtitle {
      color: white;
      margin-bottom: 1.5rem !important; }
    .cards-2 .card.green .card-link {
      color: white;
      background: #048086; }
      .cards-2 .card.green .card-link:hover {
        background: #FFF !important;
        color: #003F44 !important; }
  @media (max-width: 767.98px) {
    .cards-2 .card {
      height: auto !important;
      min-height: 0;
      margin-bottom: 1.5rem; }
      .cards-2 .card .card-title {
        font-size: 2.2rem;
        line-height: 2.6rem; }
      .cards-2 .card .card-subtitle {
        font-size: 1.6rem; } }

@media only screen and (max-width: 1360px) and (min-width: 1100px) {
  .cards-1 .card .card-body {
    width: 55%; }
    .cards-1 .card .card-body .card-title {
      font-size: 18px;
      line-height: 21px; }
    .cards-1 .card .card-body .card-subtitle {
      font-size: 15px;
      line-height: 17px; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 16-may-2018, 10:35:23
    Author     : PC03-MAXI
*/
article {
  margin-bottom: 1.5rem; }
  article figure {
    border-top: 2px solid #048086;
    margin-bottom: 1rem; }
  article .category {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    background: #048086;
    font-size: 1.6rem;
    color: white;
    font-weight: 500;
    margin-bottom: 1.8rem;
    margin-right: 0.6rem; }
  article time.date {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1.6rem;
    font-weight: 400; }
  article .title {
    color: rgba(4, 128, 134, 0.92);
    font-weight: 500;
    font-size: 2.4rem;
    margin-bottom: 1.5rem; }
    article .title:focus, article .title:hover, article .title:active {
      color: #003F44; }
  article .cont {
    color: #333333;
    font-size: 1.6rem; }
    article .cont p {
      color: #333333;
      font-size: 1.6rem; }
  article .btn {
    color: #048086;
    font-weight: 500;
    font-size: 1.6rem; }
    article .btn:focus, article .btn:hover, article .btn:active {
      color: #003F44; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 17-may-2018, 12:08:45
    Author     : codigowww
*/
#smartwizard {
  box-shadow: none; }
  #smartwizard .nav-tabs {
    display: none; }
  #smartwizard .sw-toolbar {
    background: transparent; }
  #smartwizard .step-content {
    background: transparent; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 17-may-2018, 13:55:05
    Author     : codigowww
*/
.md-checkbox {
  position: relative;
  margin: 16px 0;
  text-align: left;
  display: flex; }
  .md-checkbox.md-checkbox-inline {
    display: inline-block; }
  .md-checkbox label {
    cursor: pointer; }
    .md-checkbox label:before, .md-checkbox label:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0; }
    .md-checkbox label:before {
      width: 18px;
      height: 18px;
      background: transparent;
      border: 2px solid rgba(255, 255, 255, 0.9);
      border-radius: 2px;
      cursor: pointer;
      transition: background .3s;
      margin-right: 15px; }
  .md-checkbox input[type="checkbox"] {
    outline: 0;
    margin-right: 8px;
    visibility: hidden; }
    .md-checkbox input[type="checkbox"]:checked + label:before {
      background: rgba(255, 255, 255, 0.9);
      border: none; }
    .md-checkbox input[type="checkbox"]:checked + label:after {
      transform: rotate(-45deg);
      top: 4.7px;
      left: 4px;
      width: 10px;
      height: 5px;
      border: 2px solid #048086;
      border-top-style: none;
      border-right-style: none; }
    .md-checkbox input[type="checkbox"]:disabled + label:before {
      border-color: rgba(0, 0, 0, 0.26); }
    .md-checkbox input[type="checkbox"]:disabled:checked + label:before {
      background: rgba(0, 0, 0, 0.26); }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 26-jul-2018, 16:00:40
    Author     : PC03-MAXI
*/
.md-radio [type="radio"]:checked,
.md-radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px; }

.md-radio [type="radio"]:checked + label,
.md-radio [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 25px;
  margin-bottom: 0;
  display: inline-block;
  color: white; }

.md-radio [type="radio"]:checked + label:before,
.md-radio [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 2px solid white;
  border-radius: 100%;
  background: transparent; }

.md-radio [type="radio"]:checked + label:after,
.md-radio [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: white;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }

.md-radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

.md-radio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 17-may-2018, 17:19:10
    Author     : codigowww
*/
.asistente {
  position: fixed;
  z-index: 10;
  background: #EB992F;
  border-top-left-radius: 4rem;
  border-bottom-left-radius: 4rem;
  width: 24.5rem;
  height: 6.5rem;
  top: 55%;
  right: 0;
  align-items: center;
  transition: all ease 0.3s;
  cursor: pointer; }
  .asistente:before {
    content: url(../img/asistente.svg);
    position: relative;
    margin-left: 0.5rem;
    left: 0;
    width: 5.3rem;
    height: 5.3rem;
    display: block; }
  .asistente .copy, .asistente .copy_hov {
    color: black;
    margin-left: 1.5rem;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 0;
    opacity: 1; }
  .asistente .copy_hov {
    /*        display:none;*/
    position: absolute;
    opacity: 0;
    font-size: 1rem;
    transition: all 0.3s ease-out 0s; }
  .asistente:hover {
    width: 47rem; }
    .asistente:hover .copy {
      display: none;
      opacity: 0; }
    .asistente:hover .copy_hov {
      /*            display:block;*/
      position: relative;
      font-size: 1.8rem;
      line-height: 2.2rem;
      opacity: 1;
      transition: all 0.1s ease-out 0.3s; }
  @media (max-width: 1199.98px) {
    .asistente {
      display: none; } }

.btn-asistente {
  background: #EB992F;
  border-radius: 4rem;
  width: auto;
  height: 4.7rem;
  display: flex;
  align-items: center;
  color: #1F1F1F;
  text-transform: none;
  font-size: 2rem; }
  .btn-asistente:before {
    content: " ";
    background: url(../img/asistente.svg);
    background-size: 3.3rem;
    position: relative;
    margin-left: 0;
    left: -1rem;
    width: 3.3rem;
    height: 3.3rem;
    display: block; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 04-jul-2018, 12:57:20
    Author     : PC03-MAXI
*/
.tip {
  display: flex;
  align-items: center; }
  .tip .icon-box {
    margin-right: 1.8rem;
    min-width: 6.5rem;
    min-height: 5.7rem;
    display: flex;
    align-items: center;
    justify-content: center; }
  .tip .tip-text {
    color: #003F44;
    font-size: 3rem;
    font-weight: 400;
    line-height: 110%; }
  @media (max-width: 767.98px) {
    .tip .tip-text {
      font-size: 2rem; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 24-ago-2018, 11:25:46
    Author     : Rodri
*/
@media (min-width: 578px) and (max-width: 767px) {
  .container {
    max-width: 100%; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 10-jul-2018, 18:06:00
    Author     : PC03-MAXI
*/
.social-net {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around; }
  .social-net li {
    margin-right: 0.5rem; }
    .social-net li a {
      color: white;
      font-size: 2rem; }
    .social-net li:last-child {
      margin-right: 0rem; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 30-jul-2018, 12:54:11
    Author     : PC03-MAXI
*/
ul.download {
  padding-left: 1rem !important; }
  ul.download li {
    padding-left: 1rem; }
    ul.download li a img {
      margin-right: 1rem; }
    ul.download li a.link-external {
      display: flex; }
      ul.download li a.link-external.no-link {
        cursor: auto;
        color: #333;
        text-decoration: none; }
      ul.download li a.link-external img {
        align-self: flex-start; }

#fbLiveChatModalDlg {
  /*position: relative;*/
  /*    display: flex;
        align-items: center;
        justify-content: center;*/ }
  #fbLiveChatModalDlg .modal-dialog {
    max-width: 780px;
    margin: 0rem auto 0rem;
    transform: translate(0%, -50%);
    top: 50%; }
    #fbLiveChatModalDlg .modal-dialog .modal-content {
      border-radius: 0;
      padding: 5rem;
      padding-bottom: 7rem; }
      #fbLiveChatModalDlg .modal-dialog .modal-content .alert.hide {
        display: none; }
      #fbLiveChatModalDlg .modal-dialog .modal-content .alert-danger {
        top: 1.5rem;
        width: 50%; }
      #fbLiveChatModalDlg .modal-dialog .modal-content .modal-header {
        border-radius: 0;
        border-bottom: none;
        position: absolute;
        right: 0rem;
        top: 0rem; }
        #fbLiveChatModalDlg .modal-dialog .modal-content .modal-header .close {
          width: 5rem;
          height: 5rem;
          background: #003F44;
          color: #FFF;
          font-size: 1.4rem;
          opacity: 1; }
          #fbLiveChatModalDlg .modal-dialog .modal-content .modal-header .close span {
            font-size: 2rem; }
      #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body {
        padding: 0rem; }
        #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body h4 {
          font-size: 3.4rem;
          font-weight: 400;
          line-height: 110%;
          color: #047F85;
          width: 80%;
          margin-bottom: .5rem; }
        #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body h5 {
          font-size: 2rem;
          font-weight: 300;
          line-height: 120%;
          color: #333;
          margin-bottom: 2rem; }
        #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body label {
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 120%;
          color: #666;
          display: block;
          margin-bottom: 0.2rem; }
        #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body input[type="text"], #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body input[type="email"], #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body textarea {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 4.2rem;
          color: #003F44;
          border: 1px solid;
          padding: 0rem 1.5rem 0rem 1rem;
          width: 100%; }
        #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body textarea {
          line-height: 120%;
          padding-top: 1rem;
          resize: none;
          height: 9.3rem; }
        #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body .radio-buttons .md-radio {
          display: inline-block;
          margin-right: 2rem; }
          #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body .radio-buttons .md-radio label {
            cursor: pointer;
            line-height: 2.4rem;
            padding-left: 3rem; }
        #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body .radio-buttons input {
          margin-right: .75rem; }
        #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body .radio-buttons .md-radio [type="radio"]:checked + label:after, #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body .radio-buttons .md-radio [type="radio"]:not(:checked) + label:after {
          background: #666; }
        #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body .radio-buttons .md-radio [type="radio"]:checked + label:before, #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body .radio-buttons .md-radio [type="radio"]:not(:checked) + label:before {
          border-color: #666; }
        #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body .comunicarse p {
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 140%;
          color: #333; }
        #fbLiveChatModalDlg .modal-dialog .modal-content .modal-body .required-fields {
          position: absolute;
          right: 0rem;
          font-size: 1.2rem;
          color: #666;
          font-style: italic; }
      #fbLiveChatModalDlg .modal-dialog .modal-content .modal-footer {
        border-top: none;
        position: absolute;
        bottom: 0rem;
        right: 0rem;
        padding: 0rem; }
        #fbLiveChatModalDlg .modal-dialog .modal-content .modal-footer .btn {
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 4.2rem; }
          #fbLiveChatModalDlg .modal-dialog .modal-content .modal-footer .btn-submit {
            border: 1px solid #47989D;
            width: 19rem;
            color: #036A6F;
            background: #FFF; }
            #fbLiveChatModalDlg .modal-dialog .modal-content .modal-footer .btn-submit:hover {
              background: #47989D;
              color: #FFF; }
          #fbLiveChatModalDlg .modal-dialog .modal-content .modal-footer .btn-close {
            color: #666;
            background: #FFF;
            width: 12rem; }
            #fbLiveChatModalDlg .modal-dialog .modal-content .modal-footer .btn-close:hover {
              color: #111; }

#surveysubmit {
  display: flex !important;
  margin-top: 2rem; }
  #surveysubmit #aicsurveysend {
    margin-left: 0rem;
    margin-right: 1rem; }
  #surveysubmit #aicsurveycancel {
    color: #003f44; }

/*#av_chat_header{
    position: fixed;
    top: 0rem;
    z-index: 99999;
    width: 35rem;
}*/
@media (max-width: 767.98px) {
  #fbLiveChatModalDlg .modal-dialog .modal-content .modal-footer {
    bottom: -6rem; } }

/*
 *  ----------------- SECTIONS -----------------
 */
/* ------------------------------------------------------------ *        HOME
\* ------------------------------------------------------------ */
section {
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative; }
  section .icon-top {
    position: absolute;
    top: 0;
    /* left: 5rem; */
    background: rgba(129, 156, 169, 0.2);
    padding: 1rem;
    z-index: 1000; }
  section .icon-box {
    background: rgba(129, 156, 169, 0.2);
    padding: 1rem;
    display: inline-block; }
  section .icon-top.green, section .icon-box.green {
    background: #048086;
    color: white; }
  section .icon-top.white, section .icon-box.white {
    background: white;
    color: #048086; }
  section .icon-top.green-dark, section .icon-box.green-dark {
    background: #003F44;
    color: white; }
  section.first-section {
    margin-top: 16.6rem; }
    section.first-section.h100 {
      min-height: calc(100vh -  16.6rem); }
    @media (max-width: 767.98px) {
      section.first-section {
        margin-top: 0; } }
  section.h100 {
    min-height: 100vh; }
  section .img-bg {
    position: absolute;
    top: 0;
    width: 100%; }
  section [class*="img-bg"] {
    overflow: hidden; }
  section p {
    /*color: #003F44;*/
    font-size: 1.8rem;
    font-weight: 400; }

/* Dispositivos Pequeños y Tablets vertical , Celulares horizontal min-width:576px  */
@media (max-width: 767.98px) {
  section {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; } }

/* Dispositivos Medianos , Tablets horizontal min-width:768px  */
/* Dispositivos grandes , min-width:992px */
/* Escritorio , min-width:1200 px */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  section.first-section {
    margin-top: 0rem; } }

/* ------------------------------------------------------------ *        HOME
\* ------------------------------------------------------------ */
section.home-main {
  /*background:url(../img/bg/header.jpg);*/
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #003f44;
  display: flex;
  padding-top: 0;
  padding-bottom: 0; }
  section.home-main .content {
    width: 100%;
    position: relative; }
  section.home-main .container-fluid {
    position: relative;
    z-index: 1;
    padding-top: 2.5rem;
    padding-bottom: 3.2rem; }
    @media (max-width: 767.98px) {
      section.home-main .container-fluid {
        padding-top: 0rem; } }
  section.home-main .bg {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNS8xNS8xOFGbqDYAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAGElEQVQImQXBAQEAAAABIEPtdyMVFAuGHoR+DnmrFX1FAAAAAElFTkSuQmCC");
    background-repeat: repeat;
    position: absolute;
    width: 50%;
    height: 100%;
    z-index: 0; }
  section.home-main .bg-l {
    left: 0;
    background: rgba(129, 156, 169, 0.85);
    background-repeat: repeat; }
  section.home-main .bg-r {
    left: 50%;
    background: rgba(29, 48, 56, 0.8);
    background-repeat: repeat; }
  section.home-main h2 {
    font-size: 4.8rem;
    color: white;
    font-weight: 500;
    margin-bottom: .5rem; }
  section.home-main p {
    font-size: 2.8rem;
    color: rgba(255, 255, 255, 0.92);
    font-weight: 500;
    margin-bottom: 0rem;
    line-height: 3rem; }
  section.home-main .cont-box-home {
    padding: 1rem 3rem 1.5rem 3rem; }
  section.home-main span.llama {
    font-size: 2rem;
    color: white;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: 1rem; }
  section.home-main .telefono {
    font-size: 3.4rem;
    font-weight: bold;
    padding: 0rem; }
  section.home-main btn {
    font-size: 1.4rem;
    font-weight: 500; }
  section.home-main .col-l {
    /*padding-right: 5rem;*/ }
  section.home-main .col-r {
    /*padding-left: 5rem;*/ }
  section.home-main .cont-box-home {
    padding: 0 3rem 0 3rem; }
  @media (max-width: 1199.98px) {
    section.home-main {
      height: auto;
      background-image: none !important; }
      section.home-main .container {
        padding-top: 0;
        padding-bottom: 0; }
      section.home-main h2 {
        font-size: 3.3rem;
        margin-bottom: 1.5rem; }
      section.home-main p {
        font-size: 2rem;
        margin-bottom: 2rem; }
      section.home-main .col-l {
        background: #819CA9;
        padding-top: 3rem;
        padding-bottom: 3rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem; }
        section.home-main .col-l .btn {
          margin-bottom: 1.5rem; }
      section.home-main .col-r {
        padding-left: 1.5rem;
        background: #546E7A;
        padding-top: 3rem;
        padding-bottom: 3rem;
        padding-right: 1.5rem; } }
  @media (max-width: 767.98px) {
    section.home-main .col-l, section.home-main .col-r {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; }
      section.home-main .col-l h2, section.home-main .col-r h2 {
        font-size: 2.8rem;
        margin-bottom: 1rem; }
      section.home-main .col-l p, section.home-main .col-r p {
        font-size: 2rem;
        line-height: 2.4rem;
        margin-bottom: 1.5rem; }
      section.home-main .col-l .btn, section.home-main .col-r .btn {
        margin-bottom: 0;
        line-height: 4.2rem;
        height: 4.2rem; } }

section.home-cards {
  background: rgba(129, 156, 169, 0.2); }
  section.home-cards h2.title {
    font-size: 3.4rem;
    font-weight: 400;
    color: #003F44;
    margin-bottom: 3rem; }
    @media (max-width: 767.98px) {
      section.home-cards h2.title {
        font-size: 2rem;
        margin-bottom: 2rem; } }
  @media (max-width: 767.98px) {
    section.home-cards .btn-outline-primary {
      margin: 0 0 1rem 0 !important;
      width: 100%;
      display: block; } }

section.home-servicios {
  background: rgba(129, 156, 169, 0.1); }
  section.home-servicios .title {
    font-size: 3.4rem;
    font-weight: bold;
    color: #048086;
    margin-bottom: 4rem; }
  section.home-servicios .card {
    padding-right: 3rem;
    min-height: 16rem; }
    section.home-servicios .card .card-body {
      padding-bottom: 2rem; }
    section.home-servicios .card .card-title {
      margin-bottom: 2rem; }
    section.home-servicios .card .card-link {
      font-size: 1.4rem; }

section.home-links {
  background: #EEEEEE; }
  section.home-links .card {
    padding-bottom: 0rem;
    height: 100%; }
    section.home-links .card .card-body {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      padding: 2rem 2rem; }
    section.home-links .card .card-title {
      margin-bottom: 1rem;
      font-size: 2rem; }
    section.home-links .card .card-subtitle {
      font-size: 1.6rem;
      margin-bottom: 2rem !important; }
    section.home-links .card .card-link {
      font-size: 1.4rem; }
  @media (max-width: 1199.98px) {
    section.home-links .card {
      height: auto; }
      section.home-links .card .card-subtitle {
        margin-bottom: 3rem !important; }
      section.home-links .card .btn {
        max-width: 70%; } }
  @media (max-width: 1199.98px) {
    section.home-links .card {
      height: auto; }
      section.home-links .card .card-subtitle {
        margin-bottom: 3rem !important; }
      section.home-links .card .btn {
        max-width: 70%; } }

section.home-articulos {
  padding-top: 0;
  position: relative; }
  section.home-articulos.icon-white .icon-top {
    background: #FFF; }
  section.home-articulos .container {
    position: relative; }
  section.home-articulos .icon-top {
    position: absolute;
    top: -10rem;
    /* left: 5rem; */
    background: #EEEEEE;
    padding: 1rem;
    z-index: 1000; }
  section.home-articulos header {
    padding-top: 10rem;
    padding-bottom: 6.5rem;
    background: url(../img/siempresaludable.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    background-color: #819CA9; }
    section.home-articulos header .title {
      font-size: 3.4rem;
      color: white;
      font-weight: 400;
      margin-bottom: 1rem; }
    section.home-articulos header .subtitle {
      font-size: 2rem;
      color: white;
      margin-bottom: 2.5rem;
      max-width: 41rem; }
  @media (max-width: 1199.98px) {
    section.home-articulos header {
      background: #819CA9;
      padding-top: 3.4rem;
      padding-bottom: 5rem; }
      section.home-articulos header .title {
        font-size: 2.4rem;
        margin-bottom: 1rem; }
      section.home-articulos header .subtitle {
        font-size: 1.6rem; } }
  @media (max-width: 1199.98px) {
    section.home-articulos header {
      background: #819CA9;
      padding-top: 3.4rem;
      padding-bottom: 5rem; }
      section.home-articulos header .title {
        font-size: 2.4rem;
        margin-bottom: 1rem; }
      section.home-articulos header .subtitle {
        font-size: 1.6rem; } }
  section.home-articulos ul.pagination {
    position: relative;
    /* align-items: center; */
    /* text-align: center; */
    /* display: flex; */
    /* align-items: center; */
    /* flex-direction: row; */
    justify-content: center; }
    section.home-articulos ul.pagination:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #444;
      position: absolute;
      left: 0;
      bottom: 50%;
      z-index: 0; }
    section.home-articulos ul.pagination .next {
      z-index: 2; }
      section.home-articulos ul.pagination .next a {
        color: white !important;
        padding-top: 0;
        padding-bottom: 0; }
  @media (max-width: 1199.98px) {
    section.home-articulos ul.pagination:after {
      display: none; }
    section.home-articulos ul.pagination .next {
      width: 100%; }
      section.home-articulos ul.pagination .next a {
        display: block; } }
  section.home-articulos .articles {
    padding-top: 3rem;
    padding-bottom: 3rem; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 25-jul-2018, 18:59:53
    Author     : PC03-MAXI
*/
.newsletter {
  position: relative;
  background: rgba(129, 156, 169, 0.3);
  padding-top: 5rem;
  padding-bottom: 5rem; }
  .newsletter .container {
    position: relative; }
  .newsletter .triangle {
    position: absolute;
    top: -5rem;
    left: 2rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 2rem 2rem 0 2rem;
    border-color: #048086 transparent transparent transparent; }
  .newsletter p.suscribe {
    font-size: 2rem;
    color: #003F44;
    font-weight: 500; }
  .newsletter p.authorize {
    margin-top: 4rem;
    font-size: 1.6rem;
    color: #003F44;
    font-weight: 400; }
  .newsletter form .form-control {
    padding: 0 1.5rem;
    height: 6rem;
    font-size: 1.6rem;
    color: rgba(0, 63, 68, 0.54); }
    .newsletter form .form-control::placeholder {
      color: rgba(0, 63, 68, 0.54); }
  .newsletter form .btn {
    padding: 0 4rem;
    height: 6rem; }
    @media (max-width: 1199.98px) {
      .newsletter form .btn {
        padding: 0 2rem; } }

@media (max-width: 1199.98px) {
  .newsletter {
    background: #D9E1E5; } }

@media (max-width: 1199.98px) {
  .newsletter {
    background: #D9E1E5; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 10-jul-2018, 15:50:16
    Author     : PC03-MAXI
*/
section.section-orientacion {
  background: #003F44;
  position: relative;
  padding-bottom: 5rem;
  padding-top: 4.5rem;
  /*@media only screen and (min-width: 990px) and(max-width: 1575px){*/
  /*}*/ }
  section.section-orientacion .tooltip {
    font-size: 3rem !important; }
  section.section-orientacion .triangle {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-right: 30px solid #003F44;
    border-bottom: 30px solid transparent;
    z-index: 32;
    position: absolute;
    right: 50%;
    top: 25%; }
  section.section-orientacion .help-block {
    padding-top: 0.5rem;
    font-size: 1.2rem;
    color: rgba(255, 0, 0, 0.92); }
  section.section-orientacion .title {
    font-size: 5rem;
    line-height: 5rem;
    font-weight: 500;
    color: white;
    /*font-weight:bold;*/
    padding-bottom: 2rem;
    margin-bottom: 2.8rem;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.94);
    width: 100%; }
  section.section-orientacion .subtitle {
    margin-bottom: 0 !important;
    color: #FFF !important;
    font-weight: 400 !important;
    font-size: 2.4rem !important; }
  section.section-orientacion form {
    /*        input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus, 
            input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset;
        }*/
    /*        .field-email{
                    margin-bottom: 1rem;
                }*/ }
    section.section-orientacion form .req-fields {
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.6rem;
      font-weight: 500;
      /*            position: relative;
                        top: -2rem;*/ }
      @media (max-width: 1199.98px) {
        section.section-orientacion form .req-fields {
          top: 0; } }
    section.section-orientacion form #orientacionform-telefono_caracteristica {
      width: 6rem; }
    section.section-orientacion form .md-checkbox label:before {
      top: 5px; }
    section.section-orientacion form .fields-telefono {
      margin-top: 2rem; }
    section.section-orientacion form .info-links {
      color: #FFF;
      display: flex;
      flex-direction: column; }
      section.section-orientacion form .info-links a {
        margin-bottom: .5rem;
        font-size: 1.6rem;
        font-weight: 500;
        cursor: auto; }
        section.section-orientacion form .info-links a i {
          margin-left: 1.4rem;
          cursor: pointer; }
    section.section-orientacion form .btn.btn-block {
      margin-top: 0rem; }
    section.section-orientacion form .textarea-cont {
      border-top: 1px dotted rgba(255, 255, 255, 0.94);
      padding-top: 2.8rem;
      margin-top: 2rem;
      margin-bottom: 3rem;
      position: relative;
      /*            &:after{
                            content: " ";
                            pointer-events: none;
                            width: 100%;
                            height: 11rem;
                            position: absolute;
                            bottom: 0;
                             Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#003f44+0,003f44+100&0+0,1+100 
                            background: -moz-linear-gradient(top, rgba(0,63,68,0) 0%, rgba(0,63,68,1) 100%);  FF3.6-15 
                            background: -webkit-linear-gradient(top, rgba(0,63,68,0) 0%,rgba(0,63,68,1) 100%);  Chrome10-25,Safari5.1-6 
                            background: linear-gradient(to bottom, rgba(0,63,68,0) 0%,rgba(0,63,68,1) 100%);  W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ 
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00003f44', endColorstr='#003f44',GradientType=0 );  IE6-9 
                        }*/ }
      section.section-orientacion form .textarea-cont textarea {
        border: none;
        background: transparent;
        width: 100%;
        height: 7rem;
        color: rgba(255, 255, 255, 0.54);
        font-size: 1.6rem;
        font-weight: 400;
        resize: none; }
        section.section-orientacion form .textarea-cont textarea:focus, section.section-orientacion form .textarea-cont textarea:active, section.section-orientacion form .textarea-cont textarea:hover {
          outline: none; }
    section.section-orientacion form .warning {
      align-items: center; }
      section.section-orientacion form .warning i {
        font-size: 2.6rem; }
      section.section-orientacion form .warning .text {
        font-size: 1.6rem;
        color: white;
        font-weight: 500; }
        section.section-orientacion form .warning .text p {
          font-size: 1.4rem;
          font-weight: 500; }
  @media (max-width: 1199.98px) {
    section.section-orientacion .title {
      margin-top: 2rem;
      font-size: 3.4rem; }
    section.section-orientacion .subtitle {
      font-size: 2rem;
      margin-bottom: 2rem; } }
  section.section-orientacion .bg {
    position: absolute;
    width: 50%;
    left: 0;
    top: 0;
    background: url(../img/bg/contact.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    height: 100%; }
    section.section-orientacion .bg .bg-title {
      height: 36.1rem;
      background: rgba(4, 128, 134, 0.9);
      width: 100%;
      position: absolute;
      bottom: 0; }
  section.section-orientacion .left-text-cont {
    justify-content: flex-end; }
  @media (max-width: 767.98px) {
    section.section-orientacion section.section.orientacion {
      padding-top: 0rem; } }
  @media only screen and (min-width: 990px) and (max-width: 1200px) {
    section.section-orientacion form label {
      font-size: 1.2rem !important; } }
  section.section-orientacion form label {
    font-size: 1.4rem; }
  section.section-orientacion .bg {
    background-position: center 17vw; }
    @media only screen and (min-width: 1800px) and (max-width: 1920px) {
      section.section-orientacion .bg {
        background-position: center 8vw; } }
    section.section-orientacion .bg .bg-title {
      top: 0;
      bottom: auto;
      background: #048086; }
  section.section-orientacion .left-text-cont {
    justify-content: flex-start; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 29-jun-2018, 14:47:11
    Author     : Rodri
*/
.section-comunicate {
  background: transparent url(../img/icon_comunicate.png) center center no-repeat;
  position: relative; }
  .section-comunicate.has_asistente_before {
    border-top: 1px solid #FFF; }
  .section-comunicate.has_orientacion_after {
    border-bottom: 1px solid #FFF; }
  .section-comunicate .icon-middle {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 50%;
    margin-left: -6.8rem; }
  .section-comunicate:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 50%;
    background: #048086;
    z-index: -1; }
  .section-comunicate:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    right: 0;
    background: white;
    z-index: -1; }
  .section-comunicate h2 {
    color: #FFF !important;
    font-size: 6rem !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
    line-height: 6.4rem !important; }
  .section-comunicate h3 {
    color: #FFF;
    font-size: 2.4rem;
    font-weight: 400; }
  .section-comunicate h4 {
    font-size: 2rem;
    font-weight: bold;
    color: #003F44;
    margin-bottom: 1rem; }
  .section-comunicate p {
    font-size: 1.8rem;
    font-weight: 400;
    color: #003F44;
    margin-bottom: 1rem; }
    .section-comunicate p strong {
      display: block; }
  @media (max-width: 767.98px) {
    .section-comunicate {
      background: #048086; }
      .section-comunicate:before, .section-comunicate .icon-middle {
        display: none; }
      .section-comunicate h2 {
        font-size: 4rem; }
      .section-comunicate h3 {
        font-size: 1.8rem;
        margin-bottom: 1.5rem; }
      .section-comunicate h4, .section-comunicate p {
        color: rgba(255, 255, 255, 0.92); } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 13-ago-2018, 12:47:15
    Author     : PC03-MAXI
*/
.page-planes-beneficios .planes-category .container {
  position: relative; }

.page-planes-beneficios .btn-solicita-orientacion {
  position: absolute;
  right: 1.5rem;
  top: 0rem;
  padding-left: 6.6rem;
  padding-right: 6.6rem;
  z-index: 2;
  color: #048086;
  font-weight: bold; }
  @media (max-width: 1199.98px) {
    .page-planes-beneficios .btn-solicita-orientacion {
      position: relative;
      right: unset;
      margin-left: 1rem;
      margin-right: 1rem;
      /* left: 0; */
      /* margin: auto; */
      display: block; } }

.page-planes-beneficios section.downloads {
  padding-bottom: 0; }
  .page-planes-beneficios section.downloads h2 {
    font-size: 2.2rem;
    font-weight: 500;
    color: #003F44;
    margin-bottom: 2rem; }
  .page-planes-beneficios section.downloads p.autorizado {
    font-size: 1.8rem;
    color: #333333;
    margin-top: 2rem; }
  .page-planes-beneficios section.downloads .document-item {
    display: block;
    margin-bottom: 0.5rem; }

.links-bar {
  display: flex;
  justify-content: flex-end;
  margin-right: 3rem;
  margin-top: 3rem;
  border-bottom: none; }
  .links-bar a {
    color: #048086;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0.5rem 1.5rem;
    border-right: 1px solid #048086;
    transition: all .2s ease-out; }
    .links-bar a:hover {
      background: #048086;
      color: #FFF; }
    .links-bar a:last-child {
      border-right: none; }

.planes-category .category {
  color: #869FAC;
  font-size: 3.4rem;
  font-weight: 500;
  margin-bottom: 0; }

.planes-category .title {
  font-size: 6.5rem;
  color: #003F44;
  font-weight: 500;
  margin-bottom: 0rem; }
  .planes-category .title.blue {
    color: #03576F; }
  .planes-category .title.green {
    color: #048086; }

.planes-category .subtitle {
  font-size: 2.2rem;
  color: #048086;
  font-weight: 500;
  margin-bottom: 3rem; }

.planes-category .card.card-planes-beneficios {
  border: none;
  box-shadow: 0px 4px 14px 0 rgba(0, 0, 0, 0.1);
  /*        margin-bottom: 2rem;*/
  /*        height: 100%;*/ }
  .planes-category .card.card-planes-beneficios.platino .card-body {
    padding-top: 3.8rem;
    z-index: 2; }
  .planes-category .card.card-planes-beneficios.platino .card-header .icon {
    margin-top: 0rem;
    height: 3.4rem; }
  .planes-category .card.card-planes-beneficios .card-header:first-child {
    border-radius: calc(0.85rem - 1px) calc(0.85rem - 1px) 0 0; }
  .planes-category .card.card-planes-beneficios .card-header {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#884c74+0,693a5a+100 */
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00636a+0,003f44+100 */
    background: #00636a;
    /* Old browsers */
    background: -moz-linear-gradient(top, #00636a 0%, #003f44 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #00636a 0%, #003f44 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #00636a 0%, #003f44 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00636a', endColorstr='#003f44',GradientType=0 );
    /* IE6-9 */
    color: white;
    padding-top: 2rem;
    padding-bottom: 2rem;
    /*            padding-top:3.8rem;*/
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center; }
    .planes-category .card.card-planes-beneficios .card-header.blue {
      background: #03576F; }
    .planes-category .card.card-planes-beneficios .card-header.green {
      background: #048086; }
    .planes-category .card.card-planes-beneficios .card-header h3 {
      font-size: 2.6rem;
      font-weight: 500; }
    .planes-category .card.card-planes-beneficios .card-header .clase {
      font-weight: 400;
      font-size: 2rem;
      color: white; }
    .planes-category .card.card-planes-beneficios .card-header .icon {
      position: relative;
      margin-top: 1rem;
      height: 5rem;
      /*                                &:after{
                                                    content: " ";
                                                    background: #003F44;
                                                    border-radius: 50%;
                                                    width: 6rem;
                                                    height: 6rem;
                                                    display: block;
                                                    position: absolute;
                                                    left: 0;
                                                    right: 0;
                                                    margin: auto;
                                                    top: 25px;
                                                }*/ }
      .planes-category .card.card-planes-beneficios .card-header .icon img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        top: 3.5rem;
        z-index: 2;
        height: 4rem; }
      .planes-category .card.card-planes-beneficios .card-header .icon:before {
        content: " ";
        background: white;
        border-radius: 50%;
        width: 10rem;
        height: 10rem;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 9px; }
  .planes-category .card.card-planes-beneficios .card-body {
    padding-top: 4.2rem;
    padding-bottom: 3.5rem; }
    .planes-category .card.card-planes-beneficios .card-body .desc {
      font-size: 1.8rem;
      color: #333;
      margin-bottom: 2rem;
      font-weight: 400; }
    .planes-category .card.card-planes-beneficios .card-body .beneficios-list {
      list-style: none;
      margin-bottom: 1rem; }
      .planes-category .card.card-planes-beneficios .card-body .beneficios-list li {
        color: #048086;
        font-size: 1.8rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px #94ABB6 dotted; }
        .planes-category .card.card-planes-beneficios .card-body .beneficios-list li:last-child {
          border-bottom: none; }
        .planes-category .card.card-planes-beneficios .card-body .beneficios-list li .left {
          /*                        display: flex;
                        align-items: center;*/
          width: 80%; }
        .planes-category .card.card-planes-beneficios .card-body .beneficios-list li .right {
          width: 40%;
          text-align: right; }
          @media screen and (min-width: 900px) and (max-width: 1200px) {
            .planes-category .card.card-planes-beneficios .card-body .beneficios-list li .right:not(.price) {
              font-size: 1.3rem; } }
          .planes-category .card.card-planes-beneficios .card-body .beneficios-list li .right .label {
            font-size: 1.6rem;
            display: block;
            /* margin-top: -1px; */
            position: relative;
            top: -1rem; }
          .planes-category .card.card-planes-beneficios .card-body .beneficios-list li .right.price {
            font-size: 3.6rem; }
            .planes-category .card.card-planes-beneficios .card-body .beneficios-list li .right.price:before {
              content: "$";
              position: relative;
              font-size: 1.6rem;
              top: -7px;
              left: -2px; }
          .planes-category .card.card-planes-beneficios .card-body .beneficios-list li .right.without-value span {
            padding: 26px 0;
            top: 0; }
          .planes-category .card.card-planes-beneficios .card-body .beneficios-list li .right.percent {
            font-size: 3.6rem; }
            .planes-category .card.card-planes-beneficios .card-body .beneficios-list li .right.percent:before {
              content: "%";
              position: relative;
              font-size: 1.6rem;
              top: -7px;
              left: -2px; }
          .planes-category .card.card-planes-beneficios .card-body .beneficios-list li .right.other {
            font-size: 3.6rem; }
        .planes-category .card.card-planes-beneficios .card-body .beneficios-list li.head {
          color: #003F44;
          font-size: 1.6rem;
          font-weight: 500; }
    .planes-category .card.card-planes-beneficios .card-body .prima {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-items: center; }
      .planes-category .card.card-planes-beneficios .card-body .prima .sticker {
        position: relative;
        left: -12px;
        background: #003F44;
        display: inline-block;
        padding-left: 1.8rem;
        padding-right: 1.8rem;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        font-size: 1.6rem;
        color: white;
        align-self: flex-start; }
      .planes-category .card.card-planes-beneficios .card-body .prima .price {
        text-align: center;
        font-size: 6.5rem;
        font-weight: 500;
        color: #003F44; }
        .planes-category .card.card-planes-beneficios .card-body .prima .price small {
          font-size: 3.4rem;
          position: relative;
          top: -25px;
          left: -0.5rem; }
        .planes-category .card.card-planes-beneficios .card-body .prima .price:before {
          content: "$";
          font-size: 2rem;
          margin-right: 0.3rem;
          position: relative;
          top: -1rem; }
      .planes-category .card.card-planes-beneficios .card-body .prima .btn {
        background: #003F44; }
        .planes-category .card.card-planes-beneficios .card-body .prima .btn:hover, .planes-category .card.card-planes-beneficios .card-body .prima .btn:active, .planes-category .card.card-planes-beneficios .card-body .prima .btn:focus {
          background: rgba(0, 63, 68, 0.54) !important; }
  .planes-category .card.card-planes-beneficios.color1 .card-header {
    background: #048086; }
    .planes-category .card.card-planes-beneficios.color1 .card-header .icon:after {
      background: #048086; }
  .planes-category .card.card-planes-beneficios.color1 .card-body .prima .sticker {
    background: #048086; }
  .planes-category .card.card-planes-beneficios.platino .card-header {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e16059+0,be2b23+100 */
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00afaf+0,048086+100 */
    background: #00afaf;
    /* Old browsers */
    background: -moz-linear-gradient(top, #00afaf 0%, #048086 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #00afaf 0%, #048086 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #00afaf 0%, #048086 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00afaf', endColorstr='#048086',GradientType=0 );
    /* IE6-9 */ }
    .planes-category .card.card-planes-beneficios.platino .card-header .icon:after {
      background: #048086; }
  .planes-category .card.card-planes-beneficios.platino .card-body .prima .sticker {
    background: #048086; }
  .planes-category .card.card-planes-beneficios.platino .card-body .prima .price {
    color: #048086; }
  .planes-category .card.card-planes-beneficios.platino .card-body .prima .btn {
    background: #048086; }
    .planes-category .card.card-planes-beneficios.platino .card-body .prima .btn:hover, .planes-category .card.card-planes-beneficios.platino .card-body .prima .btn:active, .planes-category .card.card-planes-beneficios.platino .card-body .prima .btn:focus {
      background: rgba(4, 128, 134, 0.54) !important; }
  .planes-category .card.card-planes-beneficios.color3 .card-header {
    background: #004D60; }
    .planes-category .card.card-planes-beneficios.color3 .card-header .icon:after {
      background: #004D60; }
  .planes-category .card.card-planes-beneficios.color3 .card-body .prima .sticker {
    background: #004D60; }

@media (max-width: 767.98px) {
  .planes-category .category {
    font-size: 2.4rem; }
  .planes-category .btn-solicita-orientacion {
    position: relative;
    right: 0;
    top: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
    z-index: 2;
    color: #048086;
    font-weight: bold; } }

/*
    Created on : 21-ago-2018, 16:16:31
    Author     : Rodri
*/
.section-haciendo-contacto {
  padding: 8.5rem 0 7rem 0;
  background: #EAEEF1; }
  .section-haciendo-contacto h1 {
    line-height: 6.5rem;
    color: #02576E;
    font-size: 6.5rem;
    margin-bottom: 2rem;
    font-weight: 500; }
    .section-haciendo-contacto h1 i {
      width: 12.7rem;
      height: 12.7rem;
      background: #03576F;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      float: left;
      margin-right: 2.5rem; }
      .section-haciendo-contacto h1 i svg {
        width: 5rem;
        height: 5rem;
        fill: #FFF; }
  .section-haciendo-contacto p {
    color: #01576D;
    font-size: 2.2rem; }
    .section-haciendo-contacto p strong {
      font-weight: 500; }
    .section-haciendo-contacto p.green {
      position: relative;
      background: #048086;
      color: #FFF;
      font-size: 1.8rem;
      /*text-align: center;*/
      margin: 0;
      padding: 2rem;
      font-weight: 400;
      border-top: #FABE00 solid 0.6rem; }
      .section-haciendo-contacto p.green strong {
        /*display: block;*/
        /*font-size: 2.2rem;*/
        font-weight: 500; }
      .section-haciendo-contacto p.green img {
        float: left;
        margin-right: 1.5rem;
        margin-top: 1rem; }
  @media (max-width: 767.98px) {
    .section-haciendo-contacto {
      padding: 3rem 0 3rem 0; }
      .section-haciendo-contacto h1 {
        display: flex;
        font-size: 2.5rem;
        align-items: center; }
        .section-haciendo-contacto h1 i {
          width: 5rem;
          height: 5rem;
          float: none;
          margin-right: 1.5rem; }
          .section-haciendo-contacto h1 i svg {
            width: 3rem;
            height: 3rem; }
      .section-haciendo-contacto p {
        font-size: 1.8rem; }
        .section-haciendo-contacto p.green {
          font-size: 1.6rem;
          /*padding: 5.6rem 2.5rem 3rem 2.5rem;*/
          font-weight: 400;
          text-align: center;
          /*border-top: #FABE00 solid 1.5rem;*/
          margin-top: 3rem; }
          .section-haciendo-contacto p.green img {
            display: block;
            margin: 0 auto 1rem auto;
            float: none; }
          .section-haciendo-contacto p.green strong {
            /*font-size: 1.8rem;*/ } }

.section-cuidandote {
  padding-top: 6rem; }
  .section-cuidandote .border-t {
    border-top: #004E61 dotted 1px;
    margin-top: 3rem;
    padding-top: 4rem; }
  .section-cuidandote h2 {
    color: #03576F;
    font-size: 6.5rem;
    font-weight: 600;
    line-height: 6.7rem;
    margin-bottom: 1rem; }
  .section-cuidandote h3 {
    color: #03576F;
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: 1rem; }
  .section-cuidandote p {
    font-size: 1.8rem;
    color: #333333;
    margin-bottom: 1rem; }
    .section-cuidandote p strong {
      color: #02576E;
      display: block;
      font-weight: 600; }
  .section-cuidandote ul {
    color: #333333;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-left: 2.5rem;
    list-style: none; }
    .section-cuidandote ul li {
      margin-bottom: 0.5rem;
      position: relative; }
      .section-cuidandote ul li:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border: #333333 solid 2px;
        border-radius: 100%;
        margin-left: -2.5rem;
        float: left;
        margin-top: 0.7rem;
        opacity: 0.54; }
  @media (max-width: 767.98px) {
    .section-cuidandote {
      padding: 3rem 0 4rem 0; }
      .section-cuidandote h2 {
        font-size: 2.5rem;
        line-height: 3rem; }
      .section-cuidandote h3 {
        font-size: 2.5rem; }
      .section-cuidandote p {
        font-size: 1.6rem; }
      .section-cuidandote .border-t {
        margin-top: 1.5rem;
        padding-top: 2rem; } }

.section-gold-service {
  padding: 8rem 0 8rem 0;
  position: relative;
  background: #048086;
  color: #FFF; }
  .section-gold-service:before {
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 50%;
    position: absolute;
    background: url("../img/planes-medicos/cuidado-personalizado/gold-service.jpg") center center no-repeat;
    background-size: cover; }
  .section-gold-service h2 {
    font-size: 6.5rem;
    font-weight: 600; }
  .section-gold-service h3 {
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: 1.5rem; }
  .section-gold-service ul {
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-left: 2.5rem;
    list-style: none; }
    .section-gold-service ul li {
      margin-bottom: 0.5rem;
      position: relative; }
      .section-gold-service ul li:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border: #FFF solid 2px;
        border-radius: 100%;
        margin-left: -2.5rem;
        float: left;
        margin-top: 0.7rem;
        opacity: 0.54; }
  @media (max-width: 767.98px) {
    .section-gold-service {
      padding: 0 0 3rem 0; }
      .section-gold-service:before {
        display: block;
        position: relative;
        left: 0;
        height: 20rem;
        margin-bottom: 1.5rem; }
      .section-gold-service h2 {
        font-size: 2.5rem; }
      .section-gold-service h3 {
        font-size: 2rem; } }

/*
    Author     : MAXI
*/
.page-cuidado-coordinado .main-content {
  position: relative; }
  .page-cuidado-coordinado .main-content:before {
    content: " ";
    width: 50%;
    height: 100%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+0,ffffff+50 */
    background: #eeeeee;
    /* Old browsers */
    background: -moz-linear-gradient(left, #eeeeee 0%, white 50%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #eeeeee 0%, white 50%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #eeeeee 0%, white 50%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#ffffff',GradientType=1 );
    /* IE6-9 */
    position: absolute;
    right: 0;
    top: 0; }

.page-cuidado-coordinado section {
  padding-top: 8.4rem;
  padding-bottom: 8.4rem; }

.page-cuidado-coordinado section.unidad-dorada {
  color: #66522D; }
  .page-cuidado-coordinado section.unidad-dorada h2 {
    font-size: 6.5rem;
    font-weight: 500; }
  .page-cuidado-coordinado section.unidad-dorada p.subtitle {
    font-size: 2rem; }
  .page-cuidado-coordinado section.unidad-dorada p.buenas-manos {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 3rem; }
  .page-cuidado-coordinado section.unidad-dorada .numeros-contacto {
    margin-top: 3rem; }
    .page-cuidado-coordinado section.unidad-dorada .numeros-contacto h3 {
      font-size: 3.4rem;
      margin-bottom: 3rem; }
      .page-cuidado-coordinado section.unidad-dorada .numeros-contacto h3 i {
        background: #66522D;
        padding: 0.8rem 1rem;
        margin-right: 2rem; }
  .page-cuidado-coordinado section.unidad-dorada .btn-localidades {
    padding: 0rem 3rem;
    display: inline-flex;
    color: #66522D;
    border-color: #66522D; }
    .page-cuidado-coordinado section.unidad-dorada .btn-localidades i {
      font-size: 2.5rem; }

.page-cuidado-coordinado section.camp {
  color: #003F44; }
  .page-cuidado-coordinado section.camp h2 {
    font-size: 6.5rem;
    font-weight: 500; }
  .page-cuidado-coordinado section.camp p.subtitle {
    font-size: 2rem;
    margin-bottom: 3rem; }
  .page-cuidado-coordinado section.camp .camp-ofrece {
    margin-top: 3rem; }
    .page-cuidado-coordinado section.camp .camp-ofrece h3 {
      font-size: 3.4rem;
      margin-bottom: 2.5rem;
      color: #003F44; }
  .page-cuidado-coordinado section.camp .directorio-camp {
    margin-top: 2rem; }
    .page-cuidado-coordinado section.camp .directorio-camp h4 {
      font-size: 2.4rem;
      color: #003F44;
      margin-bottom: 0.5rem; }
    .page-cuidado-coordinado section.camp .directorio-camp .btn {
      color: #003F44;
      padding: 0; }

.page-cuidado-coordinado p {
  font-size: 2rem; }
  .page-cuidado-coordinado p.small {
    font-size: 1.6rem; }

.page-cuidado-coordinado section.camp ul {
  font-size: 1.6rem; }
  .page-cuidado-coordinado section.camp ul li {
    padding-left: 2.5rem;
    position: relative; }
    .page-cuidado-coordinado section.camp ul li:before {
      position: absolute;
      left: 0;
      top: 0.6rem; }

@media (max-width: 767.98px) {
  .page-cuidado-coordinado .section-banner {
    padding-top: 0;
    padding-bottom: 1.5rem; }
  .page-cuidado-coordinado .main-content:before {
    display: none; }
  .page-cuidado-coordinado .unidad-dorada {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .page-cuidado-coordinado h2 {
    font-size: 2.5rem !important; }
  .page-cuidado-coordinado h3 {
    font-size: 2rem !important;
    margin-bottom: 2rem; }
    .page-cuidado-coordinado h3 i img {
      width: 2rem; }
  .page-cuidado-coordinado p.subtitle, .page-cuidado-coordinado p.buenas-manos, .page-cuidado-coordinado p {
    font-size: 1.8rem !important; }
  .page-cuidado-coordinado .camp {
    padding: 0 0 3rem 0; } }

/*
/*
    Author     : MAXI
*/
.page-proveedores-farmacias {
  padding-bottom: 0; }
  .page-proveedores-farmacias .document-item {
    display: block;
    margin-bottom: 0.3rem; }
  .page-proveedores-farmacias .busca-proveedores p {
    color: #048086;
    font-size: 1.8rem;
    margin-bottom: 2.5rem; }
  .page-proveedores-farmacias h1 {
    margin-top: 8.4rem;
    color: #048086;
    font-size: 6.4rem;
    font-weight: 500; }
  .page-proveedores-farmacias p.subtitle {
    font-size: 1.8rem;
    color: #333333;
    max-width: 57rem; }
  .page-proveedores-farmacias .home-cards {
    margin-top: 4rem;
    margin-bottom: 6rem;
    background: transparent; }
    .page-proveedores-farmacias .home-cards .cards-1 .card .card-body {
      height: 20rem; }
  .page-proveedores-farmacias #comofunciona h1 {
    font-size: 5.4rem !important; }
    .page-proveedores-farmacias #comofunciona h1.borde-top {
      border-top: 1px solid #DDD;
      padding-top: 2rem; }
  .page-proveedores-farmacias #comofunciona h3 {
    margin-bottom: 1rem !important; }
  .page-proveedores-farmacias #comofunciona .legal-eoc i {
    font-size: 3rem;
    margin-right: 1rem;
    color: #048086;
    position: relative;
    top: -1px; }
  .page-proveedores-farmacias .directorios-tabs {
    margin-bottom: 5rem; }
    .page-proveedores-farmacias .directorios-tabs .section-tabs {
      border-bottom: 1px solid #048086;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+51,eeeeee+100 */
      background: white;
      /* Old browsers */
      background: -moz-linear-gradient(top, white 51%, #eeeeee 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, white 51%, #eeeeee 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, white 51%, #eeeeee 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eeeeee',GradientType=0 );
      /* IE6-9 */ }
    .page-proveedores-farmacias .directorios-tabs ul.nav-tabs {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-end; }
      .page-proveedores-farmacias .directorios-tabs ul.nav-tabs li .nav-link {
        font-size: 1.6rem;
        line-height: 1.8rem;
        border: 1px solid transparent;
        color: #666666;
        font-weight: 500;
        border-radius: 0;
        text-align: center;
        padding-top: 2rem;
        padding-bottom: 2rem; }
        .page-proveedores-farmacias .directorios-tabs ul.nav-tabs li .nav-link.active {
          color: white;
          background-color: #048086;
          border-color: #048086 #048086 #048086;
          border-radius: 0; }
    .page-proveedores-farmacias .directorios-tabs .btn-open {
      margin-bottom: 1px;
      padding-left: 3rem;
      position: relative; }
      .page-proveedores-farmacias .directorios-tabs .btn-open:before {
        content: '';
        display: block;
        height: 1px;
        background: rgba(0, 63, 68, 0.12);
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -1px;
        z-index: 0; }
      .page-proveedores-farmacias .directorios-tabs .btn-open:hover {
        color: #FFF; }
      .page-proveedores-farmacias .directorios-tabs .btn-open .btn {
        text-align: left;
        min-width: 51rem;
        color: #FFF;
        position: relative; }
        .page-proveedores-farmacias .directorios-tabs .btn-open .btn svg {
          margin-top: 0.75rem;
          float: right;
          margin-left: 3rem;
          fill: #FFF;
          width: 2.4rem;
          height: 2.4rem; }
    .page-proveedores-farmacias .directorios-tabs #directoriosprov ul li {
      margin-bottom: 1.5rem; }
    .page-proveedores-farmacias .directorios-tabs .tab-content {
      padding-top: 8rem;
      padding-bottom: 5rem; }
      .page-proveedores-farmacias .directorios-tabs .tab-content h3 {
        color: #048086;
        font-size: 3.4rem;
        font-weight: 500;
        margin-bottom: 3rem;
        max-width: 75rem; }
      .page-proveedores-farmacias .directorios-tabs .tab-content h4 {
        color: #048086;
        font-size: 2.4rem;
        font-weight: 500;
        border-bottom: 1px dotted #94ABB6;
        margin-bottom: 3rem;
        padding-bottom: 1rem; }
      .page-proveedores-farmacias .directorios-tabs .tab-content ul.list-circle li a {
        font-size: 2rem;
        font-weight: 400;
        color: #048086; }
      .page-proveedores-farmacias .directorios-tabs .tab-content ul.list-circle li ol {
        margin-left: 6rem; }
        .page-proveedores-farmacias .directorios-tabs .tab-content ul.list-circle li ol li:before {
          display: none; }
  .page-proveedores-farmacias #collapseOne {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around; }
  .page-proveedores-farmacias .prod {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1; }
  .page-proveedores-farmacias #collapseOne .prod .col-lg-6 {
    flex: 0 0 70%;
    max-width: 70%; }
  @media (max-width: 767.98px) {
    .page-proveedores-farmacias #collapseOne {
      display: block; }
    .page-proveedores-farmacias h1 {
      margin-top: 3rem;
      font-size: 2.5rem; }
    .page-proveedores-farmacias .home-cards {
      margin-top: 3rem;
      margin-bottom: 1.5rem; }
    .page-proveedores-farmacias .directorios-tabs .btn-open .btn {
      min-width: 0; }
    .page-proveedores-farmacias .directorios-tabs .tab-content {
      padding-top: 3rem;
      padding-bottom: 3rem; }
      .page-proveedores-farmacias .directorios-tabs .tab-content h3 {
        font-size: 2rem;
        margin-bottom: 1.5rem; }
      .page-proveedores-farmacias .directorios-tabs .tab-content h4 {
        font-size: 1.8rem;
        margin-bottom: 1rem; }
      .page-proveedores-farmacias .directorios-tabs .tab-content ul.list-circle li a {
        font-size: 1.4rem; }
    .page-proveedores-farmacias .directorios-tabs ul.nav-tabs {
      flex-direction: column; }
      .page-proveedores-farmacias .directorios-tabs ul.nav-tabs li .nav-link {
        font-size: 1.4rem;
        padding: 1rem; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 21-ago-2018, 11:27:59
    Author     : Rodri
*/
.section-bienestar-integral-map {
  position: relative;
  padding: 0;
  background: #FFF; }
  .section-bienestar-integral-map:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 50%;
    background: #03576F;
    z-index: 0;
    margin-left: -10rem; }
  .section-bienestar-integral-map .beneficios-actividades {
    margin-top: 3rem; }
    .section-bienestar-integral-map .beneficios-actividades h3 {
      font-size: 2.8rem;
      margin-bottom: 1rem; }
  .section-bienestar-integral-map .map {
    position: absolute;
    top: 0;
    height: 42rem;
    right: 0;
    left: 50%;
    z-index: 2;
    margin-left: -10rem; }
    .section-bienestar-integral-map .map iframe {
      position: relative;
      width: 100%;
      height: 100%; }
    .section-bienestar-integral-map .map p {
      color: #01576D;
      font-size: 2.2rem;
      font-weight: 500;
      position: absolute;
      max-width: 23.6rem;
      background: white;
      top: 6rem;
      left: 0;
      z-index: 2;
      padding: 1.5rem 1.5rem 1.5rem 3rem; }
  .section-bienestar-integral-map h1 {
    color: #03576F;
    font-size: 6.5rem;
    font-weight: 500;
    margin-bottom: 1rem; }
  .section-bienestar-integral-map h2 {
    color: #02576E;
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: 1rem; }
  .section-bienestar-integral-map h3 {
    color: #02576E;
    font-size: 2.2rem;
    font-weight: 500;
    margin-bottom: 3rem; }
  .section-bienestar-integral-map ul.beneficios {
    color: #01576D;
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-left: 2.5rem;
    list-style: none; }
    .section-bienestar-integral-map ul.beneficios li {
      margin-bottom: 0rem;
      position: relative; }
      .section-bienestar-integral-map ul.beneficios li:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border: #03576F solid 2px;
        border-radius: 100%;
        margin-left: -2.5rem;
        float: left;
        margin-top: 0.95rem;
        opacity: 0.54; }
  .section-bienestar-integral-map .col-left {
    padding-top: 6rem;
    padding-bottom: 7rem; }
  .section-bienestar-integral-map .col-right {
    position: relative;
    padding-top: 42rem;
    background: #03576F; }
    .section-bienestar-integral-map .col-right:before {
      content: '';
      left: -1.5rem;
      right: 0;
      height: 1px;
      top: 47.1rem;
      background: #FFF;
      position: absolute; }
    .section-bienestar-integral-map .col-right .nav {
      margin-bottom: 2.5rem;
      flex-wrap: nowrap !important; }
      .section-bienestar-integral-map .col-right .nav .nav-item .nav-link {
        transition: all .2s ease-out;
        border-radius: 0;
        font-size: 1.9rem;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.74);
        display: inline-block;
        line-height: 5rem;
        padding: 0 1rem; }
        .section-bienestar-integral-map .col-right .nav .nav-item .nav-link.active {
          color: #EB992F; }
    .section-bienestar-integral-map .col-right p {
      color: #FFF;
      font-size: 1.6rem;
      font-weight: 400; }
      .section-bienestar-integral-map .col-right p .orange {
        color: #EB992F;
        font-size: 2.2rem;
        font-weight: 500;
        display: block;
        line-height: 2.6rem; }
    .section-bienestar-integral-map .col-right a svg {
      display: inline-block;
      vertical-align: middle;
      width: 2.4rem;
      height: 2.4rem;
      fill: #FFF;
      margin-right: 0.5rem;
      margin-top: -2px; }
    .section-bienestar-integral-map .col-right a:hover svg {
      fill: #212529; }
  .section-bienestar-integral-map .embed-responsive {
    display: none; }
  @media (max-width: 767.98px) {
    .section-bienestar-integral-map {
      min-height: 0;
      padding: 2rem 0 0 0; }
      .section-bienestar-integral-map:before {
        display: none; }
      .section-bienestar-integral-map .map {
        display: none; }
      .section-bienestar-integral-map .embed-responsive {
        display: block;
        margin-bottom: 1rem; }
      .section-bienestar-integral-map .col-left {
        padding-top: 0rem;
        padding-bottom: 1.5rem; }
      .section-bienestar-integral-map .col-right {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem; }
        .section-bienestar-integral-map .col-right:before {
          display: none; }
        .section-bienestar-integral-map .col-right .nav {
          flex-wrap: wrap !important; }
      .section-bienestar-integral-map h1 {
        font-size: 2.5rem;
        line-height: 3.2rem; }
      .section-bienestar-integral-map h2 {
        font-size: 2.2rem;
        margin-bottom: 1rem; }
      .section-bienestar-integral-map h3 {
        font-size: 1.8rem;
        margin-bottom: 2rem; }
      .section-bienestar-integral-map ul.beneficios {
        font-size: 1.8rem; }
        .section-bienestar-integral-map ul.beneficios li:before {
          margin-top: 0.7rem; } }

.swiper-container-a, .swiper-container-b {
  position: relative; }
  .swiper-container-a .swiper-pagination-a, .swiper-container-a .swiper-pagination-b, .swiper-container-b .swiper-pagination-a, .swiper-container-b .swiper-pagination-b {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2; }
    .swiper-container-a .swiper-pagination-a .swiper-pagination-bullet, .swiper-container-a .swiper-pagination-b .swiper-pagination-bullet, .swiper-container-b .swiper-pagination-a .swiper-pagination-bullet, .swiper-container-b .swiper-pagination-b .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: #FFF;
      opacity: 1; }
    .swiper-container-a .swiper-pagination-a .swiper-pagination-bullet-active, .swiper-container-a .swiper-pagination-b .swiper-pagination-bullet-active, .swiper-container-b .swiper-pagination-a .swiper-pagination-bullet-active, .swiper-container-b .swiper-pagination-b .swiper-pagination-bullet-active {
      background: #048086; }

.section-patitas-calientes {
  background: #048086;
  color: #FFF;
  padding: 6rem 0 7rem 0; }
  .section-patitas-calientes h2 {
    font-size: 6.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem; }
  .section-patitas-calientes p {
    font-size: 1.8rem; }
    .section-patitas-calientes p strong {
      font-size: 2rem;
      font-weight: 600;
      display: block; }
      .section-patitas-calientes p strong.tel {
        font-size: 3rem;
        line-height: 4rem; }
    .section-patitas-calientes p.line {
      border-top: #94ABB6 dotted 1px;
      padding-top: 1.5rem;
      margin-top: 2rem;
      font-size: 1.4rem; }
  .section-patitas-calientes h4 {
    margin-bottom: 1rem;
    font-size: 2.8rem;
    font-weight: 500; }
    .section-patitas-calientes h4 img {
      float: lefT;
      margin-right: 1.5rem;
      margin-top: 0.5rem; }
    .section-patitas-calientes h4 span {
      font-weight: 400;
      display: block; }
  .section-patitas-calientes ul.beneficios {
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-left: 2.5rem;
    list-style: none; }
    .section-patitas-calientes ul.beneficios li {
      margin-bottom: 0rem;
      position: relative; }
      .section-patitas-calientes ul.beneficios li:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border: #FFF solid 2px;
        border-radius: 100%;
        margin-left: -2.5rem;
        float: left;
        margin-top: 0.7rem;
        opacity: 0.54; }
  @media (max-width: 767.98px) {
    .section-patitas-calientes {
      padding: 1.5rem 0; }
      .section-patitas-calientes h2 {
        font-size: 2.8rem;
        font-weight: 600;
        margin-bottom: 1.5rem; }
      .section-patitas-calientes p {
        font-size: 1.6rem; }
      .section-patitas-calientes h4 {
        margin-bottom: 1rem;
        font-size: 2.2rem;
        font-weight: 500;
        overflow: hidden; }
      .section-patitas-calientes ul.beneficios {
        font-size: 1.8rem; }
        .section-patitas-calientes ul.beneficios li:before {
          margin-top: 0.7rem; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 13-ago-2018, 12:47:15
    Author     : PC03-MAXI
*/
.page-clinicas-especializadas section.que-es-vita-care {
  padding-top: 6rem;
  padding-bottom: 5rem;
  background: #F6F6F6; }
  .page-clinicas-especializadas section.que-es-vita-care h2 {
    color: #003F44;
    font-size: 3.4rem;
    font-weight: 700;
    margin-bottom: 2.3rem; }
  .page-clinicas-especializadas section.que-es-vita-care p {
    color: #003F44;
    font-size: 1.8rem;
    font-weight: 400; }
  .page-clinicas-especializadas section.que-es-vita-care:before {
    content: " ";
    width: calc(40% - 1.5rem);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url("../img/planes-medicos/clinicas-especializadas/vita-care.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  @media (max-width: 767.98px) {
    .page-clinicas-especializadas section.que-es-vita-care {
      padding: 0 0 3rem 0; }
      .page-clinicas-especializadas section.que-es-vita-care:before {
        position: relative;
        width: 100%;
        display: block;
        height: 20rem;
        margin-bottom: 2rem; }
      .page-clinicas-especializadas section.que-es-vita-care h2 {
        font-size: 2.5rem;
        margin-bottom: 1rem; }
      .page-clinicas-especializadas section.que-es-vita-care p {
        font-size: 1.6rem; } }

.page-clinicas-especializadas section.beneficios-vita {
  color: #048086;
  padding-top: 8rem;
  padding-bottom: 9rem; }
  .page-clinicas-especializadas section.beneficios-vita h2 {
    font-size: 3.4rem;
    font-weight: 500;
    color: #048086;
    margin-bottom: 2rem; }
  .page-clinicas-especializadas section.beneficios-vita h3 {
    color: #048086;
    font-size: 2.4rem;
    font-weight: 500;
    padding-bottom: 2rem;
    border-bottom: 1px dotted #94ABB6;
    margin-bottom: 2rem; }
  .page-clinicas-especializadas section.beneficios-vita ul li {
    position: relative;
    padding-left: 3rem;
    color: #048086; }
    .page-clinicas-especializadas section.beneficios-vita ul li:before {
      position: absolute;
      left: 0;
      top: -0.1rem; }
  .page-clinicas-especializadas section.beneficios-vita ul.list-circle li {
    padding-left: 2rem; }
    .page-clinicas-especializadas section.beneficios-vita ul.list-circle li:before {
      top: 0.6rem; }
  .page-clinicas-especializadas section.beneficios-vita .btn-share {
    color: #048086; }
  @media (max-width: 767.98px) {
    .page-clinicas-especializadas section.beneficios-vita {
      padding: 3rem 0; }
      .page-clinicas-especializadas section.beneficios-vita h2 {
        font-size: 2.5rem;
        margin-bottom: 1rem; } }

.page-clinicas-especializadas section.contacto {
  padding-top: 7rem;
  padding-bottom: 6rem;
  background: #048086;
  color: white; }
  .page-clinicas-especializadas section.contacto .icon-top {
    position: absolute;
    top: -7rem; }
  .page-clinicas-especializadas section.contacto h2 {
    font-size: 3.4rem;
    font-weight: 500;
    margin-bottom: 2.8rem; }
  .page-clinicas-especializadas section.contacto .telefonos .tel {
    padding: 2.3rem 0rem;
    border-right: 1px dotted #94ABB6; }
    .page-clinicas-especializadas section.contacto .telefonos .tel a {
      font-size: 2rem;
      font-weight: 700; }
    .page-clinicas-especializadas section.contacto .telefonos .tel .desc {
      font-size: 2rem;
      font-weight: 400;
      display: block; }
    .page-clinicas-especializadas section.contacto .telefonos .tel.no-border {
      border-right: none; }
  .page-clinicas-especializadas section.contacto .horario-localidades {
    margin-top: 2.2rem;
    margin-bottom: 2rem;
    border-bottom: 1px dotted #94ABB6;
    border-top: 1px dotted #94ABB6;
    padding: 2.3rem 0rem; }
    .page-clinicas-especializadas section.contacto .horario-localidades .horario {
      font-size: 2rem;
      font-weight: bold; }
    .page-clinicas-especializadas section.contacto .horario-localidades .btn-localidades {
      padding: 0rem 3rem;
      display: inline-flex; }
      .page-clinicas-especializadas section.contacto .horario-localidades .btn-localidades i {
        font-size: 2.5rem; }
  .page-clinicas-especializadas section.contacto:before {
    content: " ";
    width: calc(40% - 1.5rem);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url("../img/planes-medicos/clinicas-especializadas/contacto.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  @media (max-width: 767.98px) {
    .page-clinicas-especializadas section.contacto {
      padding: 0 0 3rem 0; }
      .page-clinicas-especializadas section.contacto:before {
        position: relative;
        width: 100%;
        display: block;
        height: 20rem;
        margin-bottom: 2rem; }
      .page-clinicas-especializadas section.contacto h2 {
        font-size: 2.5rem;
        margin-bottom: 1rem; } }

@media only screen and (min-width: 990px) and (max-width: 1575px) {
  .page-clinicas-especializadas section.que-es-vita-care:before {
    width: calc(34% - 1.5rem); }
  .page-clinicas-especializadas section.contacto:before {
    width: calc(34% - 1.5rem); } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 13-ago-2018, 12:47:15
    Author     : PC03-MAXI
*/
@media (max-width: 767.98px) {
  .page-detalle-plan .links-bar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 0rem;
    margin-top: 0rem;
    border-bottom: none;
    flex-direction: column; } }

.page-detalle-plan section.detalle-plan {
  /*        &.platino{
                    h1{
                        color: $salmon_3;
                    }
                    p.description{
                        color: $salmon_3;
                    }


                    .card-plan-caracteristicas{
                        .card-header{
                            background:$salmon_2;

                            .type{
                                color:$salmon_2;
                            }
                            .price{

                                color:$salmon_2;
                                &:before{
                                    color:$salmon_2;

                                }
                            }
                        }

                        .card-body{

                            .desc{
                                color:$salmon_2;
                            }

                        }

                        &.color2{

                            .card-header{
                                background:$salmon_4;


                                .price{

                                    color:$salmon_4;
                                    &:before{
                                        color:$salmon_4;

                                    }
                                }
                            }

                            .card-body{

                                .desc{
                                    color:$salmon_4;
                                }


                            }

                        }


                        &.color3{

                            .card-header{
                                background:$salmon_3;
                                .price{

                                    color:$salmon_3;
                                    &:before{
                                        color:$salmon_3;

                                    }
                                }
                            }

                            .card-body{

                                .desc{
                                    color:$salmon_3;
                                }


                            }

                        }

                    }
                }*/ }
  @media (max-width: 767.98px) {
    .page-detalle-plan section.detalle-plan p.category {
      font-size: 2.4rem !important; }
    .page-detalle-plan section.detalle-plan h1 {
      font-size: 3.5rem !important; }
    .page-detalle-plan section.detalle-plan p.description {
      margin-bottom: 2rem !important; } }
  .page-detalle-plan section.detalle-plan h1 {
    font-size: 6.5rem;
    font-weight: 500;
    margin-bottom: 0;
    color: #048086; }
  .page-detalle-plan section.detalle-plan p.description {
    color: #048086;
    font-size: 1.8rem;
    margin-bottom: 5rem; }
  .page-detalle-plan section.detalle-plan p.category {
    color: #859FAC;
    font-weight: 500;
    font-size: 3.4rem;
    margin-bottom: 0; }
  .page-detalle-plan section.detalle-plan p.extra-info {
    margin-top: 3.3rem;
    font-size: 1.6rem;
    color: #333333;
    margin-bottom: 0rem;
    width: 75%; }
    @media (max-width: 767.98px) {
      .page-detalle-plan section.detalle-plan p.extra-info {
        margin-top: 7rem; } }
    @media (max-width: 1199.98px) {
      .page-detalle-plan section.detalle-plan p.extra-info {
        width: auto; } }
  .page-detalle-plan section.detalle-plan .card-plan-caracteristicas {
    border: none;
    /*box-shadow: 0px 4px 14px 0 rgba(0, 0, 0, 0.1);*/
    height: 100%; }
    .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-header:first-child {
      border-radius: 1px 1px 0 0; }
    .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-header {
      position: relative;
      background: #003F44;
      color: white;
      height: 13.5rem;
      padding-top: 3.8rem;
      padding-left: 7rem;
      padding-right: 7rem;
      text-align: center; }
      .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-header h3 {
        font-size: 2.4rem;
        font-weight: 500; }
      .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-header .clase {
        font-weight: 400;
        font-size: 2rem; }
      .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-header .price {
        position: relative;
        font-size: 6.5rem;
        font-weight: 500;
        color: #003F44;
        position: absolute;
        bottom: -4rem;
        z-index: 2;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center; }
        .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-header .price:before {
          content: "$";
          color: #003F44;
          /* position: relative; */
          /* top: -11px; */
          font-size: 2rem;
          z-index: 1;
          position: absolute;
          top: 0;
          bottom: 0;
          left: -5rem;
          right: 0;
          margin: auto;
          height: 3rem; }
      .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-header:after {
        content: " ";
        background: #F4F4F4;
        border-radius: 50%;
        width: 20rem;
        height: 20rem;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -12rem;
        z-index: 0;
        display: none; }
      .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-header .type {
        font-size: 1.8rem;
        color: #003F44;
        border-top: 1px solid #003F44;
        position: absolute;
        bottom: -6.4rem;
        z-index: 1;
        left: 0;
        right: 0;
        margin: auto;
        display: inline-block;
        width: 15rem; }
      .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-header .icon {
        position: relative;
        margin-top: 2rem;
        height: 5rem; }
        .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-header .icon img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          top: 4.2rem;
          z-index: 2; }
        .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-header .icon:before {
          content: " ";
          background: white;
          border-radius: 50%;
          width: 10rem;
          height: 10rem;
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 9px; }
        .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-header .icon:after {
          content: " ";
          background: #003F44;
          border-radius: 50%;
          width: 6rem;
          height: 6rem;
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 25px; }
    .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body {
      padding-top: 2rem;
      padding-bottom: 0;
      position: relative;
      background: #F4F4F4; }
      .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .desc {
        font-size: 1.8rem;
        color: #003F44;
        margin-bottom: 2rem;
        text-align: center;
        font-weight: 500; }
      .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .beneficios-list {
        list-style: none;
        margin-bottom: 4rem; }
        .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .beneficios-list li {
          color: #048086;
          font-size: 1.8rem;
          font-weight: 500;
          display: flex;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          border-bottom: 1px #94ABB6 dotted; }
          .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .beneficios-list li:last-child {
            border-bottom: none; }
          .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .beneficios-list li .left {
            display: flex;
            flex-direction: column;
            width: 40%; }
            .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .beneficios-list li .left .value {
              padding-left: 0.5rem;
              font-size: 3.6rem; }
            .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .beneficios-list li .left .desc {
              margin-bottom: 0;
              font-size: 1.6rem;
              color: #048086;
              position: relative;
              text-align: left;
              top: -4px; }
            .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .beneficios-list li .left.without-value .desc {
              padding: 26px 0;
              top: 0; }
            .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .beneficios-list li .left.price .value:before {
              content: "$";
              position: relative;
              font-size: 1.6rem;
              top: -7px;
              left: -2px; }
            .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .beneficios-list li .left.percent .value:before {
              content: "%";
              position: relative;
              font-size: 1.6rem;
              top: -7px;
              left: -2px; }
          .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .beneficios-list li .right {
            text-align: right;
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: flex-end; }
            .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .beneficios-list li .right sup {
              top: .5rem; }
          .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .beneficios-list li.head {
            color: #003F44;
            font-size: 1.6rem;
            font-weight: 500; }
      .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .prima {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-items: center; }
        .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .prima .sticker {
          position: relative;
          left: -12px;
          background: #003F44;
          display: inline-block;
          padding-left: 1.8rem;
          padding-right: 1.8rem;
          padding-top: 0.1rem;
          padding-bottom: 0.1rem;
          font-size: 1.6rem;
          color: white;
          align-self: flex-start; }
        .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .prima .price {
          text-align: center;
          font-size: 6.5rem;
          font-weight: 500;
          color: #048086; }
          .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .prima .price small {
            font-size: 3.4rem;
            position: relative;
            top: -25px;
            left: -0.5rem; }
          .page-detalle-plan section.detalle-plan .card-plan-caracteristicas .card-body .prima .price:before {
            content: "$";
            font-size: 2rem;
            margin-right: 0.3rem;
            position: relative;
            top: -1rem; }
    .page-detalle-plan section.detalle-plan .card-plan-caracteristicas.color3 .card-header {
      background: #048086; }
      .page-detalle-plan section.detalle-plan .card-plan-caracteristicas.color3 .card-header .price {
        color: #048086; }
        .page-detalle-plan section.detalle-plan .card-plan-caracteristicas.color3 .card-header .price:before {
          color: #048086; }
    .page-detalle-plan section.detalle-plan .card-plan-caracteristicas.color3 .card-body .desc {
      color: #048086; }
    .page-detalle-plan section.detalle-plan .card-plan-caracteristicas.color3 .card-body .prima .sticker {
      background: #048086; }
    .page-detalle-plan section.detalle-plan .card-plan-caracteristicas.color2 .card-header {
      background: #004D60; }
      .page-detalle-plan section.detalle-plan .card-plan-caracteristicas.color2 .card-header .price {
        color: #004D60; }
        .page-detalle-plan section.detalle-plan .card-plan-caracteristicas.color2 .card-header .price:before {
          color: #004D60; }
    .page-detalle-plan section.detalle-plan .card-plan-caracteristicas.color2 .card-body .desc {
      color: #004D60; }
    .page-detalle-plan section.detalle-plan .card-plan-caracteristicas.color2 .card-body .prima .sticker {
      background: #004D60; }

.page-detalle-plan section.downloads {
  padding-top: 0; }
  .page-detalle-plan section.downloads h2 {
    color: #333333;
    font-size: 2.2rem;
    font-weight: 500; }
  .page-detalle-plan section.downloads .autorizado {
    color: #333333;
    font-size: 1.8rem; }
  .page-detalle-plan section.downloads .document-item {
    display: block;
    margin-bottom: 0.3rem; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 29-jun-2018, 15:40:30
    Author     : Rodri
*/
.section-banner {
  position: relative;
  background-size: cover;
  min-height: 48rem;
  display: flex;
  align-items: center;
  background-position: center center; }
  .section-banner .breadcrumb {
    position: absolute;
    top: 1rem;
    left: 2rem;
    background: transparent;
    color: #FFF; }
    .section-banner .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
      color: #FFF;
      font-size: 2rem;
      line-height: 1.5rem;
      content: "\0203a"; }
    .section-banner .breadcrumb .breadcrumb-item {
      line-height: 2rem;
      font-weight: 600;
      text-decoration: underline;
      font-size: 1.5rem; }
      .section-banner .breadcrumb .breadcrumb-item.active {
        font-weight: 400;
        text-decoration: none;
        color: #FFF; }
  .section-banner img.bg {
    display: none; }
  .section-banner:before {
    background: rgba(129, 156, 169, 0.94);
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    z-index: 0; }
  .section-banner h2 {
    color: white;
    font-size: 5.3rem;
    line-height: 5.6rem;
    font-weight: 500;
    margin-bottom: 1rem; }
  .section-banner h3 {
    color: rgba(255, 255, 255, 0.92);
    font-size: 3.2rem;
    font-weight: 400; }
    .section-banner h3.small {
      font-size: 2.4rem;
      font-weight: 400; }
    .section-banner h3 + p {
      padding-top: 1.5rem;
      margin-top: 1.5rem;
      border-top: #FFF dotted 1px; }
  .section-banner p {
    color: rgba(255, 255, 255, 0.92);
    font-size: 2.4rem;
    margin-bottom: 1.5rem;
    font-weight: 400;
    line-height: 3.4rem; }
  .section-banner .btn {
    line-height: 6rem;
    padding: 0 3rem;
    height: 6rem;
    margin-top: 1.5rem; }
  .section-banner .col-lg-6 {
    padding-right: 6rem; }
  @media (max-width: 767.98px) {
    .section-banner {
      display: block;
      padding-top: 0;
      min-height: 0;
      background: rgba(129, 156, 169, 0.94) !important; }
      .section-banner:before {
        display: none; }
      .section-banner .col-lg-6 {
        padding-right: 1.5rem; }
      .section-banner img.bg {
        display: block;
        max-width: 100%;
        height: auto;
        margin-bottom: 1.5rem; }
      .section-banner h2 {
        font-size: 3rem;
        line-height: 3.6rem; }
      .section-banner h3 {
        font-size: 2.2rem;
        line-height: 2.6rem; }
      .section-banner p {
        font-size: 2rem;
        line-height: 2.6rem; }
      .section-banner .btn {
        line-height: 4.2rem;
        padding: 0 1.5rem;
        height: 4.2rem;
        margin-top: 0; }
      .section-banner .breadcrumb {
        top: 0.5rem;
        left: 0.5rem; }
        .section-banner .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
          font-size: 1rem; }
        .section-banner .breadcrumb .breadcrumb-item {
          font-size: 1rem; } }

@media only screen and (min-width: 990px) and (max-width: 1575px) {
  .section-banner .breadcrumb {
    width: 48vw; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 29-jun-2018, 16:40:12
    Author     : Rodri
*/
.section-afiliados {
  padding-top: 7rem; }
  .section-afiliados .container {
    position: relative; }
  .section-afiliados .icon-top {
    position: absolute;
    top: -7rem;
    /* left: 5rem; */
    background: rgba(129, 156, 169, 0.2);
    padding: 1rem;
    z-index: 1000; }
  .section-afiliados .asistente {
    position: relative;
    top: auto;
    right: auto;
    height: 4.2rem;
    width: 23.5rem;
    border-radius: 4rem;
    display: flex;
    font-size: 1.8rem;
    font-weight: 500;
    margin-left: 1.5rem; }
    .section-afiliados .asistente:before {
      width: 3.3rem;
      height: 3.3rem;
      content: '';
      background: url(../img/asistente.svg);
      background-size: cover;
      margin-right: 1.3rem; }
    .section-afiliados .asistente:hover {
      color: white;
      background: rgba(235, 153, 47, 0.54); }
  .section-afiliados .berder-left {
    border-left: #94ABB6 dotted 1px; }
  .section-afiliados h2 {
    color: #047F85;
    font-size: 3.4rem;
    font-weight: 400;
    margin-bottom: 1rem; }
  .section-afiliados h3 {
    color: #047F85;
    font-size: 2rem;
    line-height: 2.6rem;
    font-weight: 400;
    margin-bottom: 2rem; }
  .section-afiliados h4 {
    color: #003F44;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem; }
  .section-afiliados p {
    color: #003F44;
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: 1.6rem; }
    .section-afiliados p strong {
      font-weight: bold;
      display: block; }
    .section-afiliados p.box-green {
      line-height: 2rem;
      margin-top: 2rem;
      background: #048086;
      color: #FFF;
      text-align: center;
      padding: 1.5rem; }
      .section-afiliados p.box-green strong {
        font-size: 2rem; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 30-jun-2018, 14:08:49
    Author     : Rodri
*/
.section-proveedores {
  padding-top: 10rem; }
  .section-proveedores .icon-top {
    position: absolute;
    top: -10rem; }
  .section-proveedores .container {
    position: relative; }
  .section-proveedores h2 {
    display: flex;
    align-items: center;
    color: #003F44;
    font-size: 3.4rem;
    font-weight: 500;
    margin-bottom: 1.5rem; }
    .section-proveedores h2 i {
      margin-right: 1.5rem;
      background: #003F44;
      width: 6.5rem;
      height: 5.7rem;
      display: flex;
      justify-content: center;
      align-items: center; }
    .section-proveedores h2.light {
      font-weight: 400;
      margin-bottom: 2rem; }
  .section-proveedores h4 {
    color: #003F44;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem; }
  .section-proveedores p {
    color: #003F44;
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 1.5rem; }
  .section-proveedores p.more-info {
    font-weight: 500;
    border-bottom: #94ABB6 dotted 1px;
    padding-bottom: 1rem; }
  .section-proveedores ul {
    margin: 1.5rem 0 1.5rem 0;
    padding: 0;
    list-style: none; }
    .section-proveedores ul a {
      color: #047F85;
      font-size: 1.8rem;
      text-decoration: underline;
      margin-bottom: 1.5rem;
      font-weight: 400; }
      .section-proveedores ul a.download {
        /*padding-left: 3.6rem;*/
        /*background: url(../img/icon-list-download.svg) left 5px no-repeat;*/
        align-items: flex-start;
        display: flex; }
        .section-proveedores ul a.download span {
          flex: 1; }
    .section-proveedores ul.open {
      padding-left: 3.6rem;
      background: url(../img/icon-list-open.svg) left 5px no-repeat; }
  .section-proveedores .bb-gray {
    border-bottom: #94ABB6 dotted 1px; }
  .section-proveedores .br-gray {
    border-right: #94ABB6 dotted 1px; }
  @media (max-width: 767.98px) {
    .section-proveedores {
      padding-top: 7rem; }
      .section-proveedores .icon-top {
        top: -7rem; }
      .section-proveedores h2 {
        font-size: 2.6rem; }
      .section-proveedores ul.download {
        padding-left: 0 !important; } }

.box-dudas {
  margin-top: 3rem;
  background: #048086; }
  .box-dudas .gray {
    border-left: #003F44 solid 3px;
    border-right: #003F44 solid 3px;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    background: #F4F4F4; }
    .box-dudas .gray .icon-zone {
      display: flex;
      align-items: center;
      margin-left: -7.5rem;
      border-right: #94ABB6 dotted 1px; }
    .box-dudas .gray i {
      background: #FFF;
      display: flex;
      width: 12rem;
      height: 12rem;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      margin-right: 1.5rem; }
    .box-dudas .gray h2 {
      color: #047F85;
      font-size: 3.4rem;
      line-height: 3.6rem;
      flex: 1; }
    .box-dudas .gray h4 {
      color: #003F44;
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem; }
  .box-dudas p {
    color: #003F44;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 400; }
    .box-dudas p.white {
      color: white;
      padding: 1rem; }
  @media (max-width: 767.98px) {
    .box-dudas .gray {
      border-top: #003F44 solid 3px; }
      .box-dudas .gray .icon-zone {
        border: 0;
        margin-left: 0;
        margin-bottom: 1rem; }
        .box-dudas .gray .icon-zone i {
          width: 6rem;
          height: 6rem;
          padding: 1.5rem; }
      .box-dudas .gray h2 {
        font-size: 2.4rem;
        line-height: 2.6rem; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 10-jul-2018, 14:32:17
    Author     : PC03-MAXI
*/
.documents-container {
  min-height: 100%;
  border-right: 1px dotted #94ABB6; }
  .documents-container.border-none {
    border: none; }
  .documents-container .title {
    font-size: 2.4rem;
    color: #003F44;
    font-weight: 500;
    margin-bottom: 1.4rem; }
  .documents-container .document-item {
    display: block;
    font-size: 1.8rem;
    font-weight: 500;
    color: #047F85;
    margin-bottom: 0.8rem; }
  .documents-container.white {
    padding-top: 1rem;
    padding-left: 3rem; }
    .documents-container.white .document-item {
      padding-bottom: 1rem;
      color: #FFF; }
      .documents-container.white .document-item img {
        width: 1.8rem;
        height: auto;
        margin-right: 1rem; }
  @media (max-width: 767.98px) {
    .documents-container .title {
      font-size: 1.6rem;
      margin-bottom: 1rem;
      margin-top: 0.5rem; }
    .documents-container .document-item {
      font-size: 1.6rem; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 04-jul-2018, 11:16:59
    Author     : PC03-MAXI
*/
.representantes-ventas,
.solicitud-afiliacion,
.oficinas-correo {
  background: #EEE; }
  .representantes-ventas .border-bottom,
  .solicitud-afiliacion .border-bottom,
  .oficinas-correo .border-bottom {
    padding-bottom: 5rem;
    border-bottom: #94ABB6 dotted 1px !important; }
  .representantes-ventas .oficina-item p, .representantes-ventas p,
  .solicitud-afiliacion .oficina-item p,
  .solicitud-afiliacion p,
  .oficinas-correo .oficina-item p,
  .oficinas-correo p {
    font-size: 1.6rem !important;
    line-height: 2rem !important; }

.section-requisitos {
  padding-top: 10rem;
  color: #048086; }
  .section-requisitos h2 {
    font-weight: 700;
    font-size: 4.6rem;
    color: #048086;
    margin-bottom: 2.5rem; }
  .section-requisitos h4 {
    font-weight: 500;
    font-size: 2rem;
    color: #048086; }
  .section-requisitos p {
    font-weight: 400;
    font-size: 2rem;
    color: #048086;
    margin-bottom: 0; }
  .section-requisitos .cumple {
    margin-bottom: 5rem; }
  .section-requisitos .requisitos-detail-row {
    border-bottom: #036A6F dotted 1px;
    padding-bottom: 5rem;
    margin-bottom: 5rem; }
    .section-requisitos .requisitos-detail-row .requisitos-list {
      margin-left: 2rem;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      justify-content: space-between; }
      .section-requisitos .requisitos-detail-row .requisitos-list li {
        padding-left: 1rem;
        padding-right: 1rem;
        color: #048086;
        font-size: 2rem; }
        .section-requisitos .requisitos-detail-row .requisitos-list li:before {
          font: normal normal normal 24px/1 "Material Design Icons";
          content: "\F5E0";
          margin-right: 1rem;
          color: #048086;
          float: left;
          margin-left: -3.5rem;
          margin-top: 0.25rem; }
    .section-requisitos .requisitos-detail-row .requisitos-especificos {
      color: #003F44; }
      .section-requisitos .requisitos-detail-row .requisitos-especificos h3 {
        font-size: 2.4rem;
        font-weight: 500;
        color: #003F44;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;
        border-bottom: #036A6F dotted 1px; }
      .section-requisitos .requisitos-detail-row .requisitos-especificos h4 {
        font-size: 2rem;
        font-weight: 700;
        color: #003F44;
        margin-bottom: 1.7rem; }
      .section-requisitos .requisitos-detail-row .requisitos-especificos p {
        color: #003F44; }
  @media (max-width: 767.98px) {
    .section-requisitos {
      padding-top: 7rem; }
      .section-requisitos h2 {
        font-size: 3.2rem;
        margin-bottom: 1.5rem; }
      .section-requisitos h4, .section-requisitos p {
        font-size: 1.8rem; }
      .section-requisitos .cumple {
        margin-bottom: 2rem; }
      .section-requisitos .requisitos-detail-row {
        margin-bottom: 1rem; }
        .section-requisitos .requisitos-detail-row .requisitos-list li {
          font-size: 1.6rem; }
          .section-requisitos .requisitos-detail-row .requisitos-list li:before {
            margin-top: 0; }
        .section-requisitos .requisitos-detail-row .requisitos-especificos {
          padding-top: 2rem; }
          .section-requisitos .requisitos-detail-row .requisitos-especificos h3 {
            font-size: 1.8rem;
            margin-bottom: 1rem; }
          .section-requisitos .requisitos-detail-row .requisitos-especificos h4 {
            margin-bottom: 1rem; }
          .section-requisitos .requisitos-detail-row .requisitos-especificos p {
            margin-bottom: 1.5rem; } }

section.representantes-ventas .tip {
  margin-bottom: 5.8rem; }

section.representantes-ventas .representante-item {
  border-right: 1px dotted  #94ABB6; }
  section.representantes-ventas .representante-item h4 {
    font-size: 2.4rem;
    font-weight: 500;
    color: #003F44;
    margin-bottom: 1.2rem; }
  section.representantes-ventas .representante-item p {
    font-size: 1.8rem;
    color: #003F44;
    font-weight: 500; }
    section.representantes-ventas .representante-item p a.tel {
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 2rem; }

section.representantes-ventas .horario p {
  font-weight: 500; }

@media (max-width: 767.98px) {
  section.representantes-ventas .tip {
    margin-bottom: 2rem; }
  section.representantes-ventas .representante-item h4 {
    font-size: 1.8rem;
    margin-bottom: 1rem; }
  section.representantes-ventas .representante-item p {
    font-size: 1.6rem;
    margin-bottom: 1.5rem; }
    section.representantes-ventas .representante-item p a {
      font-size: 1.6rem; } }

section.solicitud-afiliacion .tip {
  margin-bottom: 3rem; }

section.solicitud-afiliacion .help {
  margin-bottom: 5rem;
  font-size: 2rem;
  color: #003F44; }

@media (max-width: 767.98px) {
  section.solicitud-afiliacion .tip {
    margin-bottom: 2rem; }
  section.solicitud-afiliacion .help {
    margin-bottom: 2rem;
    font-size: 1.6rem; } }

section.oficinas-correo .tip {
  margin-bottom: 5.8rem; }

section.oficinas-correo p {
  font-size: 1.8rem;
  color: #003F44; }

section.oficinas-correo .oficina-item {
  border-right: 1px dotted #94ABB6; }
  section.oficinas-correo .oficina-item h4 {
    font-size: 2.4rem;
    font-weight: 500;
    color: #003F44;
    margin-bottom: 1.2rem; }
  section.oficinas-correo .oficina-item p {
    font-size: 1.8rem;
    color: #003F44; }

@media (max-width: 767.98px) {
  section.oficinas-correo .tip {
    margin-bottom: 2rem; }
  section.oficinas-correo .oficina-item h4 {
    font-size: 1.8rem;
    margin-bottom: 1rem; }
  section.oficinas-correo .oficina-item p {
    font-size: 1.6rem;
    margin-bottom: 1.5rem; }
    section.oficinas-correo .oficina-item p a {
      font-size: 1.6rem; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 03-jul-2018, 16:37:54
    Author     : PC03-MAXI
*/
section.telefonos-horarios {
  color: white;
  padding-top: 10rem;
  padding-bottom: 3.2rem;
  background: #048086; }
  section.telefonos-horarios .bg-green:before {
    display: none; }
  section.telefonos-horarios .icon-top {
    position: absolute;
    top: -10rem; }
  section.telefonos-horarios .bg-green:before {
    background: #048086;
    content: ' ';
    position: absolute;
    top: -10rem;
    bottom: 0;
    left: 0;
    z-index: 0;
    width: 100vw;
    height: calc(100% + 10rem + 3.2rem);
    z-index: -1; }
  section.telefonos-horarios:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    background: url("../img/contacto/contact-info.jpg");
    background-position: center;
    width: 35%;
    height: 100%;
    z-index: 0; }
  section.telefonos-horarios .help {
    color: white;
    margin-bottom: 3rem;
    font-size: 2.4rem; }
    section.telefonos-horarios .help span {
      font-weight: 500; }
    section.telefonos-horarios .help strong {
      font-weight: bold; }
  section.telefonos-horarios .tel-column h4 {
    font-size: 2.4rem;
    margin-bottom: 2rem; }
  section.telefonos-horarios .tel-column ul {
    list-style: none; }
    section.telefonos-horarios .tel-column ul li {
      color: white;
      margin-bottom: 1.4rem;
      font-size: 1.8rem;
      line-height: 125%; }
      section.telefonos-horarios .tel-column ul li a {
        font-weight: 700;
        display: block; }
  section.telefonos-horarios .horario-share-cont {
    border-top: dotted 1px #94ABB6;
    border-bottom: dotted 1px #94ABB6;
    padding: 0;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 3.3rem; }
  section.telefonos-horarios .horario {
    display: flex; }
    section.telefonos-horarios .horario i {
      font-size: 3rem;
      margin-right: 1.2rem; }
    section.telefonos-horarios .horario p {
      font-size: 2rem;
      font-weight: 700;
      color: #FFF;
      line-height: 120%; }
  section.telefonos-horarios .share p {
    font-size: 2rem;
    margin-bottom: 0;
    color: #FFF; }
  section.telefonos-horarios .share .social-net {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  section.telefonos-horarios .asistente-chat {
    display: flex; }
    section.telefonos-horarios .asistente-chat .chat-with-us {
      font-size: 1.8rem;
      font-weight: 700;
      color: #FFF;
      flex: 1; }
  @media (max-width: 767.98px) {
    section.telefonos-horarios {
      padding-top: 2rem;
      padding-bottom: 2rem; }
      section.telefonos-horarios .help {
        margin-bottom: 1.5rem;
        font-size: 1.6rem; }
      section.telefonos-horarios .tel-column h4 {
        font-size: 1.8rem;
        margin-bottom: 1rem; }
      section.telefonos-horarios .tel-column ul li {
        color: white;
        margin-bottom: 1.4rem;
        font-size: 1.6rem; }
      section.telefonos-horarios .horario-share-cont {
        padding: 0 1.5rem;
        margin-left: 0;
        margin-right: 0;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem; }
      section.telefonos-horarios .horario, section.telefonos-horarios .asistente-chat {
        flex-direction: column; } }

section.form-contacto {
  background: #003F44;
  color: white;
  padding-top: 8rem;
  padding-bottom: 7rem; }
  section.form-contacto .help-block-error {
    font-size: 1.2rem;
    color: #ff5252;
    padding-top: 0.5rem; }
  section.form-contacto .help {
    font-size: 2.4rem;
    color: white;
    margin-bottom: 3rem; }
    section.form-contacto .help span {
      font-weight: 500; }
  section.form-contacto .form-group label {
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase; }
  section.form-contacto .form-group.required:not(.field-contactform-newsletter) label:after {
    margin-left: 1rem;
    content: "*"; }
  section.form-contacto .body-label {
    font-size: 1.6rem;
    font-weight: 500; }
  section.form-contacto .md-checkbox label {
    text-transform: none; }
  section.form-contacto .field-contactform-verifycode {
    background: rgba(255, 255, 255, 0.3);
    padding: 1.3rem; }
  section.form-contacto #contactform-plan {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  @media (max-width: 767.98px) {
    section.form-contacto {
      padding-top: 2rem;
      padding-bottom: 2rem; }
      section.form-contacto .help {
        margin-bottom: 2rem;
        font-size: 1.6rem; } }

section.contacto-links {
  background-color: rgba(129, 156, 169, 0.1); }

section.no-afiliado {
  background-color: #048086;
  color: white;
  padding-top: 12rem;
  padding-bottom: 8rem; }
  section.no-afiliado p {
    color: white; }
    section.no-afiliado p .large {
      font-size: 2rem; }
  section.no-afiliado .help {
    font-size: 3.4rem;
    color: white;
    margin-bottom: 5rem; }
    section.no-afiliado .help span {
      font-weight: 700; }
    section.no-afiliado .help strong {
      font-weight: bold; }
  section.no-afiliado .tel-row {
    padding-bottom: 3.9rem;
    border-bottom: 1px #F2F5F6 dotted;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: 3.2rem; }
    section.no-afiliado .tel-row .tel-column {
      padding-left: 2.5rem; }
      section.no-afiliado .tel-row .tel-column.bordered {
        border-right: 1px #F2F5F6 dotted; }
      section.no-afiliado .tel-row .tel-column h4 {
        font-size: 2.4rem;
        font-weight: 500;
        margin-bottom: 1.3rem; }
      section.no-afiliado .tel-row .tel-column ul {
        list-style: none; }
        section.no-afiliado .tel-row .tel-column ul li {
          color: white;
          margin-bottom: 2rem;
          font-size: 1.8rem; }
          section.no-afiliado .tel-row .tel-column ul li a {
            font-weight: 500;
            display: block; }
  section.no-afiliado .horario-column {
    border-right: 1px #F2F5F6 dotted;
    border-left: 1px #F2F5F6 dotted;
    padding-top: 1.6rem;
    padding-bottom: 3.2rem;
    padding-left: 1.5rem; }
    section.no-afiliado .horario-column h4 {
      font-size: 2rem;
      font-weight: 700; }
  section.no-afiliado .orientacion-column p {
    font-size: 1.8rem; }
  @media (max-width: 767.98px) {
    section.no-afiliado {
      padding-top: 2rem;
      padding-bottom: 2rem; }
      section.no-afiliado .help {
        margin-bottom: 1.5rem;
        font-size: 1.6rem; }
      section.no-afiliado .tel-column {
        padding-left: 1.5rem !important; }
        section.no-afiliado .tel-column.bordered.last {
          border-right: 0; }
        section.no-afiliado .tel-column h4 {
          font-size: 1.8rem;
          margin-bottom: 1rem; }
        section.no-afiliado .tel-column ul li {
          color: white;
          margin-bottom: 1.4rem;
          font-size: 1.6rem; }
      section.no-afiliado .horario-column {
        padding-left: 1.5rem;
        border-right: none; } }

section.oficinas-map {
  position: relative;
  overflow: hidden;
  padding: 0;
  background-image: url(../img/mapbg.jpg); }
  section.oficinas-map:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #004D60;
    mix-blend-mode: multiply;
    z-index: 0; }
  section.oficinas-map .container {
    z-index: 1;
    position: relative;
    padding-top: 10rem;
    padding-bottom: 7.2rem; }
  section.oficinas-map h2 {
    color: white;
    font-size: 3.4rem;
    margin-bottom: 1.6rem; }
  section.oficinas-map .help {
    font-size: 2.2rem;
    line-height: 2.8rem;
    color: rgba(255, 255, 255, 0.94); }
  section.oficinas-map .help-block {
    font-size: 1.6rem;
    padding-top: 0.5rem;
    color: white; }
  section.oficinas-map .fields-column {
    display: flex;
    align-items: flex-start; }
    section.oficinas-map .fields-column .form-group {
      width: 25.5rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      margin-bottom: 0; }
      section.oficinas-map .fields-column .form-group select {
        color: #003F44;
        font-size: 1.6rem;
        font-weight: 500;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #FFF url(../img/icon_select.jpg) right center no-repeat;
        height: 4.2rem !important;
        border: 0;
        padding: 0 1.5rem; }
    section.oficinas-map .fields-column label {
      font-size: 1.6rem;
      color: white;
      font-weight: 500; }
    section.oficinas-map .fields-column .btn-open-map {
      padding-right: 3.5rem;
      padding-left: 3.5rem;
      margin-left: 2rem;
      flex: 1;
      margin-top: 2.9rem; }
      section.oficinas-map .fields-column .btn-open-map:focus {
        color: #212529 !important; }
  section.oficinas-map #map-contact {
    width: 100%;
    height: 64rem; }
  @media (max-width: 767.98px) {
    section.oficinas-map {
      padding-top: 0;
      padding-bottom: 0; }
      section.oficinas-map #map-contact {
        /*height: 20rem;*/ }
      section.oficinas-map .container {
        padding-top: 2.5rem;
        padding-bottom: 3rem; }
      section.oficinas-map .fields-column {
        flex-direction: column;
        margin-top: 2rem; }
        section.oficinas-map .fields-column .form-group {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 0; }
        section.oficinas-map .fields-column .btn-open-map {
          padding-right: 3.5rem;
          padding-left: 3.5rem;
          margin-left: 0;
          margin-top: 2.9rem;
          display: block;
          width: 100%; } }

.infowindow {
  /*    & > div:nth-child(1) {
            & > div:nth-child(4) {
                background: #EEE !important;
            }
        }*/ }
  .infowindow #infowindow {
    padding-top: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 1.6rem !important; }
    .infowindow #infowindow ul {
      margin: 1rem 0 0 0;
      padding: 0;
      list-style: none; }
      .infowindow #infowindow ul li {
        border-top: rgba(255, 87, 34, 0.12) dotted 1px;
        padding: 1rem 0; }

@media only screen and (min-width: 990px) and (max-width: 1575px) {
  section.form-contacto .form-group label {
    font-size: 1.2rem; }
  section.telefonos-horarios:after {
    width: 28%; } }

@media (max-width: 991.98px) {
  section.telefonos-horarios:after {
    display: none; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 23-jul-2018, 16:19:01
    Author     : Rodri
*/
.section-detalle-noticias h2 {
  color: #003F44;
  font-size: 3.4rem;
  margin-bottom: 1.5rem;
  line-height: 120%; }
  .section-detalle-noticias h2 img {
    display: inline-block;
    margin-right: 1rem; }

.section-detalle-noticias h3 {
  color: #666;
  font-size: 2rem;
  margin-bottom: 2rem; }

.section-detalle-noticias h5 {
  color: #666;
  font-size: 1.8rem;
  margin-bottom: 1rem; }

.section-detalle-noticias img.noticia-img {
  width: 100%;
  margin-bottom: 2.5rem; }

.section-detalle-noticias p {
  color: #666;
  font-size: 1.6rem; }
  .section-detalle-noticias p + h3 {
    margin-top: 2rem; }

.section-detalle-noticias .swiper-pagination {
  bottom: 25px; }
  .section-detalle-noticias .swiper-pagination .swiper-pagination-bullet-active {
    background: #003F44; }

.section-detalle-noticias .platino-logo {
  max-width: 18rem; }

.section-detalle-noticias .noticia-item .cont, .section-detalle-noticias .noticia-item .btn {
  display: none; }

.section-detalle-noticias .noticia-item .title {
  color: #047F85;
  font-size: 2.4rem; }

.section-detalle-noticias .aside {
  position: relative; }
  .section-detalle-noticias .aside h3 {
    margin-bottom: 2rem; }
  .section-detalle-noticias .aside .noticia-item {
    margin-bottom: 3rem;
    padding-bottom: 1.5rem;
    border-bottom: #94ABB6 solid 1px; }
    .section-detalle-noticias .aside .noticia-item .title {
      color: #003F44;
      font-size: 1.6rem; }
  .section-detalle-noticias .aside form {
    margin-bottom: 3.5rem;
    display: flex;
    width: 100%; }
    .section-detalle-noticias .aside form input {
      flex: 1;
      background: #EEEEEE;
      border: 0;
      height: 5.4rem;
      padding: 0 1.5rem;
      color: #003F44;
      font-size: 1.6rem;
      font-weight: 500; }
    .section-detalle-noticias .aside form button {
      cursor: pointer;
      text-align: center;
      background: #003F44;
      border: 0;
      display: block;
      width: 5.2rem;
      height: 5.4rem;
      margin: 0;
      transition: all .2s ease-out;
      float: none; }
      .section-detalle-noticias .aside form button:hover {
        background: #048086; }
      .section-detalle-noticias .aside form button i {
        color: #FFF;
        font-size: 2.5rem;
        display: inline-block;
        line-height: 5.4rem; }
  .section-detalle-noticias .aside a.back {
    font-size: 1.8rem;
    right: 0;
    color: #666;
    margin-bottom: 2.5rem;
    text-align: right;
    line-height: 3.3rem;
    margin-left: auto;
    display: block;
    width: 13rem; }
    .section-detalle-noticias .aside a.back i {
      font-size: 2.8rem;
      position: relative;
      top: 4px; }

.section-detalle-noticias .download {
  list-style: none;
  padding: 0;
  margin: 0; }
  .section-detalle-noticias .download li {
    font-size: 1.6rem;
    line-height: 2rem;
    color: #003F44;
    margin-bottom: 1rem;
    background: url("../img/icons/ul_referencias.png") top left no-repeat;
    padding-left: 3.7rem; }

.section-detalle-noticias .top-bar-cont {
  position: relative; }
  .section-detalle-noticias .top-bar-cont .share {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: flex-end; }
    .section-detalle-noticias .top-bar-cont .share p {
      margin: 0 1.5rem 0 0; }

.section-detalle-noticias .comments {
  margin-top: 4rem; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 24-jul-2018, 16:43:13
    Author     : Rodri
*/
.section-glosario {
  padding-top: 0; }
  .section-glosario .form {
    z-index: 2;
    position: relative;
    margin-bottom: -6rem; }
  .section-glosario form {
    margin-bottom: 0;
    display: flex;
    width: 100%; }
    .section-glosario form input {
      flex: 1;
      background: #EEEEEE;
      border: 0;
      height: 5.4rem;
      padding: 0 1.5rem;
      color: #003F44;
      font-size: 1.6rem;
      font-weight: 500; }
    .section-glosario form a.btn-close-search {
      position: absolute;
      color: #444;
      font-size: 2.5rem;
      top: 0.9rem;
      right: 8rem; }
    .section-glosario form button {
      cursor: pointer;
      text-align: center;
      background: #003F44;
      border: 0;
      display: block;
      width: 5.2rem;
      height: 5.4rem;
      margin: 0;
      transition: all .2s ease-out;
      float: none; }
      .section-glosario form button:hover {
        background: #048086; }
      .section-glosario form button i {
        color: #FFF;
        font-size: 2.5rem;
        display: inline-block;
        line-height: 5.4rem; }
  .section-glosario .title {
    background: #003F44; }
  .section-glosario .nav-glosario {
    margin-bottom: 6rem;
    background: #048086; }
    .section-glosario .nav-glosario ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .section-glosario .nav-glosario ul li {
        display: inline-block;
        color: rgba(255, 255, 255, 0.92);
        font-size: 2.5rem;
        line-height: 6.1rem;
        margin-right: 1rem; }
  .section-glosario h1 {
    color: rgba(255, 255, 255, 0.92);
    font-size: 2.5rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    line-height: 6.1rem; }
  .section-glosario dl {
    padding-left: 4rem; }
  .section-glosario dt {
    color: #047F85;
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 1.5rem; }
  .section-glosario dd {
    color: #333333;
    font-size: 1.6rem;
    margin-bottom: 2rem; }
  .section-glosario .cintillo {
    position: relative;
    display: block;
    font-size: 5rem;
    color: rgba(255, 255, 255, 0.92);
    width: 6rem;
    height: 6rem;
    text-align: center;
    line-height: 5.6rem;
    margin-bottom: 3.5rem;
    background: #048086; }
    .section-glosario .cintillo:before {
      content: '';
      width: 1px;
      height: 14rem;
      background: #048086;
      left: 14px;
      top: -2.5rem;
      z-index: -1;
      position: absolute; }
    .section-glosario .cintillo:after {
      content: '';
      position: absolute;
      top: -14px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-top: 7px solid transparent;
      border-right: 7px solid #036A6F;
      border-bottom: 7px solid #036A6F; }
  @media (max-width: 767.98px) {
    .section-glosario .nav-glosario ul li {
      font-size: 2rem;
      line-height: 5rem; }
    .section-glosario .form {
      margin-bottom: 4rem; }
    .section-glosario .nav-glosario {
      margin-bottom: 2rem; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 25-jul-2018, 16:01:43
    Author     : Rodri
*/
.section-faqs .header-border {
  border-bottom: #048086 solid 1px;
  padding-bottom: 3rem;
  margin-bottom: 3rem; }

.section-faqs h1 {
  color: rgba(0, 63, 68, 0.92);
  font-size: 2.5rem;
  line-height: 6.1rem; }

.section-faqs h2 {
  color: #047F85;
  font-size: 6rem;
  font-weight: 300;
  margin-bottom: 3rem;
  margin-left: 2rem;
  text-transform: capitalize; }

.section-faqs form {
  margin-bottom: 0;
  display: flex;
  width: 100%; }
  .section-faqs form input {
    flex: 1;
    background: #EEEEEE;
    border: 0;
    height: 5.4rem;
    padding: 0 1.5rem;
    color: #003F44;
    font-size: 1.6rem;
    font-weight: 500; }
  .section-faqs form button {
    cursor: pointer;
    text-align: center;
    background: #003F44;
    border: 0;
    display: block;
    width: 5.2rem;
    height: 5.4rem;
    margin: 0;
    transition: all .2s ease-out;
    float: none; }
    .section-faqs form button:hover {
      background: #048086; }
    .section-faqs form button i {
      color: #FFF;
      font-size: 2.5rem;
      display: inline-block;
      line-height: 5.4rem; }

.section-faqs .nav-aside {
  display: block;
  margin: 2rem 0 0 0;
  padding: 0;
  list-style: none;
  font-size: 2rem; }
  .section-faqs .nav-aside li {
    display: block; }
  .section-faqs .nav-aside a {
    display: block;
    color: #047F85;
    margin-bottom: 2rem;
    font-weight: 400;
    text-transform: capitalize; }
    .section-faqs .nav-aside a.active {
      font-weight: bold; }

.section-faqs .card {
  position: relative;
  margin-bottom: 1rem;
  border: 0; }
  .section-faqs .card .card-header {
    background: transparent;
    border: 0;
    padding: 0; }
    .section-faqs .card .card-header button {
      white-space: normal;
      height: auto;
      text-transform: none;
      padding: 0;
      color: #047F85;
      font-weight: bold;
      font-size: 2rem; }
      .section-faqs .card .card-header button.collapsed {
        color: #666666;
        font-weight: 400; }
        .section-faqs .card .card-header button.collapsed:before {
          content: '-'; }
        .section-faqs .card .card-header button.collapsed:after {
          opacity: 0; }
      .section-faqs .card .card-header button:before {
        content: '+';
        margin-right: 1rem; }
      .section-faqs .card .card-header button:after {
        transition: all .2s ease-out;
        opacity: 1;
        content: '+';
        position: absolute;
        background: #047F85;
        height: 100%;
        width: 0.5rem;
        left: -1.8rem;
        top: 0; }
      .section-faqs .card .card-header button:hover, .section-faqs .card .card-header button:focus {
        color: #047F85;
        text-decoration: none; }
  .section-faqs .card .card-body {
    padding: 1.5rem 2.75rem;
    color: #666666;
    font-size: 1.8rem; }

.section-faqs .border-left {
  border-left: #444444 solid 1px !important; }

@media (max-width: 767.98px) {
  .section-faqs h2 {
    font-size: 4rem;
    margin-bottom: 2rem;
    margin-left: 0;
    border-top: #CCC dotted 1px;
    padding-top: 1.5rem; }
  .section-faqs .card .card-header button {
    display: flex;
    line-height: 2.4rem;
    text-align: left; }
  .section-faqs .card .card-body {
    padding: 1.5rem 2.3rem !important;
    font-size: 1.6rem; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 13-ago-2018, 16:05:51
    Author     : Rodri
*/
.section-medicare-video {
  min-height: 52.2rem;
  position: relative;
  padding: 6rem 0 6rem 0;
  /*    .embed-responsive {
            display: none;
        }*/ }
  .section-medicare-video .video-zone {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 50%;
    margin-left: -17rem; }
    .section-medicare-video .video-zone iframe {
      position: relative;
      width: 100%;
      height: 100%; }
  .section-medicare-video .text-video {
    padding-top: 4rem;
    /*padding-right: 17rem;*/ }
  .section-medicare-video h1 {
    color: #048086;
    font-size: 6.5rem;
    line-height: 7rem;
    font-weight: 500;
    margin: 0 0 1.5rem 0; }
  .section-medicare-video h3 {
    border-top: #048086 dotted 1px;
    padding-top: 2rem;
    margin: 0;
    color: #048086;
    font-size: 2.2rem;
    font-weight: 500; }
  @media (max-width: 767.98px) {
    .section-medicare-video {
      min-height: 0;
      padding: 8rem 0 2rem 0;
      /*	.embed-responsive {
                    display: block;
                }*/ }
      .section-medicare-video .video-zone {
        display: none; }
      .section-medicare-video .text-video {
        padding-top: 0;
        /*padding-right: 0;*/ }
      .section-medicare-video h1 {
        font-size: 3.5rem;
        line-height: 4rem; }
      .section-medicare-video h3 {
        font-size: 2rem;
        margin-bottom: 2rem; } }

.section-medicare-cubierta {
  background: #F8F8F8;
  padding: 7rem 0; }
  .section-medicare-cubierta h2 {
    color: #03576F;
    font-size: 3.4rem;
    font-weight: bold;
    margin-bottom: 4rem; }
  .section-medicare-cubierta .item-cubirta {
    margin-bottom: 3rem;
    align-items: center;
    display: flex; }
    .section-medicare-cubierta .item-cubirta p {
      flex: 1;
      color: #003F44;
      font-size: 2rem;
      font-weight: 400;
      margin: 0 0 0 1.5rem; }
      .section-medicare-cubierta .item-cubirta p strong {
        display: block;
        font-size: 2.2rem;
        line-height: 2.2rem; }
  .section-medicare-cubierta .cols > div:nth-child(1) {
    border-right: #94ABB6 dotted 1px; }
  .section-medicare-cubierta .cols > div:nth-child(2) {
    padding-left: 7rem; }
  @media (max-width: 767.98px) {
    .section-medicare-cubierta {
      padding: 2rem 0 0 0; }
      .section-medicare-cubierta h2 {
        font-size: 2.5rem;
        margin-bottom: 1.5rem; }
      .section-medicare-cubierta .cols > div:nth-child(2) {
        padding-left: 1.5rem; } }

.section-medicare-articles article {
  margin-bottom: 3rem; }
  .section-medicare-articles article figure {
    border-top: #FFF solid 1px; }
  .section-medicare-articles article h2.green {
    padding: 1.5rem;
    background: #048086;
    color: #FFF;
    line-height: 2.4rem;
    font-size: 2.4rem;
    font-weight: 400; }
    .section-medicare-articles article h2.green strong {
      font-size: 4rem;
      line-height: 4rem;
      font-weight: 500;
      display: block; }
  .section-medicare-articles article h2.line {
    color: #048086;
    font-size: 3.4rem;
    border-bottom: #94ABB6 dotted 1px;
    padding-bottom: 1.5rem;
    padding-top: 0.5rem;
    margin-bottom: 1.5rem;
    font-weight: 400; }
    .section-medicare-articles article h2.line strong {
      font-weight: 500; }
  .section-medicare-articles article p {
    color: #333333;
    font-size: 2rem; }
    .section-medicare-articles article p strong {
      color: #047F85;
      font-weight: bold; }
  .section-medicare-articles article ul {
    color: #333333;
    font-size: 2rem;
    margin-left: 2rem;
    margin-bottom: 1.5rem; }

.section-medicare-elegible {
  position: relative;
  padding: 0; }
  .section-medicare-elegible:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    background: url("../img/planes-medicos/sobre-medicare/elegibilidad.jpg") center center no-repeat;
    background-size: cover;
    z-index: 0; }
  .section-medicare-elegible:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    background: #F8F8F8;
    z-index: 0; }
  .section-medicare-elegible .container {
    position: relative;
    z-index: 1; }
    .section-medicare-elegible .container .col-12 {
      background: #F8F8F8;
      padding: 4rem 5rem; }
  .section-medicare-elegible h2 {
    color: #047F85;
    font-size: 3.4rem;
    font-weight: 500;
    margin-bottom: 2rem; }
  .section-medicare-elegible h3 {
    color: #047F85;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 2rem; }
  .section-medicare-elegible ul {
    color: #003F44;
    font-size: 1.8rem;
    margin-bottom: 2rem;
    margin-left: 2rem; }
    .section-medicare-elegible ul li {
      margin-bottom: 1rem; }
  @media (max-width: 767.98px) {
    .section-medicare-elegible .container .col-12 {
      background: #F8F8F8;
      padding: 1.5rem; }
    .section-medicare-elegible h2 {
      font-size: 2.5rem; }
    .section-medicare-elegible .btn {
      font-size: 1.2rem; } }

section.programa-mejoramiento-calidad-box-heart {
  padding-top: 0rem; }
  section.programa-mejoramiento-calidad-box-heart .box-heart {
    position: absolute;
    top: 0rem;
    left: 1.5rem;
    background: url("../img/icons/box-heart.png");
    width: 65px;
    height: 57px; }
  section.programa-mejoramiento-calidad-box-heart .container {
    position: relative; }

section.programa-mejoramiento-calidad-info .box-txt {
  margin-bottom: 3rem; }
  section.programa-mejoramiento-calidad-info .box-txt.border-t {
    border-top: #94ABB6 dotted 1px;
    padding-top: 3rem; }
  section.programa-mejoramiento-calidad-info .box-txt h2 {
    color: #047F85;
    margin-bottom: 2rem;
    font-size: 3.4rem;
    font-weight: 500; }
  section.programa-mejoramiento-calidad-info .box-txt p {
    color: #003F44;
    font-size: 1.8rem;
    line-height: 140%;
    font-weight: 400; }
  section.programa-mejoramiento-calidad-info .box-txt.quote {
    padding: 5.5rem 3rem;
    display: flex;
    align-items: center;
    margin-bottom: 2rem; }
    section.programa-mejoramiento-calidad-info .box-txt.quote:before {
      content: " ";
      background: url("../img/icons/quote-open.png");
      width: 50px;
      height: 40px;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 1.5rem; }
    section.programa-mejoramiento-calidad-info .box-txt.quote:after {
      content: " ";
      background: url("../img/icons/quote-close.png");
      width: 50px;
      height: 40px;
      background-size: cover;
      position: absolute;
      bottom: 1rem;
      right: 1.5rem; }
    section.programa-mejoramiento-calidad-info .box-txt.quote p {
      color: #047F85;
      font-style: italic;
      font-weight: 500;
      line-height: 2.2rem;
      font-size: 1.8rem;
      text-align: center; }

section.programa-mejoramiento-calidad-objetivos {
  background: #F4F7F8; }
  section.programa-mejoramiento-calidad-objetivos h2 {
    color: #047F85;
    margin-bottom: 3rem;
    font-size: 3.4rem;
    font-weight: 500; }
  section.programa-mejoramiento-calidad-objetivos ul li {
    list-style: none;
    position: relative;
    padding-left: 2rem;
    font-size: 1.8rem;
    font-weight: 400;
    color: #333;
    line-height: 140%;
    margin-bottom: 1.5rem; }
    section.programa-mejoramiento-calidad-objetivos ul li:before {
      content: " ";
      border: 2px solid #048086;
      border-radius: 10rem;
      width: 10px;
      height: 10px;
      position: absolute;
      left: 0;
      top: 0.8rem; }
  section.programa-mejoramiento-calidad-objetivos p.c-green {
    line-height: 120%;
    color: #003F44; }
  section.programa-mejoramiento-calidad-objetivos .qip {
    margin-top: 6rem; }
    section.programa-mejoramiento-calidad-objetivos .qip .box-qip {
      position: relative;
      background: #FFF;
      padding: 3.5rem 0rem;
      display: flex;
      align-items: center; }
      section.programa-mejoramiento-calidad-objetivos .qip .box-qip:before {
        content: " ";
        position: absolute;
        left: 0;
        width: 87px;
        height: 100%;
        background: #048086;
        border-right: 4px solid #003F44; }
      section.programa-mejoramiento-calidad-objetivos .qip .box-qip:after {
        content: " ";
        position: absolute;
        right: 0;
        width: 32px;
        height: 100%;
        background: #048086;
        border-left: 4px solid #003F44; }
      section.programa-mejoramiento-calidad-objetivos .qip .box-qip .happy-face {
        background: #FFF url("../img/icons/happy-face.png") no-repeat;
        background-size: 40px;
        background-position: center;
        border-radius: 10rem;
        position: absolute;
        left: 3.8rem;
        top: 2.25rem;
        width: 9.7rem;
        height: 9.7rem; }
      section.programa-mejoramiento-calidad-objetivos .qip .box-qip p {
        width: 80%;
        margin: 0px auto;
        font-size: 2rem;
        font-weight: 400;
        line-height: 120%;
        color: #003F44;
        position: relative;
        left: 4rem; }

section.programa-mejoramiento-calidad-responsabilidad h3 {
  font-size: 3.4rem;
  font-weight: 500;
  color: #047F85;
  margin-bottom: 1.5rem; }

section.programa-mejoramiento-calidad-responsabilidad p {
  font-size: 1.8rem;
  line-height: 125%;
  font-weight: 400;
  color: #003F44; }

@media (max-width: 767.98px) {
  section.programa-mejoramiento-calidad-info {
    padding-top: 6rem; }
    section.programa-mejoramiento-calidad-info .box-txt.quote p {
      font-size: 2rem; }
    section.programa-mejoramiento-calidad-info .box-txt h2 {
      font-size: 2.4rem; }
    section.programa-mejoramiento-calidad-info .box-txt p {
      font-size: 1.6rem; }
  section.programa-mejoramiento-calidad-objetivos h2 {
    font-size: 2.4rem; }
  section.programa-mejoramiento-calidad-objetivos p, section.programa-mejoramiento-calidad-objetivos ul li {
    font-size: 1.6rem; }
  section.programa-mejoramiento-calidad-objetivos .qip {
    margin-top: 3rem; }
    section.programa-mejoramiento-calidad-objetivos .qip .box-qip {
      justify-content: center; }
      section.programa-mejoramiento-calidad-objetivos .qip .box-qip:before {
        content: " ";
        position: absolute;
        top: 0;
        width: 100%;
        height: 87px;
        background: #048086;
        border-bottom: 4px solid #003F44;
        border-right: none; }
      section.programa-mejoramiento-calidad-objetivos .qip .box-qip:after {
        content: " ";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 32px;
        background: #048086;
        border-top: 4px solid #003F44;
        border-left: none; }
      section.programa-mejoramiento-calidad-objetivos .qip .box-qip .happy-face {
        left: auto;
        top: 3.5rem; }
      section.programa-mejoramiento-calidad-objetivos .qip .box-qip p {
        width: 100%;
        margin: 0px auto;
        font-size: 2rem;
        line-height: 120%;
        color: #003F44;
        position: relative;
        padding: 9rem 1.5rem 2rem 1.5rem;
        top: 0rem;
        left: 0rem; }
  section.programa-mejoramiento-calidad-responsabilidad {
    padding: 3rem 0rem; } }

section.cubierta-fuera-de-red-box-file {
  padding-top: 0rem; }
  section.cubierta-fuera-de-red-box-file .box-file {
    position: absolute;
    top: 0rem;
    left: 1.5rem;
    background: url("../img/icons/box-file.png");
    width: 65px;
    height: 57px; }
  section.cubierta-fuera-de-red-box-file .container {
    position: relative; }

section.cubierta-fuera-de-red {
  padding-top: 6rem;
  padding-bottom: 10rem; }
  section.cubierta-fuera-de-red h1 {
    font-size: 6rem;
    font-weight: 500;
    color: #047F85;
    margin-bottom: 2rem; }
  section.cubierta-fuera-de-red p {
    margin-bottom: 2rem;
    font-size: 1.8rem;
    color: #333; }
    section.cubierta-fuera-de-red p a {
      color: #047F85;
      text-decoration: underline; }

@media (max-width: 767.98px) {
  section.cubierta-fuera-de-red h1 {
    font-size: 3rem; }
  section.cubierta-fuera-de-red p {
    font-size: 1.6rem; } }

section.politica-de-transicion-suplido {
  padding-top: 6rem;
  padding-bottom: 10rem; }
  section.politica-de-transicion-suplido h1 {
    font-size: 6.4rem;
    font-weight: 500;
    color: #048086;
    margin-bottom: 1rem;
    line-height: 100%; }
    section.politica-de-transicion-suplido h1 span {
      font-size: 2.4rem;
      display: block;
      opacity: .92;
      line-height: 100%;
      margin-bottom: 1rem;
      margin-top: 1rem; }
  section.politica-de-transicion-suplido p {
    font-size: 1.8rem;
    color: #333333;
    font-weight: 400; }

section.politica-de-transicion-info {
  background: #F4F7F8; }
  section.politica-de-transicion-info .document-item {
    display: block;
    margin-bottom: 0.3rem; }
  section.politica-de-transicion-info h3 {
    color: #047F85;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 4rem; }
  section.politica-de-transicion-info #list-pdf h3 {
    margin-bottom: 1rem;
    font-size: 2.4rem !important; }
  section.politica-de-transicion-info p.subtitle {
    color: #333;
    font-size: 1.8rem;
    line-height: 140%;
    margin-bottom: 3rem;
    font-weight: 500; }
  section.politica-de-transicion-info ul li {
    list-style: none;
    position: relative;
    padding-left: 2rem;
    margin-bottom: 4.5rem; }
    section.politica-de-transicion-info ul li:before {
      content: " ";
      border: 2px solid #048086;
      border-radius: 10rem;
      width: 10px;
      height: 10px;
      position: absolute;
      left: 0;
      top: 0.8rem; }
    section.politica-de-transicion-info ul li p {
      font-size: 1.8rem;
      color: #333;
      line-height: 140%;
      font-weight: 400;
      margin-bottom: 3rem; }
  section.politica-de-transicion-info .lee-aqui p {
    font-size: 2rem;
    line-height: 100%;
    color: #333;
    font-weight: 400; }
  section.politica-de-transicion-info .lee-aqui a {
    color: #047F85;
    text-decoration: underline;
    display: flex;
    align-items: center;
    font-size: 1.8rem; }
    section.politica-de-transicion-info .lee-aqui a svg {
      margin-right: 1rem; }

@media (max-width: 767.98px) {
  section.politica-de-transicion-suplido {
    padding-top: 3rem;
    padding-bottom: 4rem; }
    section.politica-de-transicion-suplido h1 {
      font-size: 3rem; }
      section.politica-de-transicion-suplido h1 span {
        font-size: 1.8rem; }
    section.politica-de-transicion-suplido p {
      font-size: 1.6rem; }
    section.politica-de-transicion-suplido svg {
      margin: 0px auto;
      display: block;
      margin-bottom: 3rem;
      width: 40%;
      height: auto; }
  section.politica-de-transicion-info ul li p {
    font-size: 1.6rem;
    margin-bottom: 1.5rem; }
  section.politica-de-transicion-info a.btn {
    width: 100%;
    margin-bottom: 1.5rem; }
  section.politica-de-transicion-info .lee-aqui p {
    font-size: 1.8rem;
    margin-top: 2rem; } }

section.determinacion-organizacionales-box-file {
  padding-top: 0rem; }
  section.determinacion-organizacionales-box-file .box-file {
    position: absolute;
    top: 0rem;
    left: 1.5rem;
    background: url("../img/icons/box-file.png");
    width: 65px;
    height: 57px; }
  section.determinacion-organizacionales-box-file .container {
    position: relative; }

section.determinacion-organizacionales-info .nav-tabs.years {
  justify-content: flex-end;
  border-left: #FFF solid 1px;
  margin-left: -1px;
  border-bottom: #03576F solid 1px;
  margin-bottom: 3rem;
  margin-top: -3.5rem; }
  section.determinacion-organizacionales-info .nav-tabs.years .nav-link {
    border-color: transparent;
    color: #03576F;
    font-size: 1.4rem;
    font-weight: 600;
    padding: 0 1.5rem;
    line-height: 4rem; }
    section.determinacion-organizacionales-info .nav-tabs.years .nav-link.active {
      background: #03576F;
      color: #FFF; }

section.determinacion-organizacionales-info .form-medicamentos h2 {
  color: #026A6E;
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 1.5rem; }

section.determinacion-organizacionales-info .form-medicamentos ul {
  color: #333333;
  font-size: 2rem;
  padding-left: 2rem;
  margin-bottom: 2rem; }

section.determinacion-organizacionales-info .form-medicamentos p {
  color: #016A6D;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.4rem; }

section.determinacion-organizacionales-info h1 {
  font-size: 6rem;
  font-weight: 500;
  color: #047F85;
  margin-bottom: 2rem; }

section.determinacion-organizacionales-info p.subtitle {
  font-size: 2rem;
  font-weight: 500;
  line-height: 140%;
  color: #047F85; }

section.determinacion-organizacionales-info .tab-content {
  border-left: 1px solid #444444;
  padding: 3.5rem 0rem; }
  section.determinacion-organizacionales-info .tab-content .card {
    border: none; }
    section.determinacion-organizacionales-info .tab-content .card .card-header {
      border: none;
      padding: 0;
      background: none; }
      section.determinacion-organizacionales-info .tab-content .card .card-header button {
        transition: all .2s ease-out;
        font-size: 2rem;
        font-weight: 400;
        color: #FFF;
        padding: 0rem 1.25rem 0rem 2rem;
        text-align: left;
        background: #047F85;
        line-height: 5.5rem;
        height: auto;
        width: 100%; }
        section.determinacion-organizacionales-info .tab-content .card .card-header button:hover, section.determinacion-organizacionales-info .tab-content .card .card-header button:focus {
          text-decoration: none; }
        section.determinacion-organizacionales-info .tab-content .card .card-header button:after {
          content: ' ';
          position: absolute;
          background: #047F85;
          transition: all .2s ease-out;
          opacity: 1;
          height: 100%;
          width: 0.5rem;
          left: -0.3rem;
          top: 0; }
        section.determinacion-organizacionales-info .tab-content .card .card-header button.collapsed {
          color: #047F85;
          background: none;
          /*font-size: 2rem;*/
          font-weight: 400;
          /*padding-left: 1.5rem;*/
          line-height: 110%;
          margin-bottom: 1rem; }
          section.determinacion-organizacionales-info .tab-content .card .card-header button.collapsed:before {
            content: "+";
            font-size: 3rem;
            font-weight: 300;
            position: relative;
            top: 0.3rem;
            margin-right: 0.8rem; }
          section.determinacion-organizacionales-info .tab-content .card .card-header button.collapsed:after {
            height: 0; }
    section.determinacion-organizacionales-info .tab-content .card .card-body {
      padding: 7rem 1.5rem 3rem 6rem; }
      section.determinacion-organizacionales-info .tab-content .card .card-body.formulario {
        padding: 3rem 1.5rem; }
      section.determinacion-organizacionales-info .tab-content .card .card-body h2 {
        margin: 0 0 0.5rem 0;
        color: #047F85;
        font-size: 2rem;
        font-weight: 500; }
      section.determinacion-organizacionales-info .tab-content .card .card-body h5 {
        font-size: 1.6rem;
        font-weight: 300;
        margin-top: 1rem; }
      section.determinacion-organizacionales-info .tab-content .card .card-body ul {
        color: #333333;
        font-size: 1.8rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-left: 8rem;
        margin-bottom: 3rem;
        list-style: none; }
        section.determinacion-organizacionales-info .tab-content .card .card-body ul li {
          margin-bottom: 0rem;
          position: relative; }
          section.determinacion-organizacionales-info .tab-content .card .card-body ul li:before {
            content: '';
            display: inline-block;
            width: 1rem;
            height: 1rem;
            border: #047F85 solid 2px;
            border-radius: 100%;
            margin-left: -2.5rem;
            float: left;
            margin-top: 0.7rem;
            opacity: 0.54; }
      section.determinacion-organizacionales-info .tab-content .card .card-body h3 {
        font-size: 2.4rem;
        font-weight: 500;
        color: #003F44;
        margin-bottom: 1.5rem; }
      section.determinacion-organizacionales-info .tab-content .card .card-body h4 {
        font-size: 2rem;
        font-weight: 500;
        color: #047F85;
        margin-bottom: 3rem; }
      section.determinacion-organizacionales-info .tab-content .card .card-body p {
        font-size: 1.8rem;
        color: #333;
        line-height: 140%; }
        section.determinacion-organizacionales-info .tab-content .card .card-body p a {
          color: #047F85;
          text-decoration: underline; }
      section.determinacion-organizacionales-info .tab-content .card .card-body .faxs {
        margin-top: 7rem; }
        section.determinacion-organizacionales-info .tab-content .card .card-body .faxs .fax-item {
          border-left: 1px dotted #94ABB6;
          padding-top: 1rem;
          padding-bottom: 5rem;
          padding-left: 2.5rem; }
          section.determinacion-organizacionales-info .tab-content .card .card-body .faxs .fax-item:first-child {
            border: none; }
        section.determinacion-organizacionales-info .tab-content .card .card-body .faxs p {
          font-size: 2rem;
          color: #003F44;
          line-height: 120%; }

@media (max-width: 767.98px) {
  .section-banner h2 {
    font-size: 2.2rem;
    line-height: 140%; }
  section.determinacion-organizacionales-info {
    padding-top: 6rem; }
    section.determinacion-organizacionales-info h1 {
      font-size: 3rem;
      line-height: 120%; }
    section.determinacion-organizacionales-info p.subtitle {
      font-size: 1.8rem;
      line-height: 120%; }
    section.determinacion-organizacionales-info .tab-content .card .card-header button {
      white-space: normal;
      padding: 1rem 1.25rem 1rem 1.25rem;
      line-height: 130%; }
    section.determinacion-organizacionales-info .tab-content .card .card-body {
      padding: 3rem 1.5rem 3rem 1.5rem; }
      section.determinacion-organizacionales-info .tab-content .card .card-body p {
        font-size: 1.6rem; }
      section.determinacion-organizacionales-info .tab-content .card .card-body h4 {
        margin-bottom: 1.5rem;
        margin-top: 4rem; }
      section.determinacion-organizacionales-info .tab-content .card .card-body .faxs {
        margin-top: 3rem; }
        section.determinacion-organizacionales-info .tab-content .card .card-body .faxs .fax-item {
          padding-bottom: 1.5rem; }
          section.determinacion-organizacionales-info .tab-content .card .card-body .faxs .fax-item h3 {
            font-size: 2.2rem;
            margin-bottom: 0.5rem; }
          section.determinacion-organizacionales-info .tab-content .card .card-body .faxs .fax-item p {
            font-size: 1.8rem; } }

section.programa-manejo-de-terapia-box-heart {
  padding-top: 0rem; }
  section.programa-manejo-de-terapia-box-heart .box-heart {
    position: absolute;
    top: 0rem;
    left: 1.5rem;
    background: url("../img/icons/box-heart.png");
    width: 65px;
    height: 57px; }
  section.programa-manejo-de-terapia-box-heart .container {
    position: relative; }

section.programa-manejo-de-terapia-main p {
  font-size: 1.6rem;
  color: #047F85;
  margin-bottom: 2rem; }
  section.programa-manejo-de-terapia-main p.p-20 {
    font-size: 2rem; }
  section.programa-manejo-de-terapia-main p span.fw-medium {
    font-weight: 500; }

section.programa-manejo-de-terapia-criterios {
  background: #F6F6F6; }
  section.programa-manejo-de-terapia-criterios h2 {
    font-size: 3.4rem;
    font-weight: 500;
    color: #047F85; }
  section.programa-manejo-de-terapia-criterios p.subtitle {
    font-size: 1.8rem;
    line-height: 140%;
    color: #003F44; }
  section.programa-manejo-de-terapia-criterios .lists ul {
    padding-left: 1.5rem; }
  section.programa-manejo-de-terapia-criterios .lists > ul.no-list-style > li:before {
    display: none; }
  section.programa-manejo-de-terapia-criterios .lists > ul.no-list-style > li > ul > li > ul > li:first-child {
    margin-top: 0rem; }
  section.programa-manejo-de-terapia-criterios .lists > ul li {
    font-size: 1.6rem;
    color: #333;
    line-height: 140%;
    list-style: none !important;
    position: relative; }
  section.programa-manejo-de-terapia-criterios .lists > ul > li {
    margin-bottom: 3.5rem;
    padding-left: 0.5rem; }
    section.programa-manejo-de-terapia-criterios .lists > ul > li:before {
      content: " ";
      border: 2px solid #048086;
      border-radius: 10rem;
      width: 10px;
      height: 10px;
      position: absolute;
      left: -1.5rem;
      top: 0.5rem;
      opacity: .54; }
    section.programa-manejo-de-terapia-criterios .lists > ul > li > ul {
      margin-bottom: 2rem;
      margin-top: 0.5rem; }
      section.programa-manejo-de-terapia-criterios .lists > ul > li > ul > li {
        line-height: 160%; }
        section.programa-manejo-de-terapia-criterios .lists > ul > li > ul > li > ul {
          padding-left: 4.5rem;
          margin-bottom: 2rem;
          margin-top: 0.5rem; }
          section.programa-manejo-de-terapia-criterios .lists > ul > li > ul > li > ul > li {
            list-style: disc !important;
            margin-top: 0.5rem; }
  section.programa-manejo-de-terapia-criterios .lists .legal {
    font-size: 2rem;
    font-weight: 500;
    color: #047F85;
    line-height: 120%;
    position: relative;
    padding-left: 4rem;
    padding-right: 2rem; }
    section.programa-manejo-de-terapia-criterios .lists .legal i.mdi {
      font-size: 3.5rem;
      position: absolute;
      top: 0rem;
      left: 0rem; }
  section.programa-manejo-de-terapia-criterios .lists a.share {
    color: #003F44;
    font-size: 1.6rem;
    font-weight: 500;
    display: flex;
    margin-top: 2rem;
    align-items: center;
    text-align: right;
    justify-content: flex-end; }
    section.programa-manejo-de-terapia-criterios .lists a.share i {
      font-size: 3rem;
      margin-right: 1rem; }

section.programa-manejo-de-terapia-servicios {
  background: #FFF; }
  section.programa-manejo-de-terapia-servicios a.descarga {
    font-size: 1.8rem;
    font-weight: 500;
    color: #003F44;
    display: flex;
    align-items: center;
    line-height: 130%; }
    section.programa-manejo-de-terapia-servicios a.descarga svg {
      margin-right: 1rem;
      display: inline-block; }
  section.programa-manejo-de-terapia-servicios .para-mas-info h4 {
    font-size: 2rem;
    font-weight: 500;
    color: #003F44;
    padding-bottom: 0.5rem;
    border-bottom: 1px dotted #94ABB6;
    margin-bottom: 1rem; }
  section.programa-manejo-de-terapia-servicios .para-mas-info p {
    font-size: 1.8rem;
    color: #003F44; }

@media (max-width: 767.98px) {
  section.programa-manejo-de-terapia-main {
    padding-top: 6rem; }
  section.programa-manejo-de-terapia-criterios {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
    section.programa-manejo-de-terapia-criterios h2 {
      font-size: 3rem;
      margin-bottom: 1.5rem; }
    section.programa-manejo-de-terapia-criterios .lists .legal {
      padding-right: 0rem; }
  section.programa-manejo-de-terapia-criterios {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
    section.programa-manejo-de-terapia-criterios h2 {
      font-size: 3rem;
      margin-bottom: 1.5rem; }
    section.programa-manejo-de-terapia-criterios a.descarga {
      margin-top: 2.5rem; }
  section.programa-manejo-de-terapia-servicios .lists ul li {
    margin-bottom: 1.5rem; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 21-ago-2018, 11:27:59
    Author     : Rodri
*/
.section-bienestar-integral-map {
  position: relative;
  padding: 0;
  background: #FFF; }
  .section-bienestar-integral-map:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 50%;
    background: #03576F;
    z-index: 0;
    margin-left: -10rem; }
  .section-bienestar-integral-map .beneficios-actividades {
    margin-top: 3rem; }
    .section-bienestar-integral-map .beneficios-actividades h3 {
      font-size: 2.8rem;
      margin-bottom: 1rem; }
  .section-bienestar-integral-map .map {
    position: absolute;
    top: 0;
    height: 42rem;
    right: 0;
    left: 50%;
    z-index: 2;
    margin-left: -10rem; }
    .section-bienestar-integral-map .map iframe {
      position: relative;
      width: 100%;
      height: 100%; }
    .section-bienestar-integral-map .map p {
      color: #01576D;
      font-size: 2.2rem;
      font-weight: 500;
      position: absolute;
      max-width: 23.6rem;
      background: white;
      top: 6rem;
      left: 0;
      z-index: 2;
      padding: 1.5rem 1.5rem 1.5rem 3rem; }
  .section-bienestar-integral-map h1 {
    color: #03576F;
    font-size: 6.5rem;
    font-weight: 500;
    margin-bottom: 1rem; }
  .section-bienestar-integral-map h2 {
    color: #02576E;
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: 1rem; }
  .section-bienestar-integral-map h3 {
    color: #02576E;
    font-size: 2.2rem;
    font-weight: 500;
    margin-bottom: 3rem; }
  .section-bienestar-integral-map ul.beneficios {
    color: #01576D;
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-left: 2.5rem;
    list-style: none; }
    .section-bienestar-integral-map ul.beneficios li {
      margin-bottom: 0rem;
      position: relative; }
      .section-bienestar-integral-map ul.beneficios li:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border: #03576F solid 2px;
        border-radius: 100%;
        margin-left: -2.5rem;
        float: left;
        margin-top: 0.95rem;
        opacity: 0.54; }
  .section-bienestar-integral-map .col-left {
    padding-top: 6rem;
    padding-bottom: 7rem; }
  .section-bienestar-integral-map .col-right {
    position: relative;
    padding-top: 42rem;
    background: #03576F; }
    .section-bienestar-integral-map .col-right:before {
      content: '';
      left: -1.5rem;
      right: 0;
      height: 1px;
      top: 47.1rem;
      background: #FFF;
      position: absolute; }
    .section-bienestar-integral-map .col-right .nav {
      margin-bottom: 2.5rem;
      flex-wrap: nowrap !important; }
      .section-bienestar-integral-map .col-right .nav .nav-item .nav-link {
        transition: all .2s ease-out;
        border-radius: 0;
        font-size: 1.9rem;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.74);
        display: inline-block;
        line-height: 5rem;
        padding: 0 1rem; }
        .section-bienestar-integral-map .col-right .nav .nav-item .nav-link.active {
          color: #EB992F; }
    .section-bienestar-integral-map .col-right p {
      color: #FFF;
      font-size: 1.6rem;
      font-weight: 400; }
      .section-bienestar-integral-map .col-right p .orange {
        color: #EB992F;
        font-size: 2.2rem;
        font-weight: 500;
        display: block;
        line-height: 2.6rem; }
    .section-bienestar-integral-map .col-right a svg {
      display: inline-block;
      vertical-align: middle;
      width: 2.4rem;
      height: 2.4rem;
      fill: #FFF;
      margin-right: 0.5rem;
      margin-top: -2px; }
    .section-bienestar-integral-map .col-right a:hover svg {
      fill: #212529; }
  .section-bienestar-integral-map .embed-responsive {
    display: none; }
  @media (max-width: 767.98px) {
    .section-bienestar-integral-map {
      min-height: 0;
      padding: 2rem 0 0 0; }
      .section-bienestar-integral-map:before {
        display: none; }
      .section-bienestar-integral-map .map {
        display: none; }
      .section-bienestar-integral-map .embed-responsive {
        display: block;
        margin-bottom: 1rem; }
      .section-bienestar-integral-map .col-left {
        padding-top: 0rem;
        padding-bottom: 1.5rem; }
      .section-bienestar-integral-map .col-right {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem; }
        .section-bienestar-integral-map .col-right:before {
          display: none; }
        .section-bienestar-integral-map .col-right .nav {
          flex-wrap: wrap !important; }
      .section-bienestar-integral-map h1 {
        font-size: 2.5rem;
        line-height: 3.2rem; }
      .section-bienestar-integral-map h2 {
        font-size: 2.2rem;
        margin-bottom: 1rem; }
      .section-bienestar-integral-map h3 {
        font-size: 1.8rem;
        margin-bottom: 2rem; }
      .section-bienestar-integral-map ul.beneficios {
        font-size: 1.8rem; }
        .section-bienestar-integral-map ul.beneficios li:before {
          margin-top: 0.7rem; } }

.swiper-container-a, .swiper-container-b {
  position: relative; }
  .swiper-container-a .swiper-pagination-a, .swiper-container-a .swiper-pagination-b, .swiper-container-b .swiper-pagination-a, .swiper-container-b .swiper-pagination-b {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2; }
    .swiper-container-a .swiper-pagination-a .swiper-pagination-bullet, .swiper-container-a .swiper-pagination-b .swiper-pagination-bullet, .swiper-container-b .swiper-pagination-a .swiper-pagination-bullet, .swiper-container-b .swiper-pagination-b .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: #FFF;
      opacity: 1; }
    .swiper-container-a .swiper-pagination-a .swiper-pagination-bullet-active, .swiper-container-a .swiper-pagination-b .swiper-pagination-bullet-active, .swiper-container-b .swiper-pagination-a .swiper-pagination-bullet-active, .swiper-container-b .swiper-pagination-b .swiper-pagination-bullet-active {
      background: #048086; }

.section-patitas-calientes {
  background: #048086;
  color: #FFF;
  padding: 6rem 0 7rem 0; }
  .section-patitas-calientes h2 {
    font-size: 6.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem; }
  .section-patitas-calientes p {
    font-size: 1.8rem; }
    .section-patitas-calientes p strong {
      font-size: 2rem;
      font-weight: 600;
      display: block; }
      .section-patitas-calientes p strong.tel {
        font-size: 3rem;
        line-height: 4rem; }
    .section-patitas-calientes p.line {
      border-top: #94ABB6 dotted 1px;
      padding-top: 1.5rem;
      margin-top: 2rem;
      font-size: 1.4rem; }
  .section-patitas-calientes h4 {
    margin-bottom: 1rem;
    font-size: 2.8rem;
    font-weight: 500; }
    .section-patitas-calientes h4 img {
      float: lefT;
      margin-right: 1.5rem;
      margin-top: 0.5rem; }
    .section-patitas-calientes h4 span {
      font-weight: 400;
      display: block; }
  .section-patitas-calientes ul.beneficios {
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-left: 2.5rem;
    list-style: none; }
    .section-patitas-calientes ul.beneficios li {
      margin-bottom: 0rem;
      position: relative; }
      .section-patitas-calientes ul.beneficios li:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border: #FFF solid 2px;
        border-radius: 100%;
        margin-left: -2.5rem;
        float: left;
        margin-top: 0.7rem;
        opacity: 0.54; }
  @media (max-width: 767.98px) {
    .section-patitas-calientes {
      padding: 1.5rem 0; }
      .section-patitas-calientes h2 {
        font-size: 2.8rem;
        font-weight: 600;
        margin-bottom: 1.5rem; }
      .section-patitas-calientes p {
        font-size: 1.6rem; }
      .section-patitas-calientes h4 {
        margin-bottom: 1rem;
        font-size: 2.2rem;
        font-weight: 500;
        overflow: hidden; }
      .section-patitas-calientes ul.beneficios {
        font-size: 1.8rem; }
        .section-patitas-calientes ul.beneficios li:before {
          margin-top: 0.7rem; } }

/*
    Created on : 21-ago-2018, 16:16:31
    Author     : Rodri
*/
.section-haciendo-contacto {
  padding: 8.5rem 0 7rem 0;
  background: #EAEEF1; }
  .section-haciendo-contacto h1 {
    line-height: 6.5rem;
    color: #02576E;
    font-size: 6.5rem;
    margin-bottom: 2rem;
    font-weight: 500; }
    .section-haciendo-contacto h1 i {
      width: 12.7rem;
      height: 12.7rem;
      background: #03576F;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      float: left;
      margin-right: 2.5rem; }
      .section-haciendo-contacto h1 i svg {
        width: 5rem;
        height: 5rem;
        fill: #FFF; }
  .section-haciendo-contacto p {
    color: #01576D;
    font-size: 2.2rem; }
    .section-haciendo-contacto p strong {
      font-weight: 500; }
    .section-haciendo-contacto p.green {
      position: relative;
      background: #048086;
      color: #FFF;
      font-size: 1.8rem;
      /*text-align: center;*/
      margin: 0;
      padding: 2rem;
      font-weight: 400;
      border-top: #FABE00 solid 0.6rem; }
      .section-haciendo-contacto p.green strong {
        /*display: block;*/
        /*font-size: 2.2rem;*/
        font-weight: 500; }
      .section-haciendo-contacto p.green img {
        float: left;
        margin-right: 1.5rem;
        margin-top: 1rem; }
  @media (max-width: 767.98px) {
    .section-haciendo-contacto {
      padding: 3rem 0 3rem 0; }
      .section-haciendo-contacto h1 {
        display: flex;
        font-size: 2.5rem;
        align-items: center; }
        .section-haciendo-contacto h1 i {
          width: 5rem;
          height: 5rem;
          float: none;
          margin-right: 1.5rem; }
          .section-haciendo-contacto h1 i svg {
            width: 3rem;
            height: 3rem; }
      .section-haciendo-contacto p {
        font-size: 1.8rem; }
        .section-haciendo-contacto p.green {
          font-size: 1.6rem;
          /*padding: 5.6rem 2.5rem 3rem 2.5rem;*/
          font-weight: 400;
          text-align: center;
          /*border-top: #FABE00 solid 1.5rem;*/
          margin-top: 3rem; }
          .section-haciendo-contacto p.green img {
            display: block;
            margin: 0 auto 1rem auto;
            float: none; }
          .section-haciendo-contacto p.green strong {
            /*font-size: 1.8rem;*/ } }

.section-cuidandote {
  padding-top: 6rem; }
  .section-cuidandote .border-t {
    border-top: #004E61 dotted 1px;
    margin-top: 3rem;
    padding-top: 4rem; }
  .section-cuidandote h2 {
    color: #03576F;
    font-size: 6.5rem;
    font-weight: 600;
    line-height: 6.7rem;
    margin-bottom: 1rem; }
  .section-cuidandote h3 {
    color: #03576F;
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: 1rem; }
  .section-cuidandote p {
    font-size: 1.8rem;
    color: #333333;
    margin-bottom: 1rem; }
    .section-cuidandote p strong {
      color: #02576E;
      display: block;
      font-weight: 600; }
  .section-cuidandote ul {
    color: #333333;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-left: 2.5rem;
    list-style: none; }
    .section-cuidandote ul li {
      margin-bottom: 0.5rem;
      position: relative; }
      .section-cuidandote ul li:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border: #333333 solid 2px;
        border-radius: 100%;
        margin-left: -2.5rem;
        float: left;
        margin-top: 0.7rem;
        opacity: 0.54; }
  @media (max-width: 767.98px) {
    .section-cuidandote {
      padding: 3rem 0 4rem 0; }
      .section-cuidandote h2 {
        font-size: 2.5rem;
        line-height: 3rem; }
      .section-cuidandote h3 {
        font-size: 2.5rem; }
      .section-cuidandote p {
        font-size: 1.6rem; }
      .section-cuidandote .border-t {
        margin-top: 1.5rem;
        padding-top: 2rem; } }

.section-gold-service {
  padding: 8rem 0 8rem 0;
  position: relative;
  background: #048086;
  color: #FFF; }
  .section-gold-service:before {
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 50%;
    position: absolute;
    background: url("../img/planes-medicos/cuidado-personalizado/gold-service.jpg") center center no-repeat;
    background-size: cover; }
  .section-gold-service h2 {
    font-size: 6.5rem;
    font-weight: 600; }
  .section-gold-service h3 {
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: 1.5rem; }
  .section-gold-service ul {
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-left: 2.5rem;
    list-style: none; }
    .section-gold-service ul li {
      margin-bottom: 0.5rem;
      position: relative; }
      .section-gold-service ul li:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border: #FFF solid 2px;
        border-radius: 100%;
        margin-left: -2.5rem;
        float: left;
        margin-top: 0.7rem;
        opacity: 0.54; }
  @media (max-width: 767.98px) {
    .section-gold-service {
      padding: 0 0 3rem 0; }
      .section-gold-service:before {
        display: block;
        position: relative;
        left: 0;
        height: 20rem;
        margin-bottom: 1.5rem; }
      .section-gold-service h2 {
        font-size: 2.5rem; }
      .section-gold-service h3 {
        font-size: 2rem; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 21-ago-2018, 19:03:20
    Author     : Rodri
*/
.section-prevencion {
  padding: 7rem 0; }
  .section-prevencion h1 {
    color: #03576F;
    font-size: 6.5rem;
    font-weight: 500;
    margin-bottom: 1rem; }
  .section-prevencion h3 {
    color: #03576F;
    font-size: 2.2rem;
    font-weight: 500;
    margin-bottom: 2rem; }
  .section-prevencion h2 {
    color: #047F85;
    font-size: 2.8rem;
    font-weight: 500; }
    .section-prevencion h2.large {
      font-size: 3.4rem; }
  .section-prevencion p {
    color: #333333;
    font-size: 1.8rem;
    margin-bottom: 1rem; }
  .section-prevencion ul {
    color: #047F85;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-left: 2.5rem;
    list-style: none; }
    .section-prevencion ul li {
      margin-bottom: 0.5rem;
      position: relative; }
      .section-prevencion ul li:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border: #047F85 solid 2px;
        border-radius: 100%;
        margin-left: -2.5rem;
        float: left;
        margin-top: 0.7rem;
        opacity: 0.54; }
  .section-prevencion .box-green {
    margin-bottom: 1rem;
    background: #003F44;
    padding: 2rem;
    color: #FFF; }
    .section-prevencion .box-green p {
      line-height: 2.6rem;
      font-size: 2.2rem;
      margin-bottom: 1.5rem;
      color: #FFF; }
      .section-prevencion .box-green p span {
        font-size: 1.8rem;
        line-height: 1.8rem; }
    .section-prevencion .box-green a svg {
      display: inline-block;
      vertical-align: middle;
      width: 2.4rem;
      height: 2.4rem;
      fill: #FFF;
      margin-right: 0.5rem; }
    .section-prevencion .box-green a:hover svg {
      fill: #003F44; }
  .section-prevencion figure {
    position: relative; }
    .section-prevencion figure a {
      position: absolute;
      bottom: 2rem;
      left: 2rem; }
      .section-prevencion figure a svg {
        display: inline-block;
        vertical-align: middle;
        width: 2.4rem;
        height: 2.4rem;
        fill: #FFF;
        margin-right: 0.5rem; }
      .section-prevencion figure a:hover svg {
        fill: #003F44; }
    .section-prevencion figure p {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #048086 url("../img/icons/icon_star.png") 2rem center no-repeat;
      color: #FFF;
      font-size: 2rem;
      padding: 1.5rem 2rem 1.5rem 10rem;
      margin-bottom: 0; }
  .section-prevencion .border-t {
    border-top: #94ABB6 dotted 1px;
    padding-top: 5rem;
    margin-top: 5rem; }
  @media (max-width: 767.98px) {
    .section-prevencion {
      padding: 3rem 0 3rem 0; }
      .section-prevencion h1 {
        font-size: 3rem; }
      .section-prevencion h3 {
        font-size: 1.8rem; }
      .section-prevencion h2 {
        font-size: 2rem; }
      .section-prevencion p {
        font-size: 1.6rem; }
      .section-prevencion ul {
        margin-bottom: 2rem; }
      .section-prevencion .box-green .btn {
        font-size: 1.3rem; }
      .section-prevencion .box-green p {
        font-size: 1.8rem; }
      .section-prevencion .border-t {
        padding-top: 3rem;
        margin-top: 2.5rem; }
      .section-prevencion figure .btn {
        font-size: 1.3rem; }
      .section-prevencion figure p {
        position: absolute;
        font-size: 1.4rem;
        padding: 1rem 1rem 1rem 7rem;
        background-size: 3.5rem; } }

.section-recompensando {
  padding-top: 8rem;
  background: #F4F4F4; }
  .section-recompensando h2 {
    color: #047F85;
    font-size: 3.4rem;
    font-weight: 500;
    margin-bottom: 1.5rem; }
  .section-recompensando p {
    color: #333333;
    font-size: 1.8rem; }
    .section-recompensando p.green {
      color: #047F85;
      font-size: 2rem;
      font-weight: 500; }
  .section-recompensando ul {
    color: #047F85;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-left: 2.5rem;
    list-style: none; }
    .section-recompensando ul li {
      margin-bottom: 0.5rem;
      position: relative; }
      .section-recompensando ul li:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border: #047F85 solid 2px;
        border-radius: 100%;
        margin-left: -2.5rem;
        float: left;
        margin-top: 0.7rem;
        opacity: 0.54; }
      .section-recompensando ul li ul li:before {
        border: transparent solid 2px;
        background: #048086; }
  @media (max-width: 767.98px) {
    .section-recompensando h2 {
      font-size: 2.4rem; }
    .section-recompensando p {
      font-size: 1.6rem; }
    .section-recompensando p.green {
      font-size: 1.8rem; } }

.section-educacion-salud {
  padding: 8rem 0 8rem 0;
  position: relative;
  background: #048086;
  color: #FFF; }
  .section-educacion-salud:before {
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 50%;
    position: absolute;
    background: url("../img/planes-medicos/prevencion-educacion/educacion.jpg") center center no-repeat;
    background-size: cover; }
  .section-educacion-salud h2 {
    font-size: 3.4rem;
    font-weight: 600;
    margin-bottom: 2.5rem; }
  .section-educacion-salud ul {
    color: #FFF;
    font-size: 1.8rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-left: 2.5rem;
    list-style: none; }
    .section-educacion-salud ul li {
      margin-bottom: 3rem;
      position: relative; }
      .section-educacion-salud ul li:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border: #FFF solid 2px;
        border-radius: 100%;
        margin-left: -2.5rem;
        float: left;
        margin-top: 0.7rem;
        opacity: 0.54; }
  .section-educacion-salud .btn-open {
    margin-top: 1.5rem;
    margin-bottom: 3px;
    padding-left: 3rem;
    position: relative; }
    .section-educacion-salud .btn-open:before {
      content: '';
      display: block;
      height: 1px;
      background: rgba(255, 255, 255, 0.12);
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      margin-top: -1px;
      z-index: 0; }
    .section-educacion-salud .btn-open .btn {
      position: relative;
      z-index: 1;
      text-align: left;
      min-width: 24rem;
      background: #FFF;
      color: #048086; }
      .section-educacion-salud .btn-open .btn:hover, .section-educacion-salud .btn-open .btn:focus {
        color: #FFF;
        background: #003F44 !important; }
      .section-educacion-salud .btn-open .btn svg {
        float: left;
        margin-right: 1rem;
        margin-top: 0.75rem;
        fill: #048086;
        width: 2.4rem;
        height: 2.4rem; }
        .section-educacion-salud .btn-open .btn svg.arrow {
          float: right;
          margin-right: 0;
          margin-left: 3rem; }
  @media (max-width: 767.98px) {
    .section-educacion-salud {
      padding: 0 0 3rem 0; }
      .section-educacion-salud:before {
        position: relative;
        display: block;
        left: 0;
        height: 20rem;
        margin-bottom: 1.5rem; }
      .section-educacion-salud h2 {
        font-size: 2.4rem; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 22-ago-2018, 10:45:17
    Author     : Rodri
*/
.section-tecnologia {
  padding: 7rem 0; }
  .section-tecnologia h1 {
    color: #003F44;
    font-size: 6rem;
    font-weight: 500;
    margin-bottom: 1.5rem; }
  .section-tecnologia .alerta-tecnologia h4 {
    color: #047F85;
    font-size: 3.4rem;
    font-weight: 500;
    margin-bottom: 2rem; }
  .section-tecnologia h3 {
    color: #003F44;
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 2rem; }
  .section-tecnologia img.img-phone {
    position: absolute;
    height: 54.3rem;
    width: auto;
    max-width: initial;
    left: -15.5rem; }
  @media (min-width: 768px) {
    .section-tecnologia .text-beside-phone {
      min-height: 50.5rem; } }
  .section-tecnologia .border-top {
    border-top: #94ABB6 dotted 1px;
    margin-top: 3.5rem;
    padding-top: 3rem; }
  .section-tecnologia .border-right {
    border-right: #94ABB6 dotted 1px; }
  .section-tecnologia .social-net li a {
    color: #333333;
    font-size: 2.5rem; }
  .section-tecnologia h2 {
    color: #003F44;
    font-size: 2.4rem;
    font-weight: 600;
    margin-bottom: 1rem; }
    .section-tecnologia h2.green {
      color: #048086; }
    .section-tecnologia h2.title-aside {
      font-size: 6rem;
      color: #048086;
      line-height: 6rem;
      font-weight: 500; }
      .section-tecnologia h2.title-aside svg {
        display: block;
        width: 6rem;
        height: 6rem;
        fill: #048086; }
      .section-tecnologia h2.title-aside.orange {
        color: #EB992F; }
        .section-tecnologia h2.title-aside.orange svg {
          fill: #EB992F; }
  .section-tecnologia p {
    color: #003F44;
    font-size: 1.8rem;
    font-weight: 400; }
  .section-tecnologia ul.beneficios {
    color: #003F44;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0 0 2rem 0;
    padding: 0;
    margin-left: 2.5rem;
    list-style: none; }
    .section-tecnologia ul.beneficios li {
      margin-bottom: 0rem;
      position: relative; }
      .section-tecnologia ul.beneficios li:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border: #003F44 solid 2px;
        border-radius: 100%;
        margin-left: -2.5rem;
        float: left;
        margin-top: 0.5rem;
        opacity: 0.54; }
  .section-tecnologia .asistente {
    position: relative;
    top: auto;
    right: auto;
    height: 4.2rem;
    width: 23.5rem;
    border-radius: 4rem;
    display: flex; }
    .section-tecnologia .asistente:before {
      width: 3.3rem;
      height: 3.3rem;
      content: '';
      background: url(../img/asistente.svg);
      background-size: cover; }
    .section-tecnologia .asistente span.label {
      font-size: 1.8rem;
      font-weight: 500;
      margin-left: 1.5rem; }
  .section-tecnologia .box-gris {
    position: relative;
    background: #F4F4F4;
    margin-top: 6rem;
    padding: 3rem;
    /*margin-bottom: 3rem;*/ }
    .section-tecnologia .box-gris:before {
      content: '';
      display: block;
      position: absolute;
      top: -3rem;
      left: 6rem;
      width: 0;
      height: 0;
      border-left: 3rem solid transparent;
      border-bottom: 3rem solid #F4F4F4;
      border-right: 3rem solid transparent; }
  .section-tecnologia form {
    display: flex; }
    .section-tecnologia form input {
      border: 0;
      padding: 0 1.5rem;
      background: #F4F4F4;
      color: #003F44;
      font-size: 1.6rem;
      flex: 1; }
    .section-tecnologia form .btn {
      margin: 0; }
  .section-tecnologia .alert {
    margin: 0 0 1.5rem 0;
    padding: 0;
    display: flex; }
    .section-tecnologia .alert svg {
      margin-top: 0.5rem; }
    .section-tecnologia .alert span {
      margin-left: 1rem;
      flex: 1; }
  .section-tecnologia .btns_downloads {
    margin-bottom: 2rem;
    display: flex; }
    .section-tecnologia .btns_downloads a {
      margin: 0 1px; }
  .section-tecnologia .col-aside p {
    font-size: 1.7rem; }
  .section-tecnologia .alerta-description {
    background-color: #116f71;
    padding: 5em; }
    .section-tecnologia .alerta-description h2, .section-tecnologia .alerta-description p, .section-tecnologia .alerta-description h3 {
      color: #ffffff; }
    .section-tecnologia .alerta-description .icon, .section-tecnologia .alerta-description .text-description {
      padding-top: .5em;
      padding-bottom: .5em; }
    .section-tecnologia .alerta-description .text-description {
      display: flex;
      align-items: center; }
    @media (max-width: 991.98px) {
      .section-tecnologia .alerta-description {
        padding: 2em 0; } }
  @media (max-width: 767.98px) {
    .section-tecnologia {
      padding: 3rem 0; }
      .section-tecnologia h1 {
        font-size: 3rem; }
        .section-tecnologia h1 .btn {
          margin-top: 1rem; }
      .section-tecnologia h3 {
        font-size: 1.8rem; }
      .section-tecnologia .box-gris {
        margin-top: 3rem;
        margin-bottom: 3rem; }
      .section-tecnologia h2.title-aside {
        font-size: 3rem;
        line-height: 3rem; }
      .section-tecnologia .alerta-tecnologia h4 {
        font-size: 2.5rem; } }

section.cubierta-fuera-de-red-de-farmacias-box-hand {
  padding-top: 0rem; }
  section.cubierta-fuera-de-red-de-farmacias-box-hand .box-hand {
    position: absolute;
    top: 0rem;
    left: 1.5rem;
    background: url("../img/icons/box-hand.png");
    width: 65px;
    height: 57px; }
  section.cubierta-fuera-de-red-de-farmacias-box-hand .container {
    position: relative; }

section.cubierta-fuera-de-red-de-farmacias-main {
  padding-top: 3rem; }
  section.cubierta-fuera-de-red-de-farmacias-main h2 {
    font-size: 3.6rem;
    line-height: 4.2rem;
    font-weight: 700;
    color: #047F85;
    margin-bottom: 3rem; }
  section.cubierta-fuera-de-red-de-farmacias-main ul li {
    font-size: 2rem;
    font-weight: 400;
    color: #047F85;
    margin-bottom: 1.5rem;
    list-style: none !important;
    position: relative;
    padding-left: 3rem; }
    section.cubierta-fuera-de-red-de-farmacias-main ul li i {
      position: absolute;
      left: 0rem;
      top: -0.2rem;
      font-size: 2.2rem; }
  section.cubierta-fuera-de-red-de-farmacias-main p {
    font-size: 2rem;
    color: #047F85;
    line-height: 140%;
    margin-bottom: 3rem; }
  section.cubierta-fuera-de-red-de-farmacias-main a.share {
    color: #003F44;
    font-size: 1.6rem;
    font-weight: 500;
    display: flex;
    margin-top: 2rem;
    align-items: center;
    text-align: right;
    justify-content: flex-end; }
    section.cubierta-fuera-de-red-de-farmacias-main a.share i {
      font-size: 3rem;
      margin-right: 1rem; }

@media (max-width: 767.98px) {
  section.cubierta-fuera-de-red-de-farmacias-main {
    padding-top: 6rem; }
    section.cubierta-fuera-de-red-de-farmacias-main h2 {
      font-size: 3rem;
      line-height: 120%;
      margin-bottom: 2.5rem; }
    section.cubierta-fuera-de-red-de-farmacias-main ul {
      margin-bottom: 3rem; }
      section.cubierta-fuera-de-red-de-farmacias-main ul li {
        margin-bottom: 1.5rem;
        font-size: 1.8rem; }
    section.cubierta-fuera-de-red-de-farmacias-main p {
      font-size: 1.8rem; } }

.page-determinaciones-cubierta section.box-file {
  padding-top: 0rem; }
  .page-determinaciones-cubierta section.box-file .box-file {
    position: absolute;
    top: 0rem;
    left: 1.5rem;
    background: url("../img/icons/box-file.png");
    width: 65px;
    height: 57px; }
  .page-determinaciones-cubierta section.box-file .container {
    position: relative; }

.page-determinaciones-cubierta section.determinaciones-de-cubierta ul {
  margin-bottom: 4rem; }
  .page-determinaciones-cubierta section.determinaciones-de-cubierta ul li {
    list-style: none !important;
    font-size: 2rem;
    line-height: 140%;
    color: #047F85;
    margin-bottom: 2rem;
    position: relative;
    padding-left: 3rem; }
    .page-determinaciones-cubierta section.determinaciones-de-cubierta ul li:before {
      position: absolute;
      left: 0rem; }

.page-determinaciones-cubierta section.determinaciones-de-cubierta h1 {
  font-size: 6rem;
  font-weight: 500;
  color: #047F85;
  margin-bottom: 1rem; }

.page-determinaciones-cubierta section.determinaciones-de-cubierta p.subtitle {
  font-size: 2rem;
  font-weight: 500;
  color: #047F85;
  margin-bottom: 3rem; }

.page-determinaciones-cubierta section.determinaciones-de-cubierta h4 {
  font-size: 2.4rem;
  font-weight: 700;
  color: #047F85;
  margin-bottom: 3rem; }

.page-determinaciones-cubierta section.determinaciones-de-cubierta p {
  font-size: 2rem;
  line-height: 140%;
  color: #047F85; }

.page-determinaciones-cubierta section.determinaciones-de-cubierta a.btn.green {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .page-determinaciones-cubierta section.determinaciones-de-cubierta a.btn.green i {
    font-size: 2.6rem; }

.page-determinaciones-cubierta section.formas {
  padding-top: 0rem;
  background: #F0F3F4; }
  .page-determinaciones-cubierta section.formas .box-headset {
    position: absolute;
    top: 0rem;
    left: 1.5rem;
    background: url("../img/icons/box-headset.png");
    width: 65px;
    height: 57px; }
  .page-determinaciones-cubierta section.formas .container {
    position: relative; }
  .page-determinaciones-cubierta section.formas .formas-info {
    margin-top: 8rem; }
    .page-determinaciones-cubierta section.formas .formas-info h2 {
      font-size: 3.4rem;
      font-weight: 500;
      color: #048086; }
    .page-determinaciones-cubierta section.formas .formas-info .llamanos {
      border-top: #94ABB6 dotted 1px;
      padding-top: 2.5rem;
      display: flex;
      align-items: center; }
      .page-determinaciones-cubierta section.formas .formas-info .llamanos svg {
        width: 4rem;
        height: 4rem;
        fill: #048086;
        margin-right: 2rem; }
      .page-determinaciones-cubierta section.formas .formas-info .llamanos h4 {
        font-size: 2.4rem;
        font-weight: 500;
        margin-bottom: 0;
        color: #047F85; }
      .page-determinaciones-cubierta section.formas .formas-info .llamanos p {
        font-size: 2rem;
        color: #003F44; }
        .page-determinaciones-cubierta section.formas .formas-info .llamanos p a {
          color: #047F85;
          text-decoration: underline; }
    .page-determinaciones-cubierta section.formas .formas-info .faxs {
      /*margin-top: 7rem;*/
      /*border-bottom: 1px dotted #94ABB6;*/
      padding-bottom: 4.5rem; }
      .page-determinaciones-cubierta section.formas .formas-info .faxs .icon-download {
        position: absolute;
        top: -8rem; }
      .page-determinaciones-cubierta section.formas .formas-info .faxs.border-none {
        border: none; }
      .page-determinaciones-cubierta section.formas .formas-info .faxs h4 {
        font-size: 2rem;
        font-weight: 500;
        line-height: 130%;
        color: #047F85;
        /*width: 75%;*/ }
      .page-determinaciones-cubierta section.formas .formas-info .faxs .fax-item {
        border-left: 1px dotted #94ABB6;
        padding-top: 1rem;
        padding-left: 2.5rem; }
        .page-determinaciones-cubierta section.formas .formas-info .faxs .fax-item:first-child, .page-determinaciones-cubierta section.formas .formas-info .faxs .fax-item.border-none {
          border: none;
          padding-left: 1.5rem; }
        .page-determinaciones-cubierta section.formas .formas-info .faxs .fax-item h3 {
          font-size: 2.4rem;
          font-weight: 500;
          color: #003F44;
          line-height: 100%; }
        .page-determinaciones-cubierta section.formas .formas-info .faxs .fax-item h4 {
          font-size: 2rem;
          font-weight: 500;
          color: #003F44;
          line-height: 100%;
          margin-bottom: 3.5rem; }
      .page-determinaciones-cubierta section.formas .formas-info .faxs p {
        font-size: 2rem;
        color: #003F44;
        line-height: 120%; }
        .page-determinaciones-cubierta section.formas .formas-info .faxs p span.fw-medium {
          font-weight: 500; }
      .page-determinaciones-cubierta section.formas .formas-info .faxs .solicitud {
        display: flex;
        align-items: center;
        margin-bottom: 1rem; }
        .page-determinaciones-cubierta section.formas .formas-info .faxs .solicitud svg {
          margin-right: 1.5rem; }
        .page-determinaciones-cubierta section.formas .formas-info .faxs .solicitud span {
          font-size: 2.4rem;
          font-weight: 500;
          color: #047F85; }
    .page-determinaciones-cubierta section.formas .formas-info .faxs-new .fax-item p {
      font-size: 1.8rem;
      color: #003F44; }
    .page-determinaciones-cubierta section.formas .formas-info ul li {
      list-style: none;
      position: relative;
      padding-left: 2rem;
      font-size: 1.8rem;
      color: #333;
      line-height: 140%;
      margin-bottom: 1.5rem; }
      .page-determinaciones-cubierta section.formas .formas-info ul li:before {
        content: " ";
        border: 2px solid #048086;
        border-radius: 10rem;
        width: 10px;
        height: 10px;
        position: absolute;
        left: 0;
        top: 0.8rem; }
    .page-determinaciones-cubierta section.formas .formas-info .envios h4 {
      width: 45%; }
    .page-determinaciones-cubierta section.formas .formas-info .envios ul li {
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 1rem; }
    .page-determinaciones-cubierta section.formas .formas-info .envios p {
      font-size: 1.8rem;
      color: #003F44; }

section.excepciones {
  padding-top: 0rem; }
  section.excepciones .btn-open {
    margin-bottom: 1px;
    padding-left: 3rem;
    position: relative; }
    section.excepciones .btn-open:before {
      content: '';
      display: block;
      height: 1px;
      background: rgba(0, 63, 68, 0.12);
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      margin-top: -1px;
      z-index: 0; }
    section.excepciones .btn-open:hover {
      color: #FFF; }
    section.excepciones .btn-open .btn {
      text-align: left;
      min-width: 51rem;
      color: #FFF;
      position: relative; }
      section.excepciones .btn-open .btn svg {
        margin-top: 0.75rem;
        float: right;
        margin-left: 3rem;
        fill: #FFF;
        width: 2.4rem;
        height: 2.4rem; }
  section.excepciones ul {
    color: #003F44;
    font-weight: 400;
    font-size: 1.8rem;
    margin: 0;
    list-style: none;
    padding: 0; }
    section.excepciones ul.download li {
      color: #047F85;
      padding-left: 0; }
      section.excepciones ul.download li a {
        color: #047F85; }
      section.excepciones ul.download li:before {
        display: none; }
    section.excepciones ul li {
      padding-left: 2.5rem;
      position: relative;
      margin-bottom: 0.5rem; }
      section.excepciones ul li:before {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        border: #003F44 solid 2px;
        border-radius: 100%;
        left: 0;
        top: 0.75rem; }
      section.excepciones ul li ul {
        margin-top: 0.5rem;
        margin-left: 3rem; }
        section.excepciones ul li ul li:before {
          background: #003F44;
          border-color: #FFF; }
  section.excepciones .box-alert {
    position: absolute;
    top: 0rem;
    left: 1.5rem;
    background: url("../img/icons/box-alert.png");
    width: 65px;
    height: 57px; }
  section.excepciones .container {
    position: relative; }
  section.excepciones .excepciones-info {
    margin-top: 8rem; }
    section.excepciones .excepciones-info h2 {
      font-size: 3.4rem;
      font-weight: 500;
      color: #047F85;
      margin-bottom: 1.5rem; }
    section.excepciones .excepciones-info h4 {
      font-size: 2.4rem;
      color: #047F85;
      margin-bottom: 4.5rem; }
    section.excepciones .excepciones-info p {
      font-size: 1.8rem;
      color: #003F44;
      line-height: 120%; }
      section.excepciones .excepciones-info p strong {
        font-weight: 700; }
    section.excepciones .excepciones-info .btn-conoce-mas {
      position: relative;
      margin-top: 5rem; }
      section.excepciones .excepciones-info .btn-conoce-mas:before {
        content: " ";
        width: 100%;
        position: absolute;
        height: 1px;
        background: #003F44;
        top: 2rem; }
      section.excepciones .excepciones-info .btn-conoce-mas a {
        padding-left: 4rem;
        padding-right: 4rem;
        margin-left: -2rem;
        font-size: 1.6rem; }

@media (max-width: 767.98px) {
  .page-determinaciones-cubierta section.determinaciones-de-cubierta {
    padding-top: 6rem; }
    .page-determinaciones-cubierta section.determinaciones-de-cubierta h1 {
      font-size: 3rem; }
    .page-determinaciones-cubierta section.determinaciones-de-cubierta p.subtitle {
      font-size: 1.8rem; }
    .page-determinaciones-cubierta section.determinaciones-de-cubierta h4 {
      font-size: 2.2rem; }
    .page-determinaciones-cubierta section.determinaciones-de-cubierta ul li {
      font-size: 1.8rem;
      margin-bottom: 1.5rem; }
    .page-determinaciones-cubierta section.determinaciones-de-cubierta p {
      font-size: 1.8rem; }
  .page-determinaciones-cubierta section.formas h2 {
    font-size: 3rem; }
  .page-determinaciones-cubierta section.formas .formas-info .faxs {
    margin-top: 2rem;
    padding-bottom: 0rem; }
    .page-determinaciones-cubierta section.formas .formas-info .faxs .fax-item {
      margin-bottom: 2rem;
      padding-left: 1.5rem; }
      .page-determinaciones-cubierta section.formas .formas-info .faxs .fax-item h3 {
        font-size: 2.2rem; }
      .page-determinaciones-cubierta section.formas .formas-info .faxs .fax-item h4 {
        font-size: 1.8rem;
        margin-bottom: 2rem; }
      .page-determinaciones-cubierta section.formas .formas-info .faxs .fax-item p {
        font-size: 1.8rem; }
  .page-determinaciones-cubierta section.formas .formas-info .envios h4 {
    width: 100%; }
  .page-determinaciones-cubierta section.excepciones {
    padding-bottom: 3rem; }
    .page-determinaciones-cubierta section.excepciones h2 {
      font-size: 3rem; }
    .page-determinaciones-cubierta section.excepciones .excepciones-info .btn-open {
      padding-left: 0rem; }
      .page-determinaciones-cubierta section.excepciones .excepciones-info .btn-open a.btn {
        min-width: 100%;
        font-size: 1.4rem; }
    .page-determinaciones-cubierta section.excepciones .excepciones-info .btn-conoce-mas {
      margin-top: 3rem; }
      .page-determinaciones-cubierta section.excepciones .excepciones-info .btn-conoce-mas a {
        padding-left: 0rem;
        padding-right: 0rem;
        width: 100%;
        margin-left: 0rem; } }

.page-cuidado-y-servicio-medico h2 {
  font-size: 3.4rem;
  font-weight: 400;
  line-height: 120%;
  color: #003F44;
  margin-bottom: 6rem; }

.page-cuidado-y-servicio-medico .card .card-body .card-title {
  font-size: 2.8rem; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 03-sep-2018, 16:18:29
    Author     : Rodri
*/
.afiliados-solicitud-materiales {
  padding-top: 10rem; }
  .afiliados-solicitud-materiales h1 {
    color: #047F85;
    font-size: 4.6rem;
    font-weight: 600;
    margin-bottom: 1.5rem; }
  .afiliados-solicitud-materiales p {
    color: #048086;
    font-size: 2rem;
    margin-bottom: 2.5rem; }
  @media (max-width: 767.98px) {
    .afiliados-solicitud-materiales {
      padding-top: 7rem; }
      .afiliados-solicitud-materiales h1 {
        font-size: 2.5rem; }
      .afiliados-solicitud-materiales .btn {
        margin-bottom: 1.5rem; } }

.afiliados-solicitud-materiales-form {
  background: rgba(234, 238, 241, 0.5); }
  .afiliados-solicitud-materiales-form .help-block {
    color: #dc3535; }
  .afiliados-solicitud-materiales-form h2 {
    color: #003F44;
    font-size: 2.4rem;
    font-weight: 600;
    margin-bottom: 1rem; }
  .afiliados-solicitud-materiales-form h3 {
    font-size: 2rem;
    color: #003F44;
    font-weight: 400;
    margin-bottom: 2rem; }
  .afiliados-solicitud-materiales-form form .form-group {
    margin-bottom: 1.5rem; }
  .afiliados-solicitud-materiales-form form .md-checkbox {
    margin: 0; }
  .afiliados-solicitud-materiales-form form label {
    line-height: 1.8rem;
    color: rgba(0, 63, 68, 0.94);
    font-size: 1.6rem;
    font-weight: 500; }
    .afiliados-solicitud-materiales-form form label:before {
      top: 1px;
      border-color: #003F44; }
  .afiliados-solicitud-materiales-form form input[type="checkbox"] {
    margin-right: 15px; }
  .afiliados-solicitud-materiales-form form .form-control {
    border: #003F44 solid 1px;
    background: #FFF;
    color: rgba(0, 63, 68, 0.94);
    font-size: 1.6rem;
    padding: 0 1.5rem;
    height: 4rem !important; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 04-sep-2018, 17:38:31
    Author     : Rodri
*/
.afiliados-pago-primas {
  background: rgba(151, 172, 183, 0.1); }
  .afiliados-pago-primas h1 {
    color: #003F44;
    font-size: 4.6rem;
    font-weight: 600; }
  .afiliados-pago-primas h2 {
    color: #003F44;
    font-size: 2.8rem;
    font-weight: 400;
    margin-bottom: 3rem; }
  .afiliados-pago-primas p {
    align-items: flex-start;
    color: #003F44;
    font-size: 2rem;
    font-weight: 400; }
    .afiliados-pago-primas p.line {
      font-size: 1.8rem;
      border-top: #94ABB6 dotted 1px;
      padding-top: 3rem;
      margin-top: 4rem;
      display: flex; }
      .afiliados-pago-primas p.line i {
        margin-top: 0.5rem;
        background: #003F44;
        width: 6rem;
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.5rem; }
        .afiliados-pago-primas p.line i svg {
          width: 3rem;
          height: 3rem;
          fill: #FFF; }
      .afiliados-pago-primas p.line span {
        flex: 1; }
  .afiliados-pago-primas .lh-2 {
    line-height: 2rem; }
  .afiliados-pago-primas .card-white {
    position: relative;
    min-height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6rem 2.5rem;
    text-align: center; }
    .afiliados-pago-primas .card-white.pt {
      padding-top: 8rem;
      padding-bottom: 4rem;
      justify-content: flex-start;
      align-items: flex-start; }
      .afiliados-pago-primas .card-white.pt p {
        font-size: 1.6rem; }
        .afiliados-pago-primas .card-white.pt p.fz-20 {
          font-size: 2rem; }
    .afiliados-pago-primas .card-white .box-svg {
      background: #048086;
      width: 5.5rem;
      height: 5.5rem;
      position: absolute;
      left: 2.5rem;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
      .afiliados-pago-primas .card-white .box-svg span {
        color: #FFF;
        font-size: 3.2rem; }
    .afiliados-pago-primas .card-white i {
      position: absolute;
      left: 2.5rem;
      top: 0;
      padding: 1rem;
      background: #048086; }
      .afiliados-pago-primas .card-white i img {
        margin: 0; }
    .afiliados-pago-primas .card-white p {
      color: #047F85;
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin: 0; }
      .afiliados-pago-primas .card-white p strong {
        display: block;
        font-size: 2rem;
        margin-bottom: 1.5rem; }
    .afiliados-pago-primas .card-white img {
      margin-bottom: 1.5rem; }
  .afiliados-pago-primas .swiper {
    position: relative; }
  .afiliados-pago-primas .swiper-button-next,
  .afiliados-pago-primas .swiper-button-prev {
    background: transparent;
    width: 3.5rem;
    height: 3.5rem;
    margin-top: -1.75rem;
    border: rgba(0, 63, 68, 0.5) solid 2px;
    border-radius: 100%; }
    .afiliados-pago-primas .swiper-button-next svg,
    .afiliados-pago-primas .swiper-button-prev svg {
      fill: #003F44; }
  .afiliados-pago-primas .swiper-button-disabled {
    display: none !important; }
  .afiliados-pago-primas .swiper-button-next {
    right: -5rem; }
  .afiliados-pago-primas .swiper-button-prev {
    left: -5rem; }
  .afiliados-pago-primas ul.more-info {
    margin-top: 3rem;
    font-weight: 500;
    font-size: 1.5rem; }
    .afiliados-pago-primas ul.more-info li {
      margin-bottom: 0.5rem; }
  @media (max-width: 767.98px) {
    .afiliados-pago-primas h1 {
      font-size: 2.5rem; }
    .afiliados-pago-primas .card-white {
      margin-bottom: 1.5rem;
      min-height: 0; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
Created on : 06-sep-2018, 17:00:38
Author : Rodri
*/
.afiliados-desafiliacion p {
  color: #003F44;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 400; }
  .afiliados-desafiliacion p.medium {
    font-size: 1.8rem;
    line-height: 2.4rem; }
  .afiliados-desafiliacion p.large {
    font-size: 2rem;
    line-height: 2.6rem; }

.afiliados-desafiliacion h2 {
  color: #003F44;
  font-size: 3.4rem;
  font-weight: 500;
  margin-bottom: 1rem; }
  .afiliados-desafiliacion h2.green {
    color: #047F85; }
  .afiliados-desafiliacion h2.small {
    font-size: 2.4rem; }

.afiliados-desafiliacion h3 {
  font-size: 2.8rem;
  color: #003F44;
  font-weight: 500;
  margin-bottom: 1rem; }

.afiliados-desafiliacion h4 {
  color: #003F44;
  font-size: 2rem;
  font-weight: 500; }

.afiliados-desafiliacion .alert {
  padding: 0;
  align-items: center;
  display: flex; }
  .afiliados-desafiliacion .alert i {
    width: 6rem;
    height: 6rem;
    background: #003F44;
    padding: 1.5rem;
    margin-right: 1.5rem; }
  .afiliados-desafiliacion .alert span {
    flex: 1; }

.afiliados-desafiliacion .border-top {
  border-top: #94ABB6 dotted 1px;
  padding-top: 4rem;
  margin-top: 3rem; }

.afiliados-desafiliacion .border-left {
  padding-left: 2.5rem;
  border-left: #94ABB6 dotted 1px; }

.afiliados-desafiliacion .entregar-solicitud-title {
  font-size: 2rem; }

.afiliados-desafiliacion .detalles-importantes-title {
  font-size: 2.4rem;
  margin-top: 1.5rem;
  color: #003F44; }

.afiliados-desafiliacion .important {
  margin: 3rem 0;
  background: #EEEEEE;
  display: flex;
  align-items: center;
  border-right: #003F44 solid 4px;
  border-left: #003F44 solid 55px;
  padding: 1.5rem; }
  .afiliados-desafiliacion .important.white {
    background: white; }
  .afiliados-desafiliacion .important img {
    margin-left: -5rem;
    margin-right: 1rem; }
  .afiliados-desafiliacion .important h3 {
    color: #003F44;
    font-size: 2.8rem;
    font-weight: 600;
    margin: 0 5rem 0 0; }
  .afiliados-desafiliacion .important p {
    flex: 1; }

.afiliados-desafiliacion ul {
  color: #003F44;
  font-weight: 400;
  font-size: 1.8rem;
  margin: 0;
  list-style: none;
  padding: 0; }
  .afiliados-desafiliacion ul.download li {
    color: #047F85;
    padding-left: 0; }
    .afiliados-desafiliacion ul.download li a {
      color: #047F85; }
    .afiliados-desafiliacion ul.download li:before {
      display: none; }
  .afiliados-desafiliacion ul li {
    padding-left: 2.5rem;
    position: relative;
    margin-bottom: 0.5rem; }
    .afiliados-desafiliacion ul li:before {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      border: #003F44 solid 2px;
      border-radius: 100%;
      left: 0;
      top: 0.75rem; }
    .afiliados-desafiliacion ul li ul {
      margin-top: 0.5rem;
      margin-left: 3rem; }
      .afiliados-desafiliacion ul li ul li:before {
        background: #003F44;
        border-color: #FFF; }

.afiliados-desafiliacion .section-gray {
  background: #F5F5F5;
  padding: 5rem 0;
  margin: 5rem 0 2rem 0; }
  .afiliados-desafiliacion .section-gray p {
    font-size: 1.8rem; }
    .afiliados-desafiliacion .section-gray p strong, .afiliados-desafiliacion .section-gray p span {
      display: block; }
    .afiliados-desafiliacion .section-gray p span {
      font-weight: 600;
      font-size: 1.8rem; }
  .afiliados-desafiliacion .section-gray h2 {
    margin-bottom: 4rem;
    font-size: 2.8rem;
    font-weight: 500; }
    .afiliados-desafiliacion .section-gray h2 span {
      font-size: 1.8rem;
      font-weight: 400;
      display: block;
      line-height: 1.8rem; }
  .afiliados-desafiliacion .section-gray h3 {
    color: #048086;
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: 1rem; }
  .afiliados-desafiliacion .section-gray h4 {
    color: #003F44;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 0.5rem; }

.afiliados-desafiliacion .btn-open {
  margin-bottom: 1px;
  padding-left: 3rem;
  position: relative; }
  .afiliados-desafiliacion .btn-open:before {
    content: '';
    display: block;
    height: 1px;
    background: rgba(0, 63, 68, 0.12);
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -1px;
    z-index: 0; }
  .afiliados-desafiliacion .btn-open:hover {
    color: #FFF; }
  .afiliados-desafiliacion .btn-open .btn {
    text-align: left;
    min-width: 51rem;
    color: #FFF;
    position: relative; }
    .afiliados-desafiliacion .btn-open .btn svg {
      margin-top: 0.75rem;
      float: right;
      margin-left: 3rem;
      fill: #FFF;
      width: 2.4rem;
      height: 2.4rem; }

@media (max-width: 767.98px) {
  .afiliados-desafiliacion .important {
    flex-direction: column;
    border-left: none;
    border-right: none;
    border-top: #003F44 solid 55px;
    border-bottom: #003F44 solid 4px; }
    .afiliados-desafiliacion .important h3 {
      margin: 0 0 2rem 0; }
    .afiliados-desafiliacion .important img {
      margin-left: 0rem;
      margin-right: 0rem;
      margin-top: -5rem;
      margin-bottom: 2rem; }
  .afiliados-desafiliacion .btn-open {
    padding-left: 0rem; }
    .afiliados-desafiliacion .btn-open:before {
      display: none; }
    .afiliados-desafiliacion .btn-open .btn {
      min-width: 100%;
      font-size: 1rem; }
  .afiliados-desafiliacion .border-left {
    margin-top: 3.5rem;
    border: none;
    padding-left: 1.5rem;
    padding-right: 1.5rem; } }

.page-afiliados-reportar-fraude section.reportar-fraude-info .borde-bot {
  border-bottom: 1px dotted #94ABB6;
  padding-bottom: 5rem;
  padding-top: 6rem; }

.page-afiliados-reportar-fraude section.reportar-fraude-info .head-info p {
  font-size: 2rem;
  color: #333;
  font-weight: 400;
  line-height: 130%; }

.page-afiliados-reportar-fraude section.reportar-fraude-info .fraudes-tipos .box-text {
  padding: 2rem 1.5rem; }
  .page-afiliados-reportar-fraude section.reportar-fraude-info .fraudes-tipos .box-text h3 {
    font-size: 3.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #047F85; }
  .page-afiliados-reportar-fraude section.reportar-fraude-info .fraudes-tipos .box-text p {
    color: #003F44;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 120%; }

.page-afiliados-reportar-fraude section.reportar-fraude-info .ejemplos h2 {
  font-size: 3.4rem;
  font-weight: 500;
  color: #047F85;
  margin-bottom: 3rem; }

.page-afiliados-reportar-fraude section.reportar-fraude-info .ejemplos ul {
  padding-left: 1.5rem; }
  .page-afiliados-reportar-fraude section.reportar-fraude-info .ejemplos ul li {
    list-style: none !important;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 130%;
    color: #003F44;
    margin-bottom: 1.5rem;
    padding-left: 1.2rem; }
    .page-afiliados-reportar-fraude section.reportar-fraude-info .ejemplos ul li:before {
      content: '';
      display: inline-block;
      width: 1.2rem;
      height: 1.2rem;
      border: #003F44 solid 2px;
      border-radius: 100%;
      margin-left: -2.5rem;
      float: left;
      margin-top: 0.5rem; }

.page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-head {
  padding-top: 5rem; }
  .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-head h4 {
    font-size: 2.8rem;
    font-weight: 400;
    color: #003F44;
    line-height: 110%;
    display: flex;
    margin-bottom: 4rem; }
    .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-head h4 img {
      display: inline-block;
      margin-right: 1rem; }
  .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-head h5 {
    font-size: 2.8rem;
    font-weight: 500;
    line-height: 100%;
    color: #003F44;
    margin-bottom: 1rem; }

.page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body {
  margin-bottom: 4rem; }
  .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body .box-info {
    height: 20rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
    .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body .box-info:first-child {
      padding-left: 5rem; }
    .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body .box-info:nth-child(2) {
      border-left: 1px dotted #94ABB6;
      border-right: 1px dotted #94ABB6; }
    .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body .box-info p {
      font-size: 2rem;
      font-weight: 400;
      color: #003F44;
      line-height: 120%; }
      .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body .box-info p a, .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body .box-info p a:hover {
        font-weight: 700;
        text-decoration: none;
        display: block; }
        .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body .box-info p a.fz-small, .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body .box-info p a:hover.fz-small {
          font-size: 1.8rem; }
      .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body .box-info p strong {
        font-weight: 700; }

@media (max-width: 767.98px) {
  .page-afiliados-reportar-fraude {
    padding-bottom: 3rem; }
    .page-afiliados-reportar-fraude section.reportar-fraude-info {
      padding-top: 5rem; }
      .page-afiliados-reportar-fraude section.reportar-fraude-info .borde-bot {
        padding-top: 3rem;
        padding-bottom: 3rem; }
      .page-afiliados-reportar-fraude section.reportar-fraude-info .head-info p {
        font-size: 1.8rem; }
      .page-afiliados-reportar-fraude section.reportar-fraude-info .fraudes-tipos .box-text {
        padding: 0rem; }
        .page-afiliados-reportar-fraude section.reportar-fraude-info .fraudes-tipos .box-text h3 {
          font-size: 2.6rem; }
      .page-afiliados-reportar-fraude section.reportar-fraude-info .ejemplos h2 {
        font-size: 2.8rem; }
      .page-afiliados-reportar-fraude section.reportar-fraude-info .ejemplos ul li {
        font-size: 1.6rem; }
      .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-head {
        padding-top: 3rem; }
        .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-head h4 {
          font-size: 1.8rem;
          align-items: center;
          margin-bottom: 3rem; }
        .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-head h5 {
          font-size: 2.4rem;
          margin-bottom: 1rem; }
      .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body {
        margin-bottom: 3rem; }
        .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body .box-info {
          height: auto;
          padding: 2rem 1.5rem !important; }
          .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body .box-info:nth-child(2) {
            border-left: none;
            border-right: none;
            border-top: 1px dotted #94ABB6;
            border-bottom: 1px dotted #94ABB6; }
        .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body p {
          font-size: 1.8rem; }
          .page-afiliados-reportar-fraude section.reportar-fraude-info .opciones-body p a.fz-small {
            font-size: 1.6rem; } }

.page-afiliados-apelaciones-y-querellas a:not(.btn) {
  text-decoration: underline; }

.page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main p {
  font-size: 1.8rem;
  font-weight: 400;
  color: #003F44;
  margin-bottom: 2rem;
  line-height: 130%; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main p .green {
    color: #047F85; }

.page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-nombramiento {
  background: rgba(221, 221, 221, 0.2);
  padding: 2rem;
  padding-top: 8rem;
  position: relative; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-nombramiento:before {
    content: " ";
    background: url("../img/nombramiento-de-representante.png");
    width: 100%;
    height: 8rem;
    position: absolute;
    top: 0rem;
    left: 0rem; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-nombramiento h2 {
    font-size: 3.3rem;
    font-weight: 700;
    color: #003F44;
    margin-bottom: 0.5rem; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-nombramiento h3 {
    font-size: 2rem;
    font-weight: 700;
    color: #003F44;
    margin-bottom: 0.5rem; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-nombramiento p {
    font-size: 2rem;
    font-weight: 400;
    line-height: 120%;
    color: #003F44;
    margin-bottom: 1rem; }

.page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main h4 {
  color: #003F44;
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 1rem; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main h4.green {
    color: #047F85; }

.page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .border-left {
  border-left: #94ABB6 dotted 1px;
  padding-left: 2.5rem; }

.page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .border-top {
  border-top: #94ABB6 dotted 1px;
  padding-top: 3rem;
  margin-top: 3rem; }

.page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main h2 {
  color: #047F85;
  font-size: 3.4rem;
  font-weight: 500;
  margin-bottom: 2rem; }

.page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main h5 {
  color: #333;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem; }

.page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-solicitud {
  background: #F4F4F4;
  margin: 3rem 0 4rem 0; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-solicitud h2 {
    color: #FFF;
    background: #047F85;
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
    padding: 1.5rem 3rem; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-solicitud .documents-container {
    border: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem; }

.page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .btn-open {
  margin-bottom: 1px;
  padding-left: 3rem;
  position: relative; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .btn-open:before {
    content: '';
    display: block;
    height: 1px;
    background: rgba(0, 63, 68, 0.12);
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -1px;
    z-index: 0; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .btn-open:hover {
    color: #FFF; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .btn-open .btn {
    text-align: left;
    min-width: 51rem;
    color: #FFF; }
    .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .btn-open .btn svg {
      margin-top: 0.75rem;
      float: right;
      margin-left: 3rem;
      fill: #FFF;
      width: 2.4rem;
      height: 2.4rem; }

.page-afiliados-apelaciones-y-querellas .tab-content {
  border-left: 1px solid #444444;
  /*padding: 3.5rem 0rem;*/ }
  .page-afiliados-apelaciones-y-querellas .tab-content .card {
    border: none; }
    .page-afiliados-apelaciones-y-querellas .tab-content .card .card-header {
      border: none;
      padding: 0;
      background: none; }
      .page-afiliados-apelaciones-y-querellas .tab-content .card .card-header button {
        transition: all .2s ease-out;
        font-size: 1.6rem;
        font-weight: 400;
        color: #FFF;
        padding: 0rem 1.25rem 0rem 3rem;
        text-align: left;
        background: #047F85;
        line-height: 5.5rem;
        height: auto;
        text-transform: none;
        width: 100%; }
        .page-afiliados-apelaciones-y-querellas .tab-content .card .card-header button:hover, .page-afiliados-apelaciones-y-querellas .tab-content .card .card-header button:focus {
          text-decoration: none; }
        .page-afiliados-apelaciones-y-querellas .tab-content .card .card-header button:after {
          content: ' ';
          position: absolute;
          background: #047F85;
          transition: all .2s ease-out;
          opacity: 1;
          height: 100%;
          width: 0.5rem;
          left: -0.3rem;
          top: 0; }
        .page-afiliados-apelaciones-y-querellas .tab-content .card .card-header button.collapsed {
          color: #047F85;
          background: none;
          font-size: 1.6rem;
          font-weight: 400;
          padding-left: 1.5rem;
          line-height: 3rem;
          /*margin-bottom: 1rem;*/ }
          .page-afiliados-apelaciones-y-querellas .tab-content .card .card-header button.collapsed:before {
            content: "+";
            font-size: 3rem;
            font-weight: 300;
            position: relative;
            top: 0.3rem;
            margin-right: 0.8rem; }
          .page-afiliados-apelaciones-y-querellas .tab-content .card .card-header button.collapsed:after {
            height: 0; }
    .page-afiliados-apelaciones-y-querellas .tab-content .card .card-body {
      padding: 3rem; }
      .page-afiliados-apelaciones-y-querellas .tab-content .card .card-body h2 {
        margin: 0 0 0.5rem 0;
        color: #047F85;
        font-size: 2rem;
        font-weight: 500; }
      .page-afiliados-apelaciones-y-querellas .tab-content .card .card-body ul {
        color: #333333;
        font-size: 1.6rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-left: 8rem;
        margin-bottom: 3rem;
        list-style: none; }
        .page-afiliados-apelaciones-y-querellas .tab-content .card .card-body ul li {
          margin-bottom: 0rem;
          position: relative; }
          .page-afiliados-apelaciones-y-querellas .tab-content .card .card-body ul li:before {
            content: '';
            display: inline-block;
            width: 1rem;
            height: 1rem;
            border: #047F85 solid 2px;
            border-radius: 100%;
            margin-left: -2.5rem;
            float: left;
            margin-top: 0.7rem;
            opacity: 0.54; }
      .page-afiliados-apelaciones-y-querellas .tab-content .card .card-body h3 {
        font-size: 2.4rem;
        font-weight: 500;
        color: #003F44;
        margin-bottom: 1.5rem; }
      .page-afiliados-apelaciones-y-querellas .tab-content .card .card-body h4 {
        font-size: 2rem;
        font-weight: 500;
        color: #047F85;
        margin-bottom: 1rem; }
      .page-afiliados-apelaciones-y-querellas .tab-content .card .card-body h5 {
        font-size: 1.6rem;
        margin-bottom: 1.5rem; }
      .page-afiliados-apelaciones-y-querellas .tab-content .card .card-body p {
        font-size: 1.6rem;
        color: #333;
        line-height: 2rem; }
        .page-afiliados-apelaciones-y-querellas .tab-content .card .card-body p .green {
          color: #047F85;
          font-size: 1.8rem; }
        .page-afiliados-apelaciones-y-querellas .tab-content .card .card-body p a {
          color: #047F85;
          text-decoration: underline; }

@media (max-width: 767.98px) {
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .btn-open {
    padding-left: 0rem; }
    .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .btn-open:before {
      display: none; }
    .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .btn-open .btn {
      min-width: 100%; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .border-left {
    border: none;
    padding-left: 1.5rem; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main h4.green {
    margin-bottom: 2rem; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-nombramiento {
    padding-top: 6rem; }
    .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-nombramiento:before {
      background-repeat: no-repeat;
      background-size: 100%; }
    .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-nombramiento h2 {
      font-size: 2.4rem; }
    .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-nombramiento h3 {
      font-size: 1.8rem; }
    .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-nombramiento p {
      font-size: 1.8rem; }
    .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-nombramiento a {
      font-size: 3.4vw; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-solicitud h2 {
    margin-bottom: 0rem; }
  .page-afiliados-apelaciones-y-querellas section.apelaciones-y-querellas-main .box-solicitud .documents-container {
    flex-direction: column;
    align-items: flex-start; }
  .page-afiliados-apelaciones-y-querellas .tab-content .card .card-header button {
    white-space: normal; }
    .page-afiliados-apelaciones-y-querellas .tab-content .card .card-header button:not(.collapsed) {
      line-height: 150%;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; }
  .page-afiliados-apelaciones-y-querellas .tab-content .card .card-body ul {
    margin-left: 2.5rem; } }

/*
/*
    Author     : MAXI
*/
.afiliados-nombramiento-de-representante .copy-designar-representante {
  font-size: 1.8rem;
  color: #333333; }

.afiliados-nombramiento-de-representante .copy {
  display: flex;
  margin-bottom: 1.5rem;
  margin-top: 3rem; }
  .afiliados-nombramiento-de-representante .copy i {
    margin-right: 1.5rem;
    background: #003F44;
    width: 6.5rem;
    height: 5.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none; }
  .afiliados-nombramiento-de-representante .copy p {
    color: #003F44;
    font-size: 1.8rem; }

.afiliados-nombramiento-de-representante .descarga-col a.btn {
  padding: 0rem 3rem; }

.afiliados-nombramiento-de-representante .ubicaciones {
  margin-top: 0rem;
  /*border-top: 1px dotted #94ABB6;*/
  border-bottom: 1px dotted #94ABB6;
  padding-top: 2.8rem;
  padding-bottom: 2.8rem; }
  .afiliados-nombramiento-de-representante .ubicaciones .ubicaciones-col {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    border-right: 1px dotted #94ABB6; }
    .afiliados-nombramiento-de-representante .ubicaciones .ubicaciones-col:last-child {
      border-right: none; }
    .afiliados-nombramiento-de-representante .ubicaciones .ubicaciones-col p {
      color: #003F44; }
    .afiliados-nombramiento-de-representante .ubicaciones .ubicaciones-col .title {
      font-size: 2.4rem;
      font-weight: 500;
      color: #003F44;
      margin-bottom: 1rem; }
    .afiliados-nombramiento-de-representante .ubicaciones .ubicaciones-col .subtitle {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 0; }

@media (max-width: 767.98px) {
  .afiliados-nombramiento-de-representante .ubicaciones .ubicaciones-col {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; } }

/*
/*
    Author     : MAXI
*/
.afiliados-notificacion-privacidad p {
  color: #003F44;
  font-weight: 400;
  margin-bottom: 1.5rem;
  line-height: 2.4rem;
  font-size: 1.8rem; }
  .afiliados-notificacion-privacidad p.large {
    line-height: 2.6rem;
    font-size: 2rem; }
  .afiliados-notificacion-privacidad p.gray {
    background: #F4F4F4;
    padding: 3rem 3.5rem; }
  .afiliados-notificacion-privacidad p span {
    font-size: 1.6rem; }

.afiliados-notificacion-privacidad a {
  text-decoration: underline; }

.afiliados-notificacion-privacidad .border-top {
  border-top: #94ABB6 dotted 1px;
  padding-top: 3rem;
  margin-top: 3rem; }

.afiliados-notificacion-privacidad .border-left {
  border-left: #94ABB6 dotted 1px;
  padding-left: 2rem; }

.afiliados-notificacion-privacidad h2 {
  color: #003F44;
  font-size: 3.4rem;
  font-weight: 500;
  margin-bottom: 2.5rem; }

.afiliados-notificacion-privacidad h3 {
  color: #003F44;
  font-size: 2.8rem;
  font-weight: 400;
  margin-bottom: 2rem;
  display: flex;
  align-items: center; }
  .afiliados-notificacion-privacidad h3 img {
    margin-right: 1.5rem; }
  .afiliados-notificacion-privacidad h3 span {
    flex: 1; }

.afiliados-notificacion-privacidad h4 {
  color: #003F44;
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 1rem; }

.afiliados-notificacion-privacidad h5 {
  color: #003F44;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem; }
  .afiliados-notificacion-privacidad h5.sep-top {
    border-top: #94ABB6 dotted 1px;
    padding-top: 1.5rem;
    margin-top: 1.5rem; }

.afiliados-notificacion-privacidad ul {
  color: #003F44;
  font-size: 1.8rem;
  font-weight: 400;
  margin: 0 0 1.5rem 0;
  list-style: none;
  padding: 0; }
  .afiliados-notificacion-privacidad ul.black li:before {
    background: #003F44;
    border-color: #FFF; }
  .afiliados-notificacion-privacidad ul li {
    padding-left: 2.5rem;
    position: relative;
    margin-bottom: 1rem; }
    .afiliados-notificacion-privacidad ul li:before {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      border: #003F44 solid 2px;
      border-radius: 100%;
      left: 0;
      top: 0.75rem; }
    .afiliados-notificacion-privacidad ul li ul {
      margin-top: 1rem;
      margin-left: 1.5rem; }
      .afiliados-notificacion-privacidad ul li ul li:before {
        background: #003F44;
        border-color: #FFF; }

@media (max-width: 767.98px) {
  .afiliados-notificacion-privacidad h2 {
    font-size: 2.4rem; }
  .afiliados-notificacion-privacidad h3 {
    font-size: 2rem; }
  .afiliados-notificacion-privacidad p {
    font-size: 1.6rem; }
  .afiliados-notificacion-privacidad ul {
    font-size: 1.6rem; }
  .afiliados-notificacion-privacidad .border-left {
    padding-left: 1.5rem;
    border-left: none !important;
    border-top: 1px solid #dee2e6 !important;
    padding-bottom: 2rem;
    padding-top: 2rem; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 11-sep-2018, 17:51:01
    Author     : Rodri
*/
.quienes-somos .tab-pane {
  border-left: #03576F solid 1px;
  padding: 3rem; }
  .quienes-somos .tab-pane p {
    color: #02576E; }

.quienes-somos .nav-tabs.years {
  justify-content: flex-end;
  border-left: #FFF solid 1px;
  margin-left: -1px;
  border-bottom: #03576F solid 1px;
  margin-bottom: 0;
  margin-top: -3.5rem; }
  .quienes-somos .nav-tabs.years .nav-link {
    border-top-color: transparent;
    color: #03576F;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0 1.5rem;
    line-height: 4rem; }
    .quienes-somos .nav-tabs.years .nav-link.active, .quienes-somos .nav-tabs.years .nav-link:hover {
      background: #03576F;
      color: #FFF; }

.quienes-somos h1 {
  color: #003F44;
  font-size: 6rem;
  font-weight: 500;
  margin-bottom: 1.5rem; }

.quienes-somos h2 {
  color: #006A6C;
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 1.5rem; }

.quienes-somos p {
  color: #333;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem; }

.quienes-somos .border-top {
  border-top: #94ABB6 dotted 1px;
  padding-top: 3rem;
  margin-top: 3rem; }

.quienes-somos .border-bottom {
  border-bottom: #94ABB6 dotted 1px;
  padding-bottom: 6rem;
  margin-bottom: 3rem; }

.quienes-somos .green {
  margin-top: 3rem;
  background: #137476;
  padding: 3rem;
  color: #FFF; }
  .quienes-somos .green h2 {
    color: #FFF; }
  .quienes-somos .green ul {
    margin: 0 0 0 2rem;
    padding: 0; }
    .quienes-somos .green ul li {
      margin-bottom: 0.5rem; }

.quienes-somos .line {
  position: relative;
  padding: 2rem 5rem;
  background: url(../img/bg_line.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom: 6rem;
  margin-top: 3rem; }
  .quienes-somos .line .swiper-button-next,
  .quienes-somos .line .swiper-button-prev {
    background: none;
    width: 5rem;
    height: 5rem;
    margin-top: 0;
    top: 4.7rem; }
  .quienes-somos .line .swiper-button-next {
    right: 0; }
  .quienes-somos .line .swiper-button-prev {
    left: 0; }
  .quienes-somos .line .swiper-slide-active ul li {
    padding-top: 3.5rem; }
    .quienes-somos .line .swiper-slide-active ul li .detail {
      display: block; }
    .quienes-somos .line .swiper-slide-active ul li:before {
      width: 9rem;
      height: 9rem;
      top: 0.5rem;
      margin-left: -4.5rem;
      border-color: #EB992F; }
  .quienes-somos .line:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    left: 5rem;
    right: 5rem;
    height: 1px;
    top: 7.2rem;
    background: #FFF; }
  .quienes-somos .line ul {
    min-height: 35rem;
    position: relative;
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0; }
    @media (max-width: 767.98px) {
      .quienes-somos .line ul {
        min-height: 44rem; } }
    .quienes-somos .line ul li {
      transition: all .2s ease-out;
      display: inline-block;
      z-index: 1;
      margin: 0 4rem;
      padding-bottom: 4rem;
      cursor: pointer;
      vertical-align: top;
      position: relative; }
      .quienes-somos .line ul li:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 2rem;
        background: #FFF;
        top: 3rem;
        left: 50%;
        z-index: 1; }
      .quienes-somos .line ul li:before {
        z-index: 2;
        content: '';
        position: absolute;
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        background: #048086;
        border: #FFF solid 4px;
        border-radius: 100%;
        top: 4rem;
        left: 50%;
        margin-left: -1.2rem; }
      .quienes-somos .line ul li .detail {
        display: none;
        position: absolute;
        width: 35rem;
        background: #048086;
        color: #FFF;
        padding: 2rem;
        left: 50%;
        margin-left: -17.5rem;
        top: 13rem; }
        .quienes-somos .line ul li .detail:before {
          content: '';
          width: 1px;
          height: 15rem;
          background: #048086;
          position: absolute;
          z-index: 0;
          top: -9rem;
          left: 50%; }
        .quienes-somos .line ul li .detail p {
          /*margin: 0;*/
          color: #FFF;
          font-size: 1.6rem;
          font-weight: 400;
          font-style: italic; }
        @media (max-width: 767.98px) {
          .quienes-somos .line ul li .detail {
            width: 26rem;
            margin-left: -13rem; } }
      .quienes-somos .line ul li .year {
        position: relative;
        z-index: 3;
        color: #FFF;
        font-size: 2rem;
        font-weight: 500; }
      .quienes-somos .line ul li.active {
        padding-top: 3.5rem; }
        .quienes-somos .line ul li.active .detail {
          display: block; }
        .quienes-somos .line ul li.active:before {
          width: 9rem;
          height: 9rem;
          top: 0.5rem;
          margin-left: -4.5rem;
          border-color: #EB992F; }

@media (max-width: 767.98px) {
  .quienes-somos {
    padding-top: 3rem; }
    .quienes-somos h1 {
      font-size: 3rem; }
    .quienes-somos .nav-tabs.years {
      margin-top: 2.5rem; } }

.page-programa-calificaciones-por-estrellas section.programa-calificaciones-por-estrellas-main p {
  font-size: 1.8rem;
  color: #003F44;
  line-height: 140%;
  margin-bottom: 2rem;
  font-weight: 400; }
  .page-programa-calificaciones-por-estrellas section.programa-calificaciones-por-estrellas-main p a {
    color: #047F85;
    text-decoration: underline; }

.page-programa-calificaciones-por-estrellas section.programa-calificaciones-por-estrellas-main .files-cont {
  margin-bottom: 3rem; }
  .page-programa-calificaciones-por-estrellas section.programa-calificaciones-por-estrellas-main .files-cont .files {
    background: #F4F4F4;
    padding: 3.5rem; }
    .page-programa-calificaciones-por-estrellas section.programa-calificaciones-por-estrellas-main .files-cont .files a {
      color: #047F85;
      position: relative;
      /*padding-left: 4rem;*/
      font-weight: 400;
      line-height: 130%;
      align-items: center;
      display: flex; }
      .page-programa-calificaciones-por-estrellas section.programa-calificaciones-por-estrellas-main .files-cont .files a img {
        /*                            position: absolute;
                            top: 0.5rem;
                            left: 0rem;*/
        margin-right: 1rem; }
      .page-programa-calificaciones-por-estrellas section.programa-calificaciones-por-estrellas-main .files-cont .files a strong {
        display: block;
        flex: 1; }

@media (max-width: 767.98px) {
  .page-programa-calificaciones-por-estrellas section.programa-calificaciones-por-estrellas-main p {
    font-size: 1.6rem; }
  .page-programa-calificaciones-por-estrellas section.programa-calificaciones-por-estrellas-main .files-cont .files a {
    margin-top: 1rem;
    margin-bottom: 1rem; } }

.page-responsabilidad-social-corporativa .go-next {
  position: absolute;
  left: 0;
  top: 44rem;
  z-index: 1;
  display: flex;
  width: 11.6rem;
  height: 11.6rem;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: rgba(71, 152, 157, 0.2) solid 2.3rem; }
  .page-responsabilidad-social-corporativa .go-next span {
    transition: all .4s ease-out;
    background: #FFF;
    display: flex;
    width: 7rem;
    height: 7rem;
    justify-content: center;
    align-items: center;
    border-radius: 100%; }

.page-responsabilidad-social-corporativa .section-banner {
  background-color: #1D868B;
  background-size: auto 100%;
  background-repeat: no-repeat;
  min-height: 50rem;
  background-position: right center; }
  .page-responsabilidad-social-corporativa .section-banner:before {
    display: none; }
  .page-responsabilidad-social-corporativa .section-banner h2 {
    font-size: 5.4rem; }
  .page-responsabilidad-social-corporativa .section-banner p {
    margin: 0;
    font-size: 2.1rem;
    line-height: 2.6rem; }

.page-responsabilidad-social-corporativa article {
  position: relative;
  margin: 0;
  background-size: auto 100%;
  background-position: center right;
  background-repeat: no-repeat;
  overflow: hidden;
  text-align: right; }
  .page-responsabilidad-social-corporativa article:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 60%;
    z-index: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+90,ffffff+100&1+0,0.78+90,0+100 */
    background: -moz-linear-gradient(left, white 0%, rgba(255, 255, 255, 0.78) 90%, rgba(255, 255, 255, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, white 0%, rgba(255, 255, 255, 0.78) 90%, rgba(255, 255, 255, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0.78) 90%, rgba(255, 255, 255, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
    /* IE6-9 */ }
  .page-responsabilidad-social-corporativa article .bg {
    display: none; }
  .page-responsabilidad-social-corporativa article h2 {
    color: #003F44;
    font-size: 4rem; }
  .page-responsabilidad-social-corporativa article h3 {
    color: #003F44;
    font-size: 2.1rem; }
  .page-responsabilidad-social-corporativa article .cont-text {
    transition: all .4s ease-out;
    position: relative;
    padding: 10rem 0 20rem 0; }
    .page-responsabilidad-social-corporativa article .cont-text .descripcion {
      display: none;
      padding-top: 2rem; }
    .page-responsabilidad-social-corporativa article .cont-text .text {
      position: relative;
      z-index: 1; }
    .page-responsabilidad-social-corporativa article .cont-text ul {
      padding-left: 2rem;
      list-style-position: inside;
      direction: rtl; }
      .page-responsabilidad-social-corporativa article .cont-text ul li {
        margin-bottom: .2rem; }
    .page-responsabilidad-social-corporativa article .cont-text:before {
      transition: all .4s ease-out;
      content: '';
      background: rgba(238, 238, 238, 0.9);
      position: absolute;
      right: -4rem;
      top: 0;
      bottom: 0;
      width: 100vw;
      z-index: 0; }
    .page-responsabilidad-social-corporativa article .cont-text .more {
      transition: all .4s ease-out;
      position: absolute;
      right: -11.8rem;
      /*bottom: -14.6rem;*/
      top: 8.8rem;
      align-items: center;
      display: flex;
      transform: translate3d(0, 0, 0); }
      .page-responsabilidad-social-corporativa article .cont-text .more span {
        transition-delay: .2s;
        font-size: 1.6rem;
        font-weight: bold;
        color: #003F44; }
      .page-responsabilidad-social-corporativa article .cont-text .more i {
        margin: 2rem;
        display: flex;
        width: 11.6rem;
        height: 11.6rem;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: rgba(71, 152, 157, 0.2) solid 2.3rem; }
        .page-responsabilidad-social-corporativa article .cont-text .more i span {
          transition: all .4s ease-out;
          background: #FFF;
          display: flex;
          width: 7rem;
          height: 7rem;
          justify-content: center;
          align-items: center;
          border-radius: 100%; }
  .page-responsabilidad-social-corporativa article.article-right {
    background-position: center left;
    text-align: left; }
    .page-responsabilidad-social-corporativa article.article-right:before {
      left: auto;
      right: 0;
      transform: rotate(180deg);
      width: 60%; }
    .page-responsabilidad-social-corporativa article.article-right .cont-text:before {
      content: '';
      background: rgba(238, 238, 238, 0.9);
      position: absolute;
      left: -4rem;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100vw;
      z-index: 0; }
    .page-responsabilidad-social-corporativa article.article-right .cont-text ul {
      list-style-position: inside;
      direction: ltr; }
    .page-responsabilidad-social-corporativa article.article-right .cont-text .more {
      right: auto;
      left: -11.8rem;
      /*left: -13.6rem;*/
      flex-direction: row-reverse; }
    .page-responsabilidad-social-corporativa article.article-right.active .cont-text .more {
      transform: translate3d(0, -19rem, 0); }
  .page-responsabilidad-social-corporativa article.active .cont-text {
    padding-bottom: 10rem; }
    .page-responsabilidad-social-corporativa article.active .cont-text:before {
      right: -6rem;
      background: #FFF; }
    .page-responsabilidad-social-corporativa article.active .cont-text .more {
      /*top: 4rem;*/
      /*right: -13.6rem;*/
      /*bottom: 100%;*/
      /*                    bottom: auto;
                                        top: auto;*/
      /*transform: translateY(-5rem);*/
      transform: translate3d(2rem, -19rem, 0); }
      .page-responsabilidad-social-corporativa article.active .cont-text .more > span {
        transition-delay: 0s;
        opacity: 0;
        pointer-events: none; }
      .page-responsabilidad-social-corporativa article.active .cont-text .more i {
        border: 0; }
        .page-responsabilidad-social-corporativa article.active .cont-text .more i span {
          transform: rotate(45deg); }

#plataforma-sensibilidad {
  display: none;
  width: 100rem;
  padding: 0rem;
  padding-right: 1.5rem; }
  @media (max-width: 767.98px) {
    #plataforma-sensibilidad {
      padding: 1.5rem; } }
  #plataforma-sensibilidad h2 {
    font-size: 2.4rem;
    font-weight: 500;
    color: #006A6C;
    margin-bottom: 1.5rem; }
  #plataforma-sensibilidad p {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 2rem;
    line-height: 120%;
    font-weight: 400; }

@media (max-width: 767.98px) {
  .page-responsabilidad-social-corporativa .section-banner h2 {
    font-size: 2.2rem; }
  .page-responsabilidad-social-corporativa .go-next {
    left: auto;
    /*right: 2rem;*/ }
  .page-responsabilidad-social-corporativa article {
    text-align: left;
    padding: 0rem 2rem;
    /*            &#first-step{
                            .cont-text{
                                padding-top: 14rem;
                            }
                        }*/ }
    .page-responsabilidad-social-corporativa article:before {
      display: none; }
    .page-responsabilidad-social-corporativa article.active .cont-text:before {
      right: -2rem; }
    .page-responsabilidad-social-corporativa article.active .cont-text .more {
      position: absolute;
      top: -12rem;
      right: -4rem;
      justify-content: flex-end;
      flex-direction: row;
      transform: translate3d(0, 0, 0) !important; }
    .page-responsabilidad-social-corporativa article.article-right .cont-text:before {
      left: -2rem; }
    .page-responsabilidad-social-corporativa article.article-right .cont-text .more {
      left: -2.5rem; }
    .page-responsabilidad-social-corporativa article h2 {
      line-height: 105%;
      margin-bottom: 1.5rem;
      font-size: 3rem;
      margin-bottom: 1.5rem; }
    .page-responsabilidad-social-corporativa article h3 {
      font-size: 1.8rem; }
    .page-responsabilidad-social-corporativa article .cont-text {
      padding: 8rem 0rem 6rem; }
      .page-responsabilidad-social-corporativa article .cont-text:before {
        right: -2rem;
        background: rgba(238, 238, 238, 0.85); }
      .page-responsabilidad-social-corporativa article .cont-text .more {
        position: relative;
        flex-direction: row-reverse;
        transition: unset;
        left: -2rem;
        top: 1rem;
        justify-content: flex-end; } }

.page-empleos section.empleos-main {
  padding-bottom: 0rem; }
  .page-empleos section.empleos-main .separador {
    border-bottom: 1px dotted #94ABB6;
    padding-top: 6rem; }
  .page-empleos section.empleos-main h2 {
    font-size: 3.6rem;
    font-weight: 500;
    color: #006A6C;
    line-height: 100%;
    margin-bottom: 2rem; }
  .page-empleos section.empleos-main h3 {
    font-size: 3.2rem; }
  .page-empleos section.empleos-main p, .page-empleos section.empleos-main ul {
    margin-left: 2rem;
    font-size: 2rem;
    font-weight: 400;
    color: #333; }
  .page-empleos section.empleos-main ul li {
    margin: 0.5rem 0; }
  .page-empleos section.empleos-main .empleos-blocks h2 {
    font-size: 2.6rem;
    font-weight: 400; }
  .page-empleos section.empleos-main .img-bot {
    background: #EB992F;
    display: flex;
    align-items: center;
    padding: 2rem; }
    .page-empleos section.empleos-main .img-bot img {
      margin-right: 3rem; }
    .page-empleos section.empleos-main .img-bot p {
      color: #FFF;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 120%; }
      .page-empleos section.empleos-main .img-bot p a {
        text-decoration: underline;
        color: #FFF; }

.page-empleos section.empleos-more-info {
  background: #367e84; }
  .page-empleos section.empleos-more-info h3 {
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 120%;
    color: #fff;
    margin-bottom: 2rem;
    border-bottom: 1px solid #fff;
    padding-bottom: 1rem; }
  .page-empleos section.empleos-more-info p {
    font-weight: 400;
    line-height: 130%;
    color: #fff;
    margin-bottom: 2rem; }
    .page-empleos section.empleos-more-info p a {
      font-weight: 500; }

.page-empleos section.empleos-case-study h3 {
  font-size: 3.2rem; }

.page-empleos section.empleos-prizes {
  background: #163e43; }
  .page-empleos section.empleos-prizes h2 {
    font-size: 3.6rem;
    font-weight: 500;
    color: #fff;
    line-height: 100%; }
  .page-empleos section.empleos-prizes .mejores-patronos {
    max-width: 40%;
    margin: 0 auto;
    display: block; }
    @media (max-width: 767.98px) {
      .page-empleos section.empleos-prizes .mejores-patronos {
        max-width: 60%; } }
  .page-empleos section.empleos-prizes .line {
    position: relative;
    padding: 2rem 5rem 0;
    margin-top: 3rem; }
    .page-empleos section.empleos-prizes .line .swiper-button-next,
    .page-empleos section.empleos-prizes .line .swiper-button-prev {
      background: none;
      width: 5rem;
      height: 5rem;
      margin-top: 0;
      top: 4.7rem; }
    .page-empleos section.empleos-prizes .line .swiper-button-next {
      right: 0; }
    .page-empleos section.empleos-prizes .line .swiper-button-prev {
      left: 0; }
    .page-empleos section.empleos-prizes .line .swiper-slide-active ul li {
      padding-top: 3.5rem; }
      .page-empleos section.empleos-prizes .line .swiper-slide-active ul li .detail {
        display: block; }
      .page-empleos section.empleos-prizes .line .swiper-slide-active ul li:before {
        width: 9rem;
        height: 9rem;
        top: 0.5rem;
        margin-left: -4.5rem;
        border-color: #EB992F; }
    .page-empleos section.empleos-prizes .line:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      left: 5rem;
      right: 5rem;
      height: 1px;
      top: 7.2rem;
      background: #FFF; }
    .page-empleos section.empleos-prizes .line ul {
      position: relative;
      list-style: none;
      text-align: center;
      padding: 0;
      margin: 0; }
      .page-empleos section.empleos-prizes .line ul li {
        transition: all .2s ease-out;
        display: inline-block;
        z-index: 1;
        margin: 0 4rem;
        padding-bottom: 4rem;
        cursor: pointer;
        vertical-align: top;
        position: relative; }
        .page-empleos section.empleos-prizes .line ul li:after {
          content: '';
          position: absolute;
          width: 1px;
          height: 2rem;
          background: #FFF;
          top: 3rem;
          left: 50%;
          z-index: 1; }
        .page-empleos section.empleos-prizes .line ul li:before {
          z-index: 2;
          content: '';
          position: absolute;
          display: block;
          width: 2.4rem;
          height: 2.4rem;
          background: #048086;
          border: #FFF solid 4px;
          border-radius: 100%;
          top: 4rem;
          left: 50%;
          margin-left: -1.2rem; }
        .page-empleos section.empleos-prizes .line ul li .detail {
          display: none;
          position: absolute;
          width: 35rem;
          background: #048086;
          color: #FFF;
          padding: 2rem;
          left: 50%;
          margin-left: -17.5rem;
          top: 13rem; }
          .page-empleos section.empleos-prizes .line ul li .detail:before {
            content: '';
            width: 1px;
            height: 15rem;
            background: #048086;
            position: absolute;
            z-index: 0;
            top: -9rem;
            left: 50%; }
          .page-empleos section.empleos-prizes .line ul li .detail p {
            /*margin: 0;*/
            color: #FFF;
            font-size: 1.6rem;
            font-weight: 400;
            font-style: italic; }
          @media (max-width: 767.98px) {
            .page-empleos section.empleos-prizes .line ul li .detail {
              width: 26rem;
              margin-left: -13rem; } }
        .page-empleos section.empleos-prizes .line ul li .year {
          position: relative;
          z-index: 3;
          color: #FFF;
          font-size: 2rem;
          font-weight: 500; }
        .page-empleos section.empleos-prizes .line ul li.active {
          padding-top: 3.5rem; }
          .page-empleos section.empleos-prizes .line ul li.active .detail {
            display: block; }
          .page-empleos section.empleos-prizes .line ul li.active:before {
            width: 9rem;
            height: 9rem;
            top: 0.5rem;
            margin-left: -4.5rem;
            border-color: #EB992F; }

.page-empleos section.empleos-jobs-links {
  padding-top: 7rem;
  padding-bottom: 7rem; }

.page-empleos section.empleos-igualdad {
  background: #f2f2f2;
  padding-top: 7rem;
  padding-bottom: 7rem; }

.page-empleos .jobs-links {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none; }
  .page-empleos .jobs-links li {
    margin-right: 1.5rem; }
    .page-empleos .jobs-links li:last-child {
      margin-right: 0; }

.page-empleos .swiper-empleos {
  width: 100%;
  height: 100%; }
  .page-empleos .swiper-empleos .swiper-wrapper {
    align-items: stretch; }
  .page-empleos .swiper-empleos .swiper-pagination-bullet-active {
    background: #ffffff; }
  .page-empleos .swiper-empleos .swiper-slide {
    background: #367e84;
    color: #fff;
    padding: 3rem 1.8rem;
    height: auto; }
    .page-empleos .swiper-empleos .swiper-slide.dark {
      background: #163e43; }
    .page-empleos .swiper-empleos .swiper-slide h3 {
      font-size: 2.4rem; }
    .page-empleos .swiper-empleos .swiper-slide p {
      margin: .6em 0;
      color: #fff; }
    .page-empleos .swiper-empleos .swiper-slide ul {
      color: #fff; }

@media (max-width: 767.98px) {
  .page-empleos section.empleos-main .img-bot p {
    font-size: 1.8rem; }
  .page-empleos section.empleos-more-info {
    padding-top: 3rem; } }

.page-programa-de-cuidadores section.programa-de-cuidadores-main h3 {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 130%;
  color: #047F85;
  margin-bottom: 3rem; }

.page-programa-de-cuidadores section.programa-de-cuidadores-main h2 {
  font-size: 3.4rem;
  font-weight: 400;
  line-height: 110%;
  color: #047F85;
  margin-bottom: 1.5rem; }

.page-programa-de-cuidadores section.programa-de-cuidadores-main .ventajas {
  margin-bottom: 5rem; }
  .page-programa-de-cuidadores section.programa-de-cuidadores-main .ventajas .col-ventaja {
    border-top: 4px solid #47989D;
    padding-top: 2.5rem; }
    .page-programa-de-cuidadores section.programa-de-cuidadores-main .ventajas .col-ventaja h4 {
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 120%;
      color: #003F44;
      margin-bottom: 1.5rem; }
    .page-programa-de-cuidadores section.programa-de-cuidadores-main .ventajas .col-ventaja ul li {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 120%;
      color: #333;
      margin-bottom: 1.5rem;
      position: relative;
      padding-left: 2rem;
      padding-right: 2rem; }
      .page-programa-de-cuidadores section.programa-de-cuidadores-main .ventajas .col-ventaja ul li:before {
        position: absolute;
        top: .5rem;
        left: 0; }
      .page-programa-de-cuidadores section.programa-de-cuidadores-main .ventajas .col-ventaja ul li .info {
        color: #048086; }
    .page-programa-de-cuidadores section.programa-de-cuidadores-main .ventajas .col-ventaja a.btn {
      margin-bottom: 1.5rem;
      width: 100%; }

.page-programa-de-cuidadores section.programa-de-cuidadores-main .mas {
  margin-bottom: 5rem; }
  .page-programa-de-cuidadores section.programa-de-cuidadores-main .mas p {
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
    color: #333;
    display: block;
    width: 95%;
    margin: 0px auto; }

.page-programa-de-cuidadores section.programa-de-cuidadores-main .registrate {
  margin-top: 6rem;
  margin-bottom: 4rem; }
  .page-programa-de-cuidadores section.programa-de-cuidadores-main .registrate .box-registrate {
    position: relative;
    background: #003F44;
    display: flex;
    align-items: center;
    height: 15rem; }
    .page-programa-de-cuidadores section.programa-de-cuidadores-main .registrate .box-registrate:before {
      content: " ";
      position: absolute;
      left: 0;
      width: 5.5rem;
      height: 100%;
      background: #47989D; }
    .page-programa-de-cuidadores section.programa-de-cuidadores-main .registrate .box-registrate .hand-stop {
      background: #003F44 url(../img/icons/hand-stop.png) no-repeat;
      background-size: 28px;
      background-position: center;
      border-radius: 10rem;
      position: absolute;
      left: 1.8rem;
      top: 4rem;
      width: 7rem;
      height: 7rem; }
    .page-programa-de-cuidadores section.programa-de-cuidadores-main .registrate .box-registrate p {
      width: 80%;
      margin: 0px auto;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 120%;
      color: #FFF;
      position: relative;
      left: 2rem; }
      .page-programa-de-cuidadores section.programa-de-cuidadores-main .registrate .box-registrate p a {
        text-decoration: underline; }

@media (max-width: 767.98px) {
  .page-programa-de-cuidadores section.programa-de-cuidadores-main h2 {
    font-size: 2.4rem; }
  .page-programa-de-cuidadores section.programa-de-cuidadores-main h3 {
    font-size: 1.8rem; }
  .page-programa-de-cuidadores section.programa-de-cuidadores-main .ventajas {
    margin-bottom: 1rem; }
    .page-programa-de-cuidadores section.programa-de-cuidadores-main .ventajas .col-ventaja {
      padding-bottom: 1.5rem; }
      .page-programa-de-cuidadores section.programa-de-cuidadores-main .ventajas .col-ventaja h4 {
        font-size: 2.2rem; }
  .page-programa-de-cuidadores section.programa-de-cuidadores-main .mas p {
    font-size: 1.8rem; }
  .page-programa-de-cuidadores section.programa-de-cuidadores-main .registrate {
    margin-top: 3rem; }
    .page-programa-de-cuidadores section.programa-de-cuidadores-main .registrate .box-registrate {
      justify-content: center;
      height: 81vw; }
      .page-programa-de-cuidadores section.programa-de-cuidadores-main .registrate .box-registrate:before {
        content: " ";
        position: absolute;
        top: 0;
        width: 100%;
        height: 5.5rem; }
      .page-programa-de-cuidadores section.programa-de-cuidadores-main .registrate .box-registrate .hand-stop {
        left: auto;
        top: 2rem; }
      .page-programa-de-cuidadores section.programa-de-cuidadores-main .registrate .box-registrate p {
        width: 100%;
        margin: 0px auto;
        position: relative;
        padding: 9rem 1.5rem 1rem 1.5rem;
        top: 0rem;
        left: 0rem; } }

.page-registro-de-cuidadores section.registro-de-cuidadores-main h3 {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 130%;
  color: #047F85;
  margin-bottom: 3rem; }

.page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table {
  border: 1px solid #036A6F; }
  .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table-head-cell {
    background: #036a6f;
    background: -moz-linear-gradient(top, #036a6f 0%, #003f44 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #036a6f), color-stop(100%, #003f44));
    background: -webkit-linear-gradient(top, #036a6f 0%, #003f44 100%);
    background: -o-linear-gradient(top, #036a6f 0%, #003f44 100%);
    background: -ms-linear-gradient(top, #036a6f 0%, #003f44 100%);
    background: linear-gradient(to bottom, #036a6f 0%, #003f44 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#036a6f', endColorstr='#003f44', GradientType=0 );
    height: 6.8rem;
    display: flex;
    align-items: center; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table-head-cell .type-name {
      display: inline-block;
      background: #FFF;
      font-size: 3.4rem;
      font-weight: 400;
      color: #036A6F;
      line-height: 6.8rem;
      text-align: center;
      height: 100%;
      flex: 0.3; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table-head-cell .type-info {
      font-size: 2.1rem;
      font-weight: 400;
      color: #FFF;
      text-align: center;
      flex: 1; }
  .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table-body-row {
    border-bottom: 1px solid #EEE; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table-body-row .table-cell {
      padding: 3rem; }
      .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table-body-row .table-cell h5 {
        font-size: 2rem;
        font-weight: 500;
        line-height: 100%;
        color: #003F44;
        margin-bottom: 1rem; }
      .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table-body-row .table-cell p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 120%;
        color: #666; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table-body-row > div:first-child {
      border-right: 1px solid #EEE; }

.page-registro-de-cuidadores section.registro-de-cuidadores-extra-info {
  padding-top: 0rem; }
  .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .first-column .descargar-text {
    display: flex;
    padding-bottom: 5rem;
    border-bottom: 1px dotted #94ABB6; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .first-column .descargar-text img {
      margin-right: 2.5rem; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .first-column .descargar-text p {
      font-size: 2rem;
      font-weight: 400;
      line-height: 100%;
      color: #003F44; }
  .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .first-column .descargar-botones {
    padding-top: 3.5rem;
    padding-bottom: 5rem;
    border-bottom: 1px dotted #94ABB6; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .first-column .descargar-botones.no-border {
      border-bottom: none; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .first-column .descargar-botones h4 {
      font-size: 2.1rem;
      font-weight: 500;
      line-height: 100%;
      color: #003F44;
      margin-bottom: .5rem; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .first-column .descargar-botones p {
      font-size: 2rem;
      font-weight: 400;
      color: #333;
      margin-bottom: 1rem; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .first-column .descargar-botones ul {
      padding-left: 0rem !important; }
      .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .first-column .descargar-botones ul li {
        list-style: none;
        color: #047F85;
        padding-left: 0rem;
        font-size: 1.8rem;
        font-weight: 400;
        color: #047F85; }
  .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .second-column {
    border-left: 1px dotted #94ABB6; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .second-column .info-item {
      margin-bottom: 2.3rem; }
      .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .second-column .info-item p.large {
        font-size: 2rem;
        font-weight: 500;
        color: #003F44;
        margin-bottom: .3rem; }
      .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .second-column .info-item p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 120%;
        color: #003F44; }
        .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .second-column .info-item p strong {
          font-weight: 500; }
      .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .second-column .info-item a {
        text-decoration: underline; }

@media (max-width: 767.98px) {
  .page-registro-de-cuidadores section.registro-de-cuidadores-main h3 {
    font-size: 1.8rem; }
  .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table {
    border: 1px solid #036A6F; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table-head-cell .type-name {
      font-size: 2.6rem; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table-head-cell .type-info {
      font-size: 1.6rem; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table-body-row {
      border-bottom: 1px solid #EEE; }
      .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table-body-row .table-cell {
        padding: 2rem; }
        .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table-body-row .table-cell h5 {
          font-size: 1.8rem; }
      .page-registro-de-cuidadores section.registro-de-cuidadores-main .the-table-body-row > div:first-child {
        border-right: none; }
  .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .first-column .descargar-text {
    padding-bottom: 2.5rem;
    align-items: center; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .first-column .descargar-text p {
      font-size: 1.6rem; }
  .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .first-column .descargar-botones {
    padding-bottom: 2.5rem; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .first-column .descargar-botones ul {
      margin-bottom: 1.5rem; }
    .page-registro-de-cuidadores section.registro-de-cuidadores-extra-info .first-column .descargar-botones p {
      font-size: 1.8rem; } }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 16-oct-2018, 16:15:41
    Author     : Rodri
*/
.section-centro-prensa h1 {
  color: #003F44;
  font-size: 6rem;
  font-weight: 500; }

.section-centro-prensa h3 {
  color: #047F85;
  font-size: 2.4rem;
  font-weight: 500; }

.section-centro-prensa .gray {
  padding: 2rem;
  background: #EEEEEE; }

.section-centro-prensa p a:not(.tel) {
  color: #047F85; }

.section-centro-prensa a.mailto {
  text-decoration: underline; }

.list-noticias #infscr-loading {
  clear: both;
  text-align: center;
  padding-top: 2rem;
  width: 100%;
  margin-bottom: 2rem; }

.list-noticias article {
  margin-bottom: 3rem; }

@media (max-width: 767.98px) {
  section.section-centro-prensa h1 {
    font-size: 3rem; }
  section.section-centro-prensa h3 {
    font-size: 2.2rem; } }
