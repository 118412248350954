/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 04-jul-2018, 12:57:20
    Author     : PC03-MAXI
*/

.tip{
    display: flex;
    align-items: center;
    .icon-box{
        margin-right:1.8rem;
        min-width: 6.5rem;
        min-height: 5.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tip-text{
        color:$color2;
        font-size:3rem;
        font-weight:400;
        line-height: 110%;

    }
    
    @include media-breakpoint-down(sm) {
	.tip-text{
	    font-size:2rem;
	}
    }
}