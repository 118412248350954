/* ------------------------------------------------------------ *\
        Btn
\* ------------------------------------------------------------ */

.btn{
    line-height: 4.2rem;
    border-radius:0;
    padding: 0 1.5rem;
    height: 4.2rem;
    font-size:1.6rem;
    font-weight: 500;
    color:white;
    text-transform:uppercase;

    &.btn-icon-left {
        svg {
            display: inline-block;
            vertical-align: middle;
            width: 2.4rem;
            height: 2.4rem;
            fill: #FFF;
            margin-right: 0.5rem;
            margin-bottom: 0.4rem;
        }
    }

    &.btn-icon{
        display: flex;
        align-items: center;
        padding: 0 1.5rem;

        i{
            font-size: 2.5rem;
            margin-left:1.5rem;
        }
    }

    &.green {
        color: $va_dark !important;

        i {
            margin-right: 0.5rem;
        }
    }

    &.white-text {
        color: $white;
    }

    /* &:hover,&:focus,&:active{
         background:$pri!important;

     }*/
    &.btn-lg {
        line-height: 5.2rem;
        height: 5.2rem;
    }
}


.btn-outline-primary{
    border-color: $va_dark;
    color: $va_dark;

    &:hover,&:focus,&:active{
        background:$va_dark!important;
        color: white !important;
        border-color: $va_dark;

        &.btn-icon-left {
            svg {
                fill: white !important;
            }
        }
    }

    &.btn-icon-left {
        svg {
            fill: $va_dark;
        }
    }
}


.btn-outline-secondary{
    border-color: $color1;
    color: $color1;

    &:hover,&:focus,&:active{
        background:$color1!important;
        color: white !important;
        border-color: $color1;

        &.btn-icon-left {
            svg {
                fill: white !important;
            }
        }
    }

    &.btn-icon-left {
        svg {
            fill: $color1;
        }
    }
}

.btn-outline-primary-light{
    border-color: #048086;
    color: #048086;

    &:hover,&:focus,&:active{
        background:#048086!important;
        color: white !important;
        border-color: #048086;

        &.btn-icon-left {
            svg {
                fill: white !important;
            }
        }
    }

    &.btn-icon-left {
        svg {
            fill: #048086;
        }
    }
}

.btn-outline-light{


    &:hover,&:focus,&:active{
        background:white!important;

        svg {
            fill: $va_dark !important;
        }
    }

}

.btn-primary{

    background:$sec;
    border: none;
    color: white;
    &:hover,&:focus,&:active{
        background:$pri!important;

    }
}

.btn-link{
    color:$sec;

}
.btn-long{
    padding: 1.5rem 5.5rem;
}
.btn-link{
    color:$sec;

}

.btn-secondary{

    background:$pri;
    border: none;
    color: white;
    &:hover,&:focus,&:active{

        background:$sec!important;
    }
}


.btn-tertiary{

    background:$blue;

    &:hover,&:focus,&:active{

        background:$gray!important;
    }


}

.btn-white{

    background:white;
    color:#678796;

    &:hover,&:focus,&:active{
        color: white;
        background:$gray!important;
    }


}

.btnLlamanos {
    background-color: #E29B46 !important;

    i {
        margin-left: 15px;
        font-size: 1.3em;
    }
}

