/*
    Author     : MAXI
*/

.page-cuidado-coordinado{


    
    .main-content{
        position:relative;

        &:before{
            content:" ";
            width:50%;
            height:100%;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+0,ffffff+50 */
            background: rgb(238,238,238); /* Old browsers */
            background: -moz-linear-gradient(left, rgba(238,238,238,1) 0%, rgba(255,255,255,1) 50%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(238,238,238,1) 0%,rgba(255,255,255,1) 50%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(238,238,238,1) 0%,rgba(255,255,255,1) 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
            position:absolute;
            right:0;
            top:0;
            
        }


    }

    section{
        padding-top:8.4rem;
        padding-bottom:8.4rem;
    }

    section.unidad-dorada{


        color:$gold;

        h2{
            font-size:6.5rem;
            font-weight:500;
        }

        p.subtitle{

            font-size:2rem;
        }

        p.buenas-manos{
            font-size:2rem;
            font-weight: 500;
            margin-bottom: 3rem;
        }

        .numeros-contacto{
            margin-top:3rem;

            h3{
                i{
                    background:$gold;
                    padding: 0.8rem 1rem;
                    margin-right: 2rem;
                }
                font-size:3.4rem;
                margin-bottom: 3rem;
            }
        }

        .btn-localidades{
            padding: 0rem 3rem;
            display: inline-flex;
            color:$gold;
            border-color:$gold;
            i{
                font-size: 2.5rem; 

            }


        }

    }


    section.camp{

        color:$color2;

        h2{
            font-size:6.5rem;
            font-weight:500;
        }


        p.subtitle{
            font-size:2rem;
            margin-bottom:3rem;
        }


        .camp-ofrece{
            margin-top:3rem;

            h3{
                font-size:3.4rem;
                margin-bottom:2.5rem;
                color:$color2;
            }
        }


        .directorio-camp{

            margin-top:2rem;
            h4{

                font-size:2.4rem;
                color:$color2;
                margin-bottom: 0.5rem;
            }

            .btn{  
                color: $color2;
                padding:0;
            }
        }
    }

    p {
        font-size: 2rem;

        &.small {
            font-size: 1.6rem;
        }
    }

    section.camp{
        ul {
            font-size: 1.6rem;

            li {
                padding-left: 2.5rem;
                position: relative;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0.6rem;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .section-banner {
            padding-top: 0;
            padding-bottom: 1.5rem;
        }
        .main-content {
            &:before {
                display: none;
            }
        }
        .unidad-dorada {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
        h2 {
            font-size: 2.5rem !important;
        }
        h3 {
            font-size: 2rem !important;
            margin-bottom: 2rem;

            i {
                img {
                    width: 2rem;
                }
            }
        }
        p.subtitle, p.buenas-manos, p {
            font-size: 1.8rem !important;
        }
        .camp {
            padding: 0 0 3rem 0;
        }
    }

}