/* ------------------------------------------------------------ *\
        Main
\* ------------------------------------------------------------ */

/*
 *  ----------------- VARIABLES & B4 -----------------
 */

//Para cambiar opciones de bootstrap editar bootstrap.scss - para mantenerlo en git es necesario clonar el de la carpeta node_modules de lo contrario es ignorado
@import 'bootstrap_conf';
@import 'materialdesignicons/materialdesignicons.scss';
@import 'variables';





//@import 'bootstrap_custom/awesome-bootstrap-checkbox.scss';


/*
 *  ----------------- PLUGINS -----------------
 */


//@import '../node_modules/pretty-checkbox/src/pretty-checkbox.scss';


/*
 *  ----------------- UTILS -----------------
 */

@import 'utils/mixins';
@import 'utils/helpers';




/*
 *  ----------------- LAYOUT -----------------
 */


@import 'layout/base';
@import 'layout/header';
@import 'layout/nav';
@import 'layout/footer';
@import 'layout/fixes';
/*@import 'layout/icomoon';*/
@import 'layout/iconmd';
@import 'layout/ie11';

/*
 *  ----------------- COMPONENTES -----------------
 */
@import 'components/btn';
@import 'components/form';
@import 'components/pager';
@import 'components/slider';

@import 'components/popover';

@import 'components/list';

@import 'components/cards';
@import 'components/article';
@import 'components/stepswizard';
@import 'components/checkbox';
@import 'components/radio';
@import 'components/asistente';
@import 'components/tip';
@import 'components/container';


@import 'components/socialnet';
@import 'components/download_list';
@import 'components/chat';
/*
 *  ----------------- SECTIONS -----------------
 */
@import 'sections/sections';
@import 'sections/home';
@import 'sections/newsletter';
@import 'sections/orientacion';
@import 'sections/planes-medicos';
@import 'sections/planes-beneficios';
@import 'sections/cuidado-personal';
@import 'sections/cuidado-coordinado';
@import 'sections/proveedores-farmacias';
@import 'sections/bienestar-integral';
@import 'sections/clinicas-especializadas';
@import 'sections/planes-detalle';
@import 'sections/sectionBanner';
@import 'sections/afiliados';
@import 'sections/proveedores';
@import 'sections/documents';
@import 'sections/unete';
@import 'sections/contacto';
//@import 'sections/asistente';
@import 'sections/detalle-noticias';
@import 'sections/glosario';
@import 'sections/faqs';
@import 'sections/medicare';
@import 'sections/programa-mejoramiento-calidad';
@import 'sections/cubierta-fuera-de-red';
@import 'sections/politica-de-transicion';
@import 'sections/determinacion-organizacionales';
@import 'sections/programa-manejo-de-terapia';
@import 'sections/bienestar-integral';
@import 'sections/cuidado-personal';
@import 'sections/prevencion-educacion';
@import 'sections/tecnologia';
@import 'sections/cubierta-fuera-de-red-de-farmacias';
@import 'sections/determinaciones-cubierta';
@import 'sections/cuidado-y-servicio-medico';

@import 'sections/afiliados-solicitud-materiales';
@import 'sections/afiliados-pago-primas';
@import 'sections/afiliados-desafiliacion';
@import 'sections/reportar-fraude';
@import 'sections/apelaciones-y-querellas';
@import 'sections/nombramiento_representante';
@import 'sections/notificacion_privacidad';

@import 'sections/quienes-somos';
@import 'sections/programa-calificaciones-por-estrellas';
@import 'sections/responsabilidad-social-corporativa';
@import 'sections/empleos';
@import 'sections/programa-de-cuidadores';
@import 'sections/registro-de-cuidadores';
@import 'sections/centro-prensa';
