/* ------------------------------------------------------------ *\
        HOME
\* ------------------------------------------------------------ */



section{
    padding-top:5rem;
    padding-bottom:5rem;
    position:relative;

    .icon-top {
        position: absolute;
        top: 0;
        /* left: 5rem; */
        background: rgba(129, 156, 169, 0.2);
        padding: 1rem;
        z-index: 1000;

    }

    .icon-box{
        background: rgba(129, 156, 169, 0.2);
        padding: 1rem;
        display: inline-block;
    }


    .icon-top,.icon-box{
        &.green{
            background: #048086;
            color:white;
        }


        &.white{
            background:white;
            color:$color1;
        }

        &.green-dark{
            background: $color2;
            color:white;
        }
    }





    &.first-section {
        margin-top: 16.6rem;

        &.h100{
            min-height: calc(100vh -  16.6rem);
        }

        @include media-breakpoint-down(sm) { 
            margin-top: 0;
        }
    }

    &.h100{
        min-height: 100vh;
    }



    .img-bg{
        position: absolute;
        top: 0;
        width: 100%;

    }
    & [class*="img-bg"] {

        overflow:hidden;
    }
    
    p {
	/*color: #003F44;*/
	font-size: 1.8rem;
	font-weight: 400;
    }

}



/* Dispositivos Pequeños y Tablets vertical , Celulares horizontal min-width:576px  */
@include media-breakpoint-down(sm) { 
    section {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
}
/* Dispositivos Medianos , Tablets horizontal min-width:768px  */
@include media-breakpoint-up(md) { 

}
/* Dispositivos grandes , min-width:992px */
@include media-breakpoint-up(lg) { 

}
/* Escritorio , min-width:1200 px */
@include media-breakpoint-up(xl) { 

}

@media only screen and (min-width: 768px) and(max-width: 1200px){
    section.first-section{
        margin-top: 0rem;
    }
}