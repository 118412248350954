section.programa-mejoramiento-calidad-box-heart{
    padding-top: 0rem;
    .box-heart{
        position: absolute;
        top: 0rem;
        left: 1.5rem;
        background: url('../img/icons/box-heart.png');
        width: 65px;
        height: 57px;
    }
    .container{
        position: relative;
    }
}

section.programa-mejoramiento-calidad-info{
    .box-txt{
        margin-bottom: 3rem;
	&.border-t {
	    border-top: #94ABB6 dotted 1px;
	    padding-top: 3rem;
	}
        h2{
            color: #047F85;
            margin-bottom: 2rem;
            font-size: 3.4rem;
            font-weight: 500;
        }
        p{
            color: #003F44;
            font-size: 1.8rem;
            line-height: 140%;
	    font-weight: 400;
        }
        &.quote{
            padding: 5.5rem 3rem;
            display: flex;
            align-items: center;
            margin-bottom: 2rem;
            &:before{
                content: " ";
                background: url('../img/icons/quote-open.png');
                width: 50px;
                height: 40px;
		background-size: cover;
                position: absolute;
                top: 0;
                left: 1.5rem;
            }
            &:after{
                content: " ";
                background: url('../img/icons/quote-close.png');
                width: 50px;
                height: 40px;
		background-size: cover;
		position: absolute;
                bottom: 1rem;
                right: 1.5rem;
            }
            p{
                color: #047F85;
                font-style: italic;
                font-weight: 500;
                line-height: 2.2rem;
                font-size: 1.8rem;
                text-align: center;
            }

        }
    }
}

section.programa-mejoramiento-calidad-objetivos{
    background: #F4F7F8;
    h2{
        color: #047F85;
        margin-bottom: 3rem;
        font-size: 3.4rem;
        font-weight: 500;
    }
    ul{
        li{
            &:before{
                content: " ";
                border: 2px solid #048086;
                border-radius: 10rem;
                width: 10px;
                height: 10px;
                position: absolute;
                left: 0;
                top: 0.8rem;
            }
            list-style: none;
            position: relative;
            padding-left: 2rem;
            font-size: 1.8rem;
	    font-weight: 400;
            color: #333;
            line-height: 140%;
            margin-bottom: 1.5rem;
        }
    }
    p.c-green{
        line-height: 120%;
        color: #003F44;
    }
    .qip{
        margin-top: 6rem;
        .box-qip{
            position: relative;
            background: #FFF;
            padding: 3.5rem 0rem;
            display: flex;
            align-items: center;
            &:before{
                content: " ";
                position: absolute;
                left: 0;
                width: 87px;
                height: 100%;
                background: #048086;
                border-right: 4px solid #003F44;
            }
            &:after{
                content: " ";
                position: absolute;
                right: 0;
                width: 32px;
                height: 100%;
                background: #048086;
                border-left: 4px solid #003F44;
            }
            .happy-face{
                background: #FFF url('../img/icons/happy-face.png') no-repeat;
                background-size: 40px;
                background-position: center;
                border-radius: 10rem;
                position: absolute;
                left: 3.8rem;
                top: 2.25rem;
                width: 9.7rem;
                height: 9.7rem;
            }
            p{
                width: 80%;
                margin: 0px auto;
                font-size: 2rem;
		font-weight: 400;
                line-height: 120%;
                color: #003F44;
                position: relative;
                left: 4rem;
            }
        }
    }
}

section.programa-mejoramiento-calidad-responsabilidad{
    h3{
        font-size: 3.4rem;
        font-weight: 500;
        color: #047F85;
        margin-bottom: 1.5rem;
    }
    p{
        font-size: 1.8rem;
        line-height: 125%;
	font-weight: 400;
        color: #003F44;
    }
}

@include media-breakpoint-down(sm){
    section.programa-mejoramiento-calidad-info{
        padding-top: 6rem;
        .box-txt{
            &.quote{
                p{
                    font-size: 2rem;
                }
            }
            h2{
                font-size: 2.4rem;
            }
            p{
                font-size: 1.6rem;
            }
        }
    }

    section.programa-mejoramiento-calidad-objetivos{
        h2{
            font-size: 2.4rem;
        }
        p, ul li{
            font-size: 1.6rem;
        }
        .qip{
            margin-top: 3rem;
            .box-qip{
                justify-content: center;
                &:before{
                    content: " ";
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 87px;
                    background: #048086;
                    border-bottom: 4px solid #003F44;
                    border-right: none;
                }
                &:after{
                    content: " ";
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 32px;
                    background: #048086;
                    border-top: 4px solid #003F44;
                    border-left: none;
                }
                .happy-face{
                    left: auto;
                    top: 3.5rem;
                }
                p{
                    width: 100%;
                    margin: 0px auto;
                    font-size: 2rem;
                    line-height: 120%;
                    color: #003F44;
                    position: relative;
                    padding: 9rem 1.5rem 2rem 1.5rem;
                    top: 0rem;
                    left: 0rem;
                }
            }
        }
    }
    section.programa-mejoramiento-calidad-responsabilidad{
        padding: 3rem 0rem;
    }
}