/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 11-sep-2018, 17:51:01
    Author     : Rodri
*/

.quienes-somos {
    .tab-pane {
	border-left: #03576F solid 1px;
	padding: 3rem;
	
	p {
	    color: #02576E;
	}
    }
    .nav-tabs.years {
	justify-content: flex-end;
	border-left: #FFF solid 1px;
	margin-left: -1px;
	border-bottom: #03576F solid 1px;
	margin-bottom: 0;
	margin-top: -3.5rem;

	.nav-link {
	    border-top-color: transparent;
	    color: #03576F;
	    font-size: 1.4rem;
	    text-transform: uppercase;
	    font-weight: 600;
	    padding: 0 1.5rem;
	    line-height: 4rem;

	    &.active, &:hover {
		background: #03576F;
		color: #FFF;
	    }
	}
    }
    h1 {
	color: #003F44;
	font-size: 6rem;
	font-weight: 500;
	margin-bottom: 1.5rem;
    }
    h2 {
	color: #006A6C;
	font-size: 2.4rem;
	font-weight: 500;
	margin-bottom: 1.5rem;
    }
    p {
	color: #333;
	font-size: 1.8rem;
	font-weight: 400;
	line-height: 2.2rem;
    }
    .border-top {
	border-top: #94ABB6 dotted 1px;
	padding-top: 3rem;
	margin-top: 3rem;
    }
    .border-bottom {
	border-bottom: #94ABB6 dotted 1px;
	padding-bottom: 6rem;
	margin-bottom: 3rem;
    }
    .green {
	margin-top: 3rem;
	background: #137476;
	padding: 3rem;
	color: #FFF;
	
	h2 {
	    color: #FFF;
	}
	ul {
	    margin: 0 0 0 2rem;
	    padding: 0;
	    
	    li {
		margin-bottom: 0.5rem;
	    }
	}
    }
    .line {
	position: relative;
	padding: 2rem 5rem;
	background: url(../img/bg_line.jpg) center center no-repeat;
	background-size: cover;
	margin-bottom: 6rem;
	margin-top: 3rem;
	
	.swiper-button-next,
	.swiper-button-prev {
	    background: none;
	    width: 5rem;
	    height: 5rem;
	    margin-top: 0;
	    top: 4.7rem;
	}
	.swiper-button-next {
	    right: 0;
	}
	.swiper-button-prev {
	    left: 0;
	}
	
	.swiper-slide-active {
	    ul li {
		padding-top: 3.5rem;

		.detail {
		    display: block;
		}
		&:before {
		    width: 9rem;
		    height: 9rem;
		    top: 0.5rem;
		    margin-left: -4.5rem;
		    border-color: #EB992F;
		}
	    }
	}
	
	&:before {
	    content: '';
	    display: block;
	    position: absolute;
	    z-index: 0;
	    left: 5rem;
	    right: 5rem;
	    height: 1px;
	    top: 7.2rem;
	    background: #FFF;
	}
	
	ul {
	    min-height: 35rem;
	    position: relative;
	    list-style: none;
	    text-align: center;
	    padding: 0;
	    margin: 0;
	    
	    @include media-breakpoint-down(sm) {
		min-height: 44rem;
	    }
	    
	    li {
		transition: all .2s ease-out;
		display: inline-block;
		z-index: 1;
		margin: 0 4rem;
		padding-bottom: 4rem;
		cursor: pointer;
		vertical-align: top;
		position: relative;
		
		&:after {
		    content: '';
		    position: absolute;
		    width: 1px;
		    height: 2rem;
		    background: #FFF;
		    top: 3rem;
		    left: 50%;
		    z-index: 1;
		}
		&:before {
		    z-index: 2;
		    content: '';
		    position: absolute;
		    display: block;
		    width: 2.4rem;
		    height: 2.4rem;
		    background: #048086;
		    border: #FFF solid 4px;
		    border-radius: 100%;
		    top: 4rem;
		    left: 50%;
		    margin-left: -1.2rem;
		}
		.detail {
		    display: none;
		    position: absolute;
		    width: 35rem;
		    background: #048086;
		    color: #FFF;
		    padding: 2rem;
		    left: 50%;
		    margin-left: -17.5rem;
		    top: 13rem;
		    
		    &:before {
			content: '';
			width: 1px;
			height: 15rem;
			background: #048086;
			position: absolute;
			z-index: 0;
			top: -9rem;
			left: 50%;
		    }
		    p {
			/*margin: 0;*/
			color: #FFF;
			font-size: 1.6rem;
			font-weight: 400;
			font-style: italic;
		    }
		    
		    @include media-breakpoint-down(sm) {
			width: 26rem;
			margin-left: -13rem;
		    }
		}
		.year {
		    position: relative;
		    z-index: 3;
		    color: #FFF;
		    font-size: 2rem;
		    font-weight: 500;
		}
		
		&.active {
		    padding-top: 3.5rem;
		    
		    .detail {
			display: block;
		    }
		    &:before {
			width: 9rem;
			height: 9rem;
			top: 0.5rem;
			margin-left: -4.5rem;
			border-color: #EB992F;
		    }
		}
	    }
	}
    }
    
    @include media-breakpoint-down(sm) {
	padding-top: 3rem;
	
	h1 {
	    font-size: 3rem;
	}
	.nav-tabs.years {
	    margin-top: 2.5rem;
	}
    }
}